import { Utils } from '../Utils';
import { PdfBuilder, PdfTable } from './PdfBuilder';
import { DettaglioQuadratura } from '../../modules/main/model/quadratura/DettaglioQuadratura';
import { ExcelSheet, ExcelBuilder } from './ExcelBuilder';
export class StampaDettagliExcel {

  static async stampaDettagli(filename: string, quadrature: DettaglioQuadratura[]): Promise<void> {
    const commonHeaders = [
      "ID Flusso",
      "Data transazione",
      "IUV",
      "Pagatore (Cod. fiscale)",
      "Importo",
      "Stato",
      "Servizio",
      "Codice Tassonomia"
    ]

    const excel = new ExcelBuilder(filename)

    // Collezione degli headers aggiuntivi
    const headersCampi = {}
    const headersMetadati = {}

    // Esamino i dettagli delle transazioni recuperate
    quadrature.forEach(quadratura => {
      for (let t of quadratura.listaDettaglioTransazione) {
        if (t.servizioNome) {
          const sheetName = t.servizioNome.substring(0, 31) // Il nome del sheet viene troncato a 31 caratteri per specifiche delle libreria di xlsx

          // Controllo se nell'excel esiste già la sheet del nome servizio, altrimenti la aggiungo con gli headers comuni
          if (!excel.sheets[sheetName])
            excel.sheets[sheetName] = new ExcelSheet()

          // Crea riga per excel builder
          const row = {}
          row["ID Flusso"] = quadratura.flussoId
          row["Data transazione"] = t.dataTransazione
          row["IUV"] = t.iuv
          row["Pagatore (Cod. fiscale)"] = t.pagatoreCodiceFiscale
          row["Importo"] = t.importo
          row["Stato"] = t.stato
          row["Servizio"] = t.servizioNome
          row["Codice Tassonomia"] = t.codiceTassonomia

          // Analisi degli headers aggiuntivi
          if (t.campiPersonalizzati) { // Se i campi personalizzati sono null, il servizio è del modello 3
            if (!headersCampi[sheetName])
              headersCampi[sheetName] = new HeadersCampiPersonalizzati()

            for (let cp of t.campiPersonalizzati) {
              headersCampi[sheetName][cp.posizione] = cp.codice
              row[cp.codice] = cp.valore
            }
          } else {
            if (!headersMetadati[sheetName])
              headersMetadati[sheetName] = new HeadersMetadati()


            t.listaMetadatiPagamento.forEach((mdp) => {
              if (mdp.pagopa) {
                headersMetadati[sheetName].pagam_pagopa.add(mdp.chiave)
              } else {
                headersMetadati[sheetName].pagam.add(mdp.chiave)
              }

              row[mdp.chiave] = mdp.valore
            })
            t.listaMetadatiVersamento.forEach((mdv) => {
              if (mdv.pagopa) {
                headersMetadati[sheetName].pagopa.add(mdv.chiave)
              } else {
                headersMetadati[sheetName].none.add(mdv.chiave)
              }

              row[mdv.chiave] = mdv.valore
            })
          }

          // Aggiungo il dettaglio transazione come row nello sheet appropriato
          excel.sheets[sheetName].rows.push(row)
        }
      }
    })

    // Settiamo gli headers dei vari sheets del file excel, ognuno con i suoi headers aggiuntivi
    Object.keys(excel.sheets).forEach(sheetName => {
      let additionalHeaders = []

      if (headersCampi[sheetName]) {
        additionalHeaders = headersCampi[sheetName].toHeaders()
      } else {
        additionalHeaders = headersMetadati[sheetName].toHeaders()
      }
      excel.sheets[sheetName].headers = commonHeaders.concat(additionalHeaders)
    })

    excel.print()
  }
}

// Classi di supporto per ordinamento degli headers

class HeadersCampiPersonalizzati {
  [posizione: number]: string

  constructor() { }

  toHeaders() {
    const keys = Object.keys(this).sort()
    const headers = []

    keys.forEach(k => {
      headers.push(this[k])
    })

    return headers
  }
}

class HeadersMetadati {
  pagam_pagopa: Set<string>
  pagam: Set<string>
  pagopa: Set<string>
  none: Set<string>

  constructor() {
    this.pagam_pagopa = new Set<string>()
    this.pagam = new Set<string>()
    this.pagopa = new Set<string>()
    this.none = new Set<string>()
  }

  toHeaders() {
    return Array.from(this.pagam_pagopa).sort()
      .concat(Array.from(this.pagam).sort())
      .concat(Array.from(this.pagopa).sort())
      .concat(Array.from(this.none).sort())
  }

}
