import * as moment from "moment";

export class DateTimeFormat {
  static readonly DATE_CALENDAR = "DD/MM/YYYY";
  static readonly DATE_TIME_CALENDAR = "DD/MM/YYYY hh:mm:ss";

  static readonly LOCAL_DATE_TIME = "YYYY-MM-DD[T]00:00:00";
  static readonly LOCAL_DATE_TIME_ISO = "YYYY-MM-DD[T]HH:mm:ss";
  static readonly LOCAL_DATE_TIME_TO = "YYYY-MM-DD[T]23:59:59";
  static readonly DATE_NUMBER = "YYYYMMDD";

  static readonly ACCEPTED_FORMAT_DATES = [
    DateTimeFormat.DATE_CALENDAR,
    DateTimeFormat.DATE_TIME_CALENDAR,
    DateTimeFormat.LOCAL_DATE_TIME,
    DateTimeFormat.DATE_NUMBER,
    moment.ISO_8601,
  ];

  static readonly TIME_ZONE = "Europe/Rome (GMT+01:00)";
}

export function dayAfter(date: string): string {
  return moment(date, DateTimeFormat.DATE_CALENDAR)
    .add(1, "day")
    .format(DateTimeFormat.DATE_CALENDAR);
}

export function dayBefore(date: string): string {
  return moment(date, DateTimeFormat.DATE_CALENDAR)
    .subtract(1, "day")
    .format(DateTimeFormat.DATE_CALENDAR);
}

export function dateForFE(backendDate: string): string {
  return moment(backendDate, DateTimeFormat.LOCAL_DATE_TIME).format(
    DateTimeFormat.DATE_CALENDAR
  );
}

export function dateForBE(frontendDate: string): string {
  return moment(frontendDate, DateTimeFormat.DATE_CALENDAR).format(
    DateTimeFormat.LOCAL_DATE_TIME_ISO
  );
}
