import { ContoCorrente } from "../ente/ContoCorrente";
import { Ufficio } from "./Ufficio";

export class BeneficiarioServizio {
  id: number = null;
  enteId: number = null;
  societaId: number = null;
  livelloTerritorialeId: number = null;
  ufficio: Ufficio;
  listaContiCorrenti: ContoCorrente[];
}
