import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { FileDto } from "../modules/main/dto/File";
import { ParametriRicercaStatistiche } from "../modules/main/model/statistica/ParametriRicercaStatistiche";
import { Statistica } from "../modules/main/model/statistica/Statistica";
import { Utils } from "../utility/Utils";
import { BannerService } from "./banner.service";

@Injectable({
  providedIn: "root",
})
export class StatisticaService {
  private readonly gestisciStatisticheBasePath: string = `${environment.attivitaPianificateBaseUrl}/gestisciStatistiche`;
  private readonly statisticheUrl: string = `${this.gestisciStatisticheBasePath}/statistiche`;

  constructor(private http: HttpClient, private bannerService: BannerService) {}

  ricercaStatistiche(
    parametriRicercaStatistiche: ParametriRicercaStatistiche,
    idFunzione: string
  ): Observable<any[]> {
    // set headers
    let h: HttpHeaders = new HttpHeaders();
    h = h.append("idFunzione", idFunzione);
    // set params
    let params = new HttpParams();
    if (parametriRicercaStatistiche) {
      if (parametriRicercaStatistiche.attiva != null) {
        params = params.set(
          "attiva",
          String(parametriRicercaStatistiche.attiva)
        );
      }
      if (parametriRicercaStatistiche.avvioSchedulazione) {
        params = params.set(
          "avvioSchedulazione",
          parametriRicercaStatistiche.avvioSchedulazione
        );
      }
      if (parametriRicercaStatistiche.fineSchedulazione) {
        params = params.set(
          "fineSchedulazione",
          parametriRicercaStatistiche.fineSchedulazione
        );
      }
    }

    return this.http
      .get(this.statisticheUrl, {
        withCredentials: true,
        headers: h,
        params,
      })
      .pipe(
        map((body: any[]) => {
          return body;
        }),
        catchError((err, caught) => {
          if (err.status === 401 || err.status === 400) {
            return of(null);
          } else {
            return of(null);
          }
        })
      );
  }

  eliminaStatistiche(
    listaStatisticheId: Array<number>,
    idFunzione: string
  ): Observable<any> {
    const url: string = `${environment.attivitaPianificateBaseUrl}/eliminaStatistiche`;
    let h: HttpHeaders = new HttpHeaders();
    h = h.append("idFunzione", idFunzione);

    return this.http
      .post(url, listaStatisticheId, {
        withCredentials: true,
        headers: h,
      })
      .pipe(
        map((body: any) => {
          this.bannerService.bannerEvent.emit([
            Utils.bannerOperazioneSuccesso(),
          ]);
          return body;
        }),
        catchError((err, caught) => {
          if (err.status === 401 || err.status === 400) {
            return of(err);
          } else {
            return of(err);
          }
        })
      );
  }

  inserimentoStatistica(
    statistica: Statistica,
    idFunzione: string
  ): Observable<number> {
    let h: HttpHeaders = new HttpHeaders();
    h = h.append("idFunzione", idFunzione);

    return this.http
      .post(this.statisticheUrl, statistica, {
        withCredentials: true,
        headers: h,
      })
      .pipe(
        map((body: number) => {
          this.bannerService.bannerEvent.emit([
            Utils.bannerOperazioneSuccesso(),
          ]);
          return body;
        }),
        catchError((err, caught) => {
          if (err.status === 401 || err.status === 400) {
            return of(null);
          } else {
            return of(null);
          }
        })
      );
  }

  modificaStatistica(
    statistica: Statistica,
    idFunzione: string
  ): Observable<number> {
    const url: string = `${this.statisticheUrl}/${statistica.id}`;
    let h: HttpHeaders = new HttpHeaders();
    h = h.append("idFunzione", idFunzione);

    return this.http
      .put(url, statistica, {
        withCredentials: true,
        headers: h,
      })
      .pipe(
        map((body: number) => {
          this.bannerService.bannerEvent.emit([
            Utils.bannerOperazioneSuccesso(),
          ]);
          return body;
        }),
        catchError((err, caught) => {
          if (err.status === 401 || err.status === 400) {
            return of(err);
          } else {
            return of(err);
          }
        })
      );
  }

  dettaglioStatistica(
    idStatistica: number,
    idFunzione: string
  ): Observable<Statistica> {
    const url = `${this.statisticheUrl}/${idStatistica}`;
    let h: HttpHeaders = new HttpHeaders();
    h = h.append("idFunzione", idFunzione);

    return this.http
      .get(`${url}`, {
        withCredentials: true,
        headers: h,
      })
      .pipe(
        map((body: Statistica) => {
          return body;
        }),
        catchError((err, caught) => {
          if (err.status === 401 || err.status === 400) {
            return of(null);
          } else {
            return of(null);
          }
        })
      );
  }

  eseguiQuery(query: string, idFunzione: string): Observable<FileDto> {
    const url = `${this.gestisciStatisticheBasePath}/eseguiQuery`;
    let h: HttpHeaders = new HttpHeaders();
    h = h.append("idFunzione", idFunzione);

    return this.http
      .post<FileDto>(`${url}`, query, {
        withCredentials: true,
        headers: h,
      })
  }
}
