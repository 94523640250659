import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import {
  DEFAULT_PAGE_SIZE,
  TableDisplay,
} from "../table-footer/table-footer.component";

@Component({
  selector: "payer-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class PayerTableComponent implements OnInit {
  private _data: any[];

  @Input()
  set data(value: any[]) {
    this._data = value;
  }
  get data(): any[] {
    return this._data;
  }

  @Input()
  type: "simple" | "selectable" = "simple";

  @Input()
  selection: Set<any>;
  @Output()
  selectionChange: EventEmitter<Set<any>> = new EventEmitter<Set<any>>();

  headersTemplate: TemplateRef<any>;
  rowsTemplate: TemplateRef<any>;

  private _displayData: any[];
  get displayData(): any[] {
    return this._displayData;
  }

  private _sortField: string;
  get sortField(): string {
    return this._sortField;
  }

  private _sortDirection: 1 | -1;
  get sortDirection(): 1 | -1 {
    return this._sortDirection;
  }

  ngOnInit(): void {
    this._displayData = this.data.slice(0, DEFAULT_PAGE_SIZE);
    this.selection = new Set<any>();
  }

  updateDisplay(display: TableDisplay): void {
    this._displayData = this.data.slice(display.start, display.end);
  }

  onSelectAll(selected: boolean) {
    if (selected) {
      this.data.forEach((d) => this.selection.add(d));
    } else {
      this.selection.clear();
    }
    this.selectionChange.emit(this.selection);
  }

  onSelectRow(selected: boolean, row: any): void {
    if (selected) {
      this.selection.add(row);
    } else {
      this.selection.delete(row);
    }
    this.selectionChange.emit(this.selection);
  }

  requestSort(field: string): void {
    if (!this._sortField || this._sortField !== field) {
      this._sortField = field;
      this._sortDirection = 1;
    } else {
      if (this._sortDirection == 1) {
        this._sortDirection = -1;
      } else {
        this._sortField = null;
      }
    }

    //console.log(this._sortField, this._sortDirection);
  }
}
