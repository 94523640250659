import { ParametriRicercaServizio } from './../../../../model/servizio/ParametriRicercaServizio';
import { ConfiguraServizioService } from '../../../../../../services-old/configura-servizio.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OpzioneSelect } from '../../../../model/OpzioneSelect';
import { NuovoPagamentoService } from '../../../../../../services-old/nuovo-pagamento.service';
import { map } from 'rxjs/operators';
import { DatePickerComponent, ECalendarValue } from 'ng2-date-picker';
import { SocietaService } from '../../../../../../services-old/societa.service';
import { FunzioneService } from '../../../../../../services-old/funzione.service';
import { ParametriRicercaUtente } from '../../../../model/utente/ParametriRicercaUtente';
import { NgForm, NgModel } from '@angular/forms';
import { TipoCampoEnum } from '../../../../../../enums/tipoCampo.enum';
import { RicercaUtente } from '../../../../model/utente/RicercaUtente';
import { UtenteService } from '../../../../../../services-old/utente.service';
import * as moment from 'moment';
import { BottoneEnum } from '../../../../../../enums/bottone.enum';
import { OverlayService } from '../../../../../../services-old/overlay.service';
import { AmministrativoService } from "../../../../../../services-old/amministrativo.service";
import { FiltroGestioneElementiComponent } from "../../filtro-gestione-elementi.component";
import { Utils } from '../../../../../../utility/Utils';
import { ActivatedRoute } from "@angular/router";
import * as _ from 'lodash';

@Component({
  selector: 'app-filtro-gestione-utenti',
  templateUrl: './filtro-gestione-utenti.component.html',
  styleUrls: ['../gestisci-utenti.component.scss', './filtro-gestione-utenti.component.scss']
})
export class FiltroGestioneUtentiComponent extends FiltroGestioneElementiComponent implements OnInit {
  listaSocieta: Array<OpzioneSelect> = [];
  listaLivelliTerritoriali: Array<OpzioneSelect> = [];
  listaEnti: Array<OpzioneSelect> = [];
  listaServizi: Array<OpzioneSelect> = [];
  listaFunzioniAbilitate: Array<OpzioneSelect> = [];
  listaCodiciFiscali: string[] = [];
  listaCodiciFiscaliFiltrati: string[] = [];
  listaGruppiAbilitazione: Array<OpzioneSelect> = [];

  readonly minCharsToRetrieveCF = 1;

  isCalendarOpen = false;
  readonly minDateDDMMYYYY = '01/01/1900';
  readonly tipoData = ECalendarValue.Moment;

  filtroGestioneUtentiApplicato: ParametriRicercaUtente;

  @Input()
  filtroSocieta = null;

  @Output()
  onChangeFiltri: EventEmitter<ParametriRicercaUtente> = new EventEmitter<ParametriRicercaUtente>();

  constructor(private nuovoPagamentoService: NuovoPagamentoService, private societaService: SocietaService,
    private funzioneService: FunzioneService, private utenteService: UtenteService, private overlayService: OverlayService,
    protected amministrativoService: AmministrativoService, protected route: ActivatedRoute, private configuraServizioService: ConfiguraServizioService) {
    super(route, amministrativoService);
  }

  ngOnInit(): void {
    this.inizializzaFiltroGestioneUtenti();
    this.recuperaFiltroSocieta();
    this.recuperaFiltroLivelloTerritoriale();
    this.recuperaFiltroEnti();
    this.recuperaFiltroServizi(null);
    this.recuperaFiltroGruppiAbilitazione();
    this.letturaFunzioni();
  }

  inizializzaFiltroGestioneUtenti() {
    this.filtroGestioneUtentiApplicato = new ParametriRicercaUtente();
    if (this.listaSocieta.length == 1) {
      this.filtroGestioneUtentiApplicato.societaId = this.listaSocieta[0].value;
    } else {
      this.filtroGestioneUtentiApplicato.societaId = null;
    }
    this.filtroGestioneUtentiApplicato.servizioId = null;
    this.filtroGestioneUtentiApplicato.enteId = null;
    this.filtroGestioneUtentiApplicato.livelloTerritorialeId = null;
    this.filtroGestioneUtentiApplicato.funzioneId = null;
    this.filtroGestioneUtentiApplicato.gruppoAbilitazione = null;
  }

  recuperaFiltroSocieta(): void {
    let filtro = { visibile: true }
    this.societaService.ricercaSocieta(filtro, this.idFunzione).pipe(map(societa => {
      societa.forEach(s => {
        this.listaSocieta.push({
          value: s.id,
          label: s.nome
        });
      });

      this.listaSocieta = _.sortBy(this.listaSocieta, ['label']);

      if (this.filtroSocieta) {
        const isFiltroSocietaValido = this.listaSocieta.some(item => item.value === this.filtroSocieta);
        if (isFiltroSocietaValido) {
          this.filtroGestioneUtentiApplicato.societaId = this.filtroSocieta;
          const parametriRicercaUtente = new ParametriRicercaUtente();
          parametriRicercaUtente.societaId = this.filtroSocieta;
          this.onChangeFiltri.emit(parametriRicercaUtente);
        } else {
          window.open('/nonautorizzato', '_self');
        }
      }
      if (societa.length == 1)
        this.filtroGestioneUtentiApplicato.societaId = societa[0].id
    })).subscribe();
  }

  recuperaFiltroLivelloTerritoriale(societaId?: number): void {
    this.nuovoPagamentoService.recuperaFiltroLivelloTerritoriale(societaId, true).pipe(map(livelliTerritoriali => {
      this.listaLivelliTerritoriali = [];
      livelliTerritoriali.forEach(livello => {
        this.listaLivelliTerritoriali.push({
          value: livello.id,
          label: livello.nome
        });
      });
      this.listaLivelliTerritoriali = _.sortBy(this.listaLivelliTerritoriali, ['label']);
    })).subscribe();
  }

  recuperaFiltroEnti(idLivelloTerritoriale?: number, societaId?: number): void {
    this.nuovoPagamentoService.recuperaFiltroEnti(idLivelloTerritoriale, societaId, null, true).pipe(map(enti => {
      this.listaEnti = [];
      enti.forEach(ente => {
        this.listaEnti.push({
          value: ente.id,
          label: ente.nome
        });
      });
      this.listaEnti = _.sortBy(this.listaEnti, ['label']);
    })).subscribe();
  }

  recuperaFiltroServizi(idEnte): void {
    this.nuovoPagamentoService.recuperaFiltroServizi(idEnte, null, false).pipe(map(servizi => {
      this.listaServizi = [];
      servizi.forEach(servizio => {
        this.listaServizi.push({
          value: servizio.id,
          label: servizio.nome
        });
      });
      this.listaServizi = _.sortBy(this.listaServizi, ['label']);
    })).subscribe();
  }

  recuperaFiltroGruppiAbilitazione(): void {
    this.listaGruppiAbilitazione = [];
    this.listaGruppiAbilitazione.push({
      value: "amministratore",
      label: "Amministratore"
    });
    this.listaGruppiAbilitazione.push({
      value: "gestore",
      label: "Gestore"
    });
    this.listaGruppiAbilitazione.push({
      value: "nessuno",
      label: "Nessuno"
    });
  }

  letturaFunzioni(): void {
    this.funzioneService.letturaFunzioni().pipe(map(funzioniAbilitate => {
      funzioniAbilitate.forEach(funzione => {
        this.listaFunzioniAbilitate.push({
          value: funzione.id,
          label: funzione.descrizione
        });
      });
      this.listaFunzioniAbilitate = _.sortBy(this.listaFunzioniAbilitate, ['label']);
    })).subscribe();
  }

  setPlaceholder(campo: NgModel, tipo: string): string {
    if (this.isCampoInvalido(campo)) {
      return 'campo non valido';
    } else {
      if (TipoCampoEnum.SELECT === tipo) {
        return 'seleziona un elemento dalla lista';
      } else if (TipoCampoEnum.INPUT_TESTUALE === tipo) {
        return 'inserisci testo';
      } else {
        return 'inserisci data';
      }
    }
  }

  loadSuggestions(event): void {
    const inputCf = event.query;

    if (inputCf.length < this.minCharsToRetrieveCF) {
      this.listaCodiciFiscali = [];
      this.listaCodiciFiscaliFiltrati = [];
    } else if (inputCf.length === this.minCharsToRetrieveCF) {
      this.utenteService.letturaCodiciFiscali(inputCf, this.idFunzione).subscribe(data => {
        this.listaCodiciFiscali = data;
        this.listaCodiciFiscaliFiltrati = data;
      });
    } else {
      this.listaCodiciFiscaliFiltrati = this.listaCodiciFiscali.filter(cf => cf.toLowerCase().indexOf(inputCf.toLowerCase()) === 0);
    }
  }

  isCampoInvalido(campo: NgModel) {
    return campo?.errors;
  }

  openDatepicker(datePickerComponent: DatePickerComponent): void {
    datePickerComponent.api.open();
    this.isCalendarOpen = !this.isCalendarOpen;
  }

  pulisciFiltri(filtroGestioneUtentiForm: NgForm): void {
    this.inizializzaFiltroGestioneUtenti();
    filtroGestioneUtentiForm.resetForm(this.filtroGestioneUtentiApplicato);
    this.onChangeFiltri.emit(null);
  }

  cercaElementi(): void {
    const filtro = { ...this.filtroGestioneUtentiApplicato };

    for (const [key, value] of Object.entries(this.filtroGestioneUtentiApplicato)) {
      if (value !== undefined && value) {
        if (['dataScadenzaDa', 'ultimoAccessoDa'].includes(key)) {
          filtro[key] = moment(value).format(Utils.FORMAT_LOCAL_DATE_TIME);
        } else if (['dataScadenzaA', 'ultimoAccessoA'].includes(key)) {
          filtro[key] = moment(value).format(Utils.FORMAT_LOCAL_DATE_TIME_TO);
        } else {
          filtro[key] = value;
        }
      } else {
        filtro[key] = null;
      }
    }
    this.onChangeFiltri.emit(filtro);
  }

  disabilitaBottone(filtroGestioneUtentiForm: NgForm, nomeBottone: string): boolean {
    const isAtLeastOneFieldValued = Object.keys(filtroGestioneUtentiForm.value).some(key => filtroGestioneUtentiForm.value[key]);
    if (nomeBottone === BottoneEnum.PULISCI) {
      return !isAtLeastOneFieldValued;
    } else if (nomeBottone === BottoneEnum.CERCA) {
      return !filtroGestioneUtentiForm.valid;
    }
  }

  selezionaSocieta() {
    this.listaLivelliTerritoriali = [];
    this.listaEnti = [];
    this.recuperaFiltroLivelloTerritoriale(this.filtroGestioneUtentiApplicato.societaId);
    this.recuperaFiltroEnti(this.filtroGestioneUtentiApplicato.livelloTerritorialeId, this.filtroGestioneUtentiApplicato.societaId);
    this.filtroGestioneUtentiApplicato.livelloTerritorialeId = null;
    this.filtroGestioneUtentiApplicato.enteId = null;
    this.filtroGestioneUtentiApplicato.gruppoAbilitazione = null;
  }

  selezionaLivelloTerritoriale(): void {
    this.listaEnti = [];
    this.recuperaFiltroEnti(this.filtroGestioneUtentiApplicato.livelloTerritorialeId, this.filtroGestioneUtentiApplicato.societaId);
    this.filtroGestioneUtentiApplicato.enteId = null;
    this.filtroGestioneUtentiApplicato.gruppoAbilitazione = null;
    this.recuperaFiltroEnti(this.filtroGestioneUtentiApplicato?.livelloTerritorialeId);
  }

  selezionaEnte(): void {
    this.listaServizi = [];
    this.recuperaFiltroServizi(this.filtroGestioneUtentiApplicato?.enteId);
    this.filtroGestioneUtentiApplicato.servizioId = null;
    this.filtroGestioneUtentiApplicato.gruppoAbilitazione = null;
  }

  selezionaGruppoAbilitazione(): void {
    // Implementare eventuali comportamenti in caso di modifica del valore del campo.
  }
}
