import { ImmaginePdf } from '../../modules/main/model/tabella/ImmaginePdf';
import { FilePrinter } from './FilePrinter';
import autoTable, { CellHook } from 'jspdf-autotable';
import * as jsPDF from 'jspdf';
import 'src/assets/js/pdf-table-font.js'

interface CellAnnotation {
  title: string
  text: string
}

export class PdfTable {
  headers: string[]
  rows: any[]
  annotations: {
    [row: number]: {
      [col: number]: CellAnnotation
    }
  }
  images: ImmaginePdf[]

  constructor() {
    this.headers = []
    this.rows = []
    this.annotations = {}
    this.images = []
  }

  addCellAnnotation(row: number, column: number, title: string, text: string): void {
    if (!this.annotations[row])
      this.annotations[row] = {}

    this.annotations[row][column] = {
      title: title,
      text: text
    }
  }
}

export class PdfBuilder {
  filename: string
  tables: {
    [name: string]: PdfTable
  }

  constructor(filename: string) {
    this.filename = filename
    this.tables = {}
  }

  private static addImages(data: any, images: ImmaginePdf[], pdf: jsPDF.jsPDF) {
    images.forEach((image) => {
      if (data.section === 'body' && data.column.index === image.indiceColonna
        && (image.indiceRiga === null || data.row.index === image.indiceRiga)
        && data.row.raw[image.indiceColonna] != null) {
        const icona = new Image();
        icona.src = image.srcIcona;
        pdf.addImage(icona, 'PNG', data.cell.x + image.posizioneX, data.cell.y + image.posizioneY,
          image.larghezza, image.altezza);
      }
    })
  }

  private static addAnnotations(data: any, annotations: any, pdf: jsPDF.jsPDF) {
    if (data.section === 'body' && annotations[data.row.index]) {
      const ann = annotations[data.row.index][data.column.index]
      if (ann)
        pdf.createAnnotation({
          type: "text",
          bounds: {
            x: data.cell.x + 2,
            y: data.cell.y + 2,
            w: 50,
            h: 10
          },
          title: ann.title,
          contents: ann.text,
          open: false
        })
    }
  }

  print(): void {
    const pdf = new jsPDF.default('l', 'pt', 'a4')
    const tableKeys = Object.keys(this.tables).sort()

    tableKeys.forEach((tk, i, keys) => {
      const table = this.tables[tk]
      const body = []

      table.rows.forEach((r) => {
        const row = []

        table.headers.forEach((h) => {
          const cell = r[h]
          row.push(cell)
        })

        body.push(row)
      })

      autoTable(pdf, {
        head: [table.headers],
        body: body,
        didDrawCell: (data) => {
          PdfBuilder.addImages(data, table.images, pdf)
          PdfBuilder.addAnnotations(data, table.annotations, pdf)
        },
        styles: {
          font: 'pdf-table-font'
        }
      })

      if (i < keys.length - 1) {
        pdf.addPage('a4', 'l')
      }
    })

    FilePrinter.stampaPdf(pdf, this.filename)
  }
}
