<div class="wrapper">
  <app-overlay *ngIf="mostraModale" [isModaleGrande]="true">
    <ng-template>
      <ng-container *ngIf="datiPagamento">
        <app-nuovo-pagamento [filtroPagamento]="false" [datiPagamento]="datiPagamento"></app-nuovo-pagamento>
      </ng-container>
      <ng-container *ngIf="datiModaleTipoFlussoRendicontazione">
        <app-modale-flussi-rendicontazione
          [datiModaleTipoFlussoRendicontazione]="datiModaleTipoFlussoRendicontazione"></app-modale-flussi-rendicontazione>
        <ng-container *ngIf="idFunzione">
          <app-overlay [isModaleGrande]="false">
            <ng-template>
              <app-modale-aggiungi-tipo-campo [idFunzione]="idFunzione"></app-modale-aggiungi-tipo-campo>
            </ng-template>
          </app-overlay>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="datiModaleCampo">
        <app-modale-campo-form [datiModaleCampo]="datiModaleCampo"></app-modale-campo-form>
        <ng-container *ngIf="idFunzione">
          <app-overlay [isModaleGrande]="false">
            <ng-template>
              <app-modale-aggiungi-tipo-campo [idFunzione]="idFunzione"></app-modale-aggiungi-tipo-campo>
            </ng-template>
          </app-overlay>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="datiTipoPortaleEsterno">
        <div class="banner" [ngStyle]="{'top': '6vh'}">
          <app-banner></app-banner>
        </div>
        <app-modale-tipo-portale-esterno [datiTipoPortale]="datiTipoPortaleEsterno"></app-modale-tipo-portale-esterno>
      </ng-container>
    </ng-template>
  </app-overlay>
  <app-login-bar></app-login-bar>
  <app-sidebar></app-sidebar>
  <div class="header">
    <app-header></app-header>
  </div>
  <div class="main-panel font-color">
    <div class="content">
      <div class="banner" *ngIf="!datiTipoPortaleEsterno">
        <app-banner></app-banner>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>
