import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { tipoColonna } from '../../enums/TipoColonna.enum';
import { tipoTabella } from '../../enums/TipoTabella.enum';
import * as moment from 'moment';
// @ts-ignore
import sprite from '../../../assets/img/sprite.svg';
import { SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { Utils } from "../../utility/Utils";
import { tipoRiga } from "../../enums/TipoRiga.enum";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {

  // Proprietà base della tabella, inizializzati con la classe "Tabella"
  @Input() rows: any[];
  @Input() cols: any[];
  @Input() dataKey: any;
  @Input() tipoTabella: tipoTabella;

  // Proprietà per la modularità della tabella
  @Input() creaRigaTabella: any
  @Input() aggiornaTable: boolean;
  @Input() textLeft: string; // Messaggio in basso a sinistra con il conteggio dei risultati
  @Output() counts: EventEmitter<any> = new EventEmitter<any>();

  // Proprietà per la selezione delle singole righe della tabella
  @Input() selectedRows: boolean;
  @Input() selection: any[];
  @Output() onSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickIcon: EventEmitter<any> = new EventEmitter<any>();
  wasLinkOrIconSelected: boolean = false;

  // Proprietà per le tabelle lazy, che caricano una paginazione della backend
  @Input() isPaginataBE: boolean = false
  @Input() getRowsFromBE: any
  //@Output() onLazySelectAll : EventEmitter<boolean> = new EventEmitter<boolean>()

  // Proprietà ad hoc per la pagina "rendicontazione.component"
  @Input() isRendicontazioni: boolean = false
  @Output() statisticheRendicontazioni: EventEmitter<any> = new EventEmitter<any>();

  // Proprietà readonly con le varie enum relative alle tabelle
  readonly tipoColonnaEnum = tipoColonna;
  readonly tipoRigaEnum = tipoRiga;
  readonly tipoTabellaEnum = tipoTabella;

  // Proprietà di paginazione
  rowsPerPageOption: number[] = [5, 10, 20];
  numeroElementi = 5
  first = 0;
  pageSize = this.rowsPerPageOption[0];
  totalRecords = 0 // Numero totale delle righe recuperate, renderizzate e non

  sprite: string | SVGPathElement = sprite;

  @ViewChild("table", { static: false }) table: Table;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rows && !changes.rows.firstChange)
      this.table.reset();
    if (this.isPaginataBE == true && changes.aggiornaTable) {
      if (changes.aggiornaTable.currentValue != changes.aggiornaTable.previousValue && changes.aggiornaTable.firstChange == false)
        this.requestRowsFromBE()
    }
  }

  requestRowsFromBE(pagination?): void {
    if (this.isPaginataBE == true)
      this.getRowsFromBE(this.getPagination(pagination)).then(dataFunction => {
        dataFunction.subscribe(data => {
          let values = this.isRendicontazioni == true ? data.sintesiRendicontazioni : data
          let count = this.isRendicontazioni == true ? values.elementiTotali : data.count
          this.impostaTabella(values.elementi)
          this.totalRecords = values.elementiTotali
          this.counts.emit(count)
          if (this.isRendicontazioni == true)
            this.statisticheRendicontazioni.emit(data.statisticheEnte)
        })
      })
  }

  impostaTabella(listaElementi: any[]): void {
    this.rows = [];
    if (listaElementi) {
      listaElementi.forEach(elemento => {
        this.rows.push(this.creaRigaTabella(elemento));
      });
    }
  }

  getPagination(paginationParameter?) {
    let pagination = {}
    if (paginationParameter) {
      pagination["first"] = paginationParameter ? paginationParameter.first : 0
      paginationParameter.rows ? pagination["rows"] = paginationParameter.rows : ""
      paginationParameter.rows ? this.numeroElementi = paginationParameter.rows : ""
      paginationParameter.sortField ? pagination["sortField"] = paginationParameter.sortField : ""
      paginationParameter.sortOrder ? pagination["ascending"] = paginationParameter.sortOrder : ""
    } else {
      this.first = 0
      pagination["first"] = 0
      pagination["rows"] = this.numeroElementi
    }
    return pagination
  }

  onRowSelect(event) {
    this.updateSelection()
    this.onSelection.emit(this.selection);
  }

  onRowUnselect(event) {
    this.updateSelection()
    this.onSelection.emit(this.selection);
  }

  // onHeaderCheckboxToggle(event) {
  //   if (this.isPaginataBE) {
  //     this.onLazySelectAll.emit(event.checked)
  //   }
  //   this.onSelection.emit(this.selection)
  // }

  private updateSelection(): void {
    if (!this.isPaginataBE)
      this.selection = this.selection.filter(selection => this.rows.includes(selection));
  }

  onChangePageSize(event) {
    this.table.rows = parseInt(event);
    this.table.reset();
  }

  onLinkClick() {
    this.wasLinkOrIconSelected = true;
  }

  onIconClick(dataKey) {
    this.wasLinkOrIconSelected = true;
    this.onClickIcon.emit(dataKey);
  }

  setTotalRecords(): number {
    return this.rows.length;
  }

  onRowClick(row: any) {
    // Ignores the row click event if a link or icon event is launched
    if (!this.wasLinkOrIconSelected) {
      this.onClickRow.emit(row);
    }
    this.wasLinkOrIconSelected = false;
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field].value;
      let value2 = data2[event.field].value;
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (moment(value1, Utils.ACCEPTED_FORMAT_DATES).isValid() &&
        moment(value2, Utils.ACCEPTED_FORMAT_DATES).isValid()) {
        const value1Date = moment(value1, Utils.ACCEPTED_FORMAT_DATES);
        const value2Date = moment(value2, Utils.ACCEPTED_FORMAT_DATES);
        result = value1Date.isBefore(value2Date) ? -1 : value1Date.isAfter(value2Date) ? 1 : 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  isIconInSpriteFile(icona: string): boolean {
    return icona?.startsWith('#');
  }

}

