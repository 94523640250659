<div class="ml-5" *ngIf="!waiting">
  <!-- breadcrumb -->
  <it-breadcrumb [dark]="false" [separator]="'/'">
    <it-breadcrumb-item [id]="'breadcrumb-item-' + item.id" *ngFor="let item of breadcrumbList; let i = index"
                        >
      {{item.label}}
    </it-breadcrumb-item>
  </it-breadcrumb>

  <!-- titolo -->
  <h3 class="mb-2 page-title"><strong>Gestisci Utenti</strong>
    <span it-tooltip="{{tooltipGestisciUtentiTitle}}">
      <svg class="bg-light icon icon-lg icon-padded">
        <use xlink:href="assets/img/sprite.svg#it-help-circle"></use>
      </svg>
    </span>
  </h3>

  <!-- Sottosezione Filtri -->
  <app-filtro-gestione-utenti (onChangeFiltri)="onChangeFiltri($event)"
                              [filtroSocieta]="filtroSocieta"
  >
  </app-filtro-gestione-utenti>

  <!-- Sottosezione Lista Utenti -->
  <div class="collapse-header" id="idCollapseListaUtenti">
    <button data-toggle="collapse" data-target="#contenutoSezioneListaUtenti" aria-expanded="true"
            aria-controls="contenutoSezioneListaUtenti">
      Lista Utenti
    </button>
  </div>
  <div id="contenutoSezioneListaUtenti" class="collapse show" role="tabpanel" aria-labelledby="idCollapseListaUtenti">
    <div class="collapse-body">
      <div class="row align-items-baseline align-content-lg-around">
        <!-- tabs -->
        <div class="col-lg pb-2 pb-sm-2 pb-lg-0">
          <app-tab-view [tabs]="tabs" (onChange)="onChangeTab($event)"></app-tab-view>
        </div>
        <!-- azioni -->
        <div class="col-auto">
          <app-toolbar [toolbarIcons]="toolbarIcons" (onClick)="eseguiAzioni($event)"></app-toolbar>
        </div>
      </div>
      <!-- table -->
      <div class="row">
        <div class="col">
          <app-table [dataKey]="tableData.dataKey"
                     [cols]="tableData.cols"
                     [rows]="tableData.rows"
                     [tipoTabella]="tableData.tipoTabella"
                     [textLeft]="getNumeroRecord()"
                     (onSelection)="selezionaRigaTabella($event)"
                     [selectedRows]="true"
                     (onClickRow)="dettaglioUtente($event)"
                     [selection]="righeSelezionate">
          </app-table>
        </div>
      </div>
    </div>
  </div>
</div>


