  <div class="ml-3">
  <!--  inizio Header -->
  <div class="collapse-header" id="idCollapseContoCorrente">
  <button data-toggle="collapse" [id]="'buttonCCCollapse' + uuid" aria-expanded="false" aria-controls="contenutoSezioneDatiContoCorrente">
    <div class="row">
    <div class="col">
      Config. Conto Corrente
      <span class="title ml-3">[{{datiContoCorrente.iban}}]</span>
    </div>
    <!--    inizio Config. Conto Corrente -->
    <div class="col text-right">
        <span ngbTooltip="{{testoTooltipIconaElimina}}" placement="bottom" [ngStyle]="{fill: '#403d39'}">
        <svg *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" fill="var(--lepida-primary)" class="bg-light icon cursor-pointer" (click)="onClickDeleteIcon($event)">
          <use xlink:href="assets/img/sprite.svg#it-delete"></use>
        </svg>
        </span>
    </div>
    <!--    fine Elimina Config. Conto Corrente  -->
    </div>
  </button>
  </div>
  <!--  fine Header -->
  <div [id]="'collapseCC'+uuid" class="collapse" role="tabpanel" aria-labelledby="idCollapseContoCorrente">
    <form #datiContoCorrenteForm="ngForm">
    <!--    inizio Iban, Intestazione -->
    <div class="row mt-4 mb-0 ml-4">
      <!--      inizio Iban -->
      <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
        <label for="iban" class="title"
              [ngClass]="{'required': requiredCampi()}">
          Iban</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
        <input type="text" id="iban" name="iban" #iban="ngModel"
              [(ngModel)]="datiContoCorrente.iban"
              class="pt-0" autocomplete="off" aria-describedby="ibanHelpDescription"
              [pattern]="ibanRegex"
              [disabled]="campiDisabiitati(iban)"
              [required]="requiredCampi()"
              (ngModelChange)="onChangeModel(datiContoCorrenteForm, iban)">
        </div>
      </div>
      <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
        <div class="col">
        <p *ngIf="!isCampoInvalido(iban)">Inserisci iban</p>
        <p *ngIf="isCampoInvalido(iban)" class="text-invalid">{{getMessaggioErrore(iban)}}</p>
        </div>
      </div>
      </div>
      <!--      fine Iban -->
      <!--      inizio Intestazione -->
      <div class="col-md-8 pb-2">
      <div class="row">
        <div class="col">
        <label for="intestazione" class="title ">
          Intestazione</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
        <input type="text" id="intestazione" name="intestazione" #intestazione="ngModel"
              [(ngModel)]="datiContoCorrente.intestazione"
              class="pt-0" autocomplete="off" aria-describedby="intestazioneHelpDescription"
              [disabled]="disabilitaCampi()"
              (ngModelChange)="onChangeModel(datiContoCorrenteForm, intestazione)">
        </div>
      </div>
      <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
        <div class="col">
        <p *ngIf="!isCampoInvalido(intestazione)">Inserisci intestazione</p>
        <p *ngIf="isCampoInvalido(intestazione)" class="text-invalid">{{getMessaggioErrore(intestazione)}}</p>
        </div>
      </div>
      </div>
      <!--      fine Intestazione -->
    </div>
    <!--    fine Iban, Intestazione -->
    <!--    inizio IbanCCPostale, IntestazioneCCPostale -->
    <div class="row mt-4 mb-0 ml-4">
      <!--      inizio IbanCC -->
      <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
        <label for="ibanCCPostale" class="title">
          Iban cc postale</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
        <input type="text" id="ibanCCPostale" name="ibanCCPostale" #ibanCCPostale="ngModel"
              [(ngModel)]="datiContoCorrente.ibanCCPostale"
              [pattern]="ibanRegex"
              class="pt-0" autocomplete="off" aria-describedby="ibanCCPostaleHelpDescription"
              [disabled]="campiDisabiitati(ibanCCPostale)"
              (ngModelChange)="onChangeModel(datiContoCorrenteForm, ibanCCPostale)">
        </div>
      </div>
      <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
        <div class="col">
        <p *ngIf="!isCampoInvalido(ibanCCPostale)">Inserisci iban cc postale</p>
        <p *ngIf="isCampoInvalido(ibanCCPostale)" class="text-invalid">{{getMessaggioErrore(ibanCCPostale)}}</p>
        </div>
      </div>
      </div>
      <!--      fine IbanCC -->
      <!--      inizio IntestazioneCC -->
      <div class="col-md-8 pb-2">
      <div class="row">
        <div class="col">
        <label for="intestazioneCCPostale" class="title ">
          Intestazione cc postale</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
        <input type="text" id="intestazioneCCPostale" name="intestazioneCCPostale"
              #intestazioneCCPostale="ngModel"
              [(ngModel)]="datiContoCorrente.intestazioneCCPostale"
              class="pt-0" autocomplete="off" aria-describedby="intestazioneCCPostaleHelpDescription"
              [disabled]="disabilitaCampi()"
              (ngModelChange)="onChangeModel(datiContoCorrenteForm, intestazioneCCPostale)">
        </div>
      </div>
      <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
        <div class="col">
        <p *ngIf="!isCampoInvalido(intestazioneCCPostale)">Inserisci intestazione cc postale</p>
        <p *ngIf="isCampoInvalido(intestazioneCCPostale)"
            class="text-invalid">{{getMessaggioErrore(intestazioneCCPostale)}}</p>
        </div>
      </div>
      </div>
      <!--      fine IntestazioneCC -->
    </div>
    <!--    fine IbanCCPostale, IntestazioneCCPostale -->
    <div class="row mt-2">
      <div class="col ml-4">
      <div class="collapse-header" id="idCollapseflussoPA">
        <button data-toggle="collapse" data-target="#flussoPA" aria-expanded="true"
            aria-controls="flussoPA">
        Invio flusso di quadratura
        </button>
      </div>
      <div id="flussoPA" class="collapse show" role="tabpanel" aria-labelledby="idCollapseflussoPA">
        <div class="collapse-body">
        <div class="row mt-2 mb-0">
          <div class="col-sm-6 col-md-4 col-lg-3 pb-2 pr-3 mr-3">
          <div class="row">
            <div class="col">
            <label for="emailFlussoPA" class="title">Email</label>
            </div>
            <div class="col-2">
            <it-toggle id="emailFlussoPA" (ngModelChange)="changeEmailFlussoPagoPA(datiContoCorrenteForm, $event)"
                    [disabled]="disabilitaCampi()"
                    [(ngModel)]="flussoRiversamentoPagoPA.flagNotificaEmail" name="emailFlussoPA"></it-toggle>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
            <input type="text" autocomplete="off"
                  [placeholder]="getPlaceholderRequired('Email', flussoRiversamentoPagoPA.flagNotificaEmail)"
                  [required]="flussoRiversamentoPagoPA.flagNotificaEmail && !inputDisabled"
                  id="email" [customvalidator]="validateEmails()"
                  name="email" [(ngModel)]="flussoRiversamentoPagoPA.email"
                  #email="ngModel"
                  (ngModelChange)="onChangeModelFlusso(datiContoCorrenteForm, email)"
                  [disabled]="!flussoRiversamentoPagoPA.flagNotificaEmail || disabilitaCampi() ? true : null">
            </div>
            <div class="col-12">
            <div class="row">
              <div class="col">
              <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
                  [ngClass]="{'text-muted': !isCampoInvalido(email),
                'text-invalid': isCampoInvalido(email)}">
                {{setPlaceholder(email, TipoCampoEnum.INPUT_TESTUALE_EMAILS)}}
              </p>
              </div>
            </div>
            </div>
            <div class="col-12">
            <input type="text" autocomplete="off" placeholder="Ccn" id="ccn"
                  name="ccn" [(ngModel)]="flussoRiversamentoPagoPA.ccn"
                  #ccn="ngModel" [customvalidator]="validateEmails()"
                  (ngModelChange)="onChangeModelFlusso(datiContoCorrenteForm, ccn)"
                  [disabled]="!flussoRiversamentoPagoPA.flagNotificaEmail || disabilitaCampi() ? true : null">
            </div>
            <div class="col-12">
            <div class="row">
              <div class="col">
              <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
                  [ngClass]="{'text-muted': !isCampoInvalido(ccn),
                'text-invalid': isCampoInvalido(ccn)}">
                {{setPlaceholder(ccn, TipoCampoEnum.INPUT_TESTUALE_EMAILS)}}
              </p>
              </div>
            </div>
            </div>
          </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3 pb-2 pr-3 mr-3">
          <div class="row">
            <div class="col">
            <label for="ftpFlussoPA"
                  [ngClass]="{'required': flussoRiversamentoPagoPA.flagNotificaFtp && !inputDisabled, 'title':true}">Ftp</label>
            </div>
            <div class="col-2">
            <it-toggle id="ftpFlussoPA" (ngModelChange)="changeFtpFlussoPagoPA(datiContoCorrenteForm, $event)"
                    [disabled]="disabilitaCampi()"
                    [(ngModel)]="flussoRiversamentoPagoPA.flagNotificaFtp" name="ftpFlussoPA"></it-toggle>
            </div>
          </div>
          <div class="row">

            <div class="col-12">
            <input type="text" autocomplete="off"
                  (ngModelChange)="onChangeModelFlusso(datiContoCorrenteForm, server)"
                  [required]="flussoRiversamentoPagoPA.flagNotificaFtp && !inputDisabled"
                  [placeholder]="getPlaceholderRequired('Server (nome o IP)', flussoRiversamentoPagoPA.flagNotificaFtp)"
                  id="server" name="server" [customvalidator]="validateServer()"
                  [ngModel]="flussoRiversamentoPagoPA.server" #server="ngModel"
                  [disabled]="!flussoRiversamentoPagoPA.flagNotificaFtp || disabilitaCampi() ? true : null">
            </div>
            <div class="col-12">
            <div class="row">
              <div class="col">
              <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
                  [ngClass]="{'text-muted': !isCampoInvalido(server),
                'text-invalid': isCampoInvalido(server)}">
                {{setPlaceholder(server, TipoCampoEnum.INPUT_TESTUALE)}}
              </p>
              </div>
            </div>
            </div>

            <div class="col-12">
            <input type="text" autocomplete="off"
                  (ngModelChange)="onChangeModelFlusso(datiContoCorrenteForm, username)"
                  [required]="flussoRiversamentoPagoPA.flagNotificaFtp && !inputDisabled"
                  [placeholder]="getPlaceholderRequired('Username', flussoRiversamentoPagoPA.flagNotificaFtp)"
                  id="username" name="username"
                  #username="ngModel"
                  [ngModel]="flussoRiversamentoPagoPA.username"
                  [disabled]="!flussoRiversamentoPagoPA.flagNotificaFtp || disabilitaCampi() ? true : null">
            </div>
            <div class="col-12">
            <div class="row">
              <div class="col">
              <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
                  [ngClass]="{'text-muted': !isCampoInvalido(username),
                'text-invalid': isCampoInvalido(username)}">
                {{setPlaceholder(username, TipoCampoEnum.INPUT_TESTUALE)}}
              </p>
              </div>
            </div>
            </div>
            <div class="col-12">
            <input type="text" autocomplete="off"
                  (ngModelChange)="onChangeModelFlusso(datiContoCorrenteForm, password)"
                  [required]="flussoRiversamentoPagoPA.flagNotificaFtp && !inputDisabled"
                  [placeholder]="getPlaceholderRequired('Password', flussoRiversamentoPagoPA.flagNotificaFtp)"
                  id="password"
                  name="password" #password="ngModel"
                  [ngModel]="flussoRiversamentoPagoPA.password"
                  [disabled]="!flussoRiversamentoPagoPA.flagNotificaFtp || disabilitaCampi() ? true : null">
            </div>
            <div class="col-12">
            <div class="row">
              <div class="col">
              <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
                  [ngClass]="{'text-muted': !isCampoInvalido(password),
                'text-invalid': isCampoInvalido(password)}">
                {{setPlaceholder(password, TipoCampoEnum.INPUT_TESTUALE)}}
              </p>
              </div>
            </div>
            </div>
            <div class="col-12">
            <input type="text" autocomplete="off" id="directory"
                  (ngModelChange)="onChangeModelFlusso(datiContoCorrenteForm, directory)"
                  [required]="flussoRiversamentoPagoPA.flagNotificaFtp"
                  [placeholder]="getPlaceholderRequired('Directory', flussoRiversamentoPagoPA.flagNotificaFtp)"
                  name="directory" #directory="ngModel"
                  [ngModel]="flussoRiversamentoPagoPA.directory"
                  [disabled]="!flussoRiversamentoPagoPA.flagNotificaFtp || disabilitaCampi() ? true : null">
            </div>
            <div class="col-12">
            <div class="row">
              <div class="col">
              <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
                  [ngClass]="{'text-muted': !isCampoInvalido(directory),
                'text-invalid': isCampoInvalido(directory)}">
                {{setPlaceholder(directory, TipoCampoEnum.INPUT_TESTUALE)}}
              </p>
              </div>
            </div>
            </div>
          </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
    </form>
  </div>
</div>
