<nav class="navbar sticky-top navbar-line navbar-light bg-light bg-force">
  <div class="container-fluid">
    <div class="row align-items-center w-100 navbar-wrapper">
      <div class="col-1 pl-2" [ngClass]="{'navbar-toggle': !isL1}">
        <a class="navbar-toggler black">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
          <span class="font-menu-sidebar">MENU</span>
        </a>
      </div>
      <div [ngClass]="{'offset-1': !isSticky, 'offset-0': isSticky}"
           class="offset-sm-0 offset-md-0 offset-lg-0 col-7 pl-4 pl-sm-0 pl-md-0 pl-lg-0 col-sm-8 col-md-8 col-lg-7 p-lg-0 p-md-0 p-sm-0 navbar-brand">
        <a id="home-link" (click)="ritornaAHomePage()">
          <img class="h-60px" alt="payer" src="assets/img/payer.png">
        </a>
      </div>
      <div class="offset-1 col-2 pl-0 offset-lg-2 text-right">
        <button *ngIf="isSticky" [disabled]="isL1" type="button" [ngClass]="{'btn-sm': isSticky}"
                class="btn btn-outline-secondary"
                (click)="getLoginLink()">{{testoAccedi}}</button>
      </div>
    </div>

    <div class="row align-items-center w-100 navbar-wrapper">
      <div class="offset-10 col-1" [ngClass]="{'hide': !(isPaginaNuovoPagamento || isPaginaImieiPagamenti)}">
        <div class="row">
          <div id="cart-background" (click)="goToCarrello()" class="rounded-circle">
            <svg id="cart" viewBox="0 0 16 16" class="bi bi-cart3" xmlns="http://www.w3.org/2000/svg">
              <path d="
                      M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5z
                      M5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z
              "/>
            </svg>
          </div>
        </div>
        <div class="row">
          <div id="cart-price-container">
            <div id="cart-price" class="rounded-pill"
                 [ngClass]="{'hide': prezzoCarrello == null || prezzoCarrello == 0}">
              {{prezzoCarrello |currency:"EUR":"" | replace:'.':'|' | replace:',':'.'| replace:'|':','}}€
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
