<div class="page-section">
  <div class="row">

    <div class="col-md-4">
      <div class="form-elem-container">
        <div class="row">
          <label [ngClass]="{'disabled': datiPagamento}" for="selectLivelloTerritoriale" class="title">Livello territoriale</label>
        </div>
        <div class="row">
          <select [disabled]="datiPagamento || this.servizioId" id="selectLivelloTerritoriale"
                  [(ngModel)]="livelloTerritorialeSelezionato" (ngModelChange)="selezionaLivelloTerritoriale()">
            <option *ngFor="let item of listaLivelliTerritoriali" [ngValue]="item.value">{{item.label}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-elem-container">
        <div class="row">
          <label [ngClass]="{'disabled': datiPagamento}" for="selectEnte" class="title">Ente</label>
        </div>
        <div class="row">
          <select [disabled]="livelloTerritorialeSelezionato == null || !listaEnti.length || datiPagamento || this.servizioId" id="selectEnte"
                  [(ngModel)]="enteSelezionato" (ngModelChange)="selezionaEnte()">
            <option *ngFor="let item of listaEnti" [ngValue]="item.value">{{item.label}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-elem-container">
        <div class="row">
          <label [ngClass]="{'disabled': datiPagamento}" for="selectServizio" class="title">Servizio</label>
        </div>
        <div class="row">
          <select [disabled]="enteSelezionato == null || !listaServizi.length || datiPagamento || this.servizioId" id="selectServizio"
                  [(ngModel)]="servizioSelezionato" (ngModelChange)="selezionaServizio()">
            <option *ngFor="let item of listaServizi" [ngValue]="item.value">{{item.label}}</option>
          </select>
        </div>
      </div>
    </div>

  </div>
</div>
