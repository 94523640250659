import { map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AmministrativoService } from 'src/app/services-old/amministrativo.service';
import { AutorizzazioneAuthGuard } from './autorizzazione.authguard';

@Injectable({
  providedIn: 'root'
})
export class AmministratoreAuthGuard extends AutorizzazioneAuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private amministrativoService: AmministrativoService,
    private router: Router
  ) { super(); }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.amministrativoService.asyncAmministrativoSubject
      .pipe(map(
        isAmministratore => {
          if (!isAmministratore) {
            console.warn('Utente non amministratore.');
            return this.router.parseUrl(this.URL_NON_AUTORIZZATO);
          }

          return true;
        }
      ));
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}
