import {
  AfterContentInit,
  Component,
  Injector,
  Input,
  OnInit,
  Optional,
  Self,
  forwardRef,
  inject,
} from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
} from "@angular/forms";
import { cons } from "fp-ts/lib/ReadonlyNonEmptyArray";
import {
  ECalendarValue,
  IDatePickerConfig,
  IDatePickerDirectiveConfig,
} from "ng2-date-picker";
import { NOOP_VALUE_ACCESSOR } from "src/app/utility/reactive-forms.utility";
import { PayerIcons } from "src/app/utility/constants/page.constant";
import { DateTimeFormat } from "src/app/utility/datetime.utility";
import * as moment from "moment";

@Component({
  selector: "payer-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
})
export class DatePickerComponent {
  readonly icon: typeof PayerIcons = PayerIcons;

  @Input()
  id: string;

  @Input()
  mode: string = "day";

  @Input()
  minDate!: string;

  @Input()
  maxDate!: string;

  get config(): IDatePickerDirectiveConfig {
    return {
      format: DateTimeFormat.DATE_CALENDAR,
      locale: "it",
      min: this.minDate
        ? this.minDate
        : moment().format(DateTimeFormat.DATE_CALENDAR),
      max: this.maxDate,
    };
  }

  get control(): AbstractControl {
    return this.ngControl.control;
  }

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
    }
  }
}
