import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

interface Breadcrumb {
  label: string;
  link: string;
  active: boolean;
}

@Component({
  selector: 'payer-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbs: Breadcrumb[] = [];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.breadcrumbs.push({
      label: 'Home',
      link: '',
      active: false
    })

    this.route.snapshot.pathFromRoot.forEach((p, i, route) => {
      if (p.data.breadcrumb) {
        const bc = p.data.breadcrumb;
        this.breadcrumbs.push({
          label: bc.name,
          link: this.breadcrumbs[i - 1].link +  (String(p.url).length > 0) ? '/' + p.url : '',
          active: i == route.length - 1
        })
      }
    })
  }

}
