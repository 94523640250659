import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './tester.page.html',
  styleUrls: ['./tester.page.scss']
})
export class TesterPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
