import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserIdleService } from "angular-user-idle";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { TipoPortaleEsterno } from "src/app/modules/main/model/configuraportaliesterni/portale-esterno.model";
import { DatiModaleTipoFlussoRendicontazione } from "./modules/main/components/amministrativo/gestisci-flussi-rendicontazione/modale-flussi-rendicontazione/modale-flussi-rendicontazione.component";
import { DatiModaleCampo } from "./modules/main/components/amministrativo/gestisci-tipologia-servizio/modale-campo-form/modale-campo-form.component";
import { DatiPagamento } from "./modules/main/model/bollettino/DatiPagamento";
import { AuthguardService } from "./services-old/authguard.service";
import { MenuService } from "./services-old/menu.service";
import { OverlayService } from "./services-old/overlay.service";
import { ToponomasticaService } from "./services-old/toponomastica.service";
import { configUserIdle } from "src/environments/environment";
import { NgSelectConfig } from "@ng-select/ng-select";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "";
  mostraModale = false;
  datiPagamento: DatiPagamento = null;
  datiModaleCampo: DatiModaleCampo = null;
  datiTipoPortaleEsterno: TipoPortaleEsterno = null;
  datiModaleTipoFlussoRendicontazione: DatiModaleTipoFlussoRendicontazione =
    null;
  idFunzione: string;

  private readonly THRS: number = configUserIdle.timeout - 30;

  constructor(
    private menuService: MenuService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authGuardService: AuthguardService,
    private idleService: UserIdleService,
    private toponomasticaService: ToponomasticaService,
    private overlayService: OverlayService,
    private ngSelectConfig: NgSelectConfig
  ) {
    this.ngSelectConfig.notFoundText = 'Nessun elemento trovato.';
    this.ngSelectConfig.bindValue = 'value';
    this.ngSelectConfig.loadingText = 'Caricamento...';
  }

  ngOnInit(): void {
    this.idleService.onTimerStart().subscribe((count) => {
      if (count == this.THRS) console.log("ATTENZIONE: 30 secondi al logout.");
    });
    this.idleService.onTimeout().subscribe(() => {
      this.logout();
    });

    this.overlayService.mostraModaleDettaglioPagamentoEvent.subscribe(
      (datiPagamento) => {
        this.datiPagamento = datiPagamento;
        this.mostraModale = datiPagamento != null;
        this.cdr.detectChanges();
      }
    );

    this.overlayService.mostraModaleCampoEvent.subscribe((datiModaleCampo) => {
      this.datiModaleCampo = datiModaleCampo;
      this.mostraModale = datiModaleCampo != null;
      this.cdr.detectChanges();
    });

    this.overlayService.mostraModaleTipoCampoEvent.subscribe((idFunzione) => {
      this.idFunzione = idFunzione;
      // Non imposto mostraModale false se idFunzione null in quando mostraModale gestisce la comparsa delle modali di primo livello (non innestate)
      this.cdr.detectChanges();
    });

    this.overlayService.mostraModaleAggiungiTipoCampoEvent.subscribe(
      (idFunzione) => {
        this.idFunzione = idFunzione;
        // Non imposto mostraModale false se idFunzione null in quando mostraModale gestisce la comparsa delle modali di primo livello (non innestate)
        this.cdr.detectChanges();
      }
    );

    this.overlayService.mostraModaleTipoPortaleEsternoEvent.subscribe(
      (datiTipoPortaleEsterno) => {
        this.datiTipoPortaleEsterno = datiTipoPortaleEsterno;
        this.mostraModale = datiTipoPortaleEsterno != null;
        this.cdr.detectChanges();
      }
    );

    this.overlayService.mostraModaleTipoFlussoRendicontazione.subscribe(
      (datiModaleTipoFlussoRendicontazione) => {
        this.datiModaleTipoFlussoRendicontazione =
          datiModaleTipoFlussoRendicontazione;
        this.mostraModale = datiModaleTipoFlussoRendicontazione != null;
        this.cdr.detectChanges();
      }
    );

    this.menuService.getInfoUtente().subscribe(
      (info) => {
        this.menuService.infoUtenteEmitter.emit(info);
        if (!this.menuService.isUtenteAnonimo) {
          this.idleService.startWatching();
        }
      },
      catchError((err, caught) => of(null))
    );
  }

  // @HostListener('window:beforeunload')
  logout() {
    console.log("Request logout.");
    this.authGuardService.logout().subscribe((url) => {
      this.idleService.stopWatching();
      this.idleService.stopTimer();
      //if (url) {
      window.location.href = "/";
      //}
    });
  }
}
