import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { environment } from "src/environments/environment";
import { ErrorePage } from "./core/errors/pagina-errore/errore.page";
import { PrivacyPage } from "./core/pages/privacy/privacy.page";
import { routesGestisciPortale } from "./features/gestisci-portale/gestisci-portale.module";
import { FormEnteComponent } from "./modules/main/components/amministrativo/anagrafiche/gestisci-enti/form-ente/form-ente.component";
import { GestisciEntiComponent } from "./modules/main/components/amministrativo/anagrafiche/gestisci-enti/gestisci-enti.component";
import { FormLivelloTerritorialeComponent } from "./modules/main/components/amministrativo/anagrafiche/gestisci-livelli-territoriali/form-livello-territoriale/form-livello-territoriale.component";
import { GestisciLivelliTerritorialiComponent } from "./modules/main/components/amministrativo/anagrafiche/gestisci-livelli-territoriali/gestisci-livelli-territoriali.component";
import { FormSocietaComponent } from "./modules/main/components/amministrativo/anagrafiche/gestisci-societa/form-societa/form-societa.component";
import { GestisciSocietaComponent } from "./modules/main/components/amministrativo/anagrafiche/gestisci-societa/gestisci-societa.component";
import { FormRaggruppamentoTipologieComponent } from "./modules/main/components/amministrativo/anagrafiche/raggruppamento-tipologie/form-raggruppamento-tipologie/form-raggruppamento-tipologie.component";
import { RaggruppamentoTipologieComponent } from "./modules/main/components/amministrativo/anagrafiche/raggruppamento-tipologie/raggruppamento-tipologie.component";
import { FormAttivitaPianificateComponent } from "./modules/main/components/amministrativo/gestisci-attivita-pianificate/form-attivita-pianificate/form-attivita-pianificate.component";
import { GestisciAttivitaPianificateComponent } from "./modules/main/components/amministrativo/gestisci-attivita-pianificate/gestisci-attivita-pianificate.component";
import { FormBannerComponent } from "./modules/main/components/amministrativo/gestisci-banner/form-banner/form-banner.component";
import { GestisciBannerComponent } from "./modules/main/components/amministrativo/gestisci-banner/gestisci-banner.component";
import { FormFlussiRendicontazioneComponent } from "./modules/main/components/amministrativo/gestisci-flussi-rendicontazione/form-flussi-rendicontazione/form-flussi-rendicontazione.component";
import { GestisciFlussiRendicontazioneComponent } from "./modules/main/components/amministrativo/gestisci-flussi-rendicontazione/gestisci-flussi-rendicontazione.component";
import { FormServizioComponent } from "./modules/main/components/amministrativo/gestisci-servizi/form-servizio/form-servizio.component";
import { GestisciServiziComponent } from "./modules/main/components/amministrativo/gestisci-servizi/gestisci-servizi.component";
import { FormStatisticaComponent } from "./modules/main/components/amministrativo/gestisci-statistiche/form-statistica/form-statistica.component";
import { GestisciStatisticheComponent } from "./modules/main/components/amministrativo/gestisci-statistiche/gestisci-statistiche.component";
import { FormTipologiaServizioComponent } from "./modules/main/components/amministrativo/gestisci-tipologia-servizio/form-tipologia-servizio/form-tipologia-servizio.component";
import { GestisciTipologiaServizioComponent } from "./modules/main/components/amministrativo/gestisci-tipologia-servizio/gestisci-tipologia-servizio.component";
import { IuvSenzaBonificoComponent } from "./modules/main/components/amministrativo/gestisciportale/iuv-senza-bonifico/iuv-senza-bonifico.component";
import { DettaglioEsitoNotificaComponent } from "./modules/main/components/amministrativo/gestisciportale/monitoraggio-transazioni/dettaglio-transazione/dettaglio-esito-notifica/dettaglio-esito-notifica.component";
import { DettaglioPendenzaComponent } from "./modules/main/components/amministrativo/gestisciportale/monitoraggio-transazioni/dettaglio-transazione/dettaglio-pendenza/dettaglio-pendenza.component";
import { DettaglioTransazioneComponent } from "./modules/main/components/amministrativo/gestisciportale/monitoraggio-transazioni/dettaglio-transazione/dettaglio-transazione.component";
import { MonitoraggioTransazioniComponent } from "./modules/main/components/amministrativo/gestisciportale/monitoraggio-transazioni/monitoraggio-transazioni.component";
import { DettaglioQuadraturaComponent } from "./modules/main/components/amministrativo/gestisciportale/quadratura/dettaglio-quadratura/dettaglio-quadratura.component";
import { QuadraturaComponent } from "./modules/main/components/amministrativo/gestisciportale/quadratura/quadratura.component";
import { DettaglioRendicontazioneComponent } from "./modules/main/components/amministrativo/gestisciportale/rendicontazione/dettaglio-rendicontazione/dettaglio-rendicontazione.component";
import { RendicontazioneComponent } from "./modules/main/components/amministrativo/gestisciportale/rendicontazione/rendicontazione.component";
import { DettaglioAccessoComponent } from "./modules/main/components/amministrativo/monitora-accessi/dettaglio-accesso/dettaglio-accesso.component";
import { MonitoraAccessiComponent } from "./modules/main/components/amministrativo/monitora-accessi/monitora-accessi.component";
import { CarrelloComponent } from "./modules/main/components/carrello/carrello.component";
import { CarrelloL1Component } from "./modules/main/components/carrelloL1/carrello-l1.component";
import { CookiePolicyComponent } from "./modules/main/components/cookie-policy/cookie-policy.component";
import { HomeComponent } from "./modules/main/components/home/home.component";
import { IMieiPagamentiComponent } from "./modules/main/components/i-miei-pagamenti/i-miei-pagamenti.component";
import { NuovoPagamentoComponent } from "./modules/main/components/nuovo-pagamento/nuovo-pagamento.component";
import { PresaincaricopagamentoComponent } from "./modules/main/components/presaincaricopagamento/presaincaricopagamento.component";
import { PresaincaricopagamentoL1Component } from "./modules/main/components/presaincaricopagamentoL1/presaincaricopagamento-l1.component";
import { WaitingComponent } from "./modules/main/components/waiting/waiting.component";
import { WaitingL1Component } from "./modules/main/components/waitingL1/waiting-l1.component";
import { AuthguardService } from "./services-old/authguard.service";
import { TableTesterPage } from "./utility/pages/table-tester/table-tester.page";
import { TesterPage } from "./utility/pages/tester/tester.page";
import { UILibraryPage } from "./utility/pages/ui-library/ui-library.page";
import { routesAmministraPortale } from "./features/amministra-portale/amministra-portale.module";
import { AmministratoreAuthGuard } from "./shared/common/authguards/amministratore.authguard";

const prodRoutes: Routes = [
  // Pagine base
  { path: "home", component: HomeComponent },
  { path: "", redirectTo: "/home", pathMatch: "full" },

  // Pagine di errore
  {
    path: "badrequest",
    component: ErrorePage,
    data: {
      errorinfo: {
        code: "400",
        message: "Bad request",
      },
    },
  },
  {
    path: "nonautorizzato",
    component: ErrorePage,
    data: {
      errorinfo: {
        code: "401",
        message: "Operazione non permessa",
      },
    },
  },
  {
    path: "notfound",
    component: ErrorePage,
    data: {
      errorinfo: {
        code: "404",
        message: "Risorsa non trovata",
      },
    },
  },
  {
    path: "erroregenerico",
    component: ErrorePage,
    data: {
      errorinfo: {
        code: "500",
        message: "Errore di sistema",
      },
    },
  },

  { path: "carrelloL1", component: CarrelloL1Component, pathMatch: "full" },
  { path: "carrello", component: CarrelloComponent, pathMatch: "full" },
  {
    path: "presaincaricopagamento",
    component: PresaincaricopagamentoComponent,
  },
  {
    path: "presaincaricopagamentoL1",
    component: PresaincaricopagamentoL1Component,
  },
  { path: "waitingL1", component: WaitingL1Component, pathMatch: "full" },
  { path: "waiting", component: WaitingComponent, pathMatch: "full" },
  { path: "privacy", component: PrivacyPage },
  { path: "cookie-policy", component: CookiePolicyComponent },
  {
    path: "nuovoPagamento",
    component: NuovoPagamentoComponent,
    canActivate: [AuthguardService],
  },
  {
    path: "nuovoPagamento/:idServizioDiretto",
    component: NuovoPagamentoComponent,
  },
  { path: "iMieiPagamenti", component: IMieiPagamentiComponent },
  { path: "gestisciSocieta", component: GestisciSocietaComponent },
  { path: "gestisciEnti", component: GestisciEntiComponent },
  { path: "gestisciSocieta/aggiungiSocieta", component: FormSocietaComponent },
  {
    path: "gestisciSocieta/modificaSocieta/:societaid",
    component: FormSocietaComponent,
  },
  {
    path: "gestisciSocieta/dettaglioSocieta/:societaid",
    component: FormSocietaComponent,
  },
  {
    path: "gestisciLivelliTerritoriali",
    component: GestisciLivelliTerritorialiComponent,
  },
  {
    path: "gestisciLivelliTerritoriali/aggiungiLivelloTerritoriale",
    component: FormLivelloTerritorialeComponent,
  },
  {
    path: "gestisciLivelliTerritoriali/modificaLivelloTerritoriale/:livelloterritorialeid",
    component: FormLivelloTerritorialeComponent,
  },
  {
    path: "gestisciLivelliTerritoriali/dettaglioLivelloTerritoriale/:livelloterritorialeid",
    component: FormLivelloTerritorialeComponent,
  },
  { path: "gestisciBanner", component: GestisciBannerComponent },
  { path: "gestisciBanner/aggiungiBanner", component: FormBannerComponent },
  {
    path: "gestisciBanner/modificaBanner/:bannerid",
    component: FormBannerComponent,
  },
  {
    path: "gestisciBanner/dettaglioBanner/:bannerid",
    component: FormBannerComponent,
  },
  { path: "monitoraAccessi", component: MonitoraAccessiComponent },
  {
    path: "monitoraAccessi/dettaglioAccesso/:accessoid",
    component: DettaglioAccessoComponent,
  },
  { path: "gestisciEnti/aggiungiEnte", component: FormEnteComponent },
  { path: "gestisciEnti/modificaEnte/:enteId", component: FormEnteComponent },
  { path: "gestisciEnti/dettaglioEnte/:enteId", component: FormEnteComponent },
  {
    path: "raggruppamentoTipologie",
    component: RaggruppamentoTipologieComponent,
  },
  {
    path: "raggruppamentoTipologie/aggiungiRaggruppamento",
    component: FormRaggruppamentoTipologieComponent,
  },
  {
    path: "raggruppamentoTipologie/modificaRaggruppamento/:raggruppamentoTipologiaServizioId",
    component: FormRaggruppamentoTipologieComponent,
  },
  {
    path: "raggruppamentoTipologie/dettaglioRaggruppamento/:raggruppamentoTipologiaServizioId",
    component: FormRaggruppamentoTipologieComponent,
  },
  {
    path: "gestisciTipologiaServizi",
    component: GestisciTipologiaServizioComponent,
  },
  {
    path: "gestisciTipologiaServizi/aggiungiTipologia",
    component: FormTipologiaServizioComponent,
  },
  {
    path: "gestisciTipologiaServizi/modificaTipologia/:tipologiaServizioId",
    component: FormTipologiaServizioComponent,
  },
  {
    path: "gestisciTipologiaServizi/dettaglioTipologia/:tipologiaServizioId",
    component: FormTipologiaServizioComponent,
  },
  {
    path: "gestisciFlussiRendicontazione",
    component: GestisciFlussiRendicontazioneComponent,
  },
  {
    path: "gestisciFlussiRendicontazione/aggiungiFlussoRendicontazione",
    component: FormFlussiRendicontazioneComponent,
  },
  {
    path: "gestisciFlussiRendicontazione/modificaFlussoRendicontazione/:flussoRendicontazioneId",
    component: FormFlussiRendicontazioneComponent,
  },
  {
    path: "gestisciFlussiRendicontazione/dettaglioFlussoRendicontazione/:flussoRendicontazioneId",
    component: FormFlussiRendicontazioneComponent,
  },

  { path: "configuraServizi", component: GestisciServiziComponent },
  {
    path: "configuraServizi/aggiungiServizio",
    component: FormServizioComponent,
  },
  {
    path: "configuraServizi/modificaServizio/:servizioId",
    component: FormServizioComponent,
  },
  {
    path: "configuraServizi/dettaglioServizio/:servizioId",
    component: FormServizioComponent,
  },

  { path: "gestisciStatistiche", component: GestisciStatisticheComponent },
  {
    path: "gestisciStatistiche/aggiungiStatistica",
    component: FormStatisticaComponent,
  },
  {
    path: "gestisciStatistiche/modificaStatistica/:statisticaId",
    component: FormStatisticaComponent,
  },
  {
    path: "gestisciStatistiche/dettaglioStatistica/:statisticaId",
    component: FormStatisticaComponent,
  },
  {
    path: "gestisciAttivitaPianificate",
    component: GestisciAttivitaPianificateComponent,
  },
  {
    path: "gestisciAttivitaPianificate/aggiungiAttivitaPianificata",
    component: FormAttivitaPianificateComponent,
  },
  {
    path: "gestisciAttivitaPianificate/modificaAttivitaPianificata/:attivitaId",
    component: FormAttivitaPianificateComponent,
  },
  {
    path: "gestisciAttivitaPianificate/dettaglioAttivitaPianificata/:attivitaId",
    component: FormAttivitaPianificateComponent,
  },
  { path: "rendicontazione", component: RendicontazioneComponent },
  {
    path: "rendicontazione/dettaglioRendicontazione/:rendicontazioneId",
    component: DettaglioRendicontazioneComponent,
  },
  {
    path: "monitoraggioTransazioni",
    component: MonitoraggioTransazioniComponent,
  },
  {
    path: "monitoraggioTransazioni/dettaglioTransazioni/:transazioneId",
    component: DettaglioTransazioneComponent,
  },
  {
    path: "monitoraggioTransazioni/dettaglioTransazioni/transazione/:idTransazione/dettaglioPendenza/:dettaglioTransazioneId",
    component: DettaglioPendenzaComponent,
  },
  {
    path: "monitoraggioTransazioni/:transazioneId/esitoNotifiche",
    component: DettaglioEsitoNotificaComponent,
  },

  { path: "quadratura", component: QuadraturaComponent },
  {
    path: "quadratura/dettaglioQuadratura/:quadraturaId",
    component: DettaglioQuadraturaComponent,
  },

  { path: "iuvSenzaBonifico", component: IuvSenzaBonificoComponent },

  // {path: 'configuraPortaliEsterni', component: ConfiguraPortaliEsterniComponent},
  // {path: 'configuraPortaliEsterni/aggiungiPortaleEsterno', component: FormConfiguraPortaliEsterniComponent},
  // {path: 'configuraPortaliEsterni/modificaPortaleEsterno/:portaleEsternoId', component: FormConfiguraPortaliEsterniComponent},
  // {path: 'configuraPortaliEsterni/dettaglioPortaleEsterno/:portaleEsternoId', component: FormConfiguraPortaliEsterniComponent},

  // { path: 'gestisciUtenti', component: GestisciUtentiComponent },
  // { path: 'gestisciUtenti/aggiungiUtentePermessi', component: FormUtentePermessiComponent },
  // { path: 'gestisciUtenti/modificaUtentePermessi/:userid', component: FormUtentePermessiComponent },
  // { path: 'gestisciUtenti/dettaglioUtentePermessi/:userid', component: FormUtentePermessiComponent },

  {
    path: "",
    data: {
      breadcrumb: {
        id: "amministra-portale",
        name: "Amministra Portale",
      },
    },
    canActivateChild: [AmministratoreAuthGuard],
    loadChildren: () =>
      import("./features/amministra-portale/amministra-portale.module").then(
        (m) => m.AmministraPortaleModule
      ),
  },
  {
    path: "",
  data: {
    breadcrumb: {
      id: "gestisci-portale",
      name: "Gestisci Portale",
    },
  },
  canActivateChild: [AmministratoreAuthGuard],
  loadChildren: () => import("./features/gestisci-portale/gestisci-portale.module").then((m) => m.GestisciPortaleModule)
  },

  // Wildcard redirect
  { path: "**", redirectTo: "/notfound" },
];

const stageRoutes: Routes = [
  {
    path: "tester",
    component: TesterPage,
  },
  {
    path: "ui-library",
    component: UILibraryPage,
  },
  {
    path: "table-tester",
    component: TableTesterPage,
  },
];

const options: ExtraOptions = {
  scrollPositionRestoration: "enabled",
};

const routes: Routes = environment.production
  ? prodRoutes
  : stageRoutes.concat(prodRoutes);

@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
