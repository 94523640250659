<!-- Sottotitolo -->
<div class="collapse-header" id="heading1">
  <button data-toggle="collapse" data-target="#contenutoSezioneFiltri" aria-expanded="true"
          aria-controls="contenutoSezioneFiltri">
    Filtri
  </button>
</div>

<!-- Form filtri -->
<form #filtroForm="ngForm">
  <div id="contenutoSezioneFiltri" class="collapse show" role="tabpanel" aria-labelledby="heading1">
    <div class="collapse-body">
      <!-- Cerca -->
      <div class="row mt-2 mb-0">
        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="selectSocieta" class="title">Società</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select [ngClass]="{'text-disabled': !opzioniFiltroSocieta.length}" id="selectSocieta" name="societaId"
                      #societaInput = "ngModel"
                      [disabled]="opzioniFiltroSocieta.length == 1"
                      [(ngModel)]="societaId">
                <option [ngValue]="null" selected>Tutti</option>
                <option *ngFor="let item of opzioniFiltroSocieta" [ngValue]="item.value">{{item.label}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="filtroInput" class="title">Termini di ricerca</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input type="text" class="pt-0"
                     name="filtro"
                     id="filtroInput"
                     autocomplete="off"
                     [(ngModel)]="filtro">
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p>inserisci</p>
            </div>
          </div>
        </div>
      </div>
        <div class="row">
          <div class="col-6">
            <button type="button" id="bottonePulisci" class="btn btn-secondary" [disabled]="disabilitaBottone()"
                    (click)="pulisciFiltri()">Pulisci</button>
          </div>
          <div class="col-6">
            <button id="bottoneCerca" class="btn" [disabled]="disabilitaBottone()"
                    (click)="cercaElementi()">Cerca</button>
          </div>
        </div>
    </div>
  </div>
</form>
