<!-- Summary -->
<div id="summary" class="col-3">
  <small>
    Visualizzati elementi da {{ this.firstElement }} a {{ this.lastElement }} di
    {{ this.length }} totali.
  </small>
</div>

<!-- Paginator -->
<div id="paginator" class="col-6">
  <button (click)="pageChange(1)" [disabled]="this.page == 1">
    <svg class="icon icon-primary">
      <use href="assets/icons/bootstrap-italia-icons-expanded.svg#it-double-chevron-left"></use>
    </svg>
  </button>
  <button class="mr-3" (click)="pageChange(page - 1)" [disabled]="this.page == 1">
    <svg class="icon icon-primary">
      <use href="assets/icons/bootstrap-italia-icons.svg#it-chevron-left"></use>
    </svg>
  </button>
  <button
    *ngFor="let p of this.availablePages"
    [ngClass]="{ selected: this.page == p }"
    (click)="pageChange(p)"
  >
    {{ p }}
  </button>
  <button class="ml-3" (click)="pageChange(page + 1)" [disabled]="this.page == this.pages">
    <svg class="icon icon-primary">
      <use
        href="assets/icons/bootstrap-italia-icons.svg#it-chevron-right"
      ></use>
    </svg>
  </button>
  <button (click)="pageChange(pages)" [disabled]="this.page == this.pages">
    <svg class="icon icon-primary">
      <use href="assets/icons/bootstrap-italia-icons-expanded.svg#it-double-chevron-right"></use>
    </svg>
  </button>
</div>

<!-- Page Size Selector -->
<div id="page-size-selector" class="col-3">
  Mostra
  <div ngbDropdown class="d-inline-block">
    <button
      type="button"
      class="btn btn-primary"
      id="paginationSize"
      ngbDropdownToggle
    >
      {{ this.pageSize }}
    </button>
    <div ngbDropdownMenu aria-labelledby="paginationsSize">
      <button
        ngbDropdownItem
        *ngFor="let option of pageSizeOptions"
        (click)="pageSizeChange(option)"
      >
        {{ option }}
      </button>
    </div>
  </div>
  risultati.
</div>
