<div>
  <ng-content></ng-content>
</div>
<div [ngSwitch]="mode">
  <svg *ngSwitchCase="'asc'">
    <use href="assets/icons/bootstrap-italia-icons.svg#it-arrow-up-triangle"></use>
  </svg>
  <svg *ngSwitchCase="'desc'">
    <use href="assets/icons/bootstrap-italia-icons.svg#it-arrow-down-triangle"></use>
  </svg>
  <svg *ngSwitchDefault>
    <use href="assets/icons/bootstrap-italia-icons-expanded.svg#it-arrow-updown-triangle"></use>
  </svg>
</div>
