import { Component, Input, OnInit } from "@angular/core";
import { PayerTableComponent } from "../table/table.component";

@Component({
  selector: "payer-sort-icon",
  templateUrl: "./sort-icon.component.html",
  styleUrls: ["./sort-icon.component.scss"],
})
export class PayerSortIconComponent {
  @Input()
  field: string;

  get mode(): "asc" | "desc" | "unsorted" {
    if (this.table.sortField === this.field) {
      if (this.table.sortDirection == 1) {
        return "asc";
      } else {
        return "desc";
      }
    }

    return "unsorted";
  }

  constructor(private table: PayerTableComponent) {}
}
