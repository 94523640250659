import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FiltroGestioneElementiComponent} from '../../../filtro-gestione-elementi.component';
import {ActivatedRoute} from '@angular/router';
import {AmministrativoService} from '../../../../../../../services-old/amministrativo.service';
import {ParametriRicercaRendicontazione} from '../../../../../model/rendicontazione/ParametriRicercaRendicontazione';
import {NgForm, NgModel} from '@angular/forms';
import {BottoneEnum} from '../../../../../../../enums/bottone.enum';
import {TipoCampoEnum} from '../../../../../../../enums/tipoCampo.enum';
import {DatePickerComponent, ECalendarValue} from 'ng2-date-picker';
import * as moment from 'moment';
import * as _ from 'lodash';
import {Utils} from '../../../../../../../utility/Utils';
import {OpzioneSelect} from '../../../../../model/OpzioneSelect';
import {GestisciPortaleService} from '../../../../../../../services-old/gestisci-portale.service';
import {map} from 'rxjs/operators';
import {CampoTipologiaServizioService} from '../../../../../../../services-old/campo-tipologia-servizio.service';

@Component({
  selector: 'app-filtro-rendicontazione',
  templateUrl: './filtro-rendicontazione.component.html',
  styleUrls: ['./filtro-rendicontazione.component.scss']
})
export class FiltroRendicontazioneComponent extends FiltroGestioneElementiComponent implements OnInit {

  TipoCampoEnum = TipoCampoEnum;

  @Output()
  onChangeFiltri: EventEmitter<ParametriRicercaRendicontazione> = new EventEmitter<ParametriRicercaRendicontazione>();

  @Output()
  enti: EventEmitter<any> = new EventEmitter<any>()

  filtroRicercaRendicontazione: ParametriRicercaRendicontazione;

  isCalendarOpen: boolean;
  readonly minDateDDMMYYYY = '01/01/1990';
  readonly tipoData = ECalendarValue.String;
  readonly formatoData = Utils.FORMAT_DATE_CALENDAR;

  listaSocieta: Array<OpzioneSelect> = [];
  listaLivelloTerritoriale: Array<OpzioneSelect> = [];
  listaEnte: Array<OpzioneSelect> = [];
  listaTipologiaServizio: Array<OpzioneSelect> = [];
  listaServizio: Array<OpzioneSelect> = [];
  listaTransazioneId: Array<OpzioneSelect> = [];
  listaCanale: Array<OpzioneSelect> = [];
  listaTipoFlusso: Array<OpzioneSelect> = [];

  constructor(protected activatedRoute: ActivatedRoute, protected amministrativoService: AmministrativoService,
              private gestisciPortaleService: GestisciPortaleService,
              private campoTipologiaServizioService: CampoTipologiaServizioService) {
    super(activatedRoute, amministrativoService);
  }

  ngOnInit(): void {
    this.recuperaFiltroSocieta();
    this.recuperaFiltroLivelloTerritoriale(new ParametriRicercaRendicontazione());
    this.recuperaFiltroEnte(new ParametriRicercaRendicontazione());
    this.recuperaFiltroTipologiaServizio(new ParametriRicercaRendicontazione());
    this.recuperaFiltroServizio(new ParametriRicercaRendicontazione());
    this.recuperaFiltroCanale();
    this.recuperaFiltroTipoFlusso();

    let tempFiltroRicercaRendicontazione = JSON.parse(localStorage.getItem('filtroRicercaRendicontazione'));
    if (tempFiltroRicercaRendicontazione){
      this.filtroRicercaRendicontazione = tempFiltroRicercaRendicontazione;
      this.applicaFiltri();
    } else {
      this.inizializzaFiltri();
    }
  }

  inizializzaFiltri(): void {
    this.filtroRicercaRendicontazione = new ParametriRicercaRendicontazione();
    this.filtroRicercaRendicontazione.societaId = null;
    this.filtroRicercaRendicontazione.livelloTerritorialeId = null;
    this.filtroRicercaRendicontazione.enteId = null;
    this.filtroRicercaRendicontazione.servizioId = null;
    this.filtroRicercaRendicontazione.transazioneId = null;
    this.filtroRicercaRendicontazione.tipologiaServizioId = null;
    this.filtroRicercaRendicontazione.canaleId = null;
    this.filtroRicercaRendicontazione.dataPagamentoDa = null;
    this.filtroRicercaRendicontazione.dataPagamentoA = null;
    this.filtroRicercaRendicontazione.importoTransazioneDa = null;
    this.filtroRicercaRendicontazione.importoTransazioneA = null;
    this.filtroRicercaRendicontazione.flussoRendicontazioneId = null;
    this.filtroRicercaRendicontazione.dataCreazioneRendicontoDa = String(moment().subtract(1, 'day').format(this.formatoData));
    this.filtroRicercaRendicontazione.dataCreazioneRendicontoA = null;
    this.filtroRicercaRendicontazione.tipoFlussoId = null;
    this.filtroRicercaRendicontazione.codiceIUV = null;
  }

  recuperaFiltroSocieta(): void {
    this.gestisciPortaleService.gestisciPortaleFiltroSocieta(this.idFunzione).pipe(map(listaSocieta => {
      listaSocieta.forEach(societa => {
        this.listaSocieta.push({
          value: societa.id,
          label: societa.nome
        });
      });
      this.listaSocieta = _.sortBy(this.listaSocieta, ['label']);
      if(this.listaSocieta.length == 1)
        this.filtroRicercaRendicontazione.societaId = this.listaSocieta[0].value
    })).subscribe();
  }

  recuperaFiltroLivelloTerritoriale(parametriRicercaRendicontazione: ParametriRicercaRendicontazione): void {
    this.gestisciPortaleService.gestisciPortaleFiltroLivelloTerritoriale(parametriRicercaRendicontazione, this.idFunzione).pipe(map(listaLivelloTerritoriale => {
      listaLivelloTerritoriale.forEach(livelloTerritoriale => {
        this.listaLivelloTerritoriale.push({
          value: livelloTerritoriale.id,
          label: livelloTerritoriale.nome
        });
      });
      this.listaLivelloTerritoriale = _.sortBy(this.listaLivelloTerritoriale, ['label']);
    })).subscribe();
  }

  recuperaFiltroEnte(parametriRicercaRendicontazione: ParametriRicercaRendicontazione): void {
    this.gestisciPortaleService.gestisciPortaleFiltroEnte(parametriRicercaRendicontazione, this.idFunzione).pipe(map(listaEnte => {
      listaEnte.forEach(ente => {
        this.listaEnte.push({
          value: ente.id,
          label: ente.nome
        });
      });
      this.listaEnte = _.sortBy(this.listaEnte, ['label']);
      this.enti.emit(this.listaEnte)
    })).subscribe();
  }

  recuperaFiltroTipologiaServizio(parametriRicercaRendicontazione: ParametriRicercaRendicontazione): void {
    this.campoTipologiaServizioService.recuperaTipologieServizio(parametriRicercaRendicontazione, this.idFunzione).pipe(map(listaTipologiaServizio => {
      listaTipologiaServizio.forEach(tipologiaServizio => {
        this.listaTipologiaServizio.push({
          value: tipologiaServizio.id,
          label: tipologiaServizio.descrizione
        });
      });
      this.listaTipologiaServizio = _.sortBy(this.listaTipologiaServizio, ['label']);
    })).subscribe();
  }

  recuperaFiltroServizio(parametriRicercaRendicontazione: ParametriRicercaRendicontazione): void {
    this.gestisciPortaleService.gestisciPortaleFiltroServizio(parametriRicercaRendicontazione, this.idFunzione).pipe(map(listaServizio => {
      listaServizio.forEach(servizio => {
        this.listaServizio.push({
          value: servizio.id,
          label: servizio.nome
        });
      });
      this.listaServizio = _.sortBy(this.listaServizio, ['label']);
    })).subscribe();
  }

  recuperaFiltroCanale(): void {
    this.gestisciPortaleService.gestisciPortaleFiltroCanale(this.idFunzione).pipe(map(listaCanale => {
      listaCanale.forEach(canale => {
        this.listaCanale.push({
          value: canale.id,
          label: canale.nome
        });
      });
      this.listaCanale = _.sortBy(this.listaCanale, ['label']);
    })).subscribe();
  }

  recuperaFiltroTipoFlusso(): void {
    this.gestisciPortaleService.gestisciPortaleFiltroTipoFlusso(this.idFunzione).pipe(map(listaTipoFlusso => {
      listaTipoFlusso.forEach(tipoFlusso => {
        this.listaTipoFlusso.push({
          value: tipoFlusso.id,
          label: tipoFlusso.nome
        });
      });
      this.listaTipoFlusso = _.sortBy(this.listaTipoFlusso, ['label']);
    })).subscribe();
  }

  setPlaceholder(campo: NgModel, tipo: string): string {
    if (this.isCampoInvalido(campo)) {
      if(campo.name == "dataCreazioneRendicontoDa")
        if(campo.value == undefined)
          return 'campo obbligatorio'
        else
          return 'campo non valido';
      else
        return 'campo non valido';
    } else {
      if (TipoCampoEnum.INPUT_TESTUALE === tipo) {
        return 'inserisci testo';
      } else if (TipoCampoEnum.DATEDDMMYY === tipo) {
        return 'inserisci data';
      } else if (TipoCampoEnum.SELECT === tipo) {
        return 'seleziona un elemento dalla lista';
      } else if (TipoCampoEnum.INPUT_NUMERICO === tipo) {
        return 'inserisci id flusso rendicontazione';
      }
    }
  }

  isCampoInvalido(campo: NgModel) {
    return campo?.errors != null;
  }

  openDatepicker(datePickerComponent: DatePickerComponent): void {
    datePickerComponent.api.open();
    this.isCalendarOpen = !this.isCalendarOpen;
  }

  applicaFiltri(): void {
    const filtri = this.formattaFiltri();
    this.onChangeFiltri.emit(filtri);
  }

  cercaElementi(): void {
    localStorage.setItem('filtroRicercaRendicontazione', JSON.stringify(this.filtroRicercaRendicontazione));
    this.applicaFiltri();
  }

  private formattaFiltri() {
    const filtri: ParametriRicercaRendicontazione = JSON.parse(JSON.stringify(this.filtroRicercaRendicontazione));
    filtri.dataPagamentoDa = filtri.dataPagamentoDa
      ? moment(filtri.dataPagamentoDa, Utils.FORMAT_DATE_CALENDAR).format(Utils.FORMAT_LOCAL_DATE_TIME)
      : null;
    filtri.dataPagamentoA = filtri.dataPagamentoA
      ? moment(filtri.dataPagamentoA, Utils.FORMAT_DATE_CALENDAR).format(Utils.FORMAT_LOCAL_DATE_TIME_TO)
      : null;
    filtri.dataCreazioneRendicontoDa = filtri.dataCreazioneRendicontoDa
      ? moment(filtri.dataCreazioneRendicontoDa, Utils.FORMAT_DATE_CALENDAR).format(Utils.FORMAT_LOCAL_DATE_TIME)
      : null;
    filtri.dataCreazioneRendicontoA = filtri.dataCreazioneRendicontoA
      ? moment(filtri.dataCreazioneRendicontoA, Utils.FORMAT_DATE_CALENDAR).format(Utils.FORMAT_LOCAL_DATE_TIME_TO)
      : null;
    return filtri;
  }

  pulisciFiltri(filtroRendicontazioneForm: NgForm): void {
    localStorage.setItem('filtroRicercaRendicontazione', null);
    this.inizializzaFiltri()
    this.applicaFiltri();
  }

  disabilitaBottone(filtroRendicontazioneForm: NgForm, nomeBottone: string): boolean {
    const isAtLeastOneFieldValued = Object.keys(filtroRendicontazioneForm.value).some(key => filtroRendicontazioneForm.value[key]);
    if (nomeBottone === BottoneEnum.PULISCI) {
      return !isAtLeastOneFieldValued;
    } else if (nomeBottone === BottoneEnum.CERCA) {
      return !filtroRendicontazioneForm.valid;
    }
  }

  selezionaSocieta() {
    this.listaLivelloTerritoriale = [];
    const parametriRicercaRendicontazione = new ParametriRicercaRendicontazione();
    parametriRicercaRendicontazione.societaId = this.filtroRicercaRendicontazione.societaId;
    this.recuperaFiltroLivelloTerritoriale(parametriRicercaRendicontazione);
    this.filtroRicercaRendicontazione.livelloTerritorialeId = null;
    this.selezionaLivelloTerritoriale();
  }

  selezionaLivelloTerritoriale(): void {
    this.listaEnte = [];
    const parametriRicercaRendicontazione = new ParametriRicercaRendicontazione();
    parametriRicercaRendicontazione.societaId = this.filtroRicercaRendicontazione.societaId;
    parametriRicercaRendicontazione.livelloTerritorialeId = this.filtroRicercaRendicontazione.livelloTerritorialeId;
    this.recuperaFiltroEnte(parametriRicercaRendicontazione);
    this.filtroRicercaRendicontazione.enteId = null;
    this.selezionaEnte();
  }

  selezionaEnte(): void {
    this.listaTipologiaServizio = [];
    const parametriRicercaRendicontazione = new ParametriRicercaRendicontazione();
    parametriRicercaRendicontazione.societaId = this.filtroRicercaRendicontazione.societaId;
    parametriRicercaRendicontazione.livelloTerritorialeId = this.filtroRicercaRendicontazione.livelloTerritorialeId;
    parametriRicercaRendicontazione.enteId = this.filtroRicercaRendicontazione.enteId;
    this.recuperaFiltroTipologiaServizio(parametriRicercaRendicontazione);
    this.filtroRicercaRendicontazione.tipologiaServizioId = null;
    this.selezionaTipologiaServizio();
  }

  selezionaTipologiaServizio(): void {
    this.listaServizio = [];
    const parametriRicercaRendicontazione = new ParametriRicercaRendicontazione();
    parametriRicercaRendicontazione.societaId = this.filtroRicercaRendicontazione.societaId;
    parametriRicercaRendicontazione.livelloTerritorialeId = this.filtroRicercaRendicontazione.livelloTerritorialeId;
    parametriRicercaRendicontazione.enteId = this.filtroRicercaRendicontazione.enteId;
    parametriRicercaRendicontazione.tipologiaServizioId = this.filtroRicercaRendicontazione.tipologiaServizioId;
    this.recuperaFiltroServizio(parametriRicercaRendicontazione);
    this.filtroRicercaRendicontazione.servizioId = null;
  }

}
