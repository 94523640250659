<div class="ml-3">
  <form #datiEnteForm="ngForm">
    <!--    inizio Nome Ente, Codice Fiscale o P.IVA -->
    <div class="row mt-4 mb-0">
      <!--      inizio Nome Ente -->
      <div class="col-md-6 pb-2">
        <div class="row">
          <div class="col">
            <label for="nomeEnte" class="title " [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">
              Nome</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="text" id="nomeEnte" name="nomeEnte" #nomeEnte="ngModel"
                   [(ngModel)]="datiEnte.nomeEnte" [required]="funzione !== FunzioneGestioneEnum.DETTAGLIO"
                   class="pt-0" autocomplete="off" aria-describedby="nomeHelpDescription"
                   [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
                   (ngModelChange)="onChangeModel(datiEnteForm, nomeEnte)">
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(nomeEnte)">Inserisci nome ente</p>
            <p *ngIf="isCampoInvalido(nomeEnte)" class="text-invalid">{{getMessaggioErrore(nomeEnte)}}</p>
          </div>
        </div>
      </div>
      <!--      fine Nome Ente-->
      <!--      inizio Codice Fiscale o P.IVA-->
      <div class="col-md-6 pb-2">
        <div class="row">
          <div class="col">
            <label for="cfPiva" class="title"
                   [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">
              Codice Fiscale o Partita Iva</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="text" id="cfPiva" name="cfPiva" #cfPiva="ngModel"
                   aria-describedby="codiceFiscaleHelpDescription"
                   [pattern]="codiceFiscalPIvaRegex"
                   [(ngModel)]="datiEnte.cfPiva" [required]="funzione !== FunzioneGestioneEnum.DETTAGLIO"
                   class="pt-0" autocomplete="off" [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
                   (ngModelChange)="onChangeModel(datiEnteForm, cfPiva)">
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(cfPiva)">Inserisci codice fiscale o p.iva ente</p>
            <p *ngIf="isCampoInvalido(cfPiva)" class="text-invalid">{{getMessaggioErrore(cfPiva)}}</p>
          </div>
        </div>
      </div>
      <!--      fine Codice Fiscale o P.IVA-->
    </div>
    <!--    fine Nome Ente, Codice Fiscale o P.IVA -->
    <!--    inizio Descrizione, Logo -->
    <div class="row mt-4 mb-0">
      <!--    inizio Descrizione -->
      <div class="col-md-10 pb-2">
        <div class="row">
          <div class="col">
            <label for="descrizione" class="title ">
              Descrizione</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
             <textarea
               id="descrizione"
               type="text"
               name="descrizione"
               autocomplete="off"
               #descrizione="ngModel"
               [(ngModel)]="datiEnte.descrizione"
               (ngModelChange)="onChangeModel(datiEnteForm, descrizione)"
               maxlength="255"
               [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
               class="textArea"
             ></textarea>
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(descrizione)">Inserisci descrizione ente</p>
            <p *ngIf="isCampoInvalido(descrizione)" class="text-invalid">{{getMessaggioErrore(descrizione)}}</p>
          </div>
        </div>
      </div>
      <!--      fine Descrizione -->
      <!--      inizio Logo -->
      <div class="col-md-2 pb-2">
        <div class="row">
          <div class="col-auto">
            <label for="canvas" class="title ">
              Logo</label>
          </div>
          <div class="col-auto">
            <svg class="icon icon-sm" fill='var(--lepida-primary)' *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO
            && datiEnte && datiEnte.logo && datiEnte.logo.contenuto"
            (click)="eliminaLogo()" style="cursor: pointer">
              <use xlink:href="assets/img/sprite.svg#it-close"></use>
            </svg>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <ul class="upload-pictures-wall" style="position:absolute;">
              <li>
                <input id="pathLogo" type='file' accept='image/*' (change)='loadImg($event)' class="upload pictures-wall" style="display: none"
                     [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
                <label for="pathLogo" id="labelLogo">
                  <svg class="icon icon-sm" aria-hidden="true" *ngIf="datiEnte.logo == null || datiEnte.logo.contenuto == null"><use xlink:href="assets/img/sprite.svg#it-plus"></use></svg>
                </label>
              </li>
            </ul>

            <canvas id="canvas" width="128" height="128" (click)="onClickImage()"
                    class="canvas"
                    disabled="{{funzione === FunzioneGestioneEnum.DETTAGLIO}}">
            </canvas>
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p [style]="{marginBottom: 0}">Inserisci logo ente</p>
            <p [style]="{fontSize: '10px'}">Formati consentiti: GIF, PNG, JPEG, BMP, WBMP</p>
          </div>
        </div>
      </div>
      <!--      fine Logo -->
    </div>
    <!--    fine Decrizione, Logo -->
    <!--    inizio Chiave API, Secret API -->
    <div class="row mt-4 mb-0">
      <!--    inizio Chiave API-->
      <div class="col-md-6 pb-2">
        <div class="row">
          <div class="col">
            <label for="chiaveApi" class="title ">
              Chiave API Quadratura</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="text" id="chiaveApi" name="chiaveApi" #chiaveApi="ngModel"
                   [(ngModel)]="datiEnte.credenzialiApi.apiKey"
                   [pattern]="regexChiaveApi"
                   class="pt-0" autocomplete="off" aria-describedby="chiaveHelpDescription"
                   [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
                   (ngModelChange)="onChangeModel(datiEnteForm, chiaveApi)">
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(chiaveApi)">Inserisci chiave API quadratura</p>
            <p *ngIf="isCampoInvalido(chiaveApi)" class="text-invalid">{{getMessaggioErrore(chiaveApi)}}</p>
          </div>
        </div>
      </div>
      <!--    fine Chiave API-->
      <!--    inizio Secret API-->
      <div class="col-md-6 pb-2">
        <div class="row">
          <div class="col">
            <label for="secretApi" class="title ">
              Secret API Quadratura</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="text" id="secretApi" name="secretApi" #secretApi="ngModel"
                   [(ngModel)]="datiEnte.credenzialiApi.apiSecret"
                   [pattern]="regexSecretApi"
                   class="pt-0" autocomplete="off" aria-describedby="secretHelpDescription"
                   [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
                   (ngModelChange)="onChangeModel(datiEnteForm, secretApi)">
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(secretApi)">Inserisci secret API quadratura</p>
            <p *ngIf="isCampoInvalido(secretApi)" class="text-invalid">{{getMessaggioErrore(secretApi)}}</p>
          </div>
        </div>
      </div>
      <!--    fine Secret API-->
    </div>
    <!--    fine Chiave API, Secret API -->
    <!--    inizio Dati referente -->
      <!--      sotto-titolo -->
    <div class="row mt-4 mb-0">
      <h3 class="page-subtitle pl-1"><strong>Dati referente</strong></h3>
    </div>
<!--      inizio Nome, Cognome -->
    <div class="row mt-4 mb-0">
<!--      inizio Nome -->
      <div class="col-md-6 pb-2">
        <div class="row">
          <div class="col">
            <label for="nomeReferente" class="title ">
              Nome</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="text" id="nomeReferente" name="nomeReferente" #nomeReferente="ngModel"
                   [(ngModel)]="datiEnte.nomeReferente"
                   (ngModelChange)="onChangeModel(datiEnteForm, nomeReferente)"
                   class="pt-0" autocomplete="off" aria-describedby="nomeReferenteHelpDescription"
                   [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(nomeReferente)">Inserisci nome referente</p>
            <p *ngIf="isCampoInvalido(nomeReferente)" class="text-invalid">{{getMessaggioErrore(nomeReferente)}}</p>
          </div>
        </div>
      </div>
<!--      fine Nome -->
<!--      inizio Cognome -->
      <div class="col-md-6 pb-2">
        <div class="row">
          <div class="col">
            <label for="cognomeReferente" class="title " >
              Cognome</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="text" id="cognomeReferente" name="cognomeReferente" #cognomeReferente="ngModel"
                   [(ngModel)]="datiEnte.cognomeReferente"
                   (ngModelChange)="onChangeModel(datiEnteForm, cognomeReferente)"
                   class="pt-0" autocomplete="off" aria-describedby="cognomeReferenteHelpDescription"
                   [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(cognomeReferente)">Inserisci cognome referente</p>
            <p *ngIf="isCampoInvalido(cognomeReferente)" class="text-invalid">{{getMessaggioErrore(cognomeReferente)}}</p>
          </div>
        </div>
      </div>
<!--      fine Cognome -->
    </div>
<!--      fine Nome, Cognome -->
    <!--      inizio Email, Telefono -->
    <div class="row mt-4 mb-0">
      <!--      inizio Email -->
      <div class="col-md-8 pb-2">
        <div class="row">
          <div class="col">
            <label for="emailReferente" class="title ">
              Email</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="text" id="emailReferente" name="emailReferente" #emailReferente="ngModel"
                   [(ngModel)]="datiEnte.emailReferente"
                   [pattern]="emailRegex"
                   (ngModelChange)="onChangeModel(datiEnteForm, emailReferente)"
                   class="pt-0" autocomplete="off" aria-describedby="emailReferenteHelpDescription"
                   [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(emailReferente)">Inserisci email referente</p>
            <p *ngIf="isCampoInvalido(emailReferente)" class="text-invalid">{{getMessaggioErrore(emailReferente)}}</p>
          </div>
        </div>
      </div>
      <!--      fine Email -->
      <!--      inizio Telefono -->
      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="telefonoReferente" class="title ">
              Telefono</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="text" id="telefonoReferente" name="telefonoReferente" #telefonoReferente="ngModel"
                   [pattern]="telefonoRegex"
                   [(ngModel)]="datiEnte.telefonoReferente"
                   (ngModelChange)="onChangeModel(datiEnteForm, telefonoReferente)"
                   class="pt-0" autocomplete="off" aria-describedby="telefonoReferenteHelpDescription"
                   [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(telefonoReferente)">Inserisci telefono referente</p>
            <p *ngIf="isCampoInvalido(telefonoReferente)" class="text-invalid">{{getMessaggioErrore(telefonoReferente)}}</p>
          </div>
        </div>
      </div>
      <!--      fine Telefono -->
    </div>
    <!--      fine Email, Telefono -->
    <!-- inizio Società, Livello Territoriale, Provincia, Comune -->
    <div class="row mt-2 mb-0">
      <!--       inizio select società -->
      <div class="col-md-3 pb-2">
        <div class="row">
          <div class="col">
            <label for="societaId" class="title"  [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">
              Società</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <select
              id="societaId"
              name="societaId"
              #societaId="ngModel"
              [(ngModel)]="datiEnte.societaId"
              [required]="funzione !== FunzioneGestioneEnum.DETTAGLIO"
              [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
              (ngModelChange)="onChangeModel(datiEnteForm, societaId)"
            >
              <option *ngIf="opzioniFiltroSocieta.length != 1" [ngValue]="null" selected>Seleziona</option>
              <option *ngFor="let item of opzioniFiltroSocieta" [ngValue]="item.value">{{item.label}}</option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(societaId)">Seleziona società</p>
            <p *ngIf="isCampoInvalido(societaId)" class="text-invalid">{{getMessaggioErrore(societaId)}}</p>
          </div>
        </div>
      </div>
      <!--        fine select società -->
      <!--       inizio select livelli territoriali -->
      <div class="col-md-3 pb-2">
        <div class="row">
          <div class="col">
            <label for="livelloTerritorialeId" class="title" [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">
              Livello Territoriale</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <select
              id="livelloTerritorialeId"
              name="livelloTerritorialeId"
              #livelloTerritorialeId="ngModel"
              [(ngModel)]="datiEnte.livelloTerritorialeId"
              [required]="funzione !== FunzioneGestioneEnum.DETTAGLIO"
              [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
              (ngModelChange)="onChangeModel(datiEnteForm, livelloTerritorialeId)"
            >
              <option [ngValue]="null" selected>Seleziona</option>
              <option *ngFor="let item of opzioniFiltroLivelliTerritoriale"
                      [ngValue]="item.value">{{item.label}}</option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(livelloTerritorialeId)">Seleziona livello territoriale</p>
            <p *ngIf="isCampoInvalido(livelloTerritorialeId)" class="text-invalid">{{getMessaggioErrore(livelloTerritorialeId)}}</p>
          </div>
        </div>
      </div>
      <!--        fine select territoriali -->
      <!--       inizio select province -->
      <div class="col-md-3 pb-2">
        <div class="row">
          <div class="col">
            <label for="provincia" class="title">Provincia</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <select
              id="provincia"
              name="provincia"
              #provincia="ngModel"
              [(ngModel)]="datiEnte.provincia"
              (ngModelChange)="selezionaProvincia()"
              [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
              (ngModelChange)="onChangeModel(datiEnteForm, provincia)"
            >
              <option [ngValue]="null" selected>Seleziona</option>
              <option *ngFor="let item of opzioniFiltroProvincia" [ngValue]="item.value">{{item.label}}</option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(provincia)">Seleziona provincia</p>
            <p *ngIf="isCampoInvalido(provincia)" class="text-invalid">{{getMessaggioErrore(provincia)}}</p>
          </div>
        </div>
      </div>
      <!--        fine select province -->
      <!--       inizio select comuni -->
      <div class="col-md-3 pb-2">
        <div class="row">
          <div class="col">
            <label for="comune" class="title">Comune</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <select
              id="comune"
              name="comune"
              #comune="ngModel"
              [(ngModel)]="datiEnte.comune"
              [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO || !opzioniFiltroComuneFiltrate.length"
              (ngModelChange)="onChangeModel(datiEnteForm, comune)"
            >
              <option [ngValue]="null" selected>Seleziona</option>
              <option *ngFor="let item of opzioniFiltroComuneFiltrate" [ngValue]="item.value">{{item.label}}</option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(comune)">Seleziona comune</p>
            <p *ngIf="isCampoInvalido(comune)" class="text-invalid">{{getMessaggioErrore(comune)}}</p>
          </div>
        </div>
      </div>
      <!--        fine select comuni -->
    </div>
    <!-- fine Società, Livello Territoriale, Provincia, Comune -->
    <!--    fine Dati referente -->
  </form>
</div>
