export class ParametriRicercaEnte {
  enteId?: number;
  societaId?: number;
  livelloTerritorialeId?: number;
  comune?: string;
  provincia?: string;
  livelloIntegrazioneId?: number;
}


