import { Directive } from "@angular/core";
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { CampoServizio } from "src/app/modules/main/model/servizio/CampoServizio";
import { ConfiguraServizioService } from "src/app/services-old/configura-servizio.service";

@Directive({
  selector: "[payerCodiceNonRipetuto]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CodiceNonRipetutoDirective,
      multi: true,
    },
  ],
})
export class CodiceNonRipetutoDirective implements Validator {
  constructor(private servizio: ConfiguraServizioService) {}

  validate(control: AbstractControl): ValidationErrors | null {
    const codice: string = control.value;
    const titolo: string = control.parent.get("titolo")?.value;
    console.log(titolo)
    
    let campoConflitto: CampoServizio = undefined;
    campoConflitto = this.servizio.campoServizioAddList
      .concat(this.servizio.campoTipologiaServizioList)
      .find((campo: CampoServizio) => {
        return campo.codice && codice && campo.codice === codice && campo.titolo !== titolo;
      });

    if (campoConflitto) {
      return { codiceRipetuto: campoConflitto.titolo };
    }

    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {}
}
