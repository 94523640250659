<!-- Sottotitolo -->
<div class="collapse-header" id="idCollapseFiltri">
  <button data-toggle="collapse" data-target="#contenutoSezioneFiltri" aria-expanded="true" aria-controls="contenutoSezioneFiltri">
    Filtri
  </button>
</div>

<!-- Form filtri -->
<form #filtroForm="ngForm">
  <div id="contenutoSezioneFiltri" class="collapse show" role="tabpanel" aria-labelledby="idCollapseFiltri">
    <div class="collapse-body">
      <!-- Filtri -->
      <div class="row mt-2 mb-0">

        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="filtroIndirizzoIP" class="title">Societa</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select [ngClass]="{'text-disabled': !opzioniFiltroSocieta.length}" id="selectSocieta" name="societaId"
                      #societaInput = "ngModel"
                      [disabled]="opzioniFiltroSocieta.length == 1"
                      [(ngModel)]="filtroSocieta">
                <option [ngValue]="null" selected>Tutti</option>
                <option *ngFor="let item of opzioniFiltroSocieta" [ngValue]="item.value">{{item.label}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="filtroFunzione" class="title">Funzione visitata</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select [ngClass]="{'text-disabled': !listaFunzioniAbilitate.length}" id="filtroFunzione" name="funzioneId"
                      #filtroFunzione = "ngModel"
                      [(ngModel)]="funzioneSelezionata">
                <option [ngValue]="null" selected>Tutti</option>
                <option *ngFor="let item of listaFunzioniAbilitate" [ngValue]="item.value">{{item.label}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': !isCampoInvalido(filtroFunzione), 'text-invalid': isCampoInvalido(filtroFunzione), 'text-disabled': !listaFunzioniAbilitate.length}">
                {{setPlaceholder(filtroFunzione, TipoCampoEnum.SELECT)}}
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="filtroIdUtente" class="title">User ID (Codice fiscale)</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p-autoComplete id="filtroIdUtente" class="pt-0" name="codiceFiscale"
                              pattern="^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$"
                              #filtroIdUtente = "ngModel" [(ngModel)]="idUtenteSelezionato"
                              [suggestions]="listaIdUtentiFiltrati" (completeMethod)="suggerimentiIdUtente($event)" (onClear)="listaIdUtentiFiltrati = []"
                              [minLength]="minCharsToRetrieveCF" maxlength="16" delay="0" autoHighlight="true">
              </p-autoComplete>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': !isCampoInvalido(filtroIdUtente), 'text-invalid': isCampoInvalido(filtroIdUtente)}">
                {{setPlaceholder(filtroIdUtente, TipoCampoEnum.INPUT_TESTUALE)}}
              </p>
            </div>
          </div>
        </div>

      </div>

      <div class="row mt-2 mb-0">

        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="filtroIndirizzoIP" class="title">Indirizzo IP</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input id="filtroIndirizzoIP" class="pt-0" name="indirizzoIP"
                     type="text"
                     pattern="^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$"
                     #filtroIndirizzoIP = "ngModel" [(ngModel)]="indirizzoIPSelezionato"
                     maxlength="15"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': !isCampoInvalido(filtroIndirizzoIP), 'text-invalid': isCampoInvalido(filtroIndirizzoIP)}">
                {{setPlaceholder(filtroIndirizzoIP, TipoCampoEnum.INPUT_TESTUALE)}}
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-3 pb-0">
          <div class="row">
            <div class="col">
              <label for="filtroIdUtente" class="title">Inizio sessione Da</label>
            </div>
          </div>

          <div class="row">
            <div class="col calendar-wrapper">
              <svg class="bg-light icon img-calendar" (click)="openDatepicker(filtroDataDaCalendar)">
                <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
              </svg>
              <dp-date-picker
                mode="day"
                id="filtroDataDa"
                theme="dp-material"
                dayInput
                [config]="{
                  locale: 'it',
                  format: formatoData,
                  firstDayOfWeek: 'mo',
                  showGoToCurrent: false,
                  openOnClick: false,
                  openOnFocus: false,
                  min: minDateDDMMYYYY,
                  returnedValueType: tipoData
	              }"
                #filtroDataDaCalendar
                #filtroDataDa = "ngModel"
                [(ngModel)]="dataDaSelezionata"
                name="inizioSessione"
                #filtroDataDa
              ></dp-date-picker>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': !isCampoInvalido(filtroDataDa), 'text-invalid': isCampoInvalido(filtroDataDa)}">
                {{setPlaceholder(filtroDataDa, TipoCampoEnum.DATEDDMMYY)}}
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-3 pb-0">
          <div class="row">
            <div class="col">
              <label for="filtroIdUtente" class="title">Inizio sessione A</label>
            </div>
          </div>

          <div class="row">
            <div class="col calendar-wrapper">
              <svg class="bg-light icon img-calendar" (click)="openDatepicker(filtroDataACalendar)">
                <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
              </svg>
              <dp-date-picker
                mode="day"
                id="filtroDataA"
                theme="dp-material"
                dayInput
                [config]="{
                  locale: 'it',
                  format: formatoData,
                  firstDayOfWeek: 'mo',
                  showGoToCurrent: false,
                  openOnClick: false,
                  openOnFocus: false,
                  min: minDateDDMMYYYY,
                  returnedValueType: tipoData
	              }"
                #filtroDataACalendar
                #filtroDataA = "ngModel"
                [(ngModel)]="dataASelezionata"
                name="fineSessione"
                #filtroDataA
              ></dp-date-picker>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': !isCampoInvalido(filtroDataA), 'text-invalid': isCampoInvalido(filtroDataA)}">
                {{setPlaceholder(filtroDataA, TipoCampoEnum.DATEDDMMYY)}}
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Bottoni Pulisci e Cerca -->
    <div class="row">
      <div class="col-6">
        <button id="bottonePulisci" type="button" class="btn btn-secondary" [disabled]="disabilitaBottonePulisci()"
                (click)="pulisciFiltri(filtroForm)">Pulisci</button>
      </div>
      <div class="col-6 btn-find">
        <button id="bottoneCerca" class="btn" [disabled]="disabilitaBottoneCerca(filtroForm)"
                (click)="cercaElementi()">Cerca</button>
      </div>
    </div>
  </div>
</form>
