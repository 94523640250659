<!-- Sottotitolo -->
<div class="collapse-header" id="idCollapseFiltri">
  <button
    data-toggle="collapse"
    data-target="#contenutoSezioneFiltri"
    aria-expanded="true"
    aria-controls="contenutoSezioneFiltri"
  >
    Filtri
  </button>
</div>

<!-- Form filtri -->
<form #filtroForm="ngForm">
  <div
    id="contenutoSezioneFiltri"
    class="collapse show"
    role="tabpanel"
    aria-labelledby="idCollapseFiltri"
  >
    <div class="collapse-body">
      <!-- Prima Riga -->
      <div class="row mt-2 mb-0">
        <!-- Società -->
        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="selectSocieta" class="title">Società</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select
                [ngClass]="{ 'text-disabled': !opzioniFiltroSocieta.length }"
                id="selectSocieta"
                name="societaId"
                id="societaInput"
                name="societaInput"
                #societaInput="ngModel"
                [disabled]="opzioniFiltroSocieta.length == 1"
                [(ngModel)]="filtriRicerca.societaId"
                (ngModelChange)="selezionaSocieta()"
              >
                <option [ngValue]="null" selected>Tutti</option>
                <option
                  *ngFor="let item of opzioniFiltroSocieta"
                  [ngValue]="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Ente Impositore -->
        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="selectEnteImpositore" class="title"
                >Ente Impositore</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select
                id="selectEnteImpositore"
                name="selectEnteImpositore"
                #enteImpositoreInput="ngModel"
                [(ngModel)]="filtriRicerca.enteImpositoreId"
                (ngModelChange)="selezionaEnteImpositore()"
              >
                <option [ngValue]="null" [selected]="true">Tutti</option>
                <option
                  *ngFor="let item of listaEntiImpositore"
                  [ngValue]="item.id"
                >
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Livello di Integrazione -->
        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label
                [htmlFor]="'livelloIntegrazione'"
                class="title form-elem-title"
                >Livello di Integrazione</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select
                id="livelloIntegrazione"
                name="livelloIntegrazione"
                [(ngModel)]="filtriRicerca.livelloIntegrazioneId"
                #livelloIntegrazione="ngModel"
                (ngModelChange)="selezionaLivelloIntegrazione()"
              >
                <option [ngValue]="null" [selected]="true">Tutti</option>
                <option
                  *ngFor="let item of listaLivelloIntegrazione"
                  [ngValue]="item.id"
                >
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>
        
      </div>

      <!-- Seconda Riga -->
      <div class="row mt-2 mb-0">
        <!-- Tipologia Servizio -->
        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="inputCodice" class="title">Tipologia Servizio</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select
                id="inputCodice"
                class="pt-0"
                name="codice"
                #filtroCodice="ngModel"
                [(ngModel)]="filtriRicerca.tipologiaServizio"
                [compareWith]="comparatorFiltroTipologiaServizio"
              >
                <option [ngValue]="null" selected>Tutti</option>
                <option
                  *ngFor="let item of opzioniFiltroTipologiaServizio$ | async"
                  [ngValue]="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Servizio -->
        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="servizio" class="title">Servizio</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select
                id="servizio"
                name="servizio"
                #servizio="ngModel"
                [(ngModel)]="filtriRicerca.servizioId"
              >
                <option [ngValue]="null" [selected]="true">Tutti</option>
                <option *ngFor="let item of listaServizi" [ngValue]="item.id">
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Raggruppamento -->
        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="selectRaggruppamento" class="title"
                >Raggruppamento</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select
                id="selectRaggruppamento"
                name="raggruppamento"
                #filtroRaggruppamento="ngModel"
                [(ngModel)]="filtriRicerca.raggruppamentoId"
              >
                <option [ngValue]="null" selected>Tutti</option>
                <option
                  *ngFor="let item of opzioniRaggruppamento"
                  [ngValue]="item.id"
                >
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!-- Terza Riga -->
      <div class="row mt-2 mb-0">
        <!-- Ente Beneficiario -->
        <div class="col-md-3 pb-2">
          <div class="row">
            <div class="col">
              <label for="selectEnteBenef" class="title"
                >Ente Beneficiario</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select
                id="selectEnteBenef"
                name="enteIdBenef"
                #enteBenefInput="ngModel"
                [(ngModel)]="filtriRicerca.enteBeneficiarioId"
              >
                <option [ngValue]="null" [selected]="true">Tutti</option>
                <option
                  *ngFor="let item of listaEntiBeneficiario"
                  [ngValue]="item.id"
                >
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Abilita da -->
        <div class="col-md-2 pb-2">
          <div class="row">
            <div class="col">
              <label for="scadenzaDataDa" class="title">Abilita Da</label>
            </div>
          </div>
          <div class="row">
            <div class="col calendar-wrapper">
              <svg
                class="bg-light icon img-calendar"
                (click)="openDatepicker(datePickerScadenzaDa)"
              >
                <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
              </svg>
              <dp-date-picker
                mode="day"
                id="scadenzaDataDa"
                theme="dp-material"
                [config]="{
                  locale: 'it',
                  format: 'DD/MM/YYYY',
                  firstDayOfWeek: 'mo',
                  showGoToCurrent: false,
                  openOnClick: isCalendarOpen,
                  openOnFocus: false,
                  min: minDateDDMMYY,
                  returnedValueType: tipoData
                }"
                #scadenzaDaInput="ngModel"
                [(ngModel)]="filtriRicerca.abilitaDa"
                name="abilitaDa"
                #datePickerScadenzaDa
                (click)="isCalendarOpen = false"
              ></dp-date-picker>
            </div>
          </div>
        </div>

        <!-- Abilita A -->
        <div class="col-md-2 pb-2">
          <div class="row">
            <div class="col">
              <label for="scadenzaDataA" class="title">Abilita A</label>
            </div>
          </div>
          <div class="row">
            <div class="col calendar-wrapper">
              <svg
                class="bg-light icon img-calendar"
                (click)="openDatepicker(datePickerScadenzaA)"
              >
                <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
              </svg>
              <dp-date-picker
                mode="day"
                id="scadenzaDataA"
                theme="dp-material"
                [config]="{
                  locale: 'it',
                  format: 'DD/MM/YYYY',
                  firstDayOfWeek: 'mo',
                  showGoToCurrent: false,
                  openOnClick: isCalendarOpen,
                  openOnFocus: false,
                  min: minDateDDMMYY,
                  returnedValueType: tipoData
                }"
                #scadenzaAInput="ngModel"
                [(ngModel)]="filtriRicerca.abilitaA"
                name="abilitaA"
                #datePickerScadenzaA
                (click)="isCalendarOpen = false"
              ></dp-date-picker>
            </div>
          </div>
        </div>

        <!-- Attivo -->
        <div class="col-md-2 pb-2">
          <div class="row">
            <div class="col">
              <label for="switchAttivo" class="title">Attivo</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select
                id="switchAttivo"
                name="attivo"
                [(ngModel)]="filtriRicerca.attivo"
              >
                <option
                  *ngFor="let item of listaStatoServizio"
                  [ngValue]="item.id"
                >
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Tipo Flusso Rendicontazione -->
        <div class="col-md-3 pb-2">
          <div class="row">
            <div class="col">
              <label for="inputCodice" class="title"
                >Tipo flusso rendicontazione</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select
                id="inputFlusso"
                name="inputFlusso"
                [(ngModel)]="filtriRicerca.tipoFlussoId"
              >
                <option [ngValue]="null" [selected]="true">Tutti</option>
                <option
                  *ngFor="let item of listaFlussiRendicontazione"
                  [ngValue]="item.id"
                >
                  {{ item.codice }} - {{ item.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- Bottoni-->
      <div class="row">
        <div class="col-6">
          <button
            id="bottonePulisci"
            type="button"
            class="btn btn-secondary"
            (click)="pulisciFiltri(filtroForm)"
          >
            Pulisci
          </button>
        </div>
        <div class="col-6 text-right">
          <button id="bottoneCerca" class="btn" (click)="cercaElementi()">
            Cerca
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
