import { ViewportRuler } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ModalitaPaginaGestioneElemento } from '../../../../../../utility/enums/modalita-pagina-gestione-elemento';
import { AmministrativoService } from '../../../../../../services-old/amministrativo.service';
import { BannerService } from '../../../../../../services-old/banner.service';
import { OverlayService } from '../../../../../../services-old/overlay.service';
import { RoutingService } from '../../../../../../services-old/routing.service';
import {FormElementoParentComponent} from '../../form-elemento-parent.component';
import { FlussoRendicontazioneService } from '../../../../../../services-old/flusso-rendicontazione.service';
import {CdkDragDrop, CdkDropList, CdkDropListGroup} from '@angular/cdk/drag-drop';
import {Breadcrumb, SintesiBreadcrumb} from '../../../../dto/Breadcrumb';
import {Observable, of} from 'rxjs';
import { CampoTipoRendicontazione } from '../../../../../../modules/main/model/flussiRenditcontazione/CampoTipoRendicontazione';
import * as _ from 'lodash';
import { FlussoRendicontazione } from '../../../../../../modules/main/model/flussiRenditcontazione/FlussoRendicontazione';
import { InserimentoCampoTipoRendicontazione } from '../../../../../../modules/main/model/flussiRenditcontazione/InserimentoCampoTipoRendicontazione';
import { DatiModaleTipoFlussoRendicontazione } from '../modale-flussi-rendicontazione/modale-flussi-rendicontazione.component';
import { Utils } from '../../../../../../utility/Utils';
import {TipoModaleEnum} from '../../../../../../enums/tipoModale.enum';
import {TipoCampoEnum} from '../../../../../../enums/tipoCampo.enum';
import { aggiungiTipoCampoEvent } from '../../gestisci-tipologia-servizio/modale-campo-form/modale-aggiungi-tipo-campo/modale-aggiungi-tipo-campo.component';
import { aggiornaTipoCampoEvent } from '../../gestisci-tipologia-servizio/modale-campo-form/modale-campo-form.component';
import {CampoTipologiaServizioService} from '../../../../../../services-old/campo-tipologia-servizio.service';
import { ConfiguratoreCampiNuovoPagamento } from 'src/app/modules/main/model/campo/ConfiguratoreCampiNuovoPagamento';
import {flatMap, map} from 'rxjs/operators';

@Component({
  selector: 'app-form-flussi-rendicontazione',
  templateUrl: './form-flussi-rendicontazione.component.html',
  styleUrls: ['./form-flussi-rendicontazione.component.scss']
})
export class FormFlussiRendicontazioneComponent extends FormElementoParentComponent implements OnInit {

  private refreshItemsEvent: EventEmitter<any> = new EventEmitter<any>();


  funzione: ModalitaPaginaGestioneElemento;
  FunzioneGestioneEnum = ModalitaPaginaGestioneElemento;
  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  titoloPagina: string;
  tooltip: string;
  dettaglioFlussoRendicontazione: number;
  waiting = false;
  id
  nome: string;
  descrizione: string;
  showEditId: string | number;
  showCopiaDa: boolean = false
  showAggiungiCampo: boolean = false
  listaFlussiRendicontazione: FlussoRendicontazione[] = [];
  listaCampiAssociati: CampoTipoRendicontazione[] = []
  listaCampiAssociatiOriginal: CampoTipoRendicontazione[] = null
  tipoFlusso: FlussoRendicontazione = null;
  tipoFlussoPrecedente: FlussoRendicontazione = null;
  tipoFlussoOriginal: FlussoRendicontazione = null;
  inserimentoCampoTipoRendicontazione: InserimentoCampoTipoRendicontazione
  breadcrumbList: Breadcrumb[] = [];
  readonly lunghezzaMaxCol1: number = 5;
  readonly lunghezzaMaxCol2: number = 10;
  readonly lunghezzaMaxCol3: number = 15;
  private listaTipiCampo: any[];
  flagRendicontazione: boolean = false
  flagTipologiaServizio: boolean = false
  showReject: boolean = false

  constructor( private cdr: ChangeDetectorRef,
    private overlayService: OverlayService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected http: HttpClient,
    protected amministrativoService: AmministrativoService,
    private viewportRuler: ViewportRuler,
    protected confirmationService: ConfirmationService,
    private flussoRendicontazioneService: FlussoRendicontazioneService,
    private bannerService: BannerService,
    public campoTipologiaServizioService: CampoTipologiaServizioService,
    private routingService: RoutingService) {
    super(confirmationService, activatedRoute, amministrativoService, http, router);
    this.target = null;
    this.source = null;
  }

  ngOnInit(): void {
    this.amministrativoService.salvaCampoFormEvent = new EventEmitter<any>();
    this.listaTipiCampo = JSON.parse(localStorage.getItem('listaTipiCampo'))
    aggiungiTipoCampoEvent.subscribe(idTipoCampo => {
      this.impostaConfigurazioneCampi(of(null), idTipoCampo);
    });
  }

  initFormPage() {
    this.controllaTipoFunzione();
    this.inizializzaBreadcrumb();
    this.titoloPagina = this.getTestoFunzione(this.funzione) + ' Tipologia flussi rendicontazione';
    this.tooltip = 'In questa pagina puoi ' + this.getTestoFunzione(this.funzione, false) + ' i dettagli di una tipologia flusso rendicontazione';
    if (this.funzione === ModalitaPaginaGestioneElemento.MODIFICA || this.funzione === ModalitaPaginaGestioneElemento.DETTAGLIO) {
      this.dettaglioFlussoRendicontazione = parseInt(this.activatedRoute.snapshot.paramMap.get('flussoRendicontazioneId'));
      this.impostaDettaglioTipologia();
    } else
      this.caricaCodiciFlussoRendicontazione()
    let self = this;
    this.amministrativoService.salvaCampoFormEvent.subscribe((datiModaleTipoFlussoRendicontazione: DatiModaleTipoFlussoRendicontazione) => {
      if(datiModaleTipoFlussoRendicontazione.isModifica == true){
        this.listaCampiAssociati[datiModaleTipoFlussoRendicontazione.indexInLista] = datiModaleTipoFlussoRendicontazione.campoForm
      }else
        self.listaCampiAssociati.push(datiModaleTipoFlussoRendicontazione.campoForm)
      self.overlayService.mostraModaleTipoFlussoRendicontazione.emit(null);
    });
  }

  impostaConfigurazioneCampi(observableIniziale = of(null), idTipoCampo: number = null): void {
    let observable: Observable<number> = this.campoTipologiaServizioService.letturaConfigurazioneCampiNuovoPagamento(this.idFunzione)
    .pipe(map((configuratore: ConfiguratoreCampiNuovoPagamento) => {
      localStorage.setItem('listaCampiDettaglioTransazione', JSON.stringify(configuratore.listaCampiDettaglioTransazione));
      localStorage.setItem('listaControlliLogici', JSON.stringify(configuratore.listaControlliLogici));
      localStorage.setItem('listaTipologiche', JSON.stringify(configuratore.listaTipologiche));
      localStorage.setItem('listaJsonPath', JSON.stringify(configuratore.listaJsonPath));
      localStorage.setItem('listaTipiCampo', JSON.stringify(configuratore.listaTipiCampo));
      if (idTipoCampo) {
        aggiornaTipoCampoEvent.emit(idTipoCampo);
      }
    })).pipe(flatMap(() => observableIniziale));
    observable.subscribe();

  }

  caricaCodiciFlussoRendicontazione(): void{
    this.flussoRendicontazioneService.recuperaFlussiRendicontazione(this.idFunzione).subscribe(listaFlussiRendicontazione=>{
      this.listaFlussiRendicontazione = listaFlussiRendicontazione
      if(this.funzione === ModalitaPaginaGestioneElemento.MODIFICA)
        this.removeFlussoRendicontazione()
    })
  }

  recuperaCampiAssociati(){
    if(this.funzione === ModalitaPaginaGestioneElemento.MODIFICA && this.listaCampiAssociati.length > 0)
    this.confirmationService.confirm(
      Utils.getModale(() => {
         this.getTipoFlusso();
         this.listaCampiAssociati = []
        },
        TipoModaleEnum.CUSTOM,
          'Conferma modifiche',
          'La modifica del tipo flusso di rendicontazione elimina tutte le associazioni tra i campi tipologia rendicontazione e i campi tipo flusso rendiontazione rendicontazione.    '+
          'Le associazioni dopo il salvataggio non potranno più essere ripristinate.    ' +
          'Si vuole procedere comunque?',
          ()=>{
            this.tipoFlusso = this.tipoFlussoPrecedente ? this.listaFlussiRendicontazione?.find(x => x.id==this.tipoFlussoPrecedente?.id) : null
          }
      )
    );
    else
      this.getTipoFlusso()
  }

  removeFlussoRendicontazione(): void{
    let index
    this.listaFlussiRendicontazione.forEach((flusso, i) =>{
      if(flusso.id == this.id)
        index = i
    });
    if (index > -1)
      this.listaFlussiRendicontazione.splice(index, 1);
  }

  getTipoFlusso() {
    this.tipoFlussoPrecedente = this.tipoFlusso
    if(this.tipoFlusso != null)
      this.flussoRendicontazioneService.recuperaCampoTipoRendicontazione(this.tipoFlusso.id.toString(), this.idFunzione).subscribe(listaCampiAssociati=>{
        this.listaCampiAssociati = listaCampiAssociati
      })
  }

  controllaTipoFunzione() {
    const url = this.activatedRoute.snapshot.url[1].path;
    switch (url) {
      case 'dettaglioFlussoRendicontazione':
        this.funzione = ModalitaPaginaGestioneElemento.DETTAGLIO;
        break;
      case 'aggiungiFlussoRendicontazione':
        this.funzione = ModalitaPaginaGestioneElemento.AGGIUNGI;
        break;
      case 'modificaFlussoRendicontazione':
        this.funzione = ModalitaPaginaGestioneElemento.MODIFICA;
        break;
    }
  }

  inizializzaBreadcrumb(): void {
    const breadcrumbs: SintesiBreadcrumb[] = []
    breadcrumbs.push(new SintesiBreadcrumb('Gestisci Anagrafiche', null));
    breadcrumbs.push(new SintesiBreadcrumb('Tipo Flusso Rendicontazione', this.basePath));
    breadcrumbs.push(new SintesiBreadcrumb(this.getTestoFunzione(this.funzione) + ' Flussi rendicontazione', null));
    this.breadcrumbList = this.inizializzaBreadcrumbList(breadcrumbs);
  }

  impostaDettaglioTipologia(): void {
    this.flussoRendicontazioneService.recuperaDettaglio(this.dettaglioFlussoRendicontazione, this.idFunzione).subscribe(tipologiaServizio  => {
      this.nome = tipologiaServizio["nome"]
      this.id = tipologiaServizio["id"]
      this.descrizione = tipologiaServizio["descrizione"]
      this.listaCampiAssociati = tipologiaServizio["listaCampiTipoRendicontazione"]
      this.flagRendicontazione = tipologiaServizio["flagRendicontazione"]
      this.flagTipologiaServizio = tipologiaServizio["flagTipologiaServizio"]
      if(this.listaCampiAssociatiOriginal == null)
        this.listaCampiAssociatiOriginal = JSON.parse(JSON.stringify(tipologiaServizio["listaCampiTipoRendicontazione"]))
      this.caricaCodiciFlussoRendicontazione()
    });
  }

  calcolaDimensioneCampo(campo): string {
    let classe;
    // if (this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.DATEDDMMYY ||
    //   this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.DATEMMYY ||
    //   this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.DATEYY) {
    //   classe = 'col-lg-2 col-md-4 col-xs-6';
    // } else if (this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.INPUT_PREZZO) {
    //   classe = 'col-lg-2 col-md-4 col-xs-6';
    // } else {
    //  if (campo.lunghezza <= this.lunghezzaMaxCol1 || campo.lunghezza == undefined) {
    //     classe = 'col-lg-2 col-md-4 col-xs-6';
    //   } else if (campo.lunghezza <= this.lunghezzaMaxCol2) {
    //     classe = 'col-lg-3 col-md-4 col-xs-6';
    //   } else if (campo.lunghezza <= this.lunghezzaMaxCol3) {
    //     classe = 'col-lg-4 col-md-5 col-xs-6';
    //   } else {
        classe = 'col-lg-4 col-md-5 col-xs-6';
    //   }
    // }
    return classe;
  }

  private decodeTipoCampo(tipoCampoId: number): string {
    let find = this.listaTipiCampo.find((value) => value.id = tipoCampoId);
    if (find)
      return find.nome;
    else
      return "";
  }

  removeItem(item){
    if (this.funzione != ModalitaPaginaGestioneElemento.DETTAGLIO) {
      this.confirmationService.confirm(
        Utils.getModale(() => {
            this.listaCampiAssociati.splice(this.listaCampiAssociati.findIndex((v) => v.id === item.id), 1);
            this.refreshItemsEvent.emit(this.listaCampiAssociati);
          },
          TipoModaleEnum.ELIMINA,
        )
      );
    }
  }

  dblClick(item, i){

  }

  showModalAtClick(item, index: number) {
    item.riempimentoSinistro = item.riempimentoSinistro == true ? 1 : 0
    setTimeout(() => {
        this.overlayService.mostraModaleTipoFlussoRendicontazione.emit({
          campoForm: _.cloneDeep(item),
          idFunzione: this.idFunzione,
          funzione: this.funzione,
          idFlussoRendicontazione: this.tipoFlusso ? this.tipoFlusso.id : null,
          isModifica: true,
          indexInLista: index,
          disabilitaCampi: this.funzione == this.FunzioneGestioneEnum.DETTAGLIO || this.flagRendicontazione,
          flagTipoCampoAssociato: this.flagTipologiaServizio
        });
      }, 250);
  }

  add() {
    const campoForm = new CampoTipoRendicontazione();
    this.refreshItemsEvent.emit(this.listaCampiAssociati);
    this.showModal(campoForm);
  }

  showModal(item: CampoTipoRendicontazione) {
    this.overlayService.mostraModaleTipoFlussoRendicontazione.emit({
      campoForm: _.cloneDeep(item),
      idFunzione: this.idFunzione,
      funzione: this.funzione,
      idFlussoRendicontazione: this.tipoFlusso ? this.tipoFlusso.id : null,
      isModifica: false,
      indexInLista: null,
      disabilitaCampi: false,
      flagTipoCampoAssociato: false
    });
  }

  abilitaSalva(): boolean {
    return this.nome != null && this.nome != "";
  }

  onClickSalva(): void{
    let inserimentoCampoTipoRendicontazione = new InserimentoCampoTipoRendicontazione()
    inserimentoCampoTipoRendicontazione.id = this.id
    inserimentoCampoTipoRendicontazione.nome = this.nome
    inserimentoCampoTipoRendicontazione.descrizione = this.descrizione
    inserimentoCampoTipoRendicontazione.listaCampiTipoRendicontazione = this.listaCampiAssociati
    this.flussoRendicontazioneService.inserisciTipoFlussoRendicontazione(inserimentoCampoTipoRendicontazione, this.idFunzione).subscribe(id=>{
      if (this.funzione === ModalitaPaginaGestioneElemento.AGGIUNGI && id) {
        if(this.listaCampiAssociati.length == 0)
          this.routingService.configuraRouterAndNavigate(this.basePath + '/modificaFlussoRendicontazione/' + id);
        else
          this.routingService.configuraRouterAndNavigate(this.basePath + '/aggiungiFlussoRendicontazione');
      }
    })
  }

  onClickAnnullaIndietro(funzione: ModalitaPaginaGestioneElemento): void {
    this.showReject = true
    if (funzione === ModalitaPaginaGestioneElemento.DETTAGLIO) {
      this.tornaIndietro();
    } else {
      this.confirmationService.confirm(
        Utils.getModale(() => {
            this.tornaIndietro();
          },
          TipoModaleEnum.ANNULLA,
          null,
          null,
          ()=>{this.showReject = false}
        )
      );
    }
  }

  crea(): void{
    this.showCopiaDa = true
  }

  showCrea(): boolean{
    return (this.funzione == ModalitaPaginaGestioneElemento.AGGIUNGI) ||
      (this.funzione == ModalitaPaginaGestioneElemento.MODIFICA && this.listaCampiAssociatiOriginal?.length == 0)
  }

  showCreateDelete(): boolean{
    return !(this.flagRendicontazione || this.flagTipologiaServizio)
  }

  getNomeFlussoSelect(nome, descrizione){
    return nome + (descrizione!=null ? " - " + descrizione : "")
  }
}
