import jsPDF from "jspdf";
import * as XLSX from 'xlsx';
import * as moment from 'moment';

export class FilePrinter {

  private static readonly MOMENT_FORMAT = 'DD-MM-YYYY HH:mm'

  static stampaPdf(pdf: jsPDF, filename: string, timecode: boolean = true): void {
    if (timecode) {
      filename += "_export_" + moment().format(this.MOMENT_FORMAT) + ".pdf"
    }
    pdf.setProperties({ title: filename })
    const blob = pdf.output('blob');
    window.open(URL.createObjectURL(blob));
  }

  static stampaExcel(workbook: XLSX.WorkBook, filename: string, timecode: boolean = true): void {
    if (timecode) {
      filename += "_export_" + moment().format(this.MOMENT_FORMAT) + ".xlsx"
    }

    XLSX.writeFile(workbook, filename)
  }

  constructor() { }
}
