import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

type ErrorInfo = {
  code: string;
  message: string;
}

type BackUrlResponse = {
  url: string;
}

@Component({
  templateUrl: './errore.page.html',
  styleUrls: ['./errore.page.scss']
})
export class ErrorePage implements OnInit {

  urlBack: string;
  private error: ErrorInfo;

  get code(): string {
    return this.error.code;
  }

  get message(): string {
    return this.error.message;
  }

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {
    this.error = route.snapshot.data.errorinfo;
  }

  ngOnInit(): void {
    this.http.get<BackUrlResponse>(environment.bffBaseUrl + '/getBackUrl',
      {
        withCredentials: true
      })
      .pipe(map(res => {
        if (res && res.url) {
          this.urlBack = res.url
        }
      })).subscribe();
  }

  tornaAlServizio() {
    window.location.href = this.urlBack;
  }

}
