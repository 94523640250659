import * as moment from 'moment';
import { ControlloLogico } from './ControlloLogico';
import { OpzioneSelect } from './OpzioneSelect';

export class CampoForm {
  id: number;
  titolo: string;
  obbligatorio: boolean;
  tipoCampo: string;
  informazioni: string;
  lunghezzaVariabile: boolean;
  lunghezza: number;
  campoFisso: boolean;
  disabilitato: boolean;
  nonVisibile: boolean;
  posizione: number;
  chiave: boolean;
  controllo_logico: ControlloLogico;
  campoInput: boolean;
  jsonPath: string;
  tipologica: string;
  campoDettaglioTransazione: string;
  dipendeDa: number;
  opzioni: Array<OpzioneSelect>;
  codice: string; // Campo "codice" di CAMPO_SERVIZIO, identificativo personalizzabile dall'ente
  isCampoTipologiaServizio: boolean; // variabile aggiunta per usarla in coppia con l'id per creare un identificativo univoco
}

function readNumber(value: number): number {
  if (!isNaN(value) && Number.isFinite(value)) {
    return value;
  }

  return null;
}

function isValidRegex(value: string, campo: CampoForm): boolean {
  if (campo.controllo_logico?.regex) {
    const regex = new RegExp(campo.controllo_logico.regex);

    return regex.test(value);
  }

  return true;
}

export function readValueForCampoForm(value: string, campo: CampoForm): any {
  if (value) {
    switch (campo.tipoCampo) {
      case 'string':
        if (isValidRegex(value, campo)) {
          return value;
        }
        break;
      case 'number':
        return readNumber(parseInt(value));
      case 'date':
        if (moment(value, 'DD/MM/YYYY').isValid()) {
          return value;
        }
        break;
      case 'datemmyy':
        if (moment(value, 'MM/YYYY').isValid()) {
          return value;
        }
        break;
      case 'dateyy':
        return readNumber(parseInt(value));
      case 'importo':
        return readNumber(parseFloat(value));
      case 'select':
        if (campo.tipologica === 'anno') {
          const anno = readNumber(parseInt(value));
          if (anno) {
            if (anno >= campo.opzioni[0].value && anno <= campo.opzioni[campo.opzioni.length - 1].value) {
              return anno;
            }
          }
        } else {
          let option = undefined;
          if (campo.tipologica === 'province' || campo.tipologica === 'comuni') {
            option = campo.opzioni.find(opt => { return opt.value.toUpperCase() === value.toUpperCase() });
          }
          else {
            option = campo.opzioni.find(opt => { return opt.value === value });
          }

          if (option) {
            return option.value;
          }
        }
        break;
      default:
        console.error("Tipo campo [" + this.tipoCampo + "] sconosciuto: " + this.titolo);
        break;
    }
  }
  return null;
}

