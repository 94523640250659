<div class="ml-3">
  <form #accessoForm="ngForm">

    <div class="row mt-4 mb-0">

      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="codiceFiscale" class="title">Codice Fiscale</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              disabled
              id="codiceFiscale" name="codiceFiscale"
              [(ngModel)]="codiceFiscale"
            />
          </div>
        </div>
      </div>

      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="nominativo" class="title">Nominativo</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              disabled
              [(ngModel)]="nominativo"
              id="nominativo" name="nominativo"
            />
          </div>
        </div>
      </div>

      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="indirizzoIP" class="title">Indirizzo IP</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              id="indirizzoIP" name="indirizzoIP"
              type="text"
              disabled
              [(ngModel)]="indirizzoIP"
            />
          </div>
        </div>
      </div>

    </div>

    <div class="row mt-4 mb-0">

      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="inizioSessione" class="title">Inizio Sessione</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              id="inizioSessione" name="inizioSessione"
              type="text"
              disabled
              [(ngModel)]="inizioSessione"
            />
          </div>
        </div>
      </div>

      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="fineSessione" class="title">Fine Sessione</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              id="fineSessione" name="fineSessione"
              type="text"
              disabled
              [(ngModel)]="fineSessione"
            />
          </div>
        </div>
      </div>

      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="durataSessione" class="title">Durata Sessione</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              id="durataSessione" name="durataSessione"
              type="text"
              disabled
              [(ngModel)]="durataSessione"
            />
          </div>
        </div>
      </div>

    </div>

    <div class="row mt-4 mb-0">
      <div *ngFor="let funzione of listaFunzioni; let i = index" class="col-3">
        <it-checkbox [id]="i" [label]="funzione.label"
                     [checked]="isChecked(funzione)"
                     [disabled]="funzione.disabled"></it-checkbox>
      </div>
    </div>

  </form>
</div>
