<div id="collapseHeader">
  <ng-content select="#collapseHeaderPrefix"></ng-content>
  <button type="button" class="d-flex justify-content-between align-items-center px-3 py-2" data-toggle="collapse" [attr.data-target]="'#' + id">
    <span class="m-0" [ngClass]="this.headerClass">{{header}}</span>
    <svg class="icon">
      <use href="assets/icons/bootstrap-italia-icons.svg#it-collapse"></use>
    </svg>
  </button>
  <ng-content select="#collapseHeaderSuffix"></ng-content>
</div>
<div [id]="id" class="collapse show px-3 pb-2">
  <ng-content></ng-content>
</div>
