import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalitaPaginaGestioneElemento } from '../../../../../../utility/enums/modalita-pagina-gestione-elemento';
import { CampoTipoRendicontazione } from '../../../../../../modules/main/model/flussiRenditcontazione/CampoTipoRendicontazione';
import {OverlayService} from '../../../../../../services-old/overlay.service';
import {AmministrativoService} from '../../../../../../services-old/amministrativo.service';
import { TipoCampo } from '../../../../../../modules/main/model/campo/TipoCampo';
import { ConfirmationService } from 'primeng/api';
import {TipoModaleEnum} from '../../../../../../enums/tipoModale.enum';
import { Utils } from '../../../../../../utility/Utils';
import { aggiungiTipoCampoEvent } from '../../gestisci-tipologia-servizio/modale-campo-form/modale-aggiungi-tipo-campo/modale-aggiungi-tipo-campo.component';
import { aggiornaTipoCampoEvent } from '../../gestisci-tipologia-servizio/modale-campo-form/modale-campo-form.component';


export interface DatiModaleTipoFlussoRendicontazione {
  campoForm: CampoTipoRendicontazione,
  idFunzione: number,
  funzione: ModalitaPaginaGestioneElemento;
  idFlussoRendicontazione: number;
  isModifica: boolean,
  indexInLista: number,
  disabilitaCampi: boolean,
  flagTipoCampoAssociato: Boolean;
}


@Component({
  selector: 'app-modale-flussi-rendicontazione',
  templateUrl: './modale-flussi-rendicontazione.component.html',
  styleUrls: ['./modale-flussi-rendicontazione.component.scss']
})
export class ModaleFlussiRendicontazioneComponent implements OnInit {
  @Input()
  datiModaleTipoFlussoRendicontazione: DatiModaleTipoFlussoRendicontazione;

  form: FormGroup;
  funzione: ModalitaPaginaGestioneElemento;
  FunzioneGestioneEnum = ModalitaPaginaGestioneElemento;
  disable: boolean = false;
  disableTipoCampo: boolean
  listaTipiCampo: TipoCampo[];

  constructor(private overlayService: OverlayService,
    private amministrativoService: AmministrativoService,
    protected confirmationService: ConfirmationService
    ) {
  this.form = new FormGroup({
    id: new FormControl(null),
    tipoRendicontazioneId: new FormControl(null),
    nome: new FormControl(null),
    ordine: new FormControl(null),
    lunghezza: new FormControl(null),
    valoreCostante: new FormControl(null),
    tipoCampoId: new FormControl(null),
    carattereRiempimento: new FormControl(null),
    riempimentoSinistro: new FormControl(null)
  });
  aggiornaTipoCampoEvent.subscribe((idTipoCampo) => {
    this.datiModaleTipoFlussoRendicontazione.campoForm.tipoCampoId = idTipoCampo;
    this.listaTipiCampo = JSON.parse(localStorage.getItem('listaTipiCampo'));
  });
}

  ngOnInit(): void {
    this.funzione = this.datiModaleTipoFlussoRendicontazione.funzione
    if(this.datiModaleTipoFlussoRendicontazione.isModifica == false)
      this.initForm()
    this.listaTipiCampo = JSON.parse(localStorage.getItem('listaTipiCampo'));
    this.datiModaleTipoFlussoRendicontazione.campoForm.tipoRendicontazioneId = this.datiModaleTipoFlussoRendicontazione.idFlussoRendicontazione
    this.disable = (this.funzione == ModalitaPaginaGestioneElemento.DETTAGLIO || this.datiModaleTipoFlussoRendicontazione.disabilitaCampi) ? true : null
    this.disableTipoCampo = (this.funzione == ModalitaPaginaGestioneElemento.DETTAGLIO || this.datiModaleTipoFlussoRendicontazione.disabilitaCampi || this.datiModaleTipoFlussoRendicontazione.flagTipoCampoAssociato) ? true : null
  }

  salvaCampoForm(){
    let form = this.datiModaleTipoFlussoRendicontazione.campoForm
    if(form.valoreCostante != null && form.valoreCostante.length > form.lunghezza)
    this.confirmationService.confirm(
      Utils.getModale(() => {},
        TipoModaleEnum.CUSTOM,
        'Attenzione',
        'Il valore contenuto nel campo valore costante deve essere della lunghezza definita nell’attributo lunghezza, cambiare il valore per poter procedere'
      )
    );
    else
      this.amministrativoService.salvaCampoFormEvent.emit(this.datiModaleTipoFlussoRendicontazione);
  }

  initForm(): void {
    this.datiModaleTipoFlussoRendicontazione.campoForm.riempimentoSinistro = 0
  }

  clickIndietro() {
    this.overlayService.mostraModaleTipoFlussoRendicontazione.emit(null);
  }

  disabilitaSalva(): boolean{
    let form = this.datiModaleTipoFlussoRendicontazione.campoForm
    return (form.nome == null || form.lunghezza == null ||
      form.ordine == null || form.riempimentoSinistro == null || form.tipoCampoId == null)
  }

  addTipoCampo() {
    this.overlayService.mostraModaleTipoCampoEvent.emit(this.datiModaleTipoFlussoRendicontazione.idFunzione);
  }
}
