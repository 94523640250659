export const ListaStatiSelect = [
  {
    id: null,
    nome: "Tutti"
  },
  {
    id: true,
    nome: "Si"
  },
  {
    id: false,
    nome: "No"
  }
]