<div class="container-fluid">
  <!-- breadcrumb -->
  <it-breadcrumb [dark]="false" [separator]="'/'">
    <it-breadcrumb-item [id]="'breadcrumb-item-' + item.id" *ngFor="let item of breadcrumbList; let i = index"
                        >
      {{item.label}}
    </it-breadcrumb-item>
  </it-breadcrumb>

  <!-- titolo -->
  <h3 class="mb-2 page-title"><strong>{{titoloPagina}}</strong>
    <span it-tooltip="{{tooltip}}">
      <svg class="bg-light icon icon-lg icon-padded">
        <use xlink:href="assets/img/sprite.svg#it-help-circle"></use>
      </svg>
    </span>
  </h3>

  <!-- sezione dati banner per aggiungi/modifica/dettaglio -->
  <app-dati-banner [funzione]="funzione" [datiBanner]="datiBanner" (onValidaForm)="onChangeForm($event)"></app-dati-banner>

  <hr/>

  <!-- Bottoni -->
  <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
    <!-- Bottone sx -->
    <div class="col-2">
      <button type="button" id="buttonAnnulla" class="btn btn-secondary" (click)="onClickAnnulla(funzione)">Annulla</button>
    </div>
    <!-- Bottoni dx -->
    <div class="col-10 text-right">
      <button type="button" id="buttonSalva" class="btn" [disabled]="disabilitaBottone()" (click)="onClickSalva()">Salva</button>
    </div>
  </div>
  <div class="row pt-4" *ngIf="funzione === FunzioneGestioneEnum.DETTAGLIO">
    <button type="button" id="buttonIndietro" class="btn btn-secondary btn-indietro" (click)="onClickAnnulla(funzione)">Indietro</button>
  </div>
</div>

<!-- Modale -->
<p-confirmDialog #cb icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" class="undoButton" pButton icon="pi pi-times" label="Annulla" (click)="cb.reject()"></button>
    <button type="button" class="okButton" pButton icon="pi pi-check" label="Ok" (click)="cb.accept()"></button>
  </p-footer>
</p-confirmDialog>
