import { FilePrinter } from './FilePrinter';
import { DettaglioQuadratura } from '../../modules/main/model/quadratura/DettaglioQuadratura';
import * as XLSX from 'xlsx';
import * as FILESAVER from 'file-saver';
import * as moment from 'moment';

export class ExcelPrintUtils {
  constructor() {}

  static salvaComeFileExcel(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FILESAVER.saveAs(data, fileName + '_export_' + moment().format('DD-MM-YYYY HH:mm') + EXCEL_EXTENSION);
  }

  static stampaFileExcel(excel : DatiStampaExcel): void {
    const workbook = XLSX.utils.book_new()

    Object.entries(excel.sheets).forEach(([sheetName, data]) => {
      const worksheet = XLSX.utils.aoa_to_sheet(data)
      worksheet['!cols'] = this.fitToColumn(data)
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)
    })

    FilePrinter.stampaExcel(workbook, excel.filename)
    // const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    // this.salvaComeFileExcel(excelBuffer, excel.filename);
  }

  private static fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
  }



}

export interface DatiStampaExcel {
  filename: string
  sheets: {
    [sheetName: string] : string[][]
  }
}

export class ExcelSheet {
  headers: string[]
  rows: any[]

  constructor() {
    this.headers = []
    this.rows = []
  }
}

export class ExcelBuilder {
  filename: string
  sheets: {
    [sheet: string]: ExcelSheet
  }

  constructor(filename: string) {
    this.filename = filename
    this.sheets = {}
  }

  print(): void {
    const workbook = XLSX.utils.book_new()
    const sheetKeys = Object.keys(this.sheets).sort()

    sheetKeys.forEach((sk, i, keys) => {
      const sheet = this.sheets[sk]
      const data = [sheet.headers]

      sheet.rows.forEach((r) => {
        const row = []
        sheet.headers.forEach((h) => {
          row.push(r[h])
        })
        data.push(row)
      })

      const worksheet = XLSX.utils.aoa_to_sheet(data)
      worksheet['!cols'] = ExcelBuilder.fitToColumn(data)
      XLSX.utils.book_append_sheet(workbook, worksheet, sk)
    })

    FilePrinter.stampaExcel(workbook, this.filename)
  }

  private static fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
  }
}

