import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDatepickerModule, NgbDropdownModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { DesignAngularKitModule } from "design-angular-kit";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DpDatePickerModule } from "ng2-date-picker";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { PipeModule } from "../../pipe/pipe.module";
import { AmministrativoModule } from "./components/amministrativo/amministrativo.module";
import { CarrelloComponent } from "./components/carrello/carrello.component";
import { CarrelloL1Component } from './components/carrelloL1/carrello-l1.component';
import { HomeriservataComponent } from './components/homeriservata/homeriservata.component';
import { FiltriIMieiPagamentiComponent } from './components/i-miei-pagamenti/filtri-i-miei-pagamenti/filtri-i-miei-pagamenti.component';
import { ListaPagamentiComponent } from './components/lista-pagamenti/lista-pagamenti.component';
import { ListaPagamentiL1Component } from "./components/lista-pagamentiL1/lista-pagamenti-l1.component";
import { PresaincaricopagamentoComponent } from './components/presaincaricopagamento/presaincaricopagamento.component';
import { WaitingComponent } from './components/waiting/waiting.component';
import { WaitingL1Component } from "./components/waitingL1/waiting-l1.component";
import { MainRoutingModule } from './main-routing.module';

@NgModule({
  declarations: [
    HomeriservataComponent,
    CarrelloL1Component,
    CarrelloComponent,
    PresaincaricopagamentoComponent,
    ListaPagamentiL1Component,
    ListaPagamentiComponent,
    WaitingComponent,
    WaitingL1Component,
    FiltriIMieiPagamentiComponent,
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    DesignAngularKitModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    ConfirmDialogModule,
    DpDatePickerModule,
    DragDropModule,
    AmministrativoModule,
    PipeModule,
    NgMultiSelectDropDownModule,
  ],
  exports: [
    FiltriIMieiPagamentiComponent,
  ]
})
export class MainModule {
}
