import { Directive, HostListener, Input } from "@angular/core";
import { PayerTableComponent } from "../../components/table/table.component";

@Directive({
  selector: "[sortField]",
})
export class SortFieldDirective {
  @Input()
  sortField: string;

  constructor(private table: PayerTableComponent) {}

  @HostListener("click")
  sort(): void {
    this.table.requestSort(this.sortField);
  }
}
