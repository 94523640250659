import { TipologiaServizio } from "../tipologiaServizio/TipologiaServizio";

export class ParametriRicercaServizio {
  servizioId: number = null;
  raggruppamentoId: number = null;
  nomeServizio: string = null;
  abilitaDa: string = null;
  abilitaA: string = null;
  attivo: boolean = null;
  tipologiaServizio: TipologiaServizio = new TipologiaServizio();
  tipologiaServizioId: number = null;
  enteImpositoreId: number = null;
  enteBeneficiarioId: number = null;
  livelloIntegrazioneId: number = null;
  codiceTassonomia: string = null;
  codiceCarta: string = "86";
  attestato: boolean = null;
  tipoFlussoId: number = null;
  societaId: number = null;
}
