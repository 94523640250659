import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { of } from "rxjs";
import { AmministrativoService } from "src/app/services-old/amministrativo.service";

export abstract class PayerService {
  protected observeNull = function (err) {
    // console.error(err);
    return of(null);
  };

  protected observeEmptyObject = function (err) {
    // console.error(err);
    return of({});
  };

  protected observeEmptyList = function (err) {
    // console.error(err);
    return of([]);
  };

  protected observeNaN = function (err) {
    // console.error(err);
    return of(Number.NaN);
  };

  protected getHeadersConFunzioneAmministratore(
    idFunzione: number | string
  ): HttpHeaders {
    return new HttpHeaders({ idFunzione: idFunzione.toString() });
  }

  protected getParamsFromFiltro(filtro: any) {
    let params = new HttpParams();

    if (filtro) {
      Object.keys(filtro).forEach((param) => {
        if (filtro[param]) {
          params = params.set(param, filtro[param]);
        }
      });
    }

    return params;
  }
}
