<div class="policy-div">
  <div class="row justify-content-center">
    <div>
      <h2>
        Informativa Cookie per il sito payer.lepida.net
      </h2>
      <div class="row justify-content-center">
        <h5>
          v. 1.0 del 03.02.23
        </h5>
      </div>
    </div>
  </div>
  <hr>
  <div>
    <h3>Uso dei cookie</h3>
    <p>
      Il <strong>payer.lepida.net</strong> (nel seguito "Sito") utilizza alcuni Cookie per rendere i propri servizi
      semplici e efficienti per l'utenza che visiona le pagine del Sito. Gli utenti che visionano il Sito, vedranno
      inserite delle quantità minime di informazioni nei dispositivi in uso, che siano computer e periferiche mobili, in
      piccoli file di testo denominati "cookie" salvati nelle directory utilizzate dal browser web dell'Utente. Vi sono
      vari tipi di cookie, alcuni per rendere più efficace l'uso del Sito, altri per abilitare determinate funzionalità.
    </p>
    <p>
      Analizzandoli in dettaglio, i cookie utilizzati dal Sito permettono di:
    </p>
    <ul>
      <li>memorizzare le preferenze inserite;</li>
      <li>evitare di reinserire le stesse informazioni più volte durante la visita quali ad esempio nome utente e
        password;</li>
      <li>analizzare l'utilizzo dei servizi e dei contenuti forniti dal Sito per ottimizzarne l'esperienza di
        navigazione e i servizi offerti.</li>
    </ul>
  </div>

  <div>
    <h3>Tipologie di Cookie</h3>
    <div>

      <h4>Cookie tecnici</h4>
      <p>Sono cookie che permettono il corretto funzionamento di alcune sezioni del Sito. Possono essere di due
        tipologie:
      </p>
      <ul>
        <li>persistenti: una volta chiuso il browser non vengono distrutti ma rimangono fino ad una data di scadenza
          preimpostata; oppure</li>
        <li>di sessione: vengono rimossi ogni volta che il browser viene chiuso.</li>
      </ul>
      <p>
        Questi cookie, inviati sempre dal dominio del Sito (quindi, sempre di c.d. prima parte), sono necessari a
        visualizzare correttamente il sito e ad erogare i servizi tecnici offerti. Verranno quindi sempre utilizzati e
        inviati, a meno che l'utenza non modifichi le impostazioni nel proprio browser (tali impostazioni potrebbero
        però
        inficiare il corretto funzionamento del sito).
      </p>
      <p>
        Il Sito utilizza cookie appartenenti a questa tipologia.
      </p>
      <table class="cookie-table">
        <thead>
          <tr>
            <th>Cookie</th>
            <th>Tipologia</th>
            <th>Durata</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>AUTH_INFO</td>
            <td>di sessione</td>
            <td>non persistente</td>
          </tr>
          <tr>
            <td>idp sessione cookie</td>
            <td>di sessione</td>
            <td>non persistente</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <h4>
        Cookie analitici di prima parte
      </h4>
      <p>
        Sono cookie inviati solo dal dominio del Sito (quindi, sempre di c.d. prima parte) e utilizzati solo dal gestore
        del Sito stesso per realizzare analisi statistiche anonime: ad esempio per stimare il numero di visitatori
        unici, e per finalità di mero monitoraggio, come quella di individuare le principali parole chiave per i motori
        di ricerca che portano a una pagina web oppure di individuare le problematiche di navigazione del Sito.
      </p>
      <p>
        Tali dati non permettono di identificare personalmente l'utente perché vengono automaticamente "anonimizzati"
        prima
        di essere elaborati dal software di analisi statistica degli accessi web, pertanto questi cookie sono
        assimilabili a
        quelli tecnici e <strong>non necessitano del preventivo consenso degli utenti.</strong>
      </p>
      <p>
        Il Sito <strong>non utilizza</strong> cookie appartenenti a questa tipologia.
      </p>
    </div>


    <h4>
      Cookie per integrare prodotti e funzioni di software di terze parti
    </h4>
    <p>
      Sono cookie che integrano funzionalità sviluppate da terzi all'interno delle pagine del Sito come le icone e le
      preferenze espresse nei social network o per l'uso di servizi software di terze parti (come i software per
      generare
      le mappe e ulteriori software che offrono servizi aggiuntivi). Questi cookie sono inviati da domini di terze parti
      e
      da siti partner che offrono le loro funzionalità tra le pagine del Sito.
    </p>
    <p>
      Il Sito <strong>non utilizza</strong> cookie appartenenti a questa tipologia.
    </p>

    <h4>
      Cookie di profilazione/marketing
    </h4>
    <p>
      Sono cookie necessari a creare profili degli utenti al fine di inviare messaggi pubblicitari in linea con le
      preferenze manifestate dall'utente all'interno delle pagine del Sito.
    </p>
    <p>
      Il Sito <strong>non utilizza</strong> cookie appartenenti a questa tipologia.
    </p>

    <h4>
      Altri cookie di terze parti
    </h4>
    <p>
      Sono cookie gestiti direttamente da terzi, e sul cui contenuto Lepida S.c.p.A. non effettua alcun trattamento.
      Vengono ad esempio trasmessi cookie di terze parti quando dalle pagine Web si interagisce con i plugin di
      condivisione o con l'autenticazione attraverso le credenziali dei Social Media, quando si visualizzano dei video
      che
      risiedono su un canale Youtube o Vimeo, quando si visualizza un form contenente il componente di sicurezza
      ReCaptcha
      di Google.
    </p>
    <p>
      Il Sito <strong>non utilizza</strong> cookie appartenenti a questa tipologia.
    </p>
  </div>

  <h3>
    Consenso e informative
  </h3>
  <p>
    Il Sito, secondo la normativa vigente, <strong>non è tenuto a chiedere consenso per i cookie tecnici e/o analitici
      di prime parti e/o analitici di terze parti opportunamente oscurati</strong>, in quanto necessari a fornire i
    servizi richiesti.
  </p>
  <p>
    A norma dell'art. 122 del Codice per la protezione dei dati personali e delle Linee guida cookie e altri strumenti
    di tracciamento - 10 giugno 2021 (Pubblicato sulla Gazzetta Ufficiale n. 163 del 9 luglio 2021), Lepida S.c.p.A. può
    installare nel device dell'utente i cookies tecnici essenziali per il corretto funzionamento del Sito, nonché quelli
    analitici sopra descritti, poiché assimilati dalla normativa a quelli tecnici, anche senza il preventivo consenso
    dello stesso, fermo restando l'obbligo di informativa ai sensi dell'art. 13 del Regolamento UE n. 2016/679.
  </p>

  <h3>
    Consenso e informative esterne
  </h3>
  <h4>
    Siti Web e servizi di terze parti
  </h4>
  <p>
    Il Sito potrebbe contenere collegamenti ipertestuali detti "link" (ossia strumenti che consentono il collegamento a
    pagine Web di altri siti). I siti esterni raggiungibili tramite link dal Sito, in quanto sviluppati e gestiti da
    soggetti sui quali Lepida S.c.p.A. non ha alcuna titolarità né controllo, sono da ritenersi di responsabilità di
    soggetti terzi, quanto a contenuti, qualità, accuratezza, servizi offerti, nonché informative sulla Privacy e sulla
    gestione dei Cookie. La visita e l'utilizzo dei siti consultati dall'utente tramite link, quindi, è rimessa alla
    totale discrezionalità e responsabilità dello stesso. La presente informativa è resa con esclusivo riferimento al
    Sito e non si estende ad altri portali eventualmente consultati dall'utente.
  </p>

  <h3>
    Gestione dei cookie attraverso il browser
  </h3>
  <h4>
    Disabilitare l'utilizzo dei cookie
  </h4>
  <p>
    È possibile disabilitare l'utilizzo dei cookies modificando le impostazioni del proprio browser, seguendo questi
    link sui siti dei rispettivi fornitori:
  </p>
  <ul>
    <li>
      <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">
        Mozilla Firefox
      </a>
    </li>
    <li>
      <a href="https://support.google.com/chrome/answer/95647?hl=it">
        Google Chrome
      </a>
    </li>
    <li>
      <a href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies">
        Internet Explorer
      </a>
    </li>
    <li>
      <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
        Opera
      </a>
    </li>
    <li>
      <a href="https://support.apple.com/kb/PH17191?viewlocale=it_IT&locale=en_US">
        Safari 6/7 Maverick
      </a>
    </li>
    <li>
      <a href="https://support.apple.com/kb/PH19214?viewlocale=it_IT&locale=en_US">
        Safari 8 Yosemite
      </a>
    </li>
    <li>
      <a href="https://support.apple.com/it-it/HT201265">
        Safari su iPhone, iPad, o iPod touch
      </a>
    </li>
  </ul>

  <p>
    Nel caso in cui il tuo browser non sia presente all'interno di questo elenco, puoi selezionare la funzione "Aiuto"
    del browser per trovare le informazioni su come procedere.
  </p>

  <h4>
    Attivare l'opzione Do Not Track
  </h4>
  <p>
    Tale opzione, presente nella maggior parte dei browser di ultima generazione, è implementata per permettere agli
    utenti di evitare il tracciamento da parte dei siti progettati per adeguarsi a questa opzione.
  </p>

  <h4>
    Attivare la modalità di "navigazione anonima"
  </h4>
  <p>
    Mediante questa funzione disponibile ormai in tutti i browser, è possibile navigare in Internet senza salvare alcuna
    informazione sui siti e sulle pagine visitate.
  </p>
  <p>
    Tuttavia, i dati di navigazione, pur attivata tale funzionalità, sono registrati e conservati dai gestori dei siti e
    dai provider di connettività.
  </p>

  <h4>
    Eliminare direttamente i cookies
  </h4>
  <p>
    Attualmente quasi tutti i browser consentono di eliminare tutti i cookies installati.
  </p>
  <p>
    Per maggiori istruzioni, consultare la guida del proprio browser o visitare uno dei seguenti link sui siti dei
    rispettivi fornitori:
  </p>

  <ul>
    <li>
      <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">
        Mozilla Firefox
      </a>
    </li>
    <li>
      <a href="https://support.google.com/chrome/answer/95647?hl=it">
        Google Chrome
      </a>
    </li>
    <li>
      <a href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies">
        Internet Explorer
      </a>
    </li>
    <li>
      <a href="https://help.opera.com/en/latest/security-and-privacy/">
        Opera
      </a>
    </li>
    <li>
      <a href="https://support.apple.com/kb/PH17191?viewlocale=it_IT&locale=en_US">
        Safari 6/7 Maverick
      </a>
    </li>
    <li>
      <a href="https://support.apple.com/kb/PH19214?viewlocale=it_IT&locale=en_US">
        Safari 8 Yosemite
      </a>
    </li>
    <li>
      <a href="https://support.apple.com/it-it/HT201265">
        Safari su iPhone, iPad, o iPod touch
      </a>
    </li>
  </ul>

  <p>
    Tuttavia, ad ogni nuova navigazione saranno installati nuovamente i cookies; in ragione di ciò si invita ad eseguire
    tale operazione periodicamente o utilizzare funzioni automatizzate per la cancellazione periodica dei cookies.
  </p>
</div>
