  <div>Mostra
    <div ngbDropdown class="d-inline-block pl-2">
      <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{pageSize}}</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="onChangeSize($event)" *ngFor="let rowsPerPage of rowsPerPageOptions">
          {{rowsPerPage}}
        </button>
      </div>
    </div>risultati
  </div>
<!--  <div class="col-auto">-->
<!--    <ngb-pagination-->
<!--      [(page)]="page"-->
<!--      [pageSize]="pageSize"-->
<!--      [collectionSize]="10"-->
<!--      [boundaryLinks]="true"-->
<!--      [maxSize]="5"-->
<!--      size="sm"></ngb-pagination>-->
<!--  </div>-->

