import { Component, Input } from '@angular/core';

var index = 0;

@Component({
  selector: 'payer-collapse-div',
  templateUrl: './collapse-div.component.html',
  styleUrls: ['./collapse-div.component.scss']
})
export class CollapseDivComponent {

  @Input()
  header: string;

  @Input()
  level: '1' | '2' | '3' | '4' | '5' | '6' = '2';

  get headerClass(): string {
    return 'h' + this.level;
  }

  // @Input()
  id: string = `payer-collapse-${index++}`;

}
