<form [formGroup]="form" class="modal-wrapper">
   <div class="row ">
      <div class="col-sm-4 col-md-4 col-lg-6 form-elem-container">
         <div class="row">
            <label [htmlFor]="'nome'" class="title form-elem-title required"
               >Nome</label>
         </div>
         <div class="row">
            <input
            id="nome"
            [formControl]="form.controls['nome']"
            [(ngModel)]="datiModaleTipoFlussoRendicontazione.campoForm.nome"
            type="text"
            autocomplete="off"
            [attr.disabled]="disable">
         </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-6 form-elem-container">
         <div class="row">
            <label [htmlFor]="'ordine'" class="title form-elem-title required"
               >Ordine</label>
         </div>
         <div class="row">
            <input
            id="ordine"
            [formControl]="form.controls['ordine']"
            [(ngModel)]="datiModaleTipoFlussoRendicontazione.campoForm.ordine"
            type="number"
            autocomplete="off"
            [attr.disabled]="disable">
         </div>
      </div>
   </div>
   <div class="row">
     <div class="col-sm-4 col-md-4 col-lg-4 form-elem-container">
       <div class="row">
         <label [htmlFor]="'lunghezza'" class="title form-elem-title required"
         >Lunghezza</label>
       </div>
       <div class="row">
         <input
           id="lunghezza"
           [formControl]="form.controls['lunghezza']"
           [(ngModel)]="datiModaleTipoFlussoRendicontazione.campoForm.lunghezza"
           type="number"
           min="0"
           autocomplete="off"
           [attr.disabled]="disable">
       </div>
     </div>
      <div class="col-sm-4 col-md-4 col-lg-4 form-elem-container">
         <div class="row">
            <label [htmlFor]="'valoreCostante'" class="title form-elem-title"
               >Valore costante</label>
         </div>
         <div class="row">
            <input
            id="valoreCostante"
            [formControl]="form.controls['valoreCostante']"
            [(ngModel)]="datiModaleTipoFlussoRendicontazione.campoForm.valoreCostante"
            type="text"
            [attr.disabled]="disable">
         </div>
      </div>
     <div class="col-sm-4 col-md-4 col-lg-4 form-elem-container">
       <div class="row">
         <label [htmlFor]="'tipoCampoId'" class="title form-elem-title required"
         >Tipo Campo</label>
         <span class="pointer bottone-campo"
               *ngIf="datiModaleTipoFlussoRendicontazione.funzione !== FunzioneGestioneEnum.DETTAGLIO">
               <span ngbTooltip="Crea nuovo tipo campo" placement="bottom"
                     [ngStyle]="{fill: '#403d39'}">
                  <svg (click)="addTipoCampo()" class="bg-light icon icon-sm filled">
                     <use xlink:href="assets/img/sprite.svg#it-plus"></use>
                  </svg>
               </span>
            </span>
       </div>
       <div class="row">
         <select
           id="tipoCampoId"
           [formControl]="form.controls['tipoCampoId']"
           [(ngModel)]="datiModaleTipoFlussoRendicontazione.campoForm.tipoCampoId"
           [attr.disabled]="disableTipoCampo"
         >
           <option [ngValue]="null"></option>
           <option *ngFor="let item of listaTipiCampo" [ngValue]="item.id">{{item.nome}} - {{item.informazioni}}</option>
         </select>
       </div>
     </div>
   </div>
   <div class="row ">
      <div class="col-sm-4 col-md-4 col-lg-6 form-elem-container">
         <div class="row">
            <label [htmlFor]="'carattereRiempimento'" class="title form-elem-title"
               >Carattere riempimento</label>
         </div>
         <div class="row">
            <input
            id="carattereRiempimento"
            [formControl]="form.controls['carattereRiempimento']"
            [(ngModel)]="datiModaleTipoFlussoRendicontazione.campoForm.carattereRiempimento"
            autocomplete="off"
            maxLength="1"
            type="text"
            [attr.disabled]="disable">
         </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-6 form-elem-container">
         <div class="row">
            <label [htmlFor]="'riempimentoSinistro'" class="title form-elem-title required"
               >Riempimento sinistro</label>
         </div>
         <div class="row">
            <select
            id="riempimentoSinistro"
            [formControl]="form.controls['riempimentoSinistro']"
            [(ngModel)]="datiModaleTipoFlussoRendicontazione.campoForm.riempimentoSinistro"
            [attr.disabled]="disable"
            >
            <option [ngValue]=0>No</option>
            <option [ngValue]=1>Si</option>
            </select>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-12">
         <div class="row buttons-row">
            <div class="col-2">
               <button type="button" id="bottoneIndietro" class="btn btn-secondary"
                  (click)="clickIndietro()">{{(datiModaleTipoFlussoRendicontazione.funzione === FunzioneGestioneEnum.DETTAGLIO || disable) ? 'Indietro' : 'Annulla'}}
               </button>
            </div>
            <div class="col-10 text-right" *ngIf="!(datiModaleTipoFlussoRendicontazione.funzione === FunzioneGestioneEnum.DETTAGLIO || disable)">
               <button (click)="salvaCampoForm()" type="button" class="btn"
               [disabled]="disabilitaSalva()">
               Salva
               </button>
            </div>
         </div>
      </div>
   </div>
</form>
