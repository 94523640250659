<div id="home">
  <div>
    <img id="logo-home" alt="payer" src="../../../../../assets/img/payer.png">
  </div>
  <div>
    <p class="title">semplice come bere un bicchier d'acqua</p>
  </div>
  <div>
    <strong>PayER è il portale dei pagamenti della Regione Emilia-Romagna che ti permette di pagare con semplicità tributi e servizi</strong>
  </div>
  <div class="row">
    <div class="col-lg">
      <button class="btn" routerLink="/nuovoPagamento">Nuovo pagamento</button>
      <p>Inserisci tutti i dati del tuo versamento ed effettua con semplicità i pagamenti di tributi e servizi</p>
    </div>
    <div class="col-lg">
      <button [disabled]="!menuService.isUtenteAnonimo" class="btn" (click)="getLoginLink()">{{testoAccedi}}</button>
      <p>Per visualizzare i pagamenti effettuati o in scadenza e per pagare in pochi passi senza dover immettere manualmente tutti i dati dei tuoi versamenti</p>
    </div>
  </div>
</div>
