export class PayerError extends Error {
    constructor(msg: string) {
      super(msg);
    }
  }
  
  export class NotFoundError extends PayerError {
    path: string;
    key: any;
  
    constructor(path: string, key: any) {
      super("Risorsa non trovata.");
      this.path = path;
      this.key = key;
    }
  }
  
  export class UnexpectedValueError extends PayerError {
    expected: any;
    actual: any;
  
    constructor(expected: any, actual: any) {
      super("Ricevuto valore inaspettato.");
      this.expected = expected;
      this.actual = actual;
    }
  }
  