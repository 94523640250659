import { Clipboard } from "@angular/cdk/clipboard";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { HttpClient } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewRef,
} from "@angular/core";
import { FormControl, NgModel, ValidatorFn } from "@angular/forms";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import * as _ from "lodash";
import * as moment from "moment";
import { ConfirmationService } from "primeng/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Beneficiario } from "src/app/modules/main/model/ente/Beneficiario";
import { FlussoRendicontazioneService } from "src/app/services-old/flusso-rendicontazione.service";
import { Utils } from "src/app/utility/Utils";
import { ModalitaPaginaGestioneElemento } from "src/app/utility/enums/modalita-pagina-gestione-elemento";
import { v4 as uuidv4 } from "uuid";
import { LivelloIntegrazioneEnum } from "../../../../../../enums/livelloIntegrazione.enum";
import { TipoCampoEnum } from "../../../../../../enums/tipoCampo.enum";
import { TipoModaleEnum } from "../../../../../../enums/tipoModale.enum";
import { AmministrativoService } from "../../../../../../services-old/amministrativo.service";
import { CampoTipologiaServizioService } from "../../../../../../services-old/campo-tipologia-servizio.service";
import { ConfiguraServizioService } from "../../../../../../services-old/configura-servizio.service";
import { EnteService } from "../../../../../../services-old/ente.service";
import { OverlayService } from "../../../../../../services-old/overlay.service";
import { RoutingService } from "../../../../../../services-old/routing.service";
import { SocietaService } from "../../../../../../services-old/societa.service";
import { Breadcrumb, SintesiBreadcrumb } from "../../../../dto/Breadcrumb";
import { CampoTipologiaServizio } from "../../../../model/CampoTipologiaServizio";
import { Societa } from "../../../../model/Societa";
import { ConfiguratoreCampiNuovoPagamento } from "../../../../model/campo/ConfiguratoreCampiNuovoPagamento";
import { ContoCorrente } from "../../../../model/ente/ContoCorrente";
import { ParametriRicercaEnte } from "../../../../model/ente/ParametriRicercaEnte";
import { BeneficiarioServizio } from "../../../../model/servizio/BeneficiarioServizio";
import { CampoServizio } from "../../../../model/servizio/CampoServizio";
import { Contatti } from "../../../../model/servizio/Contatti";
import { FiltroSelect } from "../../../../model/servizio/FiltroSelect";
import {
  FlussiNotifiche,
  QuadraturaServizio,
} from "../../../../model/servizio/FlussiNotifiche";
import { ImpositoreServizio } from "../../../../model/servizio/ImpositoreServizio";
import { LivelloIntegrazioneServizio } from "../../../../model/servizio/LivelloIntegrazioneServizio";
import { NotifichePagamento } from "../../../../model/servizio/NotifichePagamento";
import { ParametriRicercaServizio } from "../../../../model/servizio/ParametriRicercaServizio";
import { RendicontazioneGiornaliera } from "../../../../model/servizio/RendicontazioneGiornaliera";
import { Servizio } from "../../../../model/servizio/Servizio";
import { ContoCorrenteComponent } from "../../anagrafiche/gestisci-enti/conto-corrente/conto-corrente.component";
import { FormElementoParentComponent } from "../../form-elemento-parent.component";
import { aggiungiTipoCampoEvent } from "../../gestisci-tipologia-servizio/modale-campo-form/modale-aggiungi-tipo-campo/modale-aggiungi-tipo-campo.component";
import { aggiornaTipoCampoEvent } from "../../gestisci-tipologia-servizio/modale-campo-form/modale-campo-form.component";

@Component({
  selector: "app-form-servizio",
  templateUrl: "./form-servizio.component.html",
  styleUrls: ["./form-servizio.component.scss"],
})
export class FormServizioComponent
  extends FormElementoParentComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    public configuraServizioService: ConfiguraServizioService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private overlayService: OverlayService,
    private societaService: SocietaService,
    private enteService: EnteService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected http: HttpClient,
    protected amministrativoService: AmministrativoService,
    protected confirmationService: ConfirmationService,
    private campoTipologiaServizioService: CampoTipologiaServizioService,
    private routingService: RoutingService,
    private flussoRendicontazioneService: FlussoRendicontazioneService,
    private clipboard: Clipboard
  ) {
    super(
      confirmationService,
      activatedRoute,
      amministrativoService,
      http,
      router
    );
  }

  private firstAdd = false;
  private isSingleClick: boolean;
  servizioId: number;

  titoloPagina: string;
  tooltip: string;

  urlBasePerNuovoPagamento: string =
    window.location.protocol + "//" + window.location.host + "/nuovoPagamento";
  ctc: string = "../../../../../../../assets/images/ctc.png";

  breadcrumbList: Breadcrumb[] = [];

  readonly lunghezzaMaxCol1: number = 5;
  readonly lunghezzaMaxCol2: number = 10;
  readonly lunghezzaMaxCol3: number = 15;

  funzione: ModalitaPaginaGestioneElemento;
  filtro: ParametriRicercaServizio;

  LivelloIntegrazioneEnum = LivelloIntegrazioneEnum;

  private listaTipiCampo: any[];

  servizio: Servizio = new Servizio();
  contatti: Contatti = new Contatti();
  impositore: ImpositoreServizio = new ImpositoreServizio();
  integrazione: LivelloIntegrazioneServizio = new LivelloIntegrazioneServizio();
  beneficiario: BeneficiarioServizio = new BeneficiarioServizio();

  listaSocieta: Societa[] = [];
  listaLivelloIntegrazione: FiltroSelect[] = [];
  listaLivelloTerritorialeImpositore: FiltroSelect[] = [];
  listaLivelloTerritorialeBeneficiario: FiltroSelect[] = [];
  listaEnti: FiltroSelect[] = [];
  listaEntiBenef: FiltroSelect[] = [];
  listaPortaleEsterno: FiltroSelect[] = [];
  listaUfficio: FiltroSelect[] = [];

  FunzioneGestioneEnum = ModalitaPaginaGestioneElemento;
  filtri: ParametriRicercaServizio;

  tipoFlussoId: number;

  campoTipologiaServizioOriginal: CampoServizio[] = [];

  private tipoCampoIdSelect: number;

  testoTooltipIconaElimina = "Elimina dati beneficiario";

  listaContiCorrente: ContoCorrente[];

  @ViewChildren("datiContoCorrente", { read: ViewContainerRef })
  datiBeneficiarioFormQuery: QueryList<any>;

  @ViewChild("datiContoCorrente", { static: false, read: ViewContainerRef })
  target: ViewContainerRef;
  private componentRef: ComponentRef<any>;

  private componentRefs: ComponentRef<any>[] = [];
  targetMap: Map<string, ViewRef> = new Map<string, ViewRef>();

  private refreshItemsEvent: EventEmitter<any> = new EventEmitter<any>();
  private listaDipendeDa: CampoTipologiaServizio[];
  rendicontazioneGiornaliera: RendicontazioneGiornaliera =
    new RendicontazioneGiornaliera();
  quadraturaServizio: QuadraturaServizio = new QuadraturaServizio();

  TipoCampoEnum = TipoCampoEnum;
  invioNotifiche: any = {};
  emailsControl: FormControl[] = [new FormControl()];
  displayCc = false;

  mapContoCorrente: Map<string, ContoCorrente> = new Map<
    string,
    ContoCorrente
  >();
  mapControllo: Map<string, boolean> = new Map<string, boolean>();

  showEditId: number;
  getListaContiCorrente = (mapContoCorrente: Map<string, ContoCorrente>) =>
    Array.from(mapContoCorrente, ([name, value]) => value);
  getListaControllo = (mapControllo: Map<string, boolean>) =>
    Array.from(mapControllo, ([name, value]) => value);

  enteId: number;

  listaFlussiRendicontazione: FiltroSelect[] = [];

  ngOnInit(): void {
    this.amministrativoService.salvaCampoFormEvent = new EventEmitter<any>();
    aggiungiTipoCampoEvent.subscribe((idTipoCampo) => {
      this.impostaConfigurazioneCampi(idTipoCampo);
    });
    this.refreshItemsEvent.subscribe((items) => {
      this.listaDipendeDa = items.filter(
        (value) => value && value.tipoCampoId === this.tipoCampoIdSelect
      );
    });
  }

  public ngAfterViewInit() {
    this.datiBeneficiarioFormQuery.changes.subscribe((ql) => {
      if (!this.firstAdd) {
        this.firstAdd = true;
        if (this.funzione == ModalitaPaginaGestioneElemento.AGGIUNGI) {
          this.aggiungiContoCorrente();
        } else {
          if (
            this.servizio.beneficiario &&
            this.servizio.beneficiario.listaContiCorrenti
          ) {
            this.servizio.beneficiario.listaContiCorrenti.forEach((value1) => {
              this.aggiungiContoCorrente(value1);
            });
          }
        }
      }
    });
  }

  private caricaLivelliIntegrazione() {
    this.configuraServizioService
      .filtroLivelliIntegrazione(null, this.idFunzione)
      .pipe(
        map((value) => {
          // console.log("caricaLivelliIntegrazione : value = ", JSON.stringify(value));
          this.listaLivelloIntegrazione = value;
        })
      )
      .subscribe();
  }

  copyTextUrl() {
    this.clipboard.copy(this.urlBasePerNuovoPagamento + "/" + this.servizioId);
  }

  initFormPage(snapshot: ActivatedRouteSnapshot) {
    this.campoTipologiaServizioOriginal = [];
    this.configuraServizioService.campoTipologiaServizioList = [];
    this.configuraServizioService.campoServizioAddList = [];
    let self = this;
    this.amministrativoService.salvaCampoFormEvent.subscribe(
      (campoForm: CampoServizio) => {
        let parentCampo = self.campoTipologiaServizioOriginal.find(
          (value: CampoServizio) =>
            value.id == campoForm.campoTipologiaServizioId ||
            value.id == campoForm.id
        );
        let campoFormIdx;
        let campoFormIdx2;
        campoFormIdx =
          self.configuraServizioService.campoTipologiaServizioList.findIndex(
            (value: CampoServizio) =>
              value.uuid && campoForm.uuid && value.uuid == campoForm.uuid
          );
        campoFormIdx2 =
          self.configuraServizioService.campoServizioAddList.findIndex(
            (value: CampoServizio) =>
              value.uuid && campoForm.uuid && value.uuid == campoForm.uuid
          );

        if (campoFormIdx != -1) {
          campoForm.modificato = true;
          self.configuraServizioService.campoTipologiaServizioList[
            campoFormIdx
          ] = _.cloneDeep(campoForm);
        } else if (campoFormIdx2 != -1) {
          self.configuraServizioService.campoServizioAddList[campoFormIdx2] =
            _.cloneDeep(campoForm);
        } else {
          campoForm.uuid = uuidv4();
          campoForm.draggable = true;
          self.configuraServizioService.campoServizioAddList.push(campoForm);
        }
        self.overlayService.mostraModaleCampoEvent.emit(null);

        self.refreshItemsDipendeDa();
      }
    );

    let filtro = { visibile: true };
    this.societaService
      .ricercaSocieta(filtro, this.idFunzione)
      .pipe(map((value: Societa[]) => (this.listaSocieta = value)))
      .subscribe();

    this.configuraServizioService
      .configuraServiziFiltroPortaleEsterno(this.idFunzione)
      .pipe(map((value: FiltroSelect[]) => (this.listaPortaleEsterno = value)))
      .subscribe();

    this.impostaConfigurazioneCampi();

    this.caricaLivelliIntegrazione();
    this.recuperaFlussiRendicontazione();
    this.controllaTipoFunzione();
    this.inizializzaBreadcrumb();
    this.titoloPagina = this.getTestoFunzione(this.funzione) + " Servizio";
    this.tooltip =
      "In questa pagina puoi " +
      this.getTestoFunzione(this.funzione, false) +
      " i dettagli di una tipologia servizio";

    if (
      this.funzione === ModalitaPaginaGestioneElemento.MODIFICA ||
      this.funzione === ModalitaPaginaGestioneElemento.DETTAGLIO
    ) {
      this.servizioId = parseInt(snapshot.paramMap.get("servizioId"));

      let self = this;
      this.configuraServizioService
        .dettaglioServizio(this.servizioId, this.idFunzione)
        .pipe(
          map((value: Servizio) => {
            this.servizio = value;
            this.filtro = new ParametriRicercaServizio();
            this.filtro.raggruppamentoId = value.raggruppamentoId;
            this.filtro.nomeServizio = value.nomeServizio;
            this.filtro.tipologiaServizioId = value.tipologiaServizioId;
            this.filtro.codiceTassonomia = value.codiceTassonomia;
            this.filtro.attestato = value.attestato;
            this.filtro.codiceCarta = value.codiceCarta;
            this.tipoFlussoId = value.tipoFlussoId;
            this.caricaCampi(value.tipologiaServizioId).subscribe(
              (campoTipologiaServizioList) => {
                self.configuraServizioService.campoServizioAddList =
                  value.listaCampiServizio
                    .filter((obj) => {
                      return !obj.campoTipologiaServizioId;
                    })
                    .map((value1) => {
                      value1.uuid = uuidv4();
                      value1.draggable = true;
                      return value1;
                    });

                self.configuraServizioService.campoTipologiaServizioList =
                  campoTipologiaServizioList.map((campoTipologiaServizio) => {
                    let campoServizio = value.listaCampiServizio.find(
                      (value1) =>
                        value1.campoTipologiaServizioId ==
                        campoTipologiaServizio.id
                    );
                    if (campoServizio) {
                      campoServizio.modificato = this.isCampoModificato(
                        campoTipologiaServizio
                      );
                    } else {
                      // copia di campo tipologia servizio in campo servizio
                      campoServizio = _.cloneDeep(campoTipologiaServizio);
                      campoServizio.id = null;
                    }
                    // setta campoTipologiaServizioId per ogni campoServizio
                    this.setCampoTipologiaServizioId(
                      campoTipologiaServizio,
                      campoServizio
                    );
                    campoServizio.uuid = uuidv4();
                    return campoServizio
                      ? campoServizio
                      : campoTipologiaServizio;
                  });

                if (this.funzione === ModalitaPaginaGestioneElemento.MODIFICA) {
                  this.campoTipologiaServizioOriginal =
                    this.configuraServizioService.campoTipologiaServizioList;
                }

                self.refreshItemsDipendeDa();
              }
            );
            this.filtro.abilitaDa = moment(
              value.abilitaDa,
              Utils.FORMAT_LOCAL_DATE_TIME
            ).format(Utils.FORMAT_DATE_CALENDAR);
            this.filtro.abilitaA = value.abilitaA
              ? moment(value.abilitaA, Utils.FORMAT_LOCAL_DATE_TIME).format(
                  Utils.FORMAT_DATE_CALENDAR
                )
              : null;
            this.filtro.attivo = value.flagAttiva;
            this.contatti = value.contatti;
            this.integrazione = value.integrazione;
            if (value.impositore && value.impositore.societaId) {
              this.impositore = value.impositore;
              this._onChangeSocietaImpositore(value.impositore.societaId);
            }

            if (
              value.beneficiario &&
              value.beneficiario.livelloTerritorialeId
            ) {
              this.beneficiario = value.beneficiario;
              // console.log("this.beneficiario", this.beneficiario);
              this.onChangeSocietaBeneficiario(
                value.beneficiario.societaId,
                true
              );
              this.enteService
                .recuperaContiCorrenti(
                  this.beneficiario.enteId,
                  this.idFunzione
                )
                .subscribe((contiCorrenti) => {
                  this.listaContiCorrente = contiCorrenti;
                });
            }

            this.emailsControl = [];
            if (value.flussiNotifiche) {
              this.rendicontazioneGiornaliera =
                value.flussiNotifiche.rendicontazioneGiornaliera != null
                  ? value.flussiNotifiche.rendicontazioneGiornaliera
                  : new RendicontazioneGiornaliera();
              this.quadraturaServizio = value.flussiNotifiche.quadratura
                ? value.flussiNotifiche.quadratura
                : new QuadraturaServizio();
              if (
                value.flussiNotifiche.notifichePagamento &&
                value.flussiNotifiche.notifichePagamento &&
                value.flussiNotifiche.notifichePagamento.length > 0
              ) {
                const strings = value.flussiNotifiche.notifichePagamento;
                if (strings && strings.length > 0) {
                  strings.forEach((obj) => {
                    const formControl = new FormControl();
                    formControl.setValue(obj.email);
                    if (
                      this.funzione == ModalitaPaginaGestioneElemento.DETTAGLIO
                    ) {
                      formControl.disable();
                    }
                    this.emailsControl.push(formControl);
                  });
                }
              }
            }

            this.filtri = this.filtro;
          })
        )
        .subscribe();
    }
  }

  private setCampoTipologiaServizioId(obj, campoServizio: CampoServizio) {
    const parentCampo = this.campoTipologiaServizioOriginal.find(
      (original: CampoServizio) =>
        original.id === obj.campoTipologiaServizioId || original.id === obj.id
    );
    if (parentCampo != null) {
      campoServizio.campoTipologiaServizioId = parentCampo.id;
    }
  }

  impostaConfigurazioneCampi(idTipoCampo: number = null): void {
    this.campoTipologiaServizioService
      .letturaConfigurazioneCampiNuovoPagamento(this.idFunzione)
      .pipe(
        map((configuratore: ConfiguratoreCampiNuovoPagamento) => {
          localStorage.setItem(
            "listaCampiDettaglioTransazione",
            JSON.stringify(configuratore.listaCampiDettaglioTransazione)
          );
          localStorage.setItem(
            "listaControlliLogici",
            JSON.stringify(configuratore.listaControlliLogici)
          );
          localStorage.setItem(
            "listaTipologiche",
            JSON.stringify(configuratore.listaTipologiche)
          );
          localStorage.setItem(
            "listaJsonPath",
            JSON.stringify(configuratore.listaJsonPath)
          );

          this.listaTipiCampo = configuratore.listaTipiCampo;

          const filter = configuratore.listaTipiCampo.filter(
            (tc) => tc.nome === TipoCampoEnum.SELECT
          );
          if (filter && filter.length > 0) {
            this.tipoCampoIdSelect = filter[0].id;
          }

          localStorage.setItem(
            "listaTipiCampo",
            JSON.stringify(configuratore.listaTipiCampo)
          );

          if (idTipoCampo) {
            aggiornaTipoCampoEvent.emit(idTipoCampo);
          }
        })
      )
      .subscribe();
  }

  caricaCampi(tipologiaServizioId: number): Observable<any> {
    let self = this;
    return this.campoTipologiaServizioService
      .campiTipologiaServizio(tipologiaServizioId, this.idFunzione)
      .pipe(
        map((value) => {
          self.campoTipologiaServizioOriginal = _.sortBy(value, "posizione");
          let list = _.cloneDeep(this.campoTipologiaServizioOriginal);

          // Nel caso della funzione Aggiungi, i campi vengono copiati da un'altra tipologia servizio, ma andranno ricreati sul db come nuove entità
          if (self.funzione !== ModalitaPaginaGestioneElemento.DETTAGLIO) {
            list.forEach((campo) => {
              campo.uuid = uuidv4();
              if (
                campo.dipendeDa &&
                this.funzione === ModalitaPaginaGestioneElemento.AGGIUNGI
              ) {
                campo.dipendeDa.riferimentoPerId = _.cloneDeep(
                  campo.dipendeDa.id
                );
                campo.dipendeDa.id = null;
              }
            });
          }
          return list;
        })
      );
  }

  controllaTipoFunzione() {
    const url = this.activatedRoute.snapshot.url[1].path;
    switch (url) {
      case "dettaglioServizio":
        this.funzione = ModalitaPaginaGestioneElemento.DETTAGLIO;
        break;
      case "aggiungiServizio":
        this.funzione = ModalitaPaginaGestioneElemento.AGGIUNGI;
        break;
      case "modificaServizio":
        this.funzione = ModalitaPaginaGestioneElemento.MODIFICA;
        break;
    }
  }

  inizializzaBreadcrumb(): void {
    const breadcrumbs: SintesiBreadcrumb[] = [];
    breadcrumbs.push(new SintesiBreadcrumb("Configura Servizi", this.basePath));
    breadcrumbs.push(
      new SintesiBreadcrumb(
        this.getTestoFunzione(this.funzione) + " Servizio",
        null
      )
    );
    this.breadcrumbList = this.inizializzaBreadcrumbList(breadcrumbs);
  }

  ngOnDestroy(): void {
    this.configuraServizioService.campoServizioAddList = [];
    this.configuraServizioService.campoTipologiaServizioList = [];
    if (this.amministrativoService.salvaCampoFormEvent.observers)
      this.amministrativoService.salvaCampoFormEvent.unsubscribe();
  }

  onClickSalva(): void {
    const emails: string[] = [];
    this.emailsControl.forEach((control) => {
      if (control.value) {
        emails.push(control.value);
      }
    });

    if (
      this.integrazione.livelloIntegrazioneId !== LivelloIntegrazioneEnum.LV1
    ) {
      let list = _.cloneDeep(
        this.configuraServizioService.campoTipologiaServizioList
      );
      const campoServizios: CampoServizio[] = list.map((value) => {
        // id a null per ogni campo servizio da aggiungere
        if (this.funzione === ModalitaPaginaGestioneElemento.AGGIUNGI) {
          value.id = null;
        }
        return value;
      });
      const posizioneIniziale =
        this.campoTipologiaServizioOriginal?.length ?? 0;
      this.configuraServizioService.campoServizioAddList.forEach(
        (value, index) => {
          let posizione;
          if (index === 0) {
            posizione = posizioneIniziale;
          } else {
            posizione =
              this.configuraServizioService.campoServizioAddList?.[index - 1]
                ?.posizione;
          }
          value.posizione = posizione + 1;
        }
      );
      this.servizio.listaCampiServizio = _.concat(
        campoServizios,
        this.configuraServizioService.campoServizioAddList
      )?.map((cs) => {
        if (cs?.dipendeDa && !cs?.dipendeDa?.id) {
          cs.dipendeDa.id = cs?.dipendeDa?.riferimentoPerId;
        }
        return cs;
      });
    }

    const flussiNotifiche = new FlussiNotifiche();
    flussiNotifiche.notifichePagamento = [];
    flussiNotifiche.rendicontazioneGiornaliera =
      this.rendicontazioneGiornaliera;
    flussiNotifiche.quadratura = this.quadraturaServizio;
    if (emails && emails.length > 0) {
      emails.forEach((email) => {
        const notifichePagamento: NotifichePagamento = new NotifichePagamento();
        notifichePagamento.email = email;
        flussiNotifiche.notifichePagamento.push(notifichePagamento);
      });
    }
    this.servizio.flussiNotifiche = flussiNotifiche;

    this.servizio.tipologiaServizioId = this.filtri.tipologiaServizio.id;
    this.servizio.raggruppamentoId = this.filtri.raggruppamentoId;
    this.servizio.nomeServizio = this.filtri.nomeServizio;
    this.servizio.codiceTassonomia = this.filtri.codiceTassonomia;
    this.servizio.attestato = this.filtri.attestato;
    this.servizio.codiceCarta = this.filtri.codiceCarta;
    this.servizio.abilitaDa = moment(
      this.filtri.abilitaDa,
      Utils.FORMAT_DATE_CALENDAR
    ).format(Utils.FORMAT_LOCAL_DATE_TIME);
    this.servizio.abilitaA = this.filtri.abilitaA
      ? moment(this.filtri.abilitaA, Utils.FORMAT_DATE_CALENDAR).format(
          Utils.FORMAT_LOCAL_DATE_TIME_TO
        )
      : null;
    this.servizio.flagAttiva = this.filtri.attivo;

    this.servizio.contatti = this.contatti;
    this.servizio.integrazione = this.integrazione;
    this.servizio.impositore = this.impositore;
    this.servizio.beneficiario = this.beneficiario;
    this.servizio.tipoFlussoId = this.tipoFlussoId;

    if (this.funzione == ModalitaPaginaGestioneElemento.AGGIUNGI) {
      this.configuraServizioService
        .inserimentoServizio(this.servizio, this.idFunzione)
        .subscribe((id) => {
          if (id) {
            this.resetPagina();
          }
        });
    } else if (this.funzione == ModalitaPaginaGestioneElemento.MODIFICA) {
      this.configuraServizioService
        .modificaServizio(this.servizio, this.idFunzione)
        .subscribe((id) => {
          if (id) {
            this.routingService.configuraRouterAndNavigate(
              this.basePath + "/modificaServizio/" + this.servizio.id
            );
          }
        });
    }
  }

  private resetPagina() {
    this.filtro = new ParametriRicercaServizio();
    this.filtri = null;
    this.servizio = new Servizio();
    this.contatti = new Contatti();
    this.impositore = new ImpositoreServizio();
    this.integrazione = new LivelloIntegrazioneServizio();
    this.beneficiario = new BeneficiarioServizio();
  }

  onChangeFiltri(event: ParametriRicercaServizio) {
    this.filtri = event;
  }

  cambiaLivelloIntegrazione(event: any) {
    if (event !== LivelloIntegrazioneEnum.LV1) {
      this.configuraServizioService.campoServizioAddList = [];
      this.caricaCampi(this.filtri.tipologiaServizio.id).subscribe((list) => {
        list.forEach((elem) => {
          // setta campoTipologiaServizioId per ogni campoServizio
          this.setCampoTipologiaServizioId(elem, elem);
        });
        this.configuraServizioService.campoTipologiaServizioList = list;
        this.refreshItemsDipendeDa();
      });
    } else {
      this.campoTipologiaServizioOriginal = [];
      this.configuraServizioService.campoTipologiaServizioList = [];
      this.configuraServizioService.campoServizioAddList = [];
    }
  }

  disabilitaCampi() {
    return this.funzione == ModalitaPaginaGestioneElemento.DETTAGLIO;
  }

  requiredPerSocietaBeneficiarioCampo() {
    return (
      this.integrazione.livelloIntegrazioneId !== LivelloIntegrazioneEnum.LV1 ||
      this.beneficiario.societaId != null
    );
  }

  onChangeSocietaImpositore(societaInput: NgModel) {
    this.impositore.livelloTerritorialeId = null;
    this.impositore.enteId = null;
    this._onChangeSocietaImpositore(societaInput.value);
  }

  private _onChangeSocietaImpositore(entity: any) {
    if (entity) {
      this.configuraServizioService
        .configuraServiziFiltroLivelloTerritoriale(entity, this.idFunzione)
        .pipe(
          map((value) => {
            this.listaLivelloTerritorialeImpositore = value;
            if (this.impositore.livelloTerritorialeId) {
              this._onChangeLivelloTerritorialeImpositore(
                this.impositore.livelloTerritorialeId
              );
            }
          })
        )
        .subscribe();
    }
  }

  onChangeSocietaBeneficiario(societaInput, onInit: boolean = false) {
    if (!onInit) {
      this.beneficiario.enteId = null;
      this.beneficiario.ufficio = null;
      this.beneficiario.livelloTerritorialeId = null;
      this.svuotaContiCorrente();
    }
    if (societaInput) {
      this.configuraServizioService
        .configuraServiziFiltroLivelloTerritoriale(
          societaInput,
          this.idFunzione
        )
        .pipe(
          map((value) => {
            this.listaLivelloTerritorialeBeneficiario = value;
            if (this.beneficiario?.livelloTerritorialeId) {
              this._onChangeLivelloTerritorialeBeneficiario(
                this.beneficiario?.livelloTerritorialeId
              );
            }
          })
        )
        .subscribe();
    }
  }

  private svuotaContiCorrente() {
    this.listaContiCorrente = null;
    // svuota mappe
    this.mapContoCorrente.clear();
    this.mapControllo.clear();
    // aggiorna la lista dei dati conti correnti
    this.componentRefs = [];
    this.target.clear();
    //this.aggiungiContoCorrente(new ContoCorrente());
    this.aggiornaListaContiCorrenti();
  }

  onChangeLivelloTerritorialeImpositore(
    societaInput: NgModel,
    livelloTerritorialeInput: NgModel
  ) {
    this.impositore.enteId = null;
    return this._onChangeLivelloTerritorialeImpositore(
      livelloTerritorialeInput.value
    );
  }

  private _onChangeLivelloTerritorialeImpositore(livelloTerritorialeId: any) {
    if (livelloTerritorialeId) {
      const params = new ParametriRicercaEnte();
      params.societaId = this.impositore.societaId;
      params.livelloTerritorialeId = livelloTerritorialeId;
      return this.configuraServizioService
        .filtroEnti(params, this.idFunzione)
        .pipe(
          map((value) => {
            this.listaEnti = value;
          })
        )
        .subscribe();
    }
  }

  onChangeLivelloTerritorialeBeneficiario(
    societaInput: NgModel,
    livelloTerritorialeInput: NgModel
  ) {
    if (!livelloTerritorialeInput.value) {
      this.beneficiario.enteId = null;
    } else {
      this._onChangeLivelloTerritorialeBeneficiario(
        livelloTerritorialeInput.value
      );
    }
  }

  private _onChangeLivelloTerritorialeBeneficiario(livelloTerritorialeId: any) {
    const params = new ParametriRicercaEnte();
    params.societaId = this.beneficiario.societaId;
    params.livelloTerritorialeId = livelloTerritorialeId;
    this.configuraServizioService
      .configuraServiziFiltroEnteBeneficiario(params, this.idFunzione)
      .pipe(
        map((value) => {
          this.listaEntiBenef = value;
          if (this.beneficiario.ufficio) {
            this.configuraServizioService
              .configuraServiziFiltroUfficio(
                this.beneficiario.enteId,
                this.idFunzione
              )
              .pipe(
                map((list) => {
                  this.listaUfficio = list;
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();
  }

  isCampoInvalido(campo: NgModel | FormControl) {
    return campo?.errors;
  }

  setPlaceholder(
    campo: NgModel | FormControl,
    tipoCampo: TipoCampoEnum
  ): string {
    if (this.funzione === ModalitaPaginaGestioneElemento.DETTAGLIO) {
      return null;
    } else if (campo instanceof NgModel && campo.control?.errors?.required) {
      return "Il campo è obbligatorio";
    } else if (this.isCampoInvalido(campo)) {
      return "campo non valido";
    } else {
      switch (tipoCampo) {
        case TipoCampoEnum.SELECT:
          return "Seleziona un elemento dalla lista";
        case TipoCampoEnum.INPUT_TESTUALE:
          return "Inserisci testo";
        case TipoCampoEnum.INPUT_TESTUALE_EMAILS:
          return "Inserisci le email separate da ;";
        case TipoCampoEnum.DATEDDMMYY:
          return "Inserisci data";
      }
    }
  }

  onChangeEnte(enteInput: NgModel) {
    if (!enteInput.value) {
      this.beneficiario.ufficio = null;
    } else {
      this.enteId = enteInput.value;
      return this.configuraServizioService
        .configuraServiziFiltroUfficio(enteInput.value, this.idFunzione)
        .pipe(
          map((value) => {
            this.listaUfficio = value;
          })
        )
        .subscribe();
    }
  }

  onChangeBeneficiario(beneficiarioInput: NgModel) {
    if (beneficiarioInput.value) {
      const b = new Beneficiario();
      b.id = beneficiarioInput.value;

      return this.enteService
        .recuperaDatiBeneficiario(b, this.idFunzione)
        .pipe(
          map((beneficiario) => {
            this.listaContiCorrente = null;
            this.componentRefs.forEach((c) => c.destroy());
            this.beneficiario.id = beneficiario.id;
            this.beneficiario.listaContiCorrenti =
              beneficiario.listaContiCorrenti;
            beneficiario.listaContiCorrenti.forEach((contoCorrente) => {
              this.aggiungiContoCorrente(contoCorrente);
            });
          })
        )
        .subscribe();
    }
  }

  // aggiungiContoCorrente(datiContoCorrente?: ContoCorrente): number {
  //   // creazione Dati Conto Corrente Component
  //   const childComponent = this.componentFactoryResolver.resolveComponentFactory(ContoCorrenteComponent);
  //   this.componentRef = this.target.createComponent(childComponent);
  //   const indexContoCorrente = this.target.length;
  //   // input
  //   const uuid = Utils.uuidv4();
  //   this.componentRef.instance.uuid = uuid;
  //   this.targetMap.set(uuid, this.componentRef.hostView);
  //   this.componentRef.instance.indexDatiContoCorrente = indexContoCorrente;
  //   this.componentRef.instance.funzione = this.funzione;
  //   let instanceContoCorrente: ContoCorrente;
  //   if (datiContoCorrente == null) {
  //     instanceContoCorrente = new ContoCorrente();
  //   } else {
  //     instanceContoCorrente = datiContoCorrente;
  //   }
  //   this.componentRef.instance.datiContoCorrente = instanceContoCorrente;
  //   if (this.listaContiCorrente != null && ModalitaPaginaGestioneElemento.MODIFICA) {
  //     this.componentRef.instance.listaContiCorrente = this.listaContiCorrente;
  //   }
  //   // output
  //   this.componentRef.instance.onChangeContoCorrente.subscribe((componenteDinamico: ComponenteDinamico) => {
  //     this.mapContoCorrente.set(componenteDinamico.uuid, componenteDinamico.oggetto);
  //     this.mapControllo.set(componenteDinamico.uuid, componenteDinamico.isFormValid);
  //     this.setListaContiCorrente();
  //   });
  //   this.componentRef.changeDetectorRef.detectChanges();
  //   return indexContoCorrente;
  // }

  aggiornaListaContiCorrenti() {
    let i = 0;
    const targetLength = this.target.length;
    const components = this.componentRefs;
    this.componentRefs = [];
    this.target.clear();
    if (components?.length) {
      while (i < targetLength) {
        const childComponent =
          this.componentFactoryResolver.resolveComponentFactory(
            ContoCorrenteComponent
          );
        const uuid = components[i].instance.uuid;
        const indexDatiContoCorrente =
          components[i].instance.indexDatiContoCorrente;
        const funzione = components[i].instance.funzione;
        const datiContoCorrente = components[i].instance.datiContoCorrente;
        const listaContiCorrente = this.listaContiCorrente;
        const disabled = this.beneficiario.societaId == null;
        this.inizializzaComponentRefInstance(
          childComponent,
          uuid,
          indexDatiContoCorrente,
          funzione,
          datiContoCorrente,
          listaContiCorrente,
          disabled
        );
        i++;
      }
    }
  }

  private inizializzaComponentRefInstance(
    childComponent: ComponentFactory<any>,
    uuid,
    index,
    funzione,
    datiContoCorrente,
    listaContiCorrente,
    disabled: boolean = false
  ) {
    this.componentRef = this.target.createComponent(childComponent);
    this.renderer.addClass(this.componentRef.location.nativeElement, "w-100");
    // input
    this.componentRef.instance.uuid = uuid;
    this.targetMap.set(uuid, this.componentRef.hostView);
    this.componentRef.instance.indexDatiContoCorrente = index;
    this.componentRef.instance.funzione = funzione;
    this.componentRef.instance.datiContoCorrente = datiContoCorrente;
    this.componentRef.instance.listaContiCorrente = listaContiCorrente;
    this.componentRef.instance.inputDisabled = disabled;
    // output
    this.componentRef.changeDetectorRef.detectChanges();
    this.componentRefs.push(this.componentRef);
  }

  showModal(item: CampoTipologiaServizio) {
    let obj = _.cloneDeep(item);
    obj.uuid = item.uuid;

    const campiDettagliotransazioneInUso: string[] =
      this.servizio.listaCampiServizio
        .filter(
          (campo) =>
            !!campo.campoDettaglioTransazione &&
            campo.campoDettaglioTransazione !== item.campoDettaglioTransazione
        )
        .map((campo) => campo.campoDettaglioTransazione)
        .concat(
          this.configuraServizioService.campoTipologiaServizioList
            .filter(
              (campo) =>
                !!campo.campoDettaglioTransazione &&
                campo.campoDettaglioTransazione !==
                  item.campoDettaglioTransazione
            )
            .map((campo) => campo.campoDettaglioTransazione)
        );

    //console.log(campiDettagliotransazioneInUso);

    this.overlayService.mostraModaleCampoEvent.emit({
      campoForm: obj,
      funzione: this.funzione,
      idFunzione: this.idFunzione,
      livelloIntegrazione: this.integrazione.livelloIntegrazioneId,
      mostraLivelloIntegrazione: true,
      listaDipendeDa: this.listaDipendeDa,
      listaCampiDettaglioInUso: campiDettagliotransazioneInUso,
    });
  }

  removeItem(item: CampoServizio) {
    if (this.funzione != ModalitaPaginaGestioneElemento.DETTAGLIO) {
      this.confirmationService.confirm(
        Utils.getModale(() => {
          this.configuraServizioService.campoServizioAddList.splice(
            this.configuraServizioService.campoServizioAddList.findIndex(
              (v) => v.id === item.id
            ),
            1
          );
          this.configuraServizioService.campoTipologiaServizioList =
            this.rimuoviDipendeDaAssociato(
              this.configuraServizioService.campoTipologiaServizioList,
              item
            );
          this.configuraServizioService.campoServizioAddList =
            this.rimuoviDipendeDaAssociato(
              this.configuraServizioService.campoServizioAddList,
              item
            );
          this.refreshItemsDipendeDa();
        }, TipoModaleEnum.ELIMINA)
      );
    }
  }

  private rimuoviDipendeDaAssociato(list, item: CampoServizio) {
    return list.map((campo) => {
      if (campo?.dipendeDa?.uuid === item.uuid) {
        campo.dipendeDa = null;
      }
      return campo;
    });
  }

  isCampoModificato(campoTipologiaServizio: CampoServizio) {
    const campoServizioTrovato = this.servizio.listaCampiServizio.find(
      (campoServizio: CampoServizio) =>
        campoServizio.campoTipologiaServizioId === campoTipologiaServizio.id
    );
    const oggettoServizio = this.oggettoConfronto(campoServizioTrovato);
    const oggettoTipologiaServizio = this.oggettoConfronto(
      campoTipologiaServizio
    );
    const isEqual = _.isEqual(oggettoServizio, oggettoTipologiaServizio);
    return !isEqual;
  }

  oggettoConfronto(item: CampoServizio) {
    return {
      campoInput: item.campoInput,
      chiave: item.chiave,
      controlloLogicoId: item.controlloLogicoId,
      dipendeDa: this.getDipendeDaConfronto(item),
      disabilitato: item.disabilitato,
      jsonPathId: item.jsonPathId,
      lunghezza: item.lunghezza,
      lunghezzaVariabile: item.lunghezzaVariabile,
      obbligatorio: item.obbligatorio,
      posizione: item.posizione,
      tipoCampoId: item.tipoCampoId,
      tipologica: item.tipologica,
      titolo: item.titolo,
      campoDettaglioTransazione: item.campoDettaglioTransazione,
      codice: item.codice,
    };
  }

  private getDipendeDaConfronto(item: CampoServizio) {
    let dipendeDaClone = _.cloneDeep(item.dipendeDa);
    if (dipendeDaClone) {
      delete dipendeDaClone.id;
    }
    return dipendeDaClone;
  }

  undo(item: CampoServizio) {
    if (this.funzione != ModalitaPaginaGestioneElemento.DETTAGLIO) {
      this.confirmationService.confirm(
        Utils.getModale(
          () => {
            const finded = this.campoTipologiaServizioOriginal.find((value) =>
              this.funzione === ModalitaPaginaGestioneElemento.AGGIUNGI
                ? value.id == item.campoTipologiaServizioId
                : value.id == item.id
            );

            const findIndex =
              this.configuraServizioService.campoTipologiaServizioList.findIndex(
                (value) => value.id == item.id
              );
            if (findIndex != -1) {
              finded.uuid =
                this.configuraServizioService.campoTipologiaServizioList[
                  findIndex
                ].uuid;
              finded.modificato = false;
              this.setCampoTipologiaServizioId(item, finded);
              this.configuraServizioService.campoTipologiaServizioList[
                findIndex
              ] = _.cloneDeep(finded);
              this.configuraServizioService.campoTipologiaServizioList[
                findIndex
              ].id = item.id;
              this.refreshItemsDipendeDa();
            }
          },
          TipoModaleEnum.CUSTOM,
          "Annullamento modifiche",
          "Confermare l'annullamento delle modifiche?"
        )
      );
    }
  }

  private refreshItemsDipendeDa() {
    const list = _.concat(
      this.configuraServizioService.campoTipologiaServizioList,
      this.configuraServizioService.campoServizioAddList
    );
    this.refreshItemsEvent.emit(list);
  }

  calcolaDimensioneCampo(campo: CampoTipologiaServizio): string {
    let classe;

    if (
      this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.DATEDDMMYY ||
      this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.DATEMMYY ||
      this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.DATEYY
    ) {
      classe = "col-lg-2 col-md-4 col-xs-6";
    } else if (
      this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.INPUT_PREZZO
    ) {
      classe = "col-lg-2 col-md-4 col-xs-6";
    } else {
      if (
        campo.lunghezza <= this.lunghezzaMaxCol1 ||
        campo.lunghezza == undefined
      ) {
        classe = "col-lg-2 col-md-4 col-xs-6";
      } else if (campo.lunghezza <= this.lunghezzaMaxCol2) {
        classe = "col-lg-3 col-md-4 col-xs-6";
      } else if (campo.lunghezza <= this.lunghezzaMaxCol3) {
        classe = "col-lg-4 col-md-5 col-xs-6";
      } else {
        classe = "col-lg-5 col-md-6 col-xs-6";
      }
    }
    return classe + " example-list";
  }

  private decodeTipoCampo(tipoCampoId: number): string {
    const find = this.listaTipiCampo.find((value) => (value.id = tipoCampoId));
    if (find) {
      return find.nome;
    } else {
      return "";
    }
  }

  add() {
    const campoForm = new CampoTipologiaServizio();
    if (
      this.integrazione.livelloIntegrazioneId !==
      this.LivelloIntegrazioneEnum.LV1
    ) {
      campoForm.campoInput = true;
    }
    this.refreshItemsDipendeDa();
    this.showModal(campoForm);
  }

  aggiungiContoCorrente(contoCorrente?: ContoCorrente): number {
    // creazione Dati Conto Corrente Component
    const childComponent =
      this.componentFactoryResolver.resolveComponentFactory(
        ContoCorrenteComponent
      );
    const indexContoCorrente = this.target.length;
    // input
    const uuid = Utils.uuidv4();
    //const funzione = this.funzione;
    let instanceContoCorrente: ContoCorrente;
    if (contoCorrente == null) {
      instanceContoCorrente = new ContoCorrente();
    } else {
      instanceContoCorrente = contoCorrente;
    }
    const listaContiCorrente = this.listaContiCorrente;
    const disabled = this.beneficiario.societaId == null;
    this.inizializzaComponentRefInstance(
      childComponent,
      uuid,
      indexContoCorrente,
      ModalitaPaginaGestioneElemento.DETTAGLIO,
      instanceContoCorrente,
      listaContiCorrente,
      disabled
    );
    return indexContoCorrente;
  }

  private setListaContiCorrente() {
    const listaContiCorrente: ContoCorrente[] = this.getListaContiCorrente(
      this.mapContoCorrente
    );
    this.beneficiario.listaContiCorrenti = listaContiCorrente;
  }

  validateUrl() {
    return ((control: FormControl) => {
      if (control.value) {
        const regex =
          "(http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?";
        if (!new RegExp(regex).test(control.value)) {
          return { url: false };
        }
      }

      return null;
    }) as ValidatorFn;
  }

  validateServer() {
    return ((control: FormControl) => {
      if (control.value) {
        const regex =
          "[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?";
        const regexIp =
          "^([0-9]{1,3})\\.([0-9]{1,3})\\.([0-9]{1,3})\\.([0-9]{1,3})$";
        if (
          new RegExp(regex).test(control.value) ||
          new RegExp(regexIp).test(control.value)
        ) {
          return null;
        } else {
          return { url: false };
        }
      }

      return null;
    }) as ValidatorFn;
  }

  validateEmail() {
    return ((control: FormControl) => {
      if (control.value) {
        const regex = "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$";
        if (!new RegExp(regex).test(control.value)) {
          return { email: false };
        }
      }

      return null;
    }) as ValidatorFn;
  }

  getPlaceholderRequired(label: string, required: boolean) {
    if (required) {
      return label + " *";
    }
    return label;
  }

  addEmail() {
    this.emailsControl.push(new FormControl());
  }

  removeEmail(index: number) {
    this.emailsControl.splice(index, 1);
  }

  selezionaDaCC() {
    this.displayCc = !this.displayCc;
  }

  changeEmailGiornaliera(event: boolean) {}

  changeFtpGiornaliera(event: boolean) {}

  showModalAtClick(item: CampoTipologiaServizio) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.showModal(item);
      }
    }, 250);
  }

  dblClick(item: CampoServizio, index: number) {
    if (this.funzione == ModalitaPaginaGestioneElemento.DETTAGLIO) {
      return;
    }
    this.isSingleClick = false;
    this.showEditId = index;
  }

  applyEdit(item: CampoServizio) {
    if (item) {
      if (
        !this.campoTipologiaServizioOriginal.find(
          (value) => value.id == item.id && value.titolo == item.titolo
        )
      ) {
        this.amministrativoService.salvaCampoFormEvent.emit(item);
      }
      this.showEditId = null;
    }
  }

  isPresenteInDettaglioAndRendicontazione() {
    return (
      !this.servizio.flagPresenzaDettaglioTransazione &&
      !this.servizio.flagPresenzaRendicontazione
    );
  }

  isPresenteInDatiBonificoAndRendicontazione() {
    return (
      !this.servizio.flagPresenzaDatiBonifico &&
      !this.servizio.flagPresenzaRendicontazione
    );
  }

  disabilitaEnteBeneficiario() {
    const servizioPresenteInRendicontazione =
      this.servizio.flagPresenzaRendicontazione;
    const servizioPresenteInQuadratura =
      this.servizio.flagPresenzaQuadraturaPagoPA;
    const datiBonificoPresentiPerServizio =
      this.servizio.flagPresenzaDatiBonifico;
    const servizioPresenteInDettaglioTransazione =
      this.servizio.flagPresenzaDettaglioTransazione;
    // Sostituire l’ente beneficiario solo se gli identificativi dati di bonifico non sono presenti nella tabella DETTAGLIO TRANSAZIONE
    // e il SERVIZIO non è presente nelle tabelle RENDICONTAZIONE e FLUSSO QUADRATURA PAGOPA
    // altrimenti disabilitare i dati del beneficiario ad eccezioni di quelli di bonifico
    return (
      servizioPresenteInRendicontazione ||
      servizioPresenteInQuadratura ||
      (servizioPresenteInDettaglioTransazione &&
        datiBonificoPresentiPerServizio)
    );
  }

  isPresenteInDettaglio() {
    return !this.servizio.flagPresenzaDettaglioTransazione;
  }

  drop(event: CdkDragDrop<{ item: CampoServizio; index: number }, any>) {
    let arr = _.cloneDeep(this.configuraServizioService.campoServizioAddList);

    arr.splice(event.previousContainer.data.index, 1);
    arr.splice(
      event.container.data.index,
      0,
      event.previousContainer.data.item
    );

    this.configuraServizioService.campoServizioAddList = arr;
  }

  isPresenteInQuadratura() {
    return !this.servizio.flagPresenzaQuadraturaPagoPA;
  }

  validateEmails() {
    return ((control: FormControl) => {
      if (control.value) {
        let emails: string[] = control.value.split(";");
        const regex = "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$";
        for (let idx in emails) {
          let value = emails[idx];
          if (value != "" && !new RegExp(regex).test(value)) {
            return { email: false };
          }
        }
      }
      return null;
    }) as ValidatorFn;
  }

  private recuperaFlussiRendicontazione() {
    //this.listaFlussiRendicontazione.push(null);
    this.flussoRendicontazioneService
      .recuperaFlussiRendicontazione(this.idFunzione)
      .pipe(
        map((listaFlussiRendicontazione) => {
          listaFlussiRendicontazione?.forEach((s) => {
            this.listaFlussiRendicontazione.push({
              id: s.id,
              codice: s.nome,
              nome: s.descrizione,
            });
          });
        })
      )
      .subscribe();
  }

  private sortContoCorrente = (
    c1: ContoCorrente,
    c2: ContoCorrente
  ): number => {
    console.log(c1);
    console.log(c2);

    if (c1 && c2) {
      return moment(c2.inizioValidita, Utils.FORMAT_DATE_CALENDAR).diff(
        moment(c1.inizioValidita, Utils.FORMAT_DATE_CALENDAR)
      );
    } else {
      return 0;
    }
  };
}
