<hr/>
<div class="row">
  <div class="col-md-6 text-center text-md-right">
    <canvas id="canvas" width="128" height="128" class="canvas">
    </canvas>
  </div>
  <div class="align-self-center col-md-6 text-md-left text-center title page-subtitle">{{servizio.nome}}</div>
</div>
<div class="row">
  <div class="col-1">
    <p class="title page-subtitle">Dati</p>
  </div>
  <div class="offset-10 col-1">
    <svg class="bg-light icon"
         data-toggle="collapse" data-target="#sezioneDati"
         aria-expanded="true" aria-controls="sezioneDati"
         (click)="aggiornaVisibilita()">
      <use [attr.xlink:href]="isVisibile ? 'assets/img/sprite.svg#it-collapse' : 'assets/img/sprite.svg#it-expand'"
           xlink:href=""></use>
    </svg>
  </div>
</div>

<span *ngIf="!isFaseVerificaPagamento">Tutti campi contrassegnati con * sono obbligatori</span>

<form [formGroup]="form">
  <div id="sezioneDati" class="collapse show">
    <div class="page-section">
      <div class="row">
        <div *ngFor="let campo of listaCampiDinamici" [ngClass]="calcolaDimensioneCampo(campo)">
          <div [ngSwitch]="campo.tipoCampo" class="form-elem-container">

            <div *ngSwitchCase="TipoCampoEnum.INPUT_TESTUALE" class="dynamic-form-elem"
                 [ngClass]="{'required': isCampoObbligatorio(campo) && !isFaseVerificaPagamento}">
              <div class="row">
                <label [htmlFor]="getIdCampo(campo)" class="title form-elem-title"
                       [ngClass]="{'disabled': isCampoDisabilitato(campo)}">{{getTitoloCampo(campo)}}</label>
              </div>
              <div class="row">
                <input
                  [id]="getIdCampo(campo)"
                  [formControl]="form.controls[getNomeCampoForm(campo)]"
                  [(ngModel)]="model[getNomeCampoForm(campo)]"
                  type="text"
                  [maxLength]="getLunghezzaCampo(campo)"
                  (ngModelChange)="formattaInput($event, campo)"
                  autocomplete="off"
                  [attr.disabled]="isCampoDisabilitato(campo)"
                />
              </div>
              <div class="row">
                <p
                  [ngClass]="{'placeholder': !isCampoInvalido(campo), 'form-elem-error': isCampoInvalido(campo)}">{{getDescrizioneCampo(campo)}}</p>
              </div>
            </div>

            <div *ngSwitchCase="TipoCampoEnum.INPUT_NUMERICO" class="dynamic-form-elem"
                 [ngClass]="{'required': isCampoObbligatorio(campo) && !isFaseVerificaPagamento}">
              <div class="row">
                <label [htmlFor]="getIdCampo(campo)" class="title form-elem-title"
                       [ngClass]="{'disabled': isCampoDisabilitato(campo)}">{{getTitoloCampo(campo)}}</label>
              </div>
              <div class="row">
                <input
                  (ngModelChange)="salvaParziale($event, campo)"
                  [id]="getIdCampo(campo)"
                  [formControl]="form.controls[getNomeCampoForm(campo)]"
                  [(ngModel)]="model[getNomeCampoForm(campo)]"
                  type="number"
                  integerNumber
                  [min]="minInputNumerico"
                  autocomplete="off"
                  [attr.disabled]="isCampoDisabilitato(campo)"
                />
              </div>
              <div class="row">
                <p
                  [ngClass]="{'placeholder': !isCampoInvalido(campo), 'form-elem-error': isCampoInvalido(campo)}">{{getDescrizioneCampo(campo)}}</p>
              </div>
            </div>

            <div *ngSwitchCase="TipoCampoEnum.SELECT" class="dynamic-form-elem"
                 [ngClass]="{'required': isCampoObbligatorio(campo) && !isFaseVerificaPagamento}">
              <div class="row">
                <label [htmlFor]="getIdCampo(campo)" class="title form-elem-title"
                       [ngClass]="{'disabled': isCampoDisabilitato(campo)}">{{getTitoloCampo(campo)}}</label>
              </div>
              <div class="row">
                <select
                  [id]="getIdCampo(campo)"
                  [formControl]="form.controls[getNomeCampoForm(campo)]"
                  [(ngModel)]="model[getNomeCampoForm(campo)]"
                  (ngModelChange)="aggiornaSelectDipendenti($event, campo)"
                  [attr.disabled]="isCampoDisabilitato(campo)"
                  >
                  <!-- [attr.disabled]="isCampoDisabilitato(campo)" -->
                  <option [ngValue]="null"></option>
                  <option *ngFor="let item of campo['opzioni']" [ngValue]="item.value">{{item.label}}</option>
                </select>
              </div>
              <div class="row">
                <p
                  [ngClass]="{'placeholder': !isCampoInvalido(campo), 'form-elem-error': isCampoInvalido(campo)}">{{getDescrizioneCampo(campo)}}</p>
              </div>
            </div>

            <div *ngSwitchCase="TipoCampoEnum.INPUT_PREZZO" class="dynamic-form-elem"
                 [ngClass]="{'required': isCampoObbligatorio(campo) && !isFaseVerificaPagamento}">
              <div class="row">
                <label [htmlFor]="getIdCampo(campo)" class="title form-elem-title"
                       [ngClass]="{'disabled': isCampoDisabilitato(campo)}">{{getTitoloCampo(campo)}}</label>
              </div>
              <div class="row">
                <input
                  (ngModelChange)="salvaParziale($event, campo)"
                  [id]="getIdCampo(campo)"
                  class="price-input"
                  [formControl]="form.controls[getNomeCampoForm(campo)]"
                  [(ngModel)]="model[getNomeCampoForm(campo)]"
                  [maxLength]="getLunghezzaCampo(campo)"
                  type="tel"
                  currencyMask
                  [options]="{allowNegative: false, prefix: '', precision: cifreDecimaliPrezzo, decimal: ',', thousands: '', nullable: true, inputMode: 'FINANCIAL'}"
                  autocomplete="off"
                  [attr.disabled]="isCampoDisabilitato(campo)"
                />
              </div>
              <div class="row">
                <p
                  [ngClass]="{'placeholder': !isCampoInvalido(campo), 'form-elem-error': isCampoInvalido(campo)}">{{getDescrizioneCampo(campo)}}</p>
              </div>
            </div>

            <div *ngSwitchCase="TipoCampoEnum.DATEDDMMYY" class="dynamic-form-elem"
                 [ngClass]="{'required': isCampoObbligatorio(campo) && !isFaseVerificaPagamento}">
              <div class="row">
                <label [htmlFor]="getIdCampo(campo)" class="title form-elem-title"
                       [ngClass]="{'disabled': isCampoDisabilitato(campo)}">{{getTitoloCampo(campo)}}</label>
              </div>
              <div class="row">
                <div class="calendar-wrapper">
                  <svg class="bg-light icon img-calendar" [ngClass]="{'disabled': isCampoDisabilitato(campo)}"
                       (click)="apriDatePicker(dayPicker, campo)">
                    <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
                  </svg>
                  <dp-date-picker
                    (ngModelChange)="salvaParziale($event, campo)"
                    mode="day"
                    #dayPicker
                    dayInput
                    theme="dp-material"
                    [formControl]="form.controls[getNomeCampoForm(campo)]"
                    [(ngModel)]="model[getNomeCampoForm(campo)]"
                    [id]="getIdCampo(campo)"
                    [config]="{
                    locale: 'it',
                    format: 'DD/MM/YYYY',
                    firstDayOfWeek: 'mo',
                    showGoToCurrent: false,
                    min: this.minDataDDMMYY,
                    returnedValueType: tipoData,
                    openOnClick: false,
                    openOnFocus: false
                  }"
                    [disabled]="isCampoDisabilitato(campo)"
                  ></dp-date-picker>
                </div>
              </div>
              <div class="row">
                <p
                  [ngClass]="{'placeholder': !isCampoInvalido(campo), 'form-elem-error': isCampoInvalido(campo)}">{{getDescrizioneCampo(campo)}}</p>
              </div>
            </div>

            <div *ngSwitchCase="TipoCampoEnum.DATEMMYY" class="dynamic-form-elem"
                 [ngClass]="{'required': isCampoObbligatorio(campo) && !isFaseVerificaPagamento}">
              <div class="row">
                <label [htmlFor]="getIdCampo(campo)" class="title form-elem-title"
                       [ngClass]="{'disabled': isCampoDisabilitato(campo)}">{{getTitoloCampo(campo)}}</label>
              </div>
              <div class="row">
                <div class="calendar-wrapper">
                  <svg class="bg-light icon img-calendar" [ngClass]="{'disabled': isCampoDisabilitato(campo)}"
                       (click)="apriDatePicker(monthPicker, campo)">
                    <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
                  </svg>
                  <dp-date-picker
                    (ngModelChange)="salvaParziale($event, campo)"
                    mode="month"
                    #monthPicker
                    monthInput
                    theme="dp-material"
                    [formControl]="form.controls[getNomeCampoForm(campo)]"
                    [(ngModel)]="model[getNomeCampoForm(campo)]"
                    [id]="getIdCampo(campo)"
                    [config]="{
                    locale: 'it',
                    format: 'MM/YYYY',
                    showGoToCurrent: false,
                    min: this.minDataMMYY,
                    returnedValueType: tipoData,
                    openOnClick: false,
                    openOnFocus: false
                  }"
                    [disabled]="isCampoDisabilitato(campo)"
                  ></dp-date-picker>
                </div>
              </div>
              <div class="row">
                <p
                  [ngClass]="{'placeholder': !isCampoInvalido(campo), 'form-elem-error': isCampoInvalido(campo)}">{{getDescrizioneCampo(campo)}}</p>
              </div>
            </div>

            <div *ngSwitchCase="TipoCampoEnum.DATEYY" class="dynamic-form-elem"
                 [ngClass]="{'required': isCampoObbligatorio(campo) && !isFaseVerificaPagamento}">
              <div class="row">
                <label [htmlFor]="getIdCampo(campo)" class="title form-elem-title"
                       [ngClass]="{'disabled': isCampoDisabilitato(campo)}">{{getTitoloCampo(campo)}}</label>
              </div>
              <div class="row">
                <input
                  (ngModelChange)="salvaParziale($event, campo)"
                  [id]="getIdCampo(campo)"
                  [formControl]="form.controls[getNomeCampoForm(campo)]"
                  [(ngModel)]="model[getNomeCampoForm(campo)]"
                  type="number"
                  integerNumber
                  [min]="this.minDataYY"
                  autocomplete="off"
                  [attr.disabled]="isCampoDisabilitato(campo)"
                />
              </div>
              <div class="row">
                <p
                  [ngClass]="{'placeholder': !isCampoInvalido(campo), 'form-elem-error': isCampoInvalido(campo)}">{{getDescrizioneCampo(campo)}}</p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

  <!--Input importo-->
  <div class="page-section" *ngIf="mostraCampoImporto">
    <div class="row">
      <div class="offset-md-8 col-md-4">
        <div class="form-elem-container" [ngClass]="{'required': !isFaseVerificaPagamento}">
          <div class="row">
            <label for="importoTotale" class="title form-elem-title" [ngClass]="{'disabled': isFaseVerificaPagamento}">
              Importo totale
            </label>
          </div>
          <div class="row">
            <input
              class="price-input"
              id="importoTotale"
              [formControl]="importoFormControl"
              [(ngModel)]="model[importoNomeCampo]"
              (ngModelChange)="salvaParziale($event, null, importoNomeCampo)"
              type="tel"
              currencyMask
              [options]="{allowNegative: false, prefix: '', precision: cifreDecimaliPrezzo, decimal: ',', thousands: '', nullable: true, inputMode: 'FINANCIAL'}"
              autocomplete="off"
              [attr.disabled]="isFaseVerificaPagamento ? true : null"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr/>

  <!--Bottoni-->
  <div class="row" *ngIf="datiPagamento">
    <!-- Bottone sx -->

    <div class="col-2">
      <button type="button" id="bottoneIndietro" class="btn btn-secondary" (click)="clickIndietro()">Indietro</button>
    </div>

    <!-- Bottoni dx -->
    <div *ngIf="isBollettinoPagato === false"
         class="col-10 text-right button-space">
      <button type="button" id="bottoneAggiungiAlCarrello" class="btn btn-secondary"
              (click)="aggiungiAlCarrello()">Aggiungi al carrello
      </button>
      <button type="button" id="bottonePagaOra" class="btn"
              (click)="pagaOra()">Paga ora
      </button>
    </div>
  </div>

  <div class="row" *ngIf="!datiPagamento">
    <!-- Bottone sx -->
    <div
      *ngIf="!isBollettinoPrecompilato || (isBollettinoPrecompilato && !isFaseVerificaPagamento)"
      class="col-2">
      <button type="button" id="bottonePulisci" class="btn btn-secondary" (click)="clickPulisci()">Pulisci</button>
    </div>
    <div *ngIf="isBollettinoPrecompilato && isFaseVerificaPagamento"
         class="col-2">
      <button type="button" id="bottoneIndietro" class="btn btn-secondary" (click)="clickIndietro()">Indietro</button>
    </div>

    <!-- Bottoni dx -->
    <div
      *ngIf="!isBollettinoPrecompilato || (isBollettinoPrecompilato && isFaseVerificaPagamento)"
      class="col-10 text-right button-space">
        <span it-tooltip="{{tooltipBottoneSalvaPerDopo}}" placement="top">
          <button type="button" (click)="salvaPerDopo()" id="bottoneSalvaPerDopo" class="btn btn-secondary"
                  [disabled]="menuService.isUtenteAnonimo || (isBollettinoPrecompilato && form.invalid)">Salva per dopo</button>
        </span>
      <button type="button" id="bottoneAggiungiAlCarrello" class="btn btn-secondary"
              [disabled]="form.invalid"
              (click)="aggiungiAlCarrello()">Aggiungi al carrello
      </button>
      <button type="button" id="bottonePagaOra" class="btn"
              [disabled]="form.invalid" (click)="pagaOra()">
        Paga ora
      </button>
    </div>

    <div type="button" *ngIf="isBollettinoPrecompilato && !isFaseVerificaPagamento"
         class="col-10 text-right">
      <button id="bottoneProcedi" class="btn" [disabled]="form.invalid" (click)="clickProcedi()">Procedi</button>
    </div>
  </div>
</form>
<p-confirmDialog #cb icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" class="undoButton" pButton icon="pi pi-times" label="Annulla" (click)="cb.reject()"></button>
    <button type="button" class="okButton" pButton icon="pi pi-check" label="Ok" (click)="cb.accept()"></button>
  </p-footer>
</p-confirmDialog>
