<form [formGroup]="form" class="modal-wrapper">
  <div class="row form-row">
    <!--    titolo  -->
    <div class="col-sm-6 col-md-6 form-elem-container">
      <div class="row">
        <label
          [htmlFor]="'titolo'"
          class="title form-elem-title"
          [ngClass]="{
            required:
              datiModaleCampo?.funzione !== FunzioneGestioneEnum.DETTAGLIO
          }"
          >Titolo</label
        >
      </div>
      <div class="row">
        <input
          id="titolo"
          [formControl]="form.controls['titolo']"
          [(ngModel)]="datiModaleCampo.campoForm.titolo"
          type="text"
          autocomplete="off"
        />
      </div>
    </div>
    <!--    tipo campo  -->
    <div class="col-sm-6 col-md-6 form-elem-container">
      <div class="row">
        <label
          [htmlFor]="'tipoCampo'"
          class="title form-elem-title"
          [ngClass]="{
            required:
              datiModaleCampo?.funzione !== FunzioneGestioneEnum.DETTAGLIO
          }"
          >Tipo Campo</label
        >
        <span
          class="pointer bottone-campo"
          *ngIf="datiModaleCampo.funzione !== FunzioneGestioneEnum.DETTAGLIO"
        >
          <span
            ngbTooltip="Crea nuovo tipo campo"
            placement="bottom"
            [ngStyle]="{ fill: '#403d39' }"
          >
            <svg (click)="addTipoCampo()" class="bg-light icon icon-sm filled">
              <use xlink:href="assets/img/sprite.svg#it-plus"></use>
            </svg>
          </span>
        </span>
      </div>
      <div class="row">
        <select
          id="tipoCampo"
          [formControl]="form.controls['tipoCampo']"
          [(ngModel)]="datiModaleCampo.campoForm.tipoCampoId"
          (ngModelChange)="selezionaTipoCampo($event)"
        >
          <option [ngValue]="null"></option>
          <option *ngFor="let item of listaTipiCampo" [ngValue]="item.id">
            {{ item.nome }} - {{ item.informazioni }}
          </option>
        </select>
      </div>
    </div>
    <!--    obbligatorio  -->
    <div class="col-sm-4 col-md-4 col-lg-2 form-elem-container">
      <div class="row">
        <label [htmlFor]="'obbligatorio'" class="title form-elem-title"
          >Obbligatorio</label
        >
      </div>
      <div class="row">
        <input
          id="obbligatorio"
          type="checkbox"
          [formControl]="form.controls['obbligatorio']"
          [(ngModel)]="datiModaleCampo.campoForm.obbligatorio"
          type="checkbox"
          autocomplete="off"
        />
      </div>
    </div>
    <!--    chiave  -->
    <div class="col-sm-4 col-md-4 col-lg-2 form-elem-container">
      <div class="row">
        <label [htmlFor]="'chiave'" class="title form-elem-title">Chiave</label>
      </div>
      <div class="row">
        <input
          id="chiave"
          type="checkbox"
          [formControl]="form.controls['chiave']"
          [(ngModel)]="datiModaleCampo.campoForm.chiave"
          (click)="clickChiave($event)"
          type="checkbox"
          autocomplete="off"
        />
      </div>
    </div>
    <!--    lunghezza variabile  -->
    <div class="col-sm-4 col-md-4 col-lg-2 form-elem-container">
      <div class="row">
        <label [htmlFor]="'lunghezzaVariabile'" class="title form-elem-title"
          >Lunghezza variabile</label
        >
      </div>
      <div class="row">
        <input
          id="lunghezzaVariabile"
          [formControl]="form.controls['lunghezzaVariabile']"
          [(ngModel)]="datiModaleCampo.campoForm.lunghezzaVariabile"
          type="checkbox"
          autocomplete="off"
        />
      </div>
    </div>
    <!--    lunghezza  -->
    <div class="col-sm-4 col-md-4 col-lg-2 form-elem-container">
      <div class="row">
        <label [htmlFor]="'lunghezza'" class="title form-elem-title"
          >Lunghezza</label
        >
      </div>
      <div class="row">
        <input
          id="lunghezza"
          [formControl]="form.controls['lunghezza']"
          [(ngModel)]="datiModaleCampo.campoForm.lunghezza"
          type="text"
          autocomplete="off"
        />
      </div>
    </div>
    <!--    campo fisso  -->
    <div class="col-sm-4 col-md-4 col-lg-2 form-elem-container">
      <div class="row">
        <label [htmlFor]="'campoFisso'" class="title form-elem-title"
          >Campo fisso</label
        >
      </div>
      <div class="row">
        <input
          id="campoFisso"
          [formControl]="form.controls['campoFisso']"
          [(ngModel)]="datiModaleCampo.campoForm.campoFisso"
          type="checkbox"
          autocomplete="off"
        />
      </div>
    </div>
    <!--    disabilitato -->
    <div class="col-sm-4 col-md-4 col-lg-2 form-elem-container">
      <div class="row">
        <label [htmlFor]="'disabilitato'" class="title form-elem-title"
          >Disabilitato</label
        >
      </div>
      <div class="row">
        <input
          id="disabilitato"
          type="checkbox"
          [formControl]="form.controls['disabilitato']"
          [(ngModel)]="datiModaleCampo.campoForm.disabilitato"
          type="checkbox"
          autocomplete="off"
        />
      </div>
    </div>
    <!--    non visibile -->
    <div class="col-sm-4 col-md-4 col-lg-2 form-elem-container">
      <div class="row">
        <label [htmlFor]="'nonVisibile'" class="title form-elem-title"
          >Campo non visibile</label
        >
      </div>
      <div class="row">
        <input
          id="nonVisibile"
          type="checkbox"
          [formControl]="form.controls['nonVisibile']"
          [(ngModel)]="datiModaleCampo.campoForm.nonVisibile"
          type="checkbox"
          autocomplete="off"
        />
      </div>
    </div>
    <!--    livello integrazione  -->
    <ng-container
      *ngIf="
        datiModaleCampo.livelloIntegrazione &&
        datiModaleCampo.mostraLivelloIntegrazione
      "
    >
      <div class="col-sm-4 col-md-4 col-lg-2 form-elem-container">
        <div class="row">
          <label [htmlFor]="'livelloIntegrazione'" class="title form-elem-title"
            >Livello di Integrazione</label
          >
        </div>
        <div class="row">
          <select
            id="livelloIntegrazione"
            [disabled]="true"
            [attr.disabled]="true"
            [formControl]="form.controls['livelloIntegrazione']"
            [(ngModel)]="datiModaleCampo.livelloIntegrazione"
            (ngModelChange)="
              cambiaLivelloIntegrazione(datiModaleCampo.campoForm, $event)
            "
          >
            <option [ngValue]="livelloIntegrazioneEnum.LV2">Livello 2</option>
            <option [ngValue]="livelloIntegrazioneEnum.LV2_BACK_OFFICE">
              Livello 2 BO
            </option>
            <option [ngValue]="livelloIntegrazioneEnum.M3">Modello 3</option>
            <option [ngValue]="livelloIntegrazioneEnum.M3P">Modello 3 da Portale Crea Avvisi</option>
          </select>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-2 form-elem-container">
        <div class="row">
          <label [htmlFor]="'campo_input'" class="title form-elem-title"
            >Campo input</label
          >
        </div>
        <div class="row">
          <input
            id="campo_input"
            [formControl]="form.controls['campo_input']"
            [(ngModel)]="datiModaleCampo.campoForm.campoInput"
            [attr.disabled]="
              datiModaleCampo.livelloIntegrazione == livelloIntegrazioneEnum.LV2
                ? true
                : null
            "
            (ngModelChange)="
              cambiaLivelloIntegrazione(
                datiModaleCampo.campoForm,
                datiModaleCampo.livelloIntegrazione
              )
            "
            type="checkbox"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-2 form-elem-container">
        <div class="row">
          <label [htmlFor]="'jsonPath'" class="title form-elem-title"
            >Json path</label
          >
        </div>
        <div class="row">
          <select
            id="jsonPath"
            [attr.disabled]="
              datiModaleCampo.livelloIntegrazione == livelloIntegrazioneEnum.LV2
                ? true
                : null
            "
            [formControl]="form.controls['jsonPath']"
            [(ngModel)]="datiModaleCampo.campoForm['jsonPathId']"
          >
            <option [ngValue]="null"></option>
            <option
              *ngFor="let item of listaJsonPathFiltrata"
              [ngValue]="item.id"
            >
              {{ item.nome_attributo }}
            </option>
          </select>
        </div>
      </div>
    </ng-container>
    <!--    tipologica  -->
    <div class="col-sm-4 col-md-4 col-lg-2 form-elem-container">
      <div class="row">
        <label
          [htmlFor]="'tipologica'"
          class="title form-elem-title"
          [ngClass]="{
            required:
              nomeTipoCampoSelezionato === TipoCampoEnum.SELECT &&
              datiModaleCampo?.funzione !== FunzioneGestioneEnum.DETTAGLIO
          }"
          >Tipologica</label
        >
      </div>
      <div class="row">
        <select
          id="tipologica"
          [formControl]="form.controls['tipologica']"
          [(ngModel)]="datiModaleCampo.campoForm.tipologica"
        >
          <option [ngValue]="null"></option>
          <option *ngFor="let item of listaTipologiche" [ngValue]="item.nome">
            {{ item.nome }}
          </option>
        </select>
      </div>
    </div>
    <!--    dipende da  -->
    <div class="col-sm-6 col-md-6 col-lg-3 form-elem-container">
      <div class="row">
        <label [htmlFor]="'dipendeDa'" class="title form-elem-title"
          >Dipende da</label
        >
      </div>
      <div class="row">
        <select
          [attr.disabled]="dipendeDaIsDisabled()"
          id="dipendeDa"
          [formControl]="form.controls['dipendeDa']"
          [(ngModel)]="datiModaleCampo.campoForm.dipendeDa"
        >
          <option [ngValue]="null"></option>
          <option
            *ngFor="let item of datiModaleCampo.listaDipendeDa"
            [ngValue]="item"
          >
            {{ item.titolo }}
          </option>
        </select>
      </div>
    </div>
    <!--    controllo logico  -->
    <div class="col-sm-6 col-md-6 col-lg-3 form-elem-container">
      <div class="row">
        <label [htmlFor]="'controllo_logico'" class="title form-elem-title"
          >Controllo logico</label
        >
      </div>
      <div class="row">
        <select
          id="controllo_logico"
          [formControl]="form.controls['controllo_logico']"
          [(ngModel)]="datiModaleCampo.campoForm.controlloLogicoId"
        >
          <option [ngValue]="null"></option>
          <option
            *ngFor="let item of listaControlliLogici"
            [ngValue]="item.nome"
          >
            {{ item.nome }}
          </option>
        </select>
      </div>
    </div>
    <!--    campo dettaglio transazione  -->
    <div
      *ngIf="showCampoDettaglioTransazione()"
      class="col-sm-6 col-md-6 col-lg-3 form-elem-container"
    >
      <div class="row">
        <label
          [htmlFor]="'campoDettaglioTransazione'"
          class="title form-elem-title"
          >Campo dettaglio transazione</label
        >
      </div>
      <div class="row">
        <select
          id="campoDettaglioTransazione"
          [formControl]="form.controls['campoDettaglioTransazione']"
          [(ngModel)]="datiModaleCampo.campoForm.campoDettaglioTransazione"
        >
          <option [ngValue]="null"></option>
          <option
            *ngFor="let item of campiDettaglioDisponibili()"
            [ngValue]="item.nome"
          >
            {{ item.nome }}
          </option>
        </select>
      </div>
    </div>

    <!--  Codice identificativo  -->
    <ng-container *ngIf="datiModaleCampo.idFunzione == 9">
      <!-- Check per Configura Servizio -->
      <div class="col-sm-6 col-md-6 col-lg-3 form-elem-container mt-1">
        <div class="row">
          <label [htmlFor]="'codice'" class="title form-elem-title"
            >Codice Identificativo</label
          >
        </div>
        <div class="row">
          <input
            id="codice"
            [formControl]="form.controls['codice']"
            [(ngModel)]="datiModaleCampo.campoForm.codice"
            type="text"
            autocomplete="off"
            [pattern]="'^(?!importo$)[a-z][a-z0-9_]*$'"
            payerCodiceNonRipetuto
          />
        </div>
        <div
          class="row"
          *ngIf="form.controls['codice'].errors"
          style="color: var(--lepida-danger)"
        >
          <small>{{ erroreCodice() }}</small>
        </div>
      </div>
    </ng-container>

    <!--    campo tipo rendicontazione  -->
    <!-- <div *ngIf="showMultiSelect" class="col-sm-12 col-md-12 col-lg-12 form-elem-container">
      <div class="row">
        <label [htmlFor]="'listCampoTipoRendicontazione'" class="title form-elem-title">Campo tipo
          rendicontazione</label>
      </div>
      <div class="row">
        <ng-multiselect-dropdown class="multiSelect" name="CampoTipoRendicontazione" ngDefaultControl
          [placeholder]="' '" [disabled]="datiModaleCampo.funzione === FunzioneGestioneEnum.DETTAGLIO"
          [settings]="dropdownSettings" [data]="listaCampoTipoRendicontazione"
          [formControl]="form.controls['listCampoTipoRendicontazione']"></ng-multiselect-dropdown>

      </div>
    </div> -->

    <div class="col-12">
      <div class="row buttons-row">
        <div class="col-2">
          <button
            type="button"
            id="bottoneIndietro"
            class="btn btn-secondary"
            (click)="clickIndietro()"
          >
            {{
              datiModaleCampo.funzione === FunzioneGestioneEnum.DETTAGLIO
                ? "Indietro"
                : "Annulla"
            }}
          </button>
        </div>

        <div
          class="col-10 text-right"
          *ngIf="datiModaleCampo.funzione !== FunzioneGestioneEnum.DETTAGLIO"
        >
          <button
            (click)="salvaCampoForm()"
            type="button"
            class="btn"
            [disabled]="form.invalid"
          >
            Salva
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
