<div class="container-fluid">
  <!-- breadcrumb -->
  <it-breadcrumb [dark]="false" [separator]="'/'">
    <it-breadcrumb-item [id]="'breadcrumb-item-' + item.id" *ngFor="let item of breadcrumbList; let i = index">
      {{item.label}}
    </it-breadcrumb-item>
  </it-breadcrumb>

  <!-- titolo -->
  <h3 class="mb-2 page-title"><strong>{{titoloPagina}}</strong>
    <span it-tooltip="{{tooltip}}">
      <svg class="bg-light icon icon-lg icon-padded">
        <use xlink:href="assets/img/sprite.svg#it-help-circle"></use>
      </svg>
    </span>
  </h3>
  <app-avviso-campi-obbligatori></app-avviso-campi-obbligatori>
  
  <!-- dati -->
  <app-filtro-gestione-servizio *ngIf="idFunzione" [filtriIniziali]="filtro" [funzione]="funzione"
    (onChangeFiltri)="onChangeFiltri($event)"></app-filtro-gestione-servizio>

  <div *ngIf="filtri" class="ml-4">
    <form #datiForm="ngForm">
      <div class="collapse-header" id="idCollapseFiltri">
        <button data-toggle="collapse" data-target="#contatti" aria-expanded="false" aria-controls="contatti">
          Contatti
        </button>
      </div>
      <div id="contatti" class="collapse" role="tabpanel" aria-labelledby="idCollapseFiltri">
        <div class="collapse-body">
          <div class="row mt-2 mb-0">
            <div class="col-sm-6 col-md-4 col-lg-4 pb-2">
              <div class="row">
                <div class="col">
                  <label for="nome" class="title">Nome</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input id="nome" name="nome" type="text" class="input-text pt-0" autocomplete="off" #nome="ngModel"
                    [(ngModel)]="contatti.nome" [disabled]="disabilitaCampi()">
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p [ngClass]="{'text-invalid': isCampoInvalido(nome)}">
                    {{setPlaceholder(nome, TipoCampoEnum.INPUT_TESTUALE)}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-4 pb-2">
              <div class="row">
                <div class="col">
                  <label for="cognome" class="title">Cognome</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input id="cognome" name="cognome" type="text" class="input-text pt-0" autocomplete="off"
                    #cognome="ngModel" [(ngModel)]="contatti.cognome" [disabled]="disabilitaCampi()">
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p [ngClass]="{'text-invalid': isCampoInvalido(cognome)}">
                    {{setPlaceholder(cognome, TipoCampoEnum.INPUT_TESTUALE)}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-4 pb-2">
              <div class="row">
                <div class="col">
                  <label for="tel" class="title">Tel. ufficio o cellulare</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input id="tel" name="tel" type="text" class="input-text pt-0" autocomplete="off" #tel="ngModel"
                    [(ngModel)]="contatti.telefono" [disabled]="disabilitaCampi()">
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p [ngClass]="{'text-invalid': isCampoInvalido(tel)}">
                    {{setPlaceholder(tel, TipoCampoEnum.INPUT_TESTUALE)}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2 mb-0">
            <div class="col-sm-6 col-md-4 col-lg-4 pb-2">
              <div class="row">
                <div class="col">
                  <label for="email" class="title">Email</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input id="email" name="email" type="text" class="input-text pt-0" autocomplete="off"
                    [customvalidator]="validateEmail()" #email="ngModel" [(ngModel)]="contatti.email"
                    [disabled]="disabilitaCampi()">
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p [ngClass]="{'text-invalid': isCampoInvalido(email)}">
                    {{setPlaceholder(email, TipoCampoEnum.INPUT_TESTUALE)}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-5 pb-2">
              <div class="row">
                <div class="col">
                  <label for="reperibile" class="title">Giorni e orario in cui è reperibile</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input id="reperibile" name="reperibile" type="text" class="input-text pt-0" autocomplete="off"
                    #reperibile="ngModel" [(ngModel)]="contatti.reperibilita" [disabled]="disabilitaCampi()">
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p [ngClass]="{'text-invalid': isCampoInvalido(reperibile)}">
                    {{setPlaceholder(reperibile, TipoCampoEnum.INPUT_TESTUALE)}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="collapse-header" id="idCollapseIntegrazione">
        <button data-toggle="collapse" data-target="#integrazione" aria-expanded="false" aria-controls="integrazione">
          Integrazione
        </button>
      </div>
      <div id="integrazione" class="collapse" role="tabpanel" aria-labelledby="idCollapseIntegrazione">
        <div class="collapse-body">
          <div class="row mt-2 mb-0">
            <div class="col-sm-6 col-md-4 col-lg-4 pb-2">
              <div class="row">
                <div class="col">
                  <label [htmlFor]="'livelloIntegrazione'" class="title form-elem-title"
                    [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Livello di Integrazione</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <select id="livelloIntegrazione" name="livelloIntegrazione"
                    [(ngModel)]="integrazione.livelloIntegrazioneId" 
                    #livelloIntegrazione="ngModel" 
                    [required]="true"
                    [disabled]="funzione == FunzioneGestioneEnum.DETTAGLIO || funzione == FunzioneGestioneEnum.MODIFICA"
                    (ngModelChange)="cambiaLivelloIntegrazione($event)">
                    <option [ngValue]="null" [selected]="true">Seleziona</option>
                    <option *ngFor="let item of listaLivelloIntegrazione" [ngValue]="item.id">{{item.nome}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p [ngClass]="{'text-invalid': isCampoInvalido(livelloIntegrazione)}">
                    {{setPlaceholder(livelloIntegrazione, TipoCampoEnum.SELECT)}}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-4 pb-2"
              *ngIf="integrazione.livelloIntegrazioneId === LivelloIntegrazioneEnum.LV2 || integrazione.livelloIntegrazioneId === LivelloIntegrazioneEnum.LV2_BACK_OFFICE">
              <div class="row">
                <div class="col">
                  <label [htmlFor]="'livelloIntegrazione'" class="title form-elem-title"
                    [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Link diretto al form</label>
                  <img role="button" (click)="copyTextUrl()" [src]="ctc" width="16" height="16" align="right">
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input id="linkDirettoForm" name="linkDirettoForm" [disabled]="true" type="text"
                    value="{{urlBasePerNuovoPagamento}}/{{servizioId}}">
                </div>
              </div>
            </div>

            <ng-container *ngIf="integrazione.livelloIntegrazioneId == LivelloIntegrazioneEnum.LV2_BACK_OFFICE">
              <div class="col-sm-6 col-md-5 col-lg-5 pb-2">
                <div class="row">
                  <div class="col">
                    <label [htmlFor]="'urlWsBO'" class="title form-elem-title"
                      [ngClass]="{'required': integrazione.livelloIntegrazioneId == LivelloIntegrazioneEnum.LV2_BACK_OFFICE}">Url
                      del web service di Back Office</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <input id="urlWsBO" name="urlWsBO" type="text" class="input-text pt-0" autocomplete="off"
                      [customvalidator]="validateUrl()"
                      [required]="integrazione.livelloIntegrazioneId == LivelloIntegrazioneEnum.LV2_BACK_OFFICE"
                      #urlWsBO="ngModel" [(ngModel)]="integrazione.urlWebServiceBackoffice"
                      [disabled]="disabilitaCampi()">
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p [ngClass]="{'text-invalid': isCampoInvalido(urlWsBO)}">
                      {{setPlaceholder(urlWsBO, TipoCampoEnum.INPUT_TESTUALE)}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="integrazione.livelloIntegrazioneId == LivelloIntegrazioneEnum.LV1">
              <div class="col-sm-6 col-md-4 col-lg-2 pb-2">
                <div class="row">
                  <div class="col">
                    <label [htmlFor]="'codiceUtente'" class="title form-elem-title"
                      [ngClass]="{'required': integrazione.livelloIntegrazioneId == LivelloIntegrazioneEnum.LV1}">Codice Utente</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <input id="codiceUtente" name="codiceUtente" type="text" class="input-text pt-0" autocomplete="off"
                      [size]="5" [maxLength]="5"
                      [required]="integrazione.livelloIntegrazioneId == LivelloIntegrazioneEnum.LV1"
                      #codiceUtente="ngModel" [(ngModel)]="integrazione.ufficio.codiceUtente"
                      [disabled]="disabilitaCampi()">
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(codiceUtente),
                         'text-invalid': isCampoInvalido(codiceUtente)}">
                      {{setPlaceholder(codiceUtente, TipoCampoEnum.INPUT_TESTUALE)}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-2 pb-2">
                <div class="row">
                  <div class="col">
                    <label [htmlFor]="'codiceEnte'" class="title form-elem-title"
                      [ngClass]="{'required': integrazione.livelloIntegrazioneId == LivelloIntegrazioneEnum.LV1}">Codice
                      Ente</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <input id="codiceEnte" name="codiceEnte" type="text" class="input-text pt-0" autocomplete="off"
                      [size]="5" [maxLength]="5"
                      [required]="integrazione.livelloIntegrazioneId == LivelloIntegrazioneEnum.LV1"
                      #codiceEnte="ngModel" [(ngModel)]="integrazione.ufficio.codiceEnte"
                      [disabled]="disabilitaCampi()">
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(codiceEnte),
                         'text-invalid': isCampoInvalido(codiceEnte)}">
                      {{setPlaceholder(codiceEnte, TipoCampoEnum.INPUT_TESTUALE)}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-2 pb-2">
                <div class="row">
                  <div class="col">
                    <label [htmlFor]="'tipoUfficio'" class="title form-elem-title">Tipo Ufficio</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <input id="tipoUfficio" name="tipoUfficio" type="text" class="input-text pt-0" autocomplete="off"
                      [size]="1" [maxLength]="1" #tipoUfficio="ngModel" [(ngModel)]="integrazione.ufficio.tipoUfficio"
                      [disabled]="disabilitaCampi()">
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-2 pb-2">
                <div class="row">
                  <div class="col">
                    <label [htmlFor]="'codiceUfficio'" class="title form-elem-title">Codice Ufficio</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <input id="codiceUfficio" name="codiceUfficio" type="text" class="input-text pt-0"
                      autocomplete="off" [size]="6" [maxLength]="6" #codiceUfficio="ngModel"
                      [(ngModel)]="integrazione.ufficio.codiceUfficio" [disabled]="disabilitaCampi()">
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4 pb-2">
                <div class="row">
                  <div class="col">
                    <label [htmlFor]="'codiceIDServizio'" class="title form-elem-title">Codice IDServizio</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <input id="codiceIDServizio" name="codiceIDServizio" type="text" class="input-text pt-0"
                      autocomplete="off" [size]="11" [maxLength]="11" #codiceIDServizio="ngModel"
                      [(ngModel)]="integrazione.codiceIdServizio" [disabled]="disabilitaCampi()">
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4 pb-2">
                <div class="row">
                  <div class="col">
                    <label [htmlFor]="'numeroTentativiNotifica'" class="title form-elem-title">Numero tentativi di
                      notifica</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <input id="numeroTentativiNotifica" name="numeroTentativiNotifica" type="number"
                      class="input-text pt-0" autocomplete="off" #numeroTentativiNotifica="ngModel"
                      [(ngModel)]="integrazione.numeroTentativiNotifica" [disabled]="disabilitaCampi()">
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-2 pb-2">
                <div class="row">
                  <div class="col">
                    <label [htmlFor]="'redirect'" class="title form-elem-title">Redirect</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <input id="redirect" name="redirect" type="checkbox" class="input-text pt-0" autocomplete="off"
                      #redirect="ngModel" [(ngModel)]="integrazione.redirect" [disabled]="disabilitaCampi()">
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-2 pb-2">
                <div class="row">
                  <div class="col">
                    <label [htmlFor]="'portaleEsterno'" class="title form-elem-title"
                      [ngClass]="{'required': integrazione.livelloIntegrazioneId == LivelloIntegrazioneEnum.LV1 || funzione !== FunzioneGestioneEnum.DETTAGLIO}">
                      Portale esterno</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <select id="portaleEsterno" name="portaleEsterno" #portaleEsterno="ngModel"
                      [(ngModel)]="integrazione.portaleEsternoId"
                      [required]="integrazione.livelloIntegrazioneId == LivelloIntegrazioneEnum.LV1"
                      [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO || !isPresenteInDettaglioAndRendicontazione()">
                      <option [ngValue]="null" [selected]="true">Seleziona</option>
                      <option *ngFor="let item of listaPortaleEsterno" [ngValue]="item.id">{{item.nome}}</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(portaleEsterno),
                         'text-invalid': isCampoInvalido(portaleEsterno)}">
                      {{setPlaceholder(portaleEsterno, TipoCampoEnum.SELECT)}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="collapse-header" id="idCollapseImpositore">
        <button data-toggle="collapse" data-target="#impositore" aria-expanded="false" aria-controls="impositore">
          Impositore
        </button>
      </div>
      <div id="impositore" class="collapse" role="tabpanel" aria-labelledby="idCollapseImpositore">
        <div class="collapse-body">
          <div class="row mt-2 mb-0">
            <div class="col-sm-6 col-md-4 col-lg-4 pb-2">
              <div class="row">
                <div class="col">
                  <label for="selectSocieta" class="title"
                    [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Società</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <select [ngClass]="{'text-disabled': !listaSocieta.length}" id="selectSocieta" name="societaId"
                    #societaInput="ngModel" [(ngModel)]="impositore.societaId"
                    (ngModelChange)="onChangeSocietaImpositore(societaInput)" [required]="true"
                    [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO || !isPresenteInDettaglioAndRendicontazione()">
                    <option [ngValue]="null" [selected]="true">Seleziona</option>
                    <option *ngFor="let item of listaSocieta" [ngValue]="item.id">{{item.nome}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
                    [ngClass]="{'text-muted': !isCampoInvalido(societaInput), 'text-invalid': isCampoInvalido(societaInput), 'text-disabled': !listaSocieta.length}">
                    {{setPlaceholder(societaInput, TipoCampoEnum.SELECT)}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-4 pb-2">
              <div class="row">
                <div class="col">
                  <label for="livelloTerritorialeId" class="title"
                    [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">
                    Livello Territoriale</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <select id="livelloTerritorialeId" name="livelloTerritorialeId" #livelloTerritorialeId="ngModel"
                    [required]="true" [(ngModel)]="impositore.livelloTerritorialeId"
                    [disabled]="!impositore.societaId || !isPresenteInDettaglioAndRendicontazione() || disabilitaCampi()"
                    (ngModelChange)="onChangeLivelloTerritorialeImpositore(societaInput, livelloTerritorialeId)">
                    <option [ngValue]="null" selected>Seleziona</option>
                    <option *ngFor="let item of listaLivelloTerritorialeImpositore" [ngValue]="item.id">{{item.nome}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
                <div class="col">
                  <p *ngIf="!isCampoInvalido(livelloTerritorialeId)">Seleziona livello territoriale</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-4 pb-2">
              <div class="row">
                <div class="col">
                  <label for="selectEnte"
                    [ngClass]="{'title': true, 'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Ente</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <select [ngClass]="{'input-disabled': !listaEnti.length}" id="selectEnte" name="enteId"
                    #enteInput="ngModel" [(ngModel)]="impositore.enteId"
                    [disabled]="!impositore.societaId || !impositore.livelloTerritorialeId || !isPresenteInDettaglioAndRendicontazione() || disabilitaCampi()">
                    <option [ngValue]="null" [selected]="true">Seleziona</option>
                    <option *ngFor="let item of listaEnti" [ngValue]="item.id">{{item.nome}}</option>
                  </select>
                </div>
              </div>
              <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
                <div class="col">
                  <p *ngIf="!isCampoInvalido(enteInput)">Seleziona Ente</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="collapse-header" id="idCollapseBeneficiario">
        <button data-toggle="collapse" data-target="#beneficiario" aria-expanded="false" aria-controls="beneficiario">
          Beneficiario
        </button>
      </div>

      <div id="beneficiario" class="collapse" role="tabpanel" aria-labelledby="idCollapseBeneficiario">
        <div class="collapse-body">
          <div class="row mt-2 mb-0">
            <div class="col-12 col-lg-6 col-xl-3 pb-2">
              <div class="row">
                <div class="col">
                  <label for="selectSocietaBenef" class="title"
                    [ngClass]="{'required': integrazione.livelloIntegrazioneId != LivelloIntegrazioneEnum.LV1}">Società</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <select [ngClass]="{'text-disabled': !listaSocieta.length}" id="selectSocietaBenef"
                    name="societaIdBenef" [required]="integrazione.livelloIntegrazioneId != LivelloIntegrazioneEnum.LV1"
                    #societaBenefInput="ngModel" [(ngModel)]="beneficiario.societaId"
                    (ngModelChange)="onChangeSocietaBeneficiario(societaBenefInput?.value)"
                    [disabled]="disabilitaCampi()">
                    <option [ngValue]="null" [selected]="true">Seleziona</option>
                    <option *ngFor="let item of listaSocieta" [ngValue]="item.id">{{item.nome}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
                    [ngClass]="{'text-muted': !isCampoInvalido(societaBenefInput), 'text-invalid': isCampoInvalido(societaBenefInput), 'text-disabled': !listaSocieta.length}">
                    {{setPlaceholder(societaBenefInput, TipoCampoEnum.SELECT)}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-3 pb-2">
              <div class="row">
                <div class="col">
                  <label for="livelloTerritorialeIdBenef" class="title"
                    [ngClass]="{'required': requiredPerSocietaBeneficiarioCampo()}">
                    Livello Territoriale</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <select id="livelloTerritorialeIdBenef" name="livelloTerritorialeIdBenef"
                    #livelloTerritorialeIdBenef="ngModel" [(ngModel)]="beneficiario.livelloTerritorialeId"
                    [disabled]="!beneficiario.societaId || disabilitaCampi()"
                    [required]="requiredPerSocietaBeneficiarioCampo()"
                    (ngModelChange)="onChangeLivelloTerritorialeBeneficiario(societaBenefInput, livelloTerritorialeIdBenef)">
                    <option [ngValue]="null" selected>Seleziona</option>
                    <option *ngFor="let item of listaLivelloTerritorialeBeneficiario" [ngValue]="item.id">{{item.nome}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
                <div class="col">
                  <p *ngIf="!isCampoInvalido(livelloTerritorialeIdBenef)">Seleziona livello territoriale</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-3 pb-2">
              <div class="row">
                <div class="col">
                  <label for="selectEnteBenef"
                    [ngClass]="{'title': true, 'required': requiredPerSocietaBeneficiarioCampo()}">Ente</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <select [ngClass]="{'input-disabled': !listaEntiBenef.length}" id="selectEnteBenef" name="enteIdBenef"
                    #enteBenefInput="ngModel" [(ngModel)]="beneficiario.enteId"
                    (ngModelChange)="onChangeEnte(enteBenefInput)" [required]="requiredPerSocietaBeneficiarioCampo()"
                    [disabled]="!beneficiario.societaId || !beneficiario.livelloTerritorialeId || disabilitaCampi()">
                    <option [ngValue]="null" [selected]="true">Seleziona</option>
                    <option *ngFor="let item of listaEntiBenef" [ngValue]="item.id">{{item.nome}}</option>
                  </select>
                </div>
              </div>
              <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
                <div class="col">
                  <p *ngIf="!isCampoInvalido(enteBenefInput)">Seleziona Ente</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-3 pb-2">
              <div class="row">
                <div class="col">
                  <label for="selectBeneficiario"
                    [ngClass]="{'title': true, 'required': requiredPerSocietaBeneficiarioCampo()}">Beneficiario</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <select [ngClass]="{'input-disabled': !listaEntiBenef.length}" 
                    id="selectBeneficiario"
                    name="selectBeneficiario" 
                    #beneficiarioInput="ngModel" 
                    [(ngModel)]="beneficiario.id"
                    (ngModelChange)="onChangeBeneficiario(beneficiarioInput)" [required]="requiredPerSocietaBeneficiarioCampo()"
                    [disabled]="!beneficiario.societaId || !beneficiario.livelloTerritorialeId || !beneficiario.enteId || disabilitaCampi()">
                    <option [ngValue]="null" [selected]="true">Seleziona</option>
                    <option *ngFor="let item of listaUfficio" [ngValue]="item.id">{{item.nome}}</option>
                  </select>
                </div>
              </div>
              <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
                <div class="col">
                  <p *ngIf="!isCampoInvalido(beneficiarioInput)">Seleziona Beneficiario</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div [attr.disabled]="beneficiario.enteId" class="collapse-header ml-3" id="idCollapseDatiBonifico">
          <button data-toggle="collapse" data-target="#datiBonifico" aria-expanded="true" aria-controls="datiBonifico">
            Conti Correnti
          </button>
        </div>
        <div id="datiBonifico" class="collapse show" role="tabpanel" aria-labelledby="idCollapseDatiBonifico"> -->

          <div class="row">
            <div #datiContoCorrente></div>
          </div>
          
        <!-- </div> -->
      </div>

      <div class="collapse-header" id="idCollapseCampiServizio">
        <button data-toggle="collapse" data-target="#campiServizio" aria-expanded="false" aria-controls="campiServizio">
          Campi del Servizio
        </button>
      </div>
      <div id="campiServizio" class="collapse" role="tabpanel" aria-labelledby="idCollapseCampiServizio">
        <ng-container
          *ngIf="integrazione.livelloIntegrazioneId && integrazione.livelloIntegrazioneId !== LivelloIntegrazioneEnum.LV1">
          <div class="collapse-body">
            <div class="row">
              <div class="col-12">
                <label class="title">Lista campi tipologia servizio</label>
                <span class="pointer bottone-campo"
                  *ngIf="funzione == FunzioneGestioneEnum.AGGIUNGI || funzione == FunzioneGestioneEnum.MODIFICA">
                  <span ngbTooltip="Crea nuovo campo" placement="bottom" [ngStyle]="{fill: '#403d39'}">
                    <svg (click)="add()" class="bg-light icon icon-sm filled">
                      <use xlink:href="assets/img/sprite.svg#it-plus"></use>
                    </svg>
                  </span>
                </span>
              </div>
            </div>
            <div class="row" *ngIf="configuraServizioService.campoTipologiaServizioList">
              <ul class="legend">
                <li><span class="field-input"></span> Campo nuovo</li>
                <li><span class="edited"></span> Campo modificato</li>
                <li><span class="required"></span> Campo obbligatorio</li>
                <li><span class="key"></span> Campo chiave</li>
              </ul>
            </div>
            <div class="row" cdkDropListGroup *ngIf="configuraServizioService.campoTipologiaServizioList">
              <ng-container *ngFor="let item of configuraServizioService.campoTipologiaServizioList;let i=index">
                <div [ngClass]="calcolaDimensioneCampo(item)" cdkDropList cdkDropListOrientation="horizontal"
                  [cdkDropListData]="{item:item, index:i}">
                  <div style="min-height: 5vh;" [ngClass]="{'example-box2':true, 'col-12': true, 'disabled': !item.modificato,
                 'field-input': item.modificato}">
                    <div (click)="showModal(item)" class="example-custom-placeholder" *cdkDragPlaceholder></div>
                    <div class="row" style="width: 100%">
                      <div (click)="showModalAtClick(item)" *ngIf="showEditId !== i" (dblclick)="dblClick(item, i)"
                        [ngClass]="{'text-right':true, 'col-7': !item.modificato, 'col-6': item.modificato}">
                        {{item.titolo}}</div>
                      <div *ngIf="showEditId == i" class="col-6 text-right">
                        <input autofocus #edit="ngModel" name="edit" (keyup.enter)="applyEdit(item)"
                          [(ngModel)]="item.titolo" />
                      </div>
                      <div (click)="showModal(item)" class="col-1"><span
                          [class]="{'required':item.obbligatorio}"></span>
                      </div>
                      <div (click)="showModal(item)" class="col-1"><span [class]="{'key':item.chiave}"></span></div>
                      <div [ngClass]="{'text-right':true, 'col-3': !item.modificato, 'col-4': item.modificato}">
                        <span [ngClass]="{'field-input': item.modificato}" it-tooltip="Ripristina al valore iniziale"
                          placement="top" *ngIf="item.modificato && funzione != FunzioneGestioneEnum.DETTAGLIO"
                          (click)="undo(item)">
                          <svg class="bg-light icon icon-lg icon-padded close" (click)="undo(item)"
                            [ngClass]="{'field-input': item.modificato}">
                            <use xlink:href="assets/img/sprite.svg#it-restore"></use>
                          </svg>
                        </span>
                        <span [ngClass]="{'field-input': item.modificato}" *ngIf="item.modificato">
                          <svg class="bg-light icon icon-lg icon-padded" (click)="undo(item)"
                            [ngClass]="{'field-input': item.modificato}">
                            <use xlink:href="assets/img/sprite.svg#it-check"></use>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngFor="let item2 of configuraServizioService.campoServizioAddList;let i=index">
                <div [ngClass]="calcolaDimensioneCampo(item2)" cdkDropList cdkDropListOrientation="horizontal"
                  [cdkDropListData]="{item:item2, index:i}" (cdkDropListDropped)="drop($event)">
                  <div style="min-height: 5vh;" [ngClass]="{'cdk-drag':true, 'example-box':true, 'col-12': true, 'disabled': item2.disabilitato,
                 'field-input': true}"
                    [cdkDragDisabled]="!item2.draggable || this.funzione === FunzioneGestioneEnum.DETTAGLIO" cdkDrag>
                    <div (click)="showModal(item2)" class="example-custom-placeholder" *cdkDragPlaceholder></div>
                    <div class="row" style="width: 100%">
                      <div (click)="showModalAtClick(item2)"
                        *ngIf="showEditId !== i + configuraServizioService.campoTipologiaServizioList.length"
                        (dblclick)="dblClick(item2, (i + configuraServizioService.campoTipologiaServizioList.length))"
                        class="col-7 text-right">{{item2.titolo}}</div>
                      <div *ngIf="showEditId == i + configuraServizioService.campoTipologiaServizioList.length"
                        class="col-6 text-right">
                        <input autofocus #edit="ngModel" name="edit" (keyup.enter)="applyEdit(item2)"
                          [(ngModel)]="item2.titolo" />
                      </div>
                      <div (click)="showModal(item2)" class="col-1"><span
                          [class]="{'required':item2.obbligatorio}"></span>
                      </div>
                      <div (click)="showModal(item2)" class="col-1"><span [class]="{'key':item2.chiave}"></span></div>
                      <div class="col-3 text-right">
                        <span *ngIf="funzione != FunzioneGestioneEnum.DETTAGLIO" class="close"
                          (click)="removeItem(item2)"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- Flussi e Notifiche -->
      <div class="collapse-header" id="idCollapseFlussi">
        <button data-toggle="collapse" data-target="#flussi" aria-expanded="false" aria-controls="flussi">
          Flussi e notifiche
        </button>
      </div>
      <div class="ml-3">
        <div id="flussi" class="collapse" role="tabpanel" aria-labelledby="idCollapseFlussi">

          <!-- Invia notifiche di pagamento -->
          <div class="collapse-header" id="idCollapseNotifiche">
            <button data-toggle="collapse" data-target="#notifiche" aria-expanded="true" aria-controls="notifiche">
              Invio notifiche di pagamento
            </button>
          </div>
          <div id="notifiche" class="collapse show" role="tabpanel" aria-labelledby="idCollapseNotifiche">
            <div class="collapse-body">
              <div class="row mt-2 mb-0">
                <div class="col-sm-6 col-md-4 col-lg-3 pb-2 pr-3 mr-3">
                  <div class="row">
                    <div class="col">
                      <label class="title">Email</label>
                      <span class="pointer bottone-campo"
                        *ngIf="funzione == FunzioneGestioneEnum.AGGIUNGI || funzione == FunzioneGestioneEnum.MODIFICA">
                        <span ngbTooltip="Aggiungi email" placement="bottom" [ngStyle]="{fill: '#403d39'}">
                          <svg (click)="addEmail()" class="bg-light icon icon-sm filled">
                            <use xlink:href="assets/img/sprite.svg#it-plus"></use>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                  <ng-container *ngFor="let item of emailsControl; let i = index">
                    <div class="row">
                      <div class="col-9">
                        <input [formControl]="item" type="text" autocomplete="off" [disabled]="disabilitaCampi()"
                          [customvalidator]="validateEmail()">
                      </div>
                      <div class="col-3 align-self-center">
                        <span *ngIf="!disabilitaCampi()" class="pointer bottone-campo">
                          <span ngbTooltip="rimuovi" placement="bottom" [ngStyle]="{fill: '#403d39'}">
                            <svg (click)="removeEmail(i)" class="bg-light icon icon-sm filled">
                              <use xlink:href="assets/img/sprite.svg#it-close"></use>
                            </svg>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(item),
                           'text-invalid': isCampoInvalido(item)}">
                          {{setPlaceholder(item, TipoCampoEnum.INPUT_TESTUALE)}}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <!-- Rendicontazione giornaliera -->
          <div class="collapse-header" id="idCollapseGiornaliera">
            <button data-toggle="collapse" data-target="#giornaliera" aria-expanded="true" aria-controls="giornaliera">
              Rendicontazione giornaliera
            </button>
          </div>
          <div id="giornaliera" class="collapse show" role="tabpanel" aria-labelledby="idCollapseGiornaliera">
            <div class="collapse-body">

              <div class="row mt-2 mb-0">
                <div class="col-sm-6 col-md-4 col-lg-3 pb-2 pr-3 mr-3">
                  <div class="row">
                    <div class="col">
                      <label for="tipoFlussoRendicontazione" class="title">Tipo flusso rendicontazione</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <select 
                        [(ngModel)]="tipoFlussoId" 
                        id="tipoFlusso" 
                        name="tipoFlusso"
                        [disabled]="funzione == FunzioneGestioneEnum.DETTAGLIO"
                      >
                        <option [ngValue]="null"></option>
                        <option 
                          *ngFor="let item of listaFlussiRendicontazione" 
                          [ngValue]="item.id"
                        >{{item.codice}} - {{item.nome}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
                    
              <div class="row mt-2 mb-0">
                <div class="col-sm-6 col-md-4 col-lg-3 pb-2 pr-3 mr-3">
                  <div class="row">
                    <div class="col" style="height: 65px;">
                      <label for="emailGiornaliera" class="title">Email</label>
                    </div>
                    <div class="col-2">
                      <it-toggle id="emailGiornaliera" (ngModelChange)="changeEmailGiornaliera($event)"
                        [disabled]="disabilitaCampi()" [(ngModel)]="rendicontazioneGiornaliera.flagNotificaEmail"
                        name="emailGiornaliera"></it-toggle>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <input type="text" autocomplete="off"
                        [placeholder]="getPlaceholderRequired('Email', rendicontazioneGiornaliera.flagNotificaEmail)"
                        #emailGiornalieraId="ngModel" [required]="rendicontazioneGiornaliera.flagNotificaEmail"
                        id="emailGiornalieraId" [customvalidator]="validateEmails()" name="emailGiornalieraId"
                        [(ngModel)]="rendicontazioneGiornaliera.email"
                        [disabled]="!rendicontazioneGiornaliera.flagNotificaEmail || disabilitaCampi() ? true : null">
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(emailGiornalieraId),
                           'text-invalid': isCampoInvalido(emailGiornalieraId)}">
                            {{setPlaceholder(emailGiornalieraId, TipoCampoEnum.INPUT_TESTUALE_EMAILS)}}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <input type="text" autocomplete="off" placeholder="Ccn" #emailGiornalieraCcn="ngModel"
                        id="emailGiornalieraCcn" [customvalidator]="validateEmails()" name="emailGiornalieraCcn"
                        [(ngModel)]="rendicontazioneGiornaliera.ccn"
                        [disabled]="!rendicontazioneGiornaliera.flagNotificaEmail || disabilitaCampi() ? true : null">
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(emailGiornalieraCcn),
                           'text-invalid': isCampoInvalido(emailGiornalieraCcn)}">
                            {{setPlaceholder(emailGiornalieraCcn, TipoCampoEnum.INPUT_TESTUALE_EMAILS)}}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <input type="number" autocomplete="off" placeholder="Max kb email attachment "
                        #maxKbEmailAttachment="ngModel" id="maxKbEmailAttachment" name="maxKbEmailAttachment"
                        [(ngModel)]="rendicontazioneGiornaliera.maxKbEmailAttachment"
                        [disabled]="!rendicontazioneGiornaliera.flagNotificaEmail || disabilitaCampi() ? true : null">
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(maxKbEmailAttachment),
                          'text-invalid': isCampoInvalido(maxKbEmailAttachment)}">
                            {{setPlaceholder(maxKbEmailAttachment, TipoCampoEnum.INPUT_TESTUALE)}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3 pb-2 pr-3 mr-3">
                  <div class="row">
                    <div class="col" style="height: 65px;">
                      <label for="ftpGiornaliera"
                        [ngClass]="{'required': rendicontazioneGiornaliera.flagNotificaFtp, 'title':true}">Ftp</label>
                    </div>
                    <div class="col-2">
                      <it-toggle id="ftpGiornaliera" (ngModelChange)="changeFtpGiornaliera($event)"
                        [disabled]="disabilitaCampi()" [(ngModel)]="rendicontazioneGiornaliera.flagNotificaFtp"
                        name="ftpGiornaliera"></it-toggle>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <input type="text" autocomplete="off" placeholder="Server"
                        [placeholder]="getPlaceholderRequired('Server (nome o IP)', rendicontazioneGiornaliera.flagNotificaFtp)"
                        id="server" name="server" #server="ngModel" [customvalidator]="validateServer()"
                        [required]="rendicontazioneGiornaliera.flagNotificaFtp"
                        [(ngModel)]="rendicontazioneGiornaliera.server"
                        [disabled]="!rendicontazioneGiornaliera.flagNotificaFtp || disabilitaCampi() ? true : null">
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(server),
                           'text-invalid': isCampoInvalido(server)}">
                            {{setPlaceholder(server, TipoCampoEnum.INPUT_TESTUALE)}}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <input type="text" autocomplete="off" placeholder="Username"
                        [placeholder]="getPlaceholderRequired('Username', rendicontazioneGiornaliera.flagNotificaFtp)"
                        id="username" name="username" #username="ngModel"
                        [required]="rendicontazioneGiornaliera.flagNotificaFtp"
                        [(ngModel)]="rendicontazioneGiornaliera.username"
                        [disabled]="!rendicontazioneGiornaliera.flagNotificaFtp || disabilitaCampi() ? true : null">
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(username),
                           'text-invalid': isCampoInvalido(username)}">
                            {{setPlaceholder(username, TipoCampoEnum.INPUT_TESTUALE)}}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <input type="text" autocomplete="off"
                        [placeholder]="getPlaceholderRequired('Password', rendicontazioneGiornaliera.flagNotificaFtp)"
                        id="password" name="password" #password="ngModel"
                        [required]="rendicontazioneGiornaliera.flagNotificaFtp"
                        [(ngModel)]="rendicontazioneGiornaliera.password"
                        [disabled]="!rendicontazioneGiornaliera.flagNotificaFtp || disabilitaCampi() ? true : null">
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(password),
                           'text-invalid': isCampoInvalido(password)}">
                            {{setPlaceholder(password, TipoCampoEnum.INPUT_TESTUALE)}}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <input type="text" autocomplete="off"
                        [placeholder]="getPlaceholderRequired('Directory', rendicontazioneGiornaliera.flagNotificaFtp)"
                        id="directory" name="directory" #directory="ngModel"
                        [required]="rendicontazioneGiornaliera.flagNotificaFtp"
                        [(ngModel)]="rendicontazioneGiornaliera.directory"
                        [disabled]="!rendicontazioneGiornaliera.flagNotificaFtp || disabilitaCampi() ? true : null">
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(directory),
                           'text-invalid': isCampoInvalido(directory)}">
                            {{setPlaceholder(directory, TipoCampoEnum.INPUT_TESTUALE)}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label for="nuovoFormato" [ngClass]="{'title':true}">Nuovo formato</label>
                    </div>
                    <div class="col-2">
                      <it-toggle id="nuovoFormato" [(ngModel)]="rendicontazioneGiornaliera.nuovoFormato"
                        [disabled]="disabilitaCampi()" name="nuovoFormato"></it-toggle>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3 pb-2 pr-3 mr-3">
                  <div class="row">
                    <div class="col" style="height: 65px;">
                      <label class="title">API</label>
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-12">
                      <input type="text" autocomplete="off"
                        [placeholder]="getPlaceholderRequired('Chiave API Rendicontazione Giornaliera', false)"
                        #ApiKeyId="ngModel" id="apiKeyId" [size]="32" [maxLength]="32" name="apiKeyId"
                        [(ngModel)]="rendicontazioneGiornaliera.apiKey" [disabled]="disabilitaCampi() ? true : null">
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
                            Inserisci chiave API Rendicontazione Giornaliera
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <input type="text" autocomplete="off"
                        [placeholder]="getPlaceholderRequired('Secret API Rendicontazione Giornaliera', false)"
                        #ApiSecretId="ngModel" id="apiSecretId" [size]="32" [maxLength]="32" name="apiSecretId"
                        [(ngModel)]="rendicontazioneGiornaliera.apiSecret" [disabled]="disabilitaCampi() ? true : null">
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
                            Inserisci secret API Rendicontazione Giornaliera
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Quadratura -->
          <div class="collapse-header" id="idCollapseQuadratura">
            <button data-toggle="collapse" data-target="#quadratura" aria-expanded="true" aria-controls="notifiche">
              Quadratura
            </button>
          </div>
          <div id="quadratura" class="collapse show" role="tabpanel" aria-labelledby="idCollapseQuadratura">
            <div class="collapse-body">
              <div class="row mt-2 mb-0">
                <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4 pb-2 pr-3 mr-3">
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col" style="height: 65px;">
                          <label class="title">API</label>
                        </div>
                      </div>
                      <div class="row">

                        <div class="col-12">
                          <input type="text" autocomplete="off"
                            [placeholder]="getPlaceholderRequired('Chiave API Quadratura', false)" #ApiQuadKeyId="ngModel"
                            id="apiQuadKeyId" [size]="32" [maxLength]="32" name="apiQuadKeyId"
                            [(ngModel)]="quadraturaServizio.apiKey"
                            [disabled]="disabilitaCampi() ? true : null">
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col">
                              <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
                                Inserisci chiave API Quadratura
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <input type="text" autocomplete="off"
                            [placeholder]="getPlaceholderRequired('Secret API Quadratura', false)" #ApiQuadSecretId="ngModel"
                             id="apiQuadSecretId" [size]="32" [maxLength]="32" name="apiQuadSecretId"
                            [(ngModel)]="quadraturaServizio.apiSecret"
                            [disabled]="disabilitaCampi() ? true : null">
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col">
                              <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
                                Inserisci secret API Quadratura
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div class="row">
          <!-- Bottone sx -->
          <div
            [ngClass]="{'col-2': funzione !== FunzioneGestioneEnum.DETTAGLIO, 'col-12 text-center': funzione === FunzioneGestioneEnum.DETTAGLIO}">
            <button id="buttonAnnulla" class="btn btn-secondary" (click)="onClickAnnulla(funzione)">{{funzione ===
              FunzioneGestioneEnum.DETTAGLIO ? 'Indietro' : 'Annulla'}}</button>
          </div>
          <!-- Bottoni dx -->
          <div class="col-10 text-right" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
            <button id="buttonSalva" class="btn"
              [disabled]="(datiForm && datiForm.invalid) || (mapControllo && getListaControllo(mapControllo).includes(false))"
              (click)="onClickSalva()">Salva
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="row" *ngIf="!filtri">
    <!-- Bottone sx -->
    <div class="col-2">
      <button id="buttonIndietro" class="btn btn-secondary" (click)="onClickAnnulla(funzione)">{{'Indietro'}}</button>
    </div>
  </div>
</div>

<!-- Modale -->
<p-confirmDialog #cb icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" class="undoButton" pButton icon="pi pi-times" label="Annulla" (click)="cb.reject()"></button>
    <button type="button" class="okButton" pButton icon="pi pi-check" label="Ok" (click)="cb.accept()"></button>
  </p-footer>
</p-confirmDialog>