import { TipologiaServizioService } from "src/app/shared/common/services/tipologia-servizio.service";
import { ParametriRicercaEnte } from "./../../../../model/ente/ParametriRicercaEnte";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ParametriRicercaServizio } from "../../../../model/servizio/ParametriRicercaServizio";
import { DatePickerComponent, ECalendarValue } from "ng2-date-picker";
import { ConfiguraServizioService } from "../../../../../../services-old/configura-servizio.service";
import * as _ from "lodash";
import { FiltroGestioneElementiComponent } from "../../filtro-gestione-elementi.component";
import { AmministrativoService } from "../../../../../../services-old/amministrativo.service";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";
import { FiltroSelect } from "../../../../model/servizio/FiltroSelect";
import { LivelloIntegrazioneEnum } from "../../../../../../enums/livelloIntegrazione.enum";
import { ListaStatiSelect } from "../../../../../../modules/main/model/select/listaStatiSelect";
import { OpzioneSelect } from "../../../../../../modules/main/model/OpzioneSelect";
import { Societa } from "../../../../../../modules/main/model/Societa";
import { SocietaService } from "../../../../../../services-old/societa.service";
import { Observable } from "rxjs";
import {
  SelectOption,
  SimpleComparator,
  compareOn,
} from "src/app/shared/common/model/select.model";
import { TipologiaServizio } from "src/app/modules/main/model/tipologiaServizio/TipologiaServizio";
import { FlussoRendicontazione } from "src/app/modules/main/model/flussiRenditcontazione/FlussoRendicontazione";
import { FlussoRendicontazioneService } from "src/app/services-old/flusso-rendicontazione.service";
import { cons } from "fp-ts/lib/ReadonlyNonEmptyArray";

@Component({
  selector: "app-filtro-ricerca-servizio",
  templateUrl: "./filtro-ricerca-servizio.component.html",
  styleUrls: ["./filtro-ricerca-servizio.component.scss"],
})
export class FiltroRicercaServizioComponent
  extends FiltroGestioneElementiComponent
  implements OnInit
{

  isCalendarOpen = false;
  readonly minDateDDMMYY = "01/01/1900";
  readonly tipoData = ECalendarValue.String;
  readonly LivelloIntegrazioneEnum = LivelloIntegrazioneEnum;

  filtriRicerca: ParametriRicercaServizio = new ParametriRicercaServizio();
  opzioniRaggruppamento: FiltroSelect[] = [];

  @Output()
  onChangeFiltri: EventEmitter<ParametriRicercaServizio> =
    new EventEmitter<ParametriRicercaServizio>();

  listaStatoServizio: any = ListaStatiSelect;
  listaLivelloIntegrazione: FiltroSelect[] = [];
  listaEntiBeneficiario: FiltroSelect[] = [];
  listaEntiImpositore: FiltroSelect[] = [];
  listaServizi: FiltroSelect[] = [];
  opzioniFiltroSocieta: Array<OpzioneSelect> = new Array<OpzioneSelect>();

  opzioniFiltroTipologiaServizio$: Observable<SelectOption<TipologiaServizio>[]>;
  comparatorFiltroTipologiaServizio: SimpleComparator<TipologiaServizio> = compareOn<TipologiaServizio>("codice");

  listaFlussiRendicontazione: FiltroSelect[] = [];
  
  constructor(
    protected amministrativoService: AmministrativoService,
    protected route: ActivatedRoute,
    private societaService: SocietaService,
    private configuraServizioService: ConfiguraServizioService,
    private tipologiaServizioService: TipologiaServizioService,
    private flussoRendicontazioneService: FlussoRendicontazioneService
  ) {
    super(route, amministrativoService);
  }

  ngOnInit(): void {
    this.inizializzaOpzioniRaggruppamento();
    this.caricaLivelliIntegrazione();
    this.caricaCodiciTipologia(new ParametriRicercaEnte());
    this.caricaEnteImpositore();
    this.caricaEnteBeneficiario();
    this.caricaServizi();
    this.letturaSocieta();
    this.recuperaFlussiRendicontazione();
    let tempFiltriRicerca = JSON.parse(
      localStorage.getItem("filtroRicercaConfiguraServizi")
    );
    if (tempFiltriRicerca) {
      this.filtriRicerca = tempFiltriRicerca;
      this.onChangeFiltri.emit(this.filtriRicerca);
    }
  }

  inizializzaOpzioniRaggruppamento(): void {
    this.configuraServizioService
      .configuraServiziFiltroRaggruppamento(null, this.idFunzione)
      .subscribe((value) => {
        this.opzioniRaggruppamento = _.orderBy(value, [
          (s) => s.nome?.toLowerCase(),
        ]);
      });
  }

  caricaCodiciTipologia(parametriRicercaEnte: ParametriRicercaEnte): void {
    this.filtriRicerca.tipologiaServizio = null;
    // let observable;

    // observable = this.configuraServizioService.configuraServiziFiltroTipologia(
    //   parametriRicercaEnte,
    //   this.idFunzione
    // );
    // this.labelOggettoTipologia = "codice";

    this.opzioniFiltroTipologiaServizio$ =
      this.tipologiaServizioService.opzioni(
        parametriRicercaEnte,
        this.idFunzione
      );

    // observable.subscribe((listaTipologieServizio) => {
    //   if (listaTipologieServizio) {
    //     listaTipologieServizio = _.sortBy(listaTipologieServizio, [
    //       this.labelOggettoTipologia,
    //     ]);
    //     this.listaCodiciTipologia = listaTipologieServizio;
    //     this.listaCodiciTipologiaFiltrati = this.listaCodiciTipologia;

    //     if (this.filtriRicerca.tipologiaServizioId) {
    //       this.filtriRicerca.tipologiaServizio =
    //         this.listaCodiciTipologiaFiltrati.find(
    //           (value) => value.id === this.filtriRicerca.tipologiaServizioId
    //         );
    //     }
    //   }
    // });
  }

  // filtraCodiciTipologia(event): void {
  //   const input = event.query;
  //   this.listaCodiciTipologiaFiltrati = this.listaCodiciTipologia.filter(
  //     (value) =>
  //       value[this.labelOggettoTipologia]
  //         .toLowerCase()
  //         .startsWith(input.toLowerCase())
  //   );
  // }

  pulisciFiltri(form: NgForm): void {
    localStorage.setItem("filtroRicercaConfiguraServizi", null);
    form.reset();
    if (!this.filtriRicerca.attivo) this.filtriRicerca.attivo = null;
    this.onChangeFiltri.emit(null);
  }

  cercaElementi(): void {
    localStorage.setItem(
      "filtroRicercaConfiguraServizi",
      JSON.stringify(this.filtriRicerca)
    );
    this.onChangeFiltri.emit(this.filtriRicerca);
  }

  openDatepicker(datePickerComponent: DatePickerComponent): void {
    datePickerComponent.api.open();
    this.isCalendarOpen = !this.isCalendarOpen;
  }

  private caricaEnteBeneficiario() {
    this.configuraServizioService
      .configuraServiziFiltroEnteBeneficiario(null, this.idFunzione)
      .pipe(
        map(
          (value) =>
            (this.listaEntiBeneficiario = _.orderBy(value, [
              (s) => s.nome.toLowerCase(),
            ]))
        )
      )
      .subscribe();
  }

  private caricaEnteImpositore() {
    this.configuraServizioService
      .configuraServiziFiltroEnteImpositore(null, this.idFunzione)
      .pipe(
        map(
          (value) =>
            (this.listaEntiImpositore = _.orderBy(value, [
              (s) => s.nome.toLowerCase(),
            ]))
        )
      )
      .subscribe();
  }

  private caricaServizi() {
    this.listaServizi = [];
    this.configuraServizioService
      .filtroServizio(null, this.idFunzione)
      .pipe(
        map((value) => {
          this.listaServizi = _.orderBy(value, [(s) => s.nome.toLowerCase()]);
        })
      )
      .subscribe();
  }

  private caricaLivelliIntegrazione() {
    this.configuraServizioService
      .filtroLivelliIntegrazione(null, this.idFunzione)
      .pipe(map((value) => {
        // console.log("caricaLivelliIntegrazione : value = ", JSON.stringify(value));
        this.listaLivelloIntegrazione = value;
      }
      ))
      .subscribe();
  }

  letturaSocieta(): void {
    let filtro = { visibile: true };
    this.societaService
      .ricercaSocieta(filtro, this.idFunzione)
      .subscribe((societa) => {
        this.popolaOpzioniFiltroSocieta(societa);
      });
  }

  private popolaOpzioniFiltroSocieta(societa: Societa[]) {
    if (societa != null) {
      societa.forEach((s) => {
        this.opzioniFiltroSocieta.push({
          value: s.id,
          label: s.nome,
        });
      });
      this.opzioniFiltroSocieta = _.sortBy(this.opzioniFiltroSocieta, [
        "label",
      ]);
    }
    if (this.opzioniFiltroSocieta.length == 1)
      this.filtriRicerca.societaId = this.opzioniFiltroSocieta[0].value;
  }

  changeFilter() {
    const parametriRicercaEnte = new ParametriRicercaEnte();
    parametriRicercaEnte.societaId = this.filtriRicerca.societaId;
    parametriRicercaEnte.enteId = this.filtriRicerca.enteImpositoreId;
    parametriRicercaEnte.livelloIntegrazioneId =
      this.filtriRicerca.livelloIntegrazioneId;
    this.configuraServizioService
      .configuraServiziFiltroEnteImpositore(
        parametriRicercaEnte,
        this.idFunzione
      )
      .pipe(
        map(
          (value) =>
            (this.listaEntiImpositore = _.orderBy(value, [
              (s) => s.nome.toLowerCase(),
            ]))
        )
      )
      .subscribe();
    this.configuraServizioService
      .filtroLivelliIntegrazione(parametriRicercaEnte, this.idFunzione)
      .pipe(map((value) => (this.listaLivelloIntegrazione = value)))
      .subscribe();
    this.caricaCodiciTipologia(parametriRicercaEnte);
  }

  selezionaSocieta() {
    this.changeFilter();
    this.filtriRicerca.enteImpositoreId = null;
    this.filtriRicerca.livelloIntegrazioneId = null;
    this.filtriRicerca.tipologiaServizio = null;
  }

  selezionaEnteImpositore() {
    this.changeFilter();
    this.filtriRicerca.livelloIntegrazioneId = null;
    this.filtriRicerca.tipologiaServizio = null;
  }

  selezionaLivelloIntegrazione() {
    this.changeFilter();
    this.filtriRicerca.tipologiaServizio = null;
  }
  // changeFilter(e) {
  //   const parametriRicercaEnte = new ParametriRicercaEnte();
  //   parametriRicercaEnte.societaId = this.filtriRicerca.societaId;
  //   parametriRicercaEnte.enteId = this.filtriRicerca.enteImpositoreId;
  //   parametriRicercaEnte.livelloIntegrazioneId = this.filtriRicerca.livelloIntegrazioneId;
  //   this.configuraServizioService.configuraServiziFiltroEnteImpositore(parametriRicercaEnte, this.idFunzione)
  //     .pipe(map((value) => this.listaEntiImpositore = _.orderBy(value,  [s => s.nome.toLowerCase()]))).subscribe();
  //   this.configuraServizioService.filtroLivelliIntegrazione(parametriRicercaEnte, this.idFunzione)
  //     .pipe(map((value) => this.listaLivelloIntegrazione = value)).subscribe();
  //   this.caricaCodiciTipologia(parametriRicercaEnte);
  // }

  private recuperaFlussiRendicontazione() {
    this.flussoRendicontazioneService.recuperaFlussiRendicontazione(
      this.idFunzione
    ).pipe(map((listaFlussiRendicontazione) => {
        listaFlussiRendicontazione.forEach((s) => {
          this.listaFlussiRendicontazione.push({
          id: s.id,
          codice: s.nome,
          nome: s.descrizione
        });
      })
    })).subscribe();
  }

}
