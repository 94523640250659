import {CampoDettaglioTransazione} from '../bollettino/CampoDettaglioTransazione';
import {EsitoPendenza} from "./EsitoPendenza";

export class DettaglioPendenza {
  id: number;
  codiceFiscalePagatore: string;
  livelloIntegrazioneId: number;
  livelloIntegrazioneNome: string;
  emailVersante: string;
  dataScadenza: string;
  codiceTassonomia: string;
  enteImpositore: string;
  servizioNome: string;
  enteBeneficiario: string;
  numeroDocumento: string;
  annoDocumento: number;
  causale: string;
  importo: number;
  listaCampoDettaglioTransazioni: CampoDettaglioTransazione[];
  listaEsitiPendenza: EsitoPendenza[];
}
