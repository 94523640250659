<!-- Sottotitolo -->
<div class="collapse-header" id="heading1">
  <button data-toggle="collapse" data-target="#contenutoSezioneFiltri" aria-expanded="true"
    aria-controls="contenutoSezioneFiltri">
    Filtri
  </button>
</div>

<!-- Form filtri -->
<form #filtroForm="ngForm" (ngSubmit)="filtroForm">
  <div id="contenutoSezioneFiltri" class="collapse show" role="tabpanel" aria-labelledby="heading1">
    <div class="collapse-body">
      <!-- inizio Società, Livello Territoriale, Ente -->
      <div class="row mt-2 mb-0">
        <!--       inizio select società -->
        <div class="col-md-3 pb-2">
          <div class="row">
            <div class="col">
              <label for="selectSocieta" class="title">Società</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select id="selectSocieta" name="societaId" [disabled]="opzioniFiltroSocieta.length == 1"
                #societaInput="ngModel" [(ngModel)]="filtroRicercaEnte.societaId" (ngModelChange)="selezionaSocieta()"
                (change)="letturaEnte()">
                <option [ngValue]="null" selected>Tutti</option>
                <option *ngFor="let item of opzioniFiltroSocieta" [ngValue]="item.value">{{item.label}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': !isCampoInvalido(societaInput), 'text-invalid': isCampoInvalido(societaInput),
                 'text-disabled': !opzioniFiltroSocieta.length}">
                {{setPlaceholder(societaInput)}}
              </p>
            </div>
          </div>
        </div>
        <!--        fine select società -->
        <!--       inizio select livelli territoriali -->
        <div class="col-md-3 pb-2">
          <div class="row">
            <div class="col">
              <label for="selectLivelliTerritoriale" class="title">Livello Territoriale</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select id="selectLivelliTerritoriale" name="livelloTerritorialeId" #livelloTerritorialeInput="ngModel"
                [(ngModel)]="filtroRicercaEnte.livelloTerritorialeId" (change)="letturaEnte()">
                <option [ngValue]="null" selected>Tutti</option>
                <option *ngFor="let item of opzioniFiltroLivelliTerritoriale" [ngValue]="item.value">{{item.label}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': !isCampoInvalido(livelloTerritorialeInput),
                 'text-invalid': isCampoInvalido(livelloTerritorialeInput),
                 'text-disabled': !opzioniFiltroLivelliTerritoriale.length}">
                {{setPlaceholder(livelloTerritorialeInput)}}
              </p>
            </div>
          </div>
        </div>
        <!--        fine select territoriali -->
        <!--       inizio select ente -->
        <div class="col-md-3 pb-2">
          <div class="row">
            <div class="col">
              <label for="selectEnte" class="title">Ente</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select id="selectEnte" name="enteId" #enteInput="ngModel" [(ngModel)]="filtroRicercaEnte.enteId">
                <option [ngValue]="null" selected>Tutti</option>
                <option *ngFor="let item of opzioniFiltroEnte" [ngValue]="item.value">{{item.label}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': !isCampoInvalido(enteInput), 'text-invalid': isCampoInvalido(enteInput),
                 'text-disabled': !opzioniFiltroEnte.length}">
                {{setPlaceholder(enteInput)}}
              </p>
            </div>
          </div>
        </div>
        <!--        fine select ente -->
      </div>
      <!-- fine Società, Livello Territoriale, Ente -->

      <!-- inizio Comune, Provincia -->
      <div class="row mt-2 mb-0">
        <!--       inizio select province -->
        <div class="col-md-3 pb-2">
          <div class="row">
            <div class="col">
              <label for="selectProvincia" class="title">Provincia</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select id="selectProvincia" name="provincia" #provinciaInput="ngModel"
                [(ngModel)]="filtroRicercaEnte.provincia" (change)="onChangeProvince()">
                <option [ngValue]="null" selected>Tutti</option>
                <option *ngFor="let item of opzioniFiltroProvincia" [ngValue]="item.value">{{item.label}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': !isCampoInvalido(provinciaInput),
                 'text-invalid': isCampoInvalido(provinciaInput),
                 'text-disabled': !opzioniFiltroProvincia.length}">
                {{setPlaceholder(provinciaInput)}}
              </p>
            </div>
          </div>
        </div>
        <!--        fine select province -->
        <!--       inizio select comuni -->
        <div class="col-md-3 pb-2">
          <div class="row">
            <div class="col">
              <label for="selectComune" class="title">Comune</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select id="selectComune" name="comune" #comuneInput="ngModel" [(ngModel)]="filtroRicercaEnte.comune"
                (change)="letturaEnte()" [disabled]="isFiltroComuniDisabled()">
                <option *ngIf="isFiltroComuniDisabled()" [ngValue]="null" selected>Seleziona una provincia...</option>
                <ng-container *ngIf="!isFiltroComuniDisabled()">
                  <option [ngValue]="null" selected>Tutti</option>
                  <option *ngFor="let item of opzioniFiltroComune" [ngValue]="item.value">{{item.label}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': !isCampoInvalido(comuneInput),
                 'text-invalid': isCampoInvalido(comuneInput),
                 'text-disabled': !opzioniFiltroComune.length}">
                {{setPlaceholder(comuneInput)}}
              </p>
            </div>
          </div>
        </div>
        <!--        fine select comuni -->
      </div>
      <!-- fine Comune, Provincia -->

      <!-- inizio Pulisci e Cerca -->
      <div class="row align-content-around">
        <div class="col-6">
          <button id="bottonePulisci" class="btn btn-secondary" type="button" (click)="pulisciFiltri(filtroForm)"
            [disabled]="disabilitaBottone(filtroForm, 'Pulisci')">Pulisci
          </button>
        </div>
        <div class="col-6 text-right">
          <button id="bottoneCerca" class="btn" (click)="cercaElementi()">Cerca
          </button>
        </div>
      </div>
      <!-- fine Pulisci e Cerca -->
    </div>
  </div>
</form>
