export class PayerMessages {
    static readonly HINT_INPUT_TEXT = "Inserisci testo.";
    static readonly HINT_INPUT_NUMBER = "Inserisci un numero.";
    static readonly HINT_INPUT_DATE = "Inserisci una data.";
    static readonly HINT_INPUT_SELECT = "Seleziona un elemento dalla lista.";
    static readonly HINT_INPUT_EMAIL = "Inserisci una email valida.";
    static readonly ERROR_REQUIRED = "Il campo è obbligatorio.";
    static readonly ERROR_INVALID = "Il campo non è valido.";
    static readonly ERROR_PATTERN = "Il valore inserito non è formattato correttamente."
  }
  
  export class PayerIcons {
    static bootstrapItalia(selector: string): string {
      return `assets/icons/bootstrap-italia-icons.svg#${selector}`;
    }
  }
  