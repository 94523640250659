import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { tipoColonna } from '../../../../../../enums/TipoColonna.enum';
import { TipoQuadraturaEnum } from '../../../../../../enums/TipoQuadraturaEnum';
import { tipoTabella } from '../../../../../../enums/TipoTabella.enum';
import { ToolEnum } from '../../../../../../enums/Tool.enum';
import { LivelloBanner, getBannerType } from '../../../../../../enums/livelloBanner.enum';
import { AmministrativoService } from '../../../../../../services-old/amministrativo.service';
import { BannerService } from '../../../../../../services-old/banner.service';
import { MenuService } from '../../../../../../services-old/menu.service';
import { QuadraturaService } from '../../../../../../services-old/quadratura.service';
import { SpinnerOverlayService } from '../../../../../../services-old/spinner-overlay.service';
import { Utils } from '../../../../../../utility/Utils';
import { ExcelBuilder, ExcelSheet } from '../../../../../../utility/stampa/ExcelBuilder';
import { PdfBuilder, PdfTable } from '../../../../../../utility/stampa/PdfBuilder';
import { StampaDettagliExcel } from '../../../../../../utility/stampa/StampaDettagliExcel';
import { StampaDettagliPdf } from '../../../../../../utility/stampa/StampaDettagliPdf';
import { Banner } from '../../../../model/banner/Banner';
import { ParametriRicercaQuadratura } from '../../../../model/quadratura/ParametriRicercaQuadratura';
import { Quadratura } from '../../../../model/quadratura/Quadratura';
import { Colonna } from '../../../../model/tabella/Colonna';
import { ImmaginePdf } from '../../../../model/tabella/ImmaginePdf';
import { Tabella } from '../../../../model/tabella/Tabella';
import { GestisciElementoComponent } from '../../gestisci-elemento.component';
import { ConteggiQuadrature, QueryQuadratura } from './../../../../model/quadratura/Quadratura';

@Component({
  selector: 'app-quadratura',
  templateUrl: './quadratura.component.html',
  styleUrls: ['./quadratura.component.scss']
})
export class QuadraturaComponent extends GestisciElementoComponent implements OnInit, AfterViewInit {

  readonly tooltipTitolo = 'In questa pagina puoi consultare la lista completa delle quadrature a cui sei abilitato e filtrarle';
  breadcrumbList = [];

  isMenuCarico = false;
  /**
   * Flag per la richiesta aggiornamento tabella.
   * Se `true` la tabella accetta di fare richiesta al DB al prossimo segnale di cambiamento.
   */
  aggiornaTabella: boolean = false
  counts: ConteggiQuadrature

  listaElementi: Quadratura[] = [];
  listaFlussiQuadrati: Quadratura[] = [];
  listaFlussiNonQuadrati: Quadratura[] = [];

  filtriRicerca: ParametriRicercaQuadratura = new ParametriRicercaQuadratura();
  righeSelezionate: any[] = [];
  tableData: Tabella = {
    rows: [],
    cols: [
      /*{field: 'societa', header: 'Società', type: tipoColonna.LINK},*/
      { field: 'ente', header: 'Ente', type: tipoColonna.LINK },
      { field: 'flussoId', header: 'Id flusso', type: tipoColonna.TESTO },
      { field: 'iban', header: 'IBAN', type: tipoColonna.TESTO },
      { field: 'psp', header: 'PSP', type: tipoColonna.TESTO },
      { field: 'dataQuadratura', header: 'Data quadratura', type: tipoColonna.TESTO },
      { field: 'importo', header: 'Importo flusso(€)', type: tipoColonna.IMPORTO },
      { field: 'iuvTotali', header: 'Numero IUV', type: tipoColonna.TESTO },
      { field: 'iuvScartati', header: 'Numero IUV scartati', type: tipoColonna.TESTO }
    ],
    dataKey: 'id.value',
    tipoTabella: tipoTabella.CHECKBOX_SELECTION
  };

  tabs = [
    { value: TipoQuadraturaEnum.TUTTI },
    { value: TipoQuadraturaEnum.QUADRATI },
    { value: TipoQuadraturaEnum.NON_QUADRATI }
  ];

  nomeTabCorrente: TipoQuadraturaEnum = TipoQuadraturaEnum.TUTTI;
  readonly formatoData = Utils.FORMAT_DATE_CALENDAR;
  firstCall = true
  readonly toolbarIcons = [
    { type: ToolEnum.EXPORT_PDF, tooltip: 'Stampa Pdf Flussi' },
    { type: ToolEnum.EXPORT_XLS, tooltip: 'Download XLSX Flussi' },
    { type: ToolEnum.EXPORT_FLUSSO, tooltip: 'Download txt Flussi' },
    { type: ToolEnum.EXPORT_PDF_DETAILS, tooltip: 'Stampa Pdf Dettaglio' },
    { type: ToolEnum.EXPORT_XLS_DETAILS, tooltip: 'Download XLSX Dettaglio' }
  ];
  indiceIconaScaricaFlussi = 2;
  indiceIconaStampaPdfDettaglio = 3;
  indiceIconaDownloadDettaglio = 4;


  constructor(router: Router,
    route: ActivatedRoute, protected http: HttpClient,
    amministrativoService: AmministrativoService,
    private renderer: Renderer2, private el: ElementRef,
    private menuService: MenuService,
    private quadraturaService: QuadraturaService,
    private spinnerOverlayService: SpinnerOverlayService,
    private bannerService: BannerService
  ) {
    super(router, route, http, amministrativoService);
  }

  // Funzioni init

  ngOnInit(): void {
    let tempFiltriRicerca = JSON.parse(localStorage.getItem('filtroRicercaQuadratura'));
    if (tempFiltriRicerca) {
      this.filtriRicerca = tempFiltriRicerca;
    } else {
      this.filtriRicerca = new ParametriRicercaQuadratura();
      this.filtriRicerca.dataQuadraturaDa = moment(String(moment().subtract(1, 'day').format(this.formatoData)), Utils.FORMAT_DATE_CALENDAR).format(Utils.FORMAT_LOCAL_DATE_TIME);
    }


    this.waitingEmitter.subscribe(() => {

      if (this.amministrativoService.mappaFunzioni) {
        this.isMenuCarico = Object.keys(this.amministrativoService.mappaFunzioni).length > 0;
      }

      if (this.isMenuCarico) {
        this.init();
      } else {
        this.menuService.menuCaricatoEvent.subscribe(() => {
          this.init();
        });
      }
    });
  }

  init() {
    this.breadcrumbList = this.inizializzaBreadcrumbList([
      { label: 'Quadratura', link: null }
    ], true);
    this.waiting = false
  }

  ngAfterViewInit(): void {
    if (!this.waiting) {
      this.renderer.addClass(this.el.nativeElement.querySelector('#breadcrumb-item-1 > li'), 'active');
    }
  }

  // Implementazioni funzioni astratte

  creaRigaTabella(elemento: Quadratura) {
    const iconaGruppoUtenti = 'assets/img/users-solid.svg#users-group';
    //const linkGestisciSocieta = '/gestisciSocieta?societaId=' + elemento.idSocieta;
    const linkGestisciEnti = '/gestisciEnti?enteId=' + elemento.idEnte;

    return {
      id: { value: elemento.id },
      //societa: Utils.creaLink(elemento.nomeSocieta, linkGestisciSocieta, iconaGruppoUtenti),
      ente: Utils.creaLink(elemento.nomeEnte, linkGestisciEnti, iconaGruppoUtenti),
      flussoId: { value: elemento.flussoId },
      iban: { value: elemento.iban },
      psp: { value: elemento.psp },
      dataQuadratura: { value: elemento.dataQuadratura ? moment(elemento.dataQuadratura).format(Utils.FORMAT_DATE_TIME_CALENDAR) : null },
      importo: { value: elemento.importoFlusso },
      iuvTotali: { value: elemento.iuvTotali },
      iuvScartati: { value: elemento.iuvScartati }
    };
  }

  eseguiAzioni(azioneTool: ToolEnum): void {
    switch (azioneTool) {
      case ToolEnum.EXPORT_PDF:
        this.stampaFlussiPdf("Lista Flussi di Quadratura")
        break;
      case ToolEnum.EXPORT_XLS:
        this.stampaFlussiExcel("Lista Flussi di Quadratura")
        break;
      case ToolEnum.EXPORT_FLUSSO:
        this.esportaFlussiInTxtFile(this.getListaIdElementiSelezionati());
        break;
      case ToolEnum.EXPORT_PDF_DETAILS:
        this.stampaDettagliPdf("Lista Dettagli Transazione")
        // this.esportaTabellaDettagliInFilePdf(this.getListaIdElementiSelezionati(), 'Lista Dettagli Transazione');
        break;
      case ToolEnum.EXPORT_XLS_DETAILS:
        this.stampaDettagliExcel("Lista Dettagli Transazione");
        break;
    }
  }

  callbackPopolaLista() {
    this.onChangeTab(this.nomeTabCorrente);
    this.listaFlussiQuadrati = this.listaElementi.filter(quadratura => this.isQuadrato(quadratura));
    this.listaFlussiNonQuadrati = this.listaElementi.filter(quadratura => !this.isQuadrato(quadratura));
  }

  getObservableFunzioneRicerca(): Observable<QueryQuadratura> {
    return this.quadraturaService.recuperaQuadrature(this.filtriRicerca, this.idFunzione);
  }

  selezionaRigaTabella(righeSelezionate: any[]): void {
    this.righeSelezionate = righeSelezionate;
  }

  getNumeroRecord(): string {
    if (this.counts == undefined)
      return ""
    else
      return 'Totale ' + this.counts?.quadratureTotali + ' flussi di cui ' +
        this.counts?.quadratureQuadrate + ' quadrati e ' + this.counts?.quadratureNonQuadrate + ' non quadrati';
  }

  // Funzioni per tabella lazy

  /**
   * Funzione riscritta per il routing ai dettagli.
   * Immagino per via del lazi load?
   * @param rigaTabella
   */
  mostraDettaglioQuadratura(rigaTabella) {
    this.righeSelezionate = []
    this.mostraDettaglioElemento('/dettaglioQuadratura', rigaTabella.id.value);
  }

  /**
   * Callback per il recupero dei conteggi del risultato della query.
   *
   * @param counts
   */
  getCounts(counts) {
    this.counts = counts
  }

  /**
   * Callback per il cambiamento dei filtri.
   *
   * @param filtri
   */
  onChangeFiltriPaginazioneBE(filtri: ParametriRicercaQuadratura): void {
    this.filtriRicerca = filtri;
    this.aggiornaTabella = !this.aggiornaTabella
  }

  /**
   * Funzione chiamata da `OnLazyLoad` della tabella di PrimeNG.
   * Rapprensenta la logica di richiesta al DB della singola pagina.
   * Da implementare per ogni tabella che usa il lazy load.
   *
   * @param pagination
   * @returns
   */
  popolaListaElementiPaginata = async (pagination): Promise<Observable<QueryQuadratura>> => {
    let pageSize = pagination.rows ? pagination.rows : this.getElementsTab();
    this.filtriRicerca.numeroPagina = pagination.first ? String(pagination.first / pageSize) : "0"
    this.filtriRicerca.numeroElementi = pageSize
    if (this.firstCall == true) {
      this.firstCall = false
      this.filtriRicerca.sort = "dataQuadratura"
      this.filtriRicerca.ascending = "false"
    } else {
      pagination.sortField ? this.filtriRicerca.sort = pagination.sortField : ""
      pagination.ascending ? this.filtriRicerca.ascending = String(pagination.ascending == 1) : ""
    }
    pagination.pagination != undefined ? this.filtriRicerca.pagination = pagination.pagination : true
    this.filtriRicerca.tab = Utils.getKeyByValue(TipoQuadraturaEnum, this.nomeTabCorrente)
    return this.getObservableFunzioneRicerca()
  }

  /**
   * Funzione di supporto per `popolaListaElementiBE`.
   * Può impostare `numeroElementi` all'interno del filtro.
   * @returns
   */
  private getElementsTab() {
    let elementsTab = 0;
    switch (this.nomeTabCorrente) {
      case TipoQuadraturaEnum.TUTTI:
        elementsTab = this.counts?.quadratureTotali ?? 0;
        break;
      case TipoQuadraturaEnum.NON_QUADRATI:
        elementsTab = this.counts?.quadratureNonQuadrate ?? 0;
        break;
      case TipoQuadraturaEnum.QUADRATI:
        elementsTab = this.counts?.quadratureQuadrate ?? 0;
        break;
      default:
        elementsTab = 0;
        break;
    }
    return elementsTab;
  }

  /**
   * Setta `nomeTabCorrente` dopo che l'utente ha cambiato Tab dalla UI.
   * @param value
   */
  onChangeTab(value: TipoQuadraturaEnum) {
    this.nomeTabCorrente = value;
    this.aggiornaTabella = !this.aggiornaTabella
  }


  // Utils

  isQuadrato(quadratura: Quadratura): boolean {
    return quadratura.quadrata === 0;
  }

  // Funzioni di stampa flussi PDF

  // async getTableDataPdflDownload() {
  //   let table = await this.getTabellaStampa()
  //   this.esportaTabellaInFilePdf(table, 'Lista Flussi di Quadratura')
  // }

  getColonneFilePdf(colonne: Colonna[]): Colonna[] {
    colonne[6].header = colonne[6].header.replace('(€)', '');
    return colonne;
  }

  getRigheFilePdf(righe: any[]): any[] {
    return righe;
  }

  getImmaginiFilePdf(righe?: any[]): ImmaginePdf[] {
    const iconaSocieta = new ImmaginePdf();
    iconaSocieta.indiceColonna = 0;
    iconaSocieta.srcIcona = 'assets/img/users-solid-pdf-img.png';
    iconaSocieta.posizioneX = 46;
    iconaSocieta.posizioneY = 14;
    iconaSocieta.larghezza = 15;
    iconaSocieta.altezza = 13;

    const iconaEnti = new ImmaginePdf();
    iconaEnti.indiceColonna = 1;
    iconaEnti.srcIcona = 'assets/img/users-solid-pdf-img.png';
    iconaEnti.posizioneX = 50;
    iconaEnti.posizioneY = 5;
    iconaEnti.larghezza = 16;
    iconaEnti.altezza = 13;

    return [iconaSocieta, iconaEnti];
  }

  getColonneFileExcel(colonne: Colonna[]): Colonna[] {
    return colonne;
  }

  getRigheFileExcel(righe: any[]): any[] {
    return righe.map(riga => {
      delete riga.id;
      //riga.societa = riga.societa.value;
      riga.ente = riga.ente.value;
      riga.flussoId = riga.flussoId.value;
      riga.iban = riga.iban.value;
      riga.psp = riga.psp.value;
      riga.dataQuadratura = riga.dataQuadratura.value;
      riga.importo = riga.importo.value;
      riga.iuvTotali = riga.iuvTotali.value;
      riga.iuvScartati = riga.iuvScartati.value;
      return riga;
    });
  }

  // Funzioni di stampa flussi in TXT

  esportaFlussiInTxtFile(listaFlussoQuadraturaPagoPaId: Array<number>): void {
    this.quadraturaService.downloadFlussi(listaFlussoQuadraturaPagoPaId, this.idFunzione).subscribe(response => {

      if (!(response instanceof HttpErrorResponse)) {
        if (response.esito != null) {
          const banner: Banner = {
            titolo: 'ATTENZIONE',
            testo: response.esito,
            tipo: getBannerType(LivelloBanner.WARNING)
          };
          this.bannerService.bannerEvent.emit([banner]);
        }
        if (response.listaFlussi.length == 0) {
          const banner: Banner = {
            titolo: 'ATTENZIONE',
            testo: "Non è stato possibile recuperare i  flussi selezionati.",
            tipo: getBannerType(LivelloBanner.WARNING)
          };
          this.bannerService.bannerEvent.emit([banner]);
        } else {
          response.listaFlussi.forEach((flusso) => {
            for (const key in flusso as any) {
              if (flusso.hasOwnProperty(key)) {
                Utils.downloadBase64ToTxtFile(flusso[key], key + ".xml");
              }
            }
          });
        }
      }
    });
  }

  // TODO aggiungi immagine attenzione a pdf dettagli

  // getImmaginiDettaglioFilePdf(righe?: any[]): ImmaginePdf[] {
  //   const iconaAllarme = new ImmaginePdf();
  //   iconaAllarme.indiceColonna = 5;
  //   iconaAllarme.srcIcona = 'assets/img/exclamation-triangle-solid-pdf-img.png';
  //   iconaAllarme.posizioneX = 0;
  //   iconaAllarme.posizioneY = 0;
  //   iconaAllarme.larghezza = 18;
  //   iconaAllarme.altezza = 18;

  //   return [iconaAllarme];
  // }

  // getImmaginiDettagliFilePdf(righe?: any[]): ImmaginePdf[] {
  //   const iconaAllarme = new ImmaginePdf();
  //   iconaAllarme.indiceColonna = 6;
  //   iconaAllarme.srcIcona = 'assets/img/exclamation-triangle-solid-pdf-img.png';
  //   iconaAllarme.posizioneX = 0;
  //   iconaAllarme.posizioneY = 0;
  //   iconaAllarme.larghezza = 18;
  //   iconaAllarme.altezza = 18;

  //   return [iconaAllarme];
  // }

  // Nuove funzioni utils per la stampa

  private async getRigheStampa(): Promise<any[]> {
    if (this.righeSelezionate.length > 0) {
      return this.righeSelezionate
    } else {
      const allRows = []
      // TODO qui si puo' implementare un check safeguard.
      // Se gli elementi totali (salvati in counts) della query sono troppi, sarebbe opportuno impedire la stampa.
      const pagination = { pagination: false }

      const quadrature = await (await (await this.popolaListaElementiPaginata(pagination)).toPromise()).elementi

      quadrature.forEach((quadratura) => {
        allRows.push(this.creaRigaTabella(quadratura))
      })

      return allRows
    }
  }

  // Nuove funzioni di stampa pdf

  private async stampaFlussiPdf(filename: string) {
    const headers: string[] = []

    for (let col of this.tableData.cols) {
      if (col.type != tipoColonna.ICONA)
        headers.push(col.header)
    }

    const pdf = new PdfBuilder(filename)
    pdf.tables[filename] = new PdfTable()
    pdf.tables[filename].headers = headers

    const rows = await this.getRigheStampa()

    for (let r of rows) {
      const row: any = {}

      for (let c of this.tableData.cols) {
        if (c.type == tipoColonna.IMPORTO)
          row[c.header] = Utils.formattaImporto(r[c.field].value)
        else
          row[c.header] = r[c.field].value
      }

      pdf.tables[filename].rows.push(row)
      pdf.tables[filename].images = this.getImmaginiFilePdf()
    }

    pdf.print()
  }

  async stampaDettagliPdf(filename: string): Promise<void> {
    const quadrature = this.getRigheStampa();
    const quadratureIds = (await quadrature).map(q => q.id.value)
    const dettagliQuadrature = await this.quadraturaService.recuperaDettagliQuadratura(quadratureIds, this.idFunzione).toPromise()
    StampaDettagliPdf.stampaDettagli(filename, dettagliQuadrature)
  }

  // Nuove funzioni di stampa excel

  private async stampaFlussiExcel(filename: string) {
    const headers: string[] = []

    for (let col of this.tableData.cols) {
      if (col.type != tipoColonna.ICONA)
        headers.push(col.header)
    }

    const excel = new ExcelBuilder(filename)
    excel.sheets[filename] = new ExcelSheet()
    excel.sheets[filename].headers = headers

    const rows = await this.getRigheStampa()

    for (let r of rows) {
      const row: any = {}

      for (let c of this.tableData.cols) {
        row[c.header] = r[c.field].value
      }

      excel.sheets[filename].rows.push(row)
    }

    excel.print()
  }

  async stampaDettagliExcel(filename: string): Promise<void> {
    const quadrature = this.getRigheStampa();
    const quadratureIds = (await quadrature).map(q => q.id.value)
    const dettagliQuadrature = await this.quadraturaService.recuperaDettagliQuadratura(quadratureIds, this.idFunzione).toPromise()
    StampaDettagliExcel.stampaDettagli(filename, dettagliQuadrature)
  }
}
