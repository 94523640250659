export class SintesiPortaleEsterno {
  id: number;
  codice: string;
  descrizione: string;
  tipoPortale: string;
}

export class FiltroPortaleEsterno {
  codicePortaleEsterno: string;
  idTipoPortaleEsterno: number;
}

export class PortaleEsterno {
  private readonly MIN_TEMPO_VALIDITA_MESSAGGIO: number = 10;

  id: number = null;
  codice: string = null;
  descrizione: string = null;
  tipoPortaleEsterno: TipoPortaleEsterno = null;
  encryptIV: string = null;
  encryptKey: string = null;
  tempoValiditaMessaggio: number = this.MIN_TEMPO_VALIDITA_MESSAGGIO;
  waitingPagePayer: boolean = false;
  indirizzi: string[] = [];
}

export class TipoPortaleEsterno {
  id: number;
  codice: string;
  descrizione?: string;

  static compare(obj1: TipoPortaleEsterno, obj2: TipoPortaleEsterno): boolean {
    return  obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }
}
