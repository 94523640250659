import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-table-tester",
  templateUrl: "./table-tester.page.html",
  styleUrls: ["./table-tester.page.scss"],
})
export class TableTesterPage implements OnInit {
  selection: Set<any>;

  data: any[];

  private ROWS: number = 60;
  private COLS: number = 5;

  constructor() {
    this.data = [];
    for (let i = 1; i <= this.ROWS; i++) {
      const d = {};
      for (let j = 1; j <= this.COLS; j++) {
        d[`Key_${j}`] = `Value_${i}-${j}`;
      }
      this.data.push(d);
    }
  }

  ngOnInit() {}
}
