<form [formGroup]="form" class="modal-wrapper">
  <div class="row">
    <div class="offset-md-1 col-md-4">
      <div class="row">
        <label [htmlFor]="'nome'" class="title form-elem-title required"
        >Nome</label>
      </div>
      <div class="row">
        <select
          id="nome"
          [formControl]="form.controls['nome']"
          [(ngModel)]="nome"
          (ngModelChange)="selezionaNome()"
        >
          <option [ngValue]="null"></option>
          <option *ngFor="let item of listaNomi" [ngValue]="item">{{item}}</option>
        </select>
      </div>
    </div>
    <div class="offset-md-2 col-md-4">
      <div class="row">
        <label [htmlFor]="'informazione'" class="title form-elem-title required"
        >Informazioni</label>
      </div>
      <div class="row">
        <input id="informazione"
               [formControl]="form.controls['informazione']"
               [(ngModel)]="informazione"
               [attr.disabled]="disabilitaCampoInformazioni()"
               (ngModelChange)="inserisciInformazioni()"
               type="text"
               autocomplete="off"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <button type="button" id="bottoneIndietro" class="btn btn-secondary"
              (click)="clickIndietro()">Annulla
      </button>
    </div>
    <div class="col-10 text-right button-space">
      <button (click)="salvaTipoCampo()" type="button" class="btn"
              [disabled]="form.invalid">
        Salva
      </button>
    </div>
  </div>
</form>
