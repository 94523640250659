<div class="container-fluid">
  <it-breadcrumb [dark]="false" [separator]="'/'">
  <it-breadcrumb-item [id]="'breadcrumb-item-' + item.id" *ngFor="let item of breadcrumbList; let i = index"
  >
  {{item.label}}
  </it-breadcrumb-item>
  </it-breadcrumb>
  <h3 class="mb-2 page-title">
     <strong>{{titoloPagina}}</strong>
     <span it-tooltip="{{tooltip}}">
        <svg class="bg-light icon icon-lg icon-padded">
           <use xlink:href="assets/img/sprite.svg#it-help-circle"></use>
        </svg>
     </span>
  </h3>
  <span *ngIf="funzione != FunzioneGestioneEnum.DETTAGLIO">Tutti i campi contrassegnati con * sono obbligatori</span>
   <br/>
  <span *ngIf="funzione != FunzioneGestioneEnum.DETTAGLIO && flagRendicontazione">Il tipo flusso di rendicontazione è stato utilizzato nella rendicontazione giornaliera, non è possibile modificarlo.</span>
  <span *ngIf="funzione != FunzioneGestioneEnum.DETTAGLIO && !flagRendicontazione && !showCrea()">Il tipo flusso di rendicontazione è stato associato ad una tipologia di servizio ma non è mai stato rendicontato, è possibile solo modificare i campi correlati.</span>
   <br/>
   <br/>
     <div class="ml-4">
     <div class="row">
        <div class="col-md-4 pb-2">
           <div class="row">
              <div class="col">
                 <label for="codice" class="title"
                    [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Nome tipo flusso</label>
              </div>
           </div>
           <div class="row">
              <div class="col">
                 <input id="nome"
                 type="text"
                 [(ngModel)]="nome"
                 [disabled]="funzione == this.FunzioneGestioneEnum.DETTAGLIO || this.flagRendicontazione"
                 />
              </div>
           </div>
           <div class="row">
              <div class="col">
                 <p>
                 </p>
              </div>
           </div>
        </div>
        <div class="col-md-4 pb-2">
           <div class="row">
              <div class="col">
                 <label for="descrizione" class="title">Descrizione tipo flusso</label>
              </div>
           </div>
           <div class="row">
              <div class="col">
                 <input id="descrizione"
                 type="text"
                 [(ngModel)]="descrizione"
                 [disabled]="funzione == this.FunzioneGestioneEnum.DETTAGLIO || this.flagRendicontazione"
                 />
              </div>
           </div>
           <div class="row">
              <div class="col">
                 <p>
                 </p>
              </div>
           </div>
        </div>
        <div id="containerBottoneCrea" style="padding-top: 20px" *ngIf="showCrea() && showCreateDelete()">
           <button id="buttonSalva" class="btn" [disabled]="!abilitaSalva()" (click)="crea()">Crea struttura flusso</button>
        </div>
     </div>
     <div class="ml-4" *ngIf="showCopiaDa">
        <div class="row">
           <div class="col-md-4 pb-2">
              <div class="row">
                 <div class="col">
                    <label for="codice" class="title">Copia da tipo flusso</label>
                 </div>
              </div>
              <div class="row">
                 <div class="col">
                    <select id="tipoFlusso" name="tipoFlusso"
                    #filtroFlusso = "ngModel"
                    [(ngModel)]="tipoFlusso"
                    (ngModelChange)="recuperaCampiAssociati()"
                    >
                    <option [ngValue]="null" selected>Nessuno</option>
                    <option *ngFor="let item of listaFlussiRendicontazione"
                    [ngValue]="item">{{getNomeFlussoSelect(item["nome"], item["descrizione"])}}</option>
                    </select>
                 </div>
              </div>
           </div>
        </div>
     </div>
     <div *ngIf="(showCopiaDa || !showCrea()) && showCreateDelete()" class="row">
        <div class="col-3">
           <label class="title">Lista campi tipo rendicontazione</label>
           <span class="pointer bottone-campo"
              *ngIf="funzione == FunzioneGestioneEnum.AGGIUNGI || funzione == FunzioneGestioneEnum.MODIFICA">
              <span ngbTooltip="Crea nuovo gruppo" placement="bottom"
                 [ngStyle]="{fill: '#403d39'}">
                 <svg (click)="add()" class="bg-light icon icon-sm filled">
                    <use xlink:href="assets/img/sprite.svg#it-plus"></use>
                 </svg>
              </span>
           </span>
        </div>
     </div>
     <div *ngIf="!waiting" class="row" >
        <ng-container *ngFor="let item of listaCampiAssociati;let i=index">
           <div style="margin-bottom: 20px;" [ngClass]="calcolaDimensioneCampo(item)" >
              <div style="min-height: 5vh;"
                 [ngClass]="{'example-box':true, 'col-12': true, 'disabled': item.disabilitato,
                 'field-input': true}">
                 <div (click)="showModalAtClick(item, i)" class="example-custom-placeholder"></div>
                 <div class="row" style="width: 100%">
                    <div (click)="showModalAtClick(item, i)" *ngIf="showEditId !== i" (dblclick)="dblClick(item, i)"
                    class="col-7 text-right">{{item.nome}}
                 </div>
                 <div *ngIf="showEditId == i" class="col-6 text-right">
                    <input autofocus
                    (keyup.enter)="showEditId = null"
                    [(ngModel)]="item.nome"/>
                 </div>
                 <div (click)="showModalAtClick(item, i)" class="col-4"><span></span></div>
                 <div *ngIf="!showCreateDelete()" (click)="showModalAtClick(item, i)" class="col-1"><span></span></div>
                 <div *ngIf="showCreateDelete()" class="col-1 text-right">
                    <span class="close"  (click)="removeItem(item)"></span>
                 </div>
              </div>
           </div>
     </div>
     </ng-container>
  </div>
  <div class="row">
     <div [ngClass]="{'col-2': funzione !== FunzioneGestioneEnum.DETTAGLIO, 'col-12 text-center': funzione === FunzioneGestioneEnum.DETTAGLIO}">
        <button id="buttonAnnulla" class="btn btn-secondary"
           (click)="onClickAnnullaIndietro(funzione)">{{funzione === FunzioneGestioneEnum.DETTAGLIO ? 'Indietro' : 'Annulla'}}</button>
     </div>
     <div class="col-10 text-right" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
        <button id="buttonSalva" class="btn" [disabled]="!abilitaSalva()" (click)="onClickSalva()">Salva</button>
     </div>
  </div>
</div>
</div>
<p-confirmDialog #cb icon="pi pi-exclamation-triangle">
  <p-footer>
     <button *ngIf="showReject" type="button" class="undoButton" pButton icon="pi pi-times" label="Annulla" (click)="cb.reject()"></button>
     <button type="button" class="okButton" pButton icon="pi pi-check" label="Ok" (click)="cb.accept()"></button>
  </p-footer>
</p-confirmDialog>