import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ModalitaPaginaGestioneElemento } from "src/app/utility/enums/modalita-pagina-gestione-elemento";
import { LivelloIntegrazioneEnum } from "../../../../../../enums/livelloIntegrazione.enum";
import { TipoCampoEnum } from "../../../../../../enums/tipoCampo.enum";
import { AmministrativoService } from "../../../../../../services-old/amministrativo.service";
import { CampoTipologiaServizioService } from "../../../../../../services-old/campo-tipologia-servizio.service";
import { FlussoRendicontazioneService } from "../../../../../../services-old/flusso-rendicontazione.service";
import { OverlayService } from "../../../../../../services-old/overlay.service";
import { CampoTipologiaServizio } from "../../../../model/CampoTipologiaServizio";
import { ControlloLogico } from "../../../../model/ControlloLogico";
import { ConfigurazioneCampoDettaglioTransazione } from "../../../../model/campo/ConfigurazioneCampoDettaglioTransazione";
import { ConfigurazioneJsonPath } from "../../../../model/campo/ConfigurazioneJsonPath";
import { ConfigurazioneTipologica } from "../../../../model/campo/ConfigurazioneTipologica";
import { TipoCampo } from "../../../../model/campo/TipoCampo";
import { CampoTipoRendicontazione } from "../../../../model/flussiRenditcontazione/CampoTipoRendicontazione";
import { CampoServizio } from "../../../../model/servizio/CampoServizio";

export interface DatiModaleCampo {
  listaDipendeDa: any[];
  livelloIntegrazione: LivelloIntegrazioneEnum;
  campoForm: CampoServizio;
  funzione: ModalitaPaginaGestioneElemento;
  idFunzione: number;
  mostraLivelloIntegrazione: boolean;
  listaCampoTipoRendicontazione?: CampoTipoRendicontazione[];
  listaCampiDettaglioInUso: string[];
}

export const aggiornaTipoCampoEvent: EventEmitter<number> =
  new EventEmitter<number>();

@Component({
  selector: "app-modale-campo-form",
  templateUrl: "./modale-campo-form.component.html",
  styleUrls: ["./modale-campo-form.component.scss"],
})
export class ModaleCampoFormComponent implements OnInit {
  @ViewChild("multiSelect") multiSelect;

  form: FormGroup;

  @Input()
  datiModaleCampo: DatiModaleCampo;

  FunzioneGestioneEnum = ModalitaPaginaGestioneElemento;

  listaCampiDettaglioTransazione: ConfigurazioneCampoDettaglioTransazione[];
  listaControlliLogici: ControlloLogico[];
  listaTipologiche: ConfigurazioneTipologica[];
  listaJsonPath: ConfigurazioneJsonPath[];
  listaTipiCampo: TipoCampo[];
  listaCampoTipoRendicontazione: CampoTipoRendicontazione[];

  listaJsonPathFiltrata: ConfigurazioneJsonPath[];

  livelloIntegrazioneEnum = LivelloIntegrazioneEnum;
  showMultiSelect: boolean = false;
  firstChange: boolean = true;
  selectedValue = [];
  firstChangeFilter = true;

  nomeTipoCampoSelezionato: string;
  TipoCampoEnum = TipoCampoEnum;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "nome",
    enableCheckAll: false,
    limitSelection: -1,
    maxHeight: 197,
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    noDataAvailablePlaceholderText: "",
  };

  constructor(
    private overlayService: OverlayService,
    private amministrativoService: AmministrativoService,
    private FlussoRendicontazioneService: FlussoRendicontazioneService,
    private campoTipologiaServizioService: CampoTipologiaServizioService
  ) {
    this.leggiConfigurazioneCampi();

    this.form = new FormGroup({
      titolo: new FormControl(null, [Validators.required]),
      tipoCampo: new FormControl(null, [Validators.required]),
      informazioni: new FormControl(null),
      livelloIntegrazione: new FormControl(null),
      obbligatorio: new FormControl(null),
      lunghezzaVariabile: new FormControl(null),
      lunghezza: new FormControl(null),
      campoFisso: new FormControl(null),
      disabilitato: new FormControl(null),
      nonVisibile: new FormControl(null),
      posizione: new FormControl(null),
      chiave: new FormControl(null),
      controllo_logico: new FormControl(null),
      campo_input: new FormControl(null),
      jsonPath: new FormControl(null),
      tipologica: new FormControl(null),
      campoDettaglioTransazione: new FormControl(null),
      // listCampoTipoRendicontazione: new FormControl(null),
      dipendeDa: new FormControl(null),
      opzioni: new FormControl(null),
      codice: new FormControl(null),
    });

    aggiornaTipoCampoEvent.subscribe((idTipoCampo) => {
      this.leggiConfigurazioneCampi();
      this.datiModaleCampo.campoForm.tipoCampoId = idTipoCampo;
    });
  }

  leggiConfigurazioneCampi(): void {
    this.listaCampiDettaglioTransazione = JSON.parse(
      localStorage.getItem("listaCampiDettaglioTransazione")
    );
    this.listaCampiDettaglioTransazione = _.sortBy(
      this.listaCampiDettaglioTransazione,
      ["nome"]
    );
    this.listaControlliLogici = JSON.parse(
      localStorage.getItem("listaControlliLogici")
    );
    this.listaControlliLogici = _.sortBy(this.listaControlliLogici, ["nome"]);
    this.listaTipologiche = JSON.parse(
      localStorage.getItem("listaTipologiche")
    );
    this.listaTipologiche = _.sortBy(this.listaTipologiche, ["nome"]);
    this.listaJsonPath = JSON.parse(localStorage.getItem("listaJsonPath"));
    this.listaJsonPath = _.sortBy(this.listaJsonPath, ["nome_attributo"]);
    this.listaTipiCampo = JSON.parse(localStorage.getItem("listaTipiCampo"));
    this.listaTipiCampo = _.sortBy(this.listaTipiCampo, [
      "nome",
      "informazioni",
    ]);
  }

  campiDettaglioDisponibili(): ConfigurazioneCampoDettaglioTransazione[] {
    return this.listaCampiDettaglioTransazione.filter(
      (cdt) => !this.datiModaleCampo.listaCampiDettaglioInUso.includes(cdt.nome)
    );
  }

  ngOnInit(): void {
    if (this.datiModaleCampo?.campoForm?.tipoCampoId) {
      const tipoCampo = this.listaTipiCampo.find(
        (tipo) => tipo.id === this.datiModaleCampo.campoForm.tipoCampoId
      );
      if (tipoCampo) {
        this.nomeTipoCampoSelezionato = tipoCampo.nome;
        if (this.nomeTipoCampoSelezionato === TipoCampoEnum.SELECT) {
          this.form.controls["tipologica"].setValidators([Validators.required]);
        }
      }
    }

    if (this.datiModaleCampo.listaDipendeDa) {
      this.datiModaleCampo.listaDipendeDa =
        this.datiModaleCampo.listaDipendeDa.filter(
          (value) => value.titolo !== this.datiModaleCampo.campoForm.titolo
        );

      this.datiModaleCampo.campoForm.dipendeDa =
        this.datiModaleCampo.listaDipendeDa.find((value) =>
          this.datiModaleCampo.campoForm?.dipendeDa &&
          this.datiModaleCampo.campoForm.dipendeDa?.id
            ? value.id &&
              value.id === this.datiModaleCampo.campoForm?.dipendeDa?.id
            : value.id &&
              value.id ===
                this.datiModaleCampo.campoForm.dipendeDa?.riferimentoPerId
        );
    }
    if (!this.datiModaleCampo.livelloIntegrazione) {
      this.datiModaleCampo.livelloIntegrazione = LivelloIntegrazioneEnum.LV2;
    }

    if (
      this.datiModaleCampo.funzione === ModalitaPaginaGestioneElemento.DETTAGLIO
    ) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    if (
      this.datiModaleCampo.livelloIntegrazione === LivelloIntegrazioneEnum.LV2
    ) {
      this.datiModaleCampo.campoForm.campoInput = true;
      if (this.datiModaleCampo.campoForm instanceof CampoServizio) {
        this.datiModaleCampo.campoForm.jsonPathId = null;
      }
    }

    // if (JSON.parse(localStorage.getItem("listaCampoTipoRendicontazione")) != null) {
    //   this.showMultiSelect = true
    //   this.selectedValue = []
    //   let listaCampoTipoRendicontazione = this.datiModaleCampo.listaCampoTipoRendicontazione.filter(x => !this.datiModaleCampo.campoForm.listaCampoTipoRendicontazioneId.includes(x.id));

    //   if (this.datiModaleCampo.campoForm.listaCampoTipoRendicontazioneId.length > 0) {
    //     let listId = this.datiModaleCampo.campoForm.listaCampoTipoRendicontazioneId
    //     let elements = JSON.parse(localStorage.getItem("listaCampoTipoRendicontazione"))
    //     listId.forEach(id => {
    //       let element = elements.find(campo => campo.id == id)
    //       if (!listaCampoTipoRendicontazione.includes(element))
    //         listaCampoTipoRendicontazione.push(element)
    //       this.selectedValue.push(element)
    //     })

    //     this.datiModaleCampo.campoForm.listaCampoTipoRendicontazioneId = []
    //     listaCampoTipoRendicontazione.forEach(x => {
    //       if (!this.datiModaleCampo.campoForm.listaCampoTipoRendicontazioneId.includes(x.id) &&
    //         !this.datiModaleCampo.campoForm.listaCampoTipoRendicontazioneId.includes(x)
    //         && listId.includes(x.id))
    //         this.datiModaleCampo.campoForm.listaCampoTipoRendicontazioneId.push(x)
    //     })
    //     this.listaCampoTipoRendicontazione = listaCampoTipoRendicontazione
    //     this.form.patchValue({
    //       listCampoTipoRendicontazione: this.datiModaleCampo.campoForm.listaCampoTipoRendicontazioneId
    //     })
    //   }
    // }
  }

  selezionaTipoCampo(tipoCampoIdSelezionato: number): void {
    const tipoCampoSelezionato = this.listaTipiCampo.find(
      (tipoCampo) => tipoCampo.id === tipoCampoIdSelezionato
    );
    this.nomeTipoCampoSelezionato = tipoCampoSelezionato?.nome;
    if (this.nomeTipoCampoSelezionato) {
      if (this.nomeTipoCampoSelezionato === TipoCampoEnum.SELECT) {
        this.form.controls["tipologica"].enable();
        this.form.controls["tipologica"].setValidators([Validators.required]);
      } else {
        this.datiModaleCampo.campoForm.tipologica = null;
        this.datiModaleCampo.campoForm.dipendeDa = null;
        this.form.controls["tipologica"].disable();
        this.form.controls["tipologica"].clearValidators();
      }
      this.form.controls["tipologica"].updateValueAndValidity();
    }
    // this.datiModaleCampo.campoForm.listaCampoTipoRendicontazioneId = []
    // if (this.firstChange)
    //   this.firstChange = false
    // else
    //   this.form.controls['listCampoTipoRendicontazione'].setValue([])
    this.filtraCampoTipologiaServizio();
  }

  clickIndietro() {
    this.overlayService.mostraModaleCampoEvent.emit(null);
  }

  cambiaLivelloIntegrazione(
    item: CampoTipologiaServizio,
    event: LivelloIntegrazioneEnum
  ) {
    if (event === LivelloIntegrazioneEnum.LV2) {
      this.datiModaleCampo.campoForm.campoInput = true;
      if (this.datiModaleCampo.campoForm instanceof CampoServizio) {
        this.datiModaleCampo.campoForm.jsonPathId = null;
      }
    }
    this.listaJsonPathFiltrata = this.listaJsonPath.filter((value) => {
      return (
        value.livello_integrazione_id === event &&
        value.campo_input === item.campoInput
      );
    });
  }

  filtraCampoTipologiaServizio(): void {
    this.listaCampoTipoRendicontazione = [];
    if (this.datiModaleCampo?.campoForm?.tipoCampoId) {
      let list = this.datiModaleCampo.listaCampoTipoRendicontazione;
      list.forEach((campo) => {
        if (campo.tipoCampoId == this.datiModaleCampo.campoForm.tipoCampoId)
          if (!this.listaCampoTipoRendicontazione.includes(campo))
            this.listaCampoTipoRendicontazione.push(campo);
      });
      if (this.firstChangeFilter) {
        this.firstChangeFilter = false;
        this.selectedValue.forEach((campo) => {
          this.listaCampoTipoRendicontazione.push(campo);
        });
      } else {
        this.selectedValue.forEach((campo) => {
          if (campo.tipoCampoId == this.datiModaleCampo.campoForm.tipoCampoId)
            this.listaCampoTipoRendicontazione.push(campo);
        });
      }
    } else
      this.listaCampoTipoRendicontazione =
        this.datiModaleCampo.listaCampoTipoRendicontazione;
  }

  // clearCampoRendicontazione() {
  //   let temp = []
  //   this.form.value?.listCampoTipoRendicontazione?.forEach(campo => {
  //     temp.push(campo.id)
  //   })
  //   this.datiModaleCampo.campoForm.listaCampoTipoRendicontazioneId = []
  //   this.datiModaleCampo.campoForm.listaCampoTipoRendicontazioneId = temp
  // }

  dipendeDaIsDisabled() {
    return !this.datiModaleCampo.campoForm.tipologica ? true : null;
  }

  salvaCampoForm() {
    // this.clearCampoRendicontazione()
    this.amministrativoService.salvaCampoFormEvent.emit(
      this.datiModaleCampo.campoForm
    );
  }

  clickChiave(event: any) {
    this.datiModaleCampo.campoForm.obbligatorio = event.target.value == "on";
  }

  addTipoCampo() {
    this.overlayService.mostraModaleTipoCampoEvent.emit(
      this.datiModaleCampo.idFunzione
    );
  }

  showCampoDettaglioTransazione(): boolean {
    return (
      this.datiModaleCampo.livelloIntegrazione != this.livelloIntegrazioneEnum.LV2_BACK_OFFICE
    );
  }

  erroreCodice(): string {
    const codice = this.form.get("codice");

    if (codice.value === "importo") {
      return 'Il codice "importo" è riservato.';
    } else if (codice.errors?.pattern) {
      return 'Il codice identificativo deve iniziare con una lettera e può contenere solo caratteri minuscoli, numeri e underscore ("_").';
    } else if (codice.errors?.codiceRipetuto) {
      return `Codice già in uso (${codice.errors.codiceRipetuto}).`;
    }
  }
}
