export class CampoTipoRendicontazione {
    id: number;
    tipoRendicontazioneId: number;
    nome: string;
    ordine: string;
    lunghezza: number;
    valoreCostante: string;
    tipoCampoId: number;
    carattereRiempimento: string;
    riempimentoSinistro: number;
  }
  