import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FiltroGestioneElementiComponent } from "../../../filtro-gestione-elementi.component";
import { NgForm, NgModel } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AmministrativoService } from "../../../../../../../services-old/amministrativo.service";
import { ParametriRicercaTransazioni } from "../../../../../model/transazione/ParametriRicercaTransazioni";
import { DatePickerComponent, ECalendarValue } from "ng2-date-picker";
import * as moment from "moment";
import { Utils } from "../../../../../../../utility/Utils";
import { OpzioneSelect } from "../../../../../model/OpzioneSelect";
import { GestisciPortaleService } from "../../../../../../../services-old/gestisci-portale.service";
import { map } from "rxjs/operators";
import * as _ from "lodash";
import { StatoTransazioneEnum } from "../../../../../../../enums/statoTransazione.enum";
import { BottoneEnum } from "../../../../../../../enums/bottone.enum";
import { FlussoRendicontazione } from "../../../../../model/flussiRenditcontazione/FlussoRendicontazione";
import { FlussoRendicontazioneService } from "../../../../../../../services-old/flusso-rendicontazione.service";
import { ParametriRicercaRendicontazione } from "src/app/modules/main/model/rendicontazione/ParametriRicercaRendicontazione";
import { Observable } from "rxjs";
import { TipologiaServizio } from "src/app/modules/main/model/tipologiaServizio/TipologiaServizio";
import { SelectOption } from "src/app/shared/common/model/select.model";
import { TipologiaServizioService } from "src/app/shared/common/services/tipologia-servizio.service";
import { FiltroSelect } from "src/app/modules/main/model/servizio/FiltroSelect";
import { ConfiguraServizioService } from "src/app/services-old/configura-servizio.service";

@Component({
  selector: "app-filtro-monitoraggio-transazioni",
  templateUrl: "./filtro-monitoraggio-transazioni.component.html",
  styleUrls: ["./filtro-monitoraggio-transazioni.component.scss"],
})
export class FiltroMonitoraggioTransazioniComponent
  extends FiltroGestioneElementiComponent
  implements OnInit
{
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected amministrativoService: AmministrativoService,
    private gestisciPortaleService: GestisciPortaleService,
    private FlussoRendicontazioneService: FlussoRendicontazioneService,
    private tipologiaServizioService: TipologiaServizioService,
    private configuraServizioService: ConfiguraServizioService,
  ) {
    super(activatedRoute, amministrativoService);
  }

  @Input() flussoRendicontazione = null;
  @Input() flussoQuadratura = null;

  @Output()
  onChangeFiltri: EventEmitter<any> = new EventEmitter<any>();

  filtroRicercaTransazioni: ParametriRicercaTransazioni;
  listaFlussiRendicontazione: FlussoRendicontazione[] = [];
  listaLivelloIntegrazione: FiltroSelect[] = [];

  // calendar
  isCalendarOpen: boolean;
  readonly minDateDDMMYYYY = "01/01/1990";
  readonly tipoData = ECalendarValue.String;
  readonly formatoData = Utils.FORMAT_DATE_CALENDAR;
  opzioniFiltroFlussoQuadratura: string[];
  opzioniFiltroFlussoQuadraturaFiltrate: string[];

  // opzioni per select
  opzioniFiltroSocieta: OpzioneSelect[] = [];
  opzioniFiltroLivelliTerritoriale: OpzioneSelect[] = [];
  opzioniFiltroEnte: OpzioneSelect[] = [];
  opzioniFiltroServizio: OpzioneSelect[] = [];
  opzioniFiltroIdentificativoTransazione: OpzioneSelect[] = [];
  opzioniFiltroCanale: OpzioneSelect[] = [];
  opzioniFiltroVersanteIndirizzoIP: OpzioneSelect[] = [];
  opzioniFiltroStatoTransazione: OpzioneSelect[] = [];

  opzioniFiltroTipologiaServizio$: Observable<
    SelectOption<TipologiaServizio>[]
  >;

  ngOnInit(): void {
    let tempFiltroRicercaTransazioni = JSON.parse(
      localStorage.getItem("filtroRicercaMonitoraggioTransazioni")
    );

    if (tempFiltroRicercaTransazioni) {
      this.filtroRicercaTransazioni = tempFiltroRicercaTransazioni;
      const filtri = this.formattaFiltri();
      this.onChangeFiltri.emit(filtri);
    } else {
      this.filtroRicercaTransazioni = new ParametriRicercaTransazioni();
      this.filtroRicercaTransazioni.dataTransazioneDa = String(
        moment().subtract(1, "day").format(this.formatoData)
      );
    }

    if (this.flussoRendicontazione) {
      this.filtroRicercaTransazioni.flussoRendicontazione =
        this.flussoRendicontazione;
      const parametriRicercaTransazioni = new ParametriRicercaTransazioni();
      parametriRicercaTransazioni.flussoRendicontazione =
        this.flussoRendicontazione;
      this.onChangeFiltri.emit(parametriRicercaTransazioni);
    }

    if (this.flussoQuadratura) {
      this.filtroRicercaTransazioni.flussoQuadratura = this.flussoQuadratura;
      const parametriRicercaTransazioni = new ParametriRicercaTransazioni();
      parametriRicercaTransazioni.flussoQuadratura = this.flussoQuadratura;
      this.onChangeFiltri.emit(parametriRicercaTransazioni);
    }

    this.recuperaFiltroSocieta();
    this.recuperaFiltroLivelloTerritoriale(
      new ParametriRicercaRendicontazione()
    );
    this.recuperaFiltroEnte(new ParametriRicercaRendicontazione());
    this.recuperaFiltroTipologiaServizio(new ParametriRicercaRendicontazione());
    this.recuperaFiltroServizio(new ParametriRicercaRendicontazione());
    this.recuperaFiltroCanale();
    this.recuperaFiltroVersanteIndirizzoIP();
    this.recuperaFiltroStatoTransazione();
    this.caricaCodiciFlussoRendicontazione();
    this.caricaLivelliIntegrazione();
  }

  recuperaFiltroSocieta(): void {
    this.gestisciPortaleService
      .gestisciPortaleFiltroSocieta(this.idFunzione)
      .pipe(
        map((listaSocieta) => {
          listaSocieta.forEach((societa) => {
            this.opzioniFiltroSocieta.push({
              value: societa.id,
              label: societa.nome,
            });
          });
          this.opzioniFiltroSocieta = _.sortBy(this.opzioniFiltroSocieta, [
            "label",
          ]);
          if (this.opzioniFiltroSocieta.length == 1)
            this.filtroRicercaTransazioni.societaId =
              this.opzioniFiltroSocieta[0].value;
        })
      )
      .subscribe();
  }

  recuperaFiltroLivelloTerritoriale(
    parametriRicerca: ParametriRicercaRendicontazione
  ): void {
    this.gestisciPortaleService
      .gestisciPortaleFiltroLivelloTerritoriale(
        parametriRicerca,
        this.idFunzione
      )
      .pipe(
        map((listaLivelloTerritoriale) => {
          listaLivelloTerritoriale.forEach((livelloTerritoriale) => {
            this.opzioniFiltroLivelliTerritoriale.push({
              value: livelloTerritoriale.id,
              label: livelloTerritoriale.nome,
            });
          });
          this.opzioniFiltroLivelliTerritoriale = _.sortBy(
            this.opzioniFiltroLivelliTerritoriale,
            ["label"]
          );
        })
      )
      .subscribe();
  }

  recuperaFiltroEnte(parametriRicerca: ParametriRicercaRendicontazione): void {
    this.gestisciPortaleService
      .gestisciPortaleFiltroEnte(parametriRicerca, this.idFunzione)
      .pipe(
        map((listaEnte) => {
          listaEnte.forEach((ente) => {
            this.opzioniFiltroEnte.push({
              value: ente.id,
              label: ente.nome,
            });
          });
          this.opzioniFiltroEnte = _.sortBy(this.opzioniFiltroEnte, ["label"]);
        })
      )
      .subscribe();
  }

  recuperaFiltroTipologiaServizio(
    parametriRicerca: ParametriRicercaRendicontazione
  ): void {
    // this.campoTipologiaServizioService.recuperaTipologieServizio(parametriRicerca, this.idFunzione).pipe(map(listaTipologiaServizio => {
    //   listaTipologiaServizio.forEach(tipologiaServizio => {
    //     this.opzioniFiltroTipologiaServizio$.push({
    //       value: tipologiaServizio.id,
    //       label: tipologiaServizio.descrizione
    //     });
    //   });
    //   this.opzioniFiltroTipologiaServizio$ = _.sortBy(this.opzioniFiltroTipologiaServizio$, ['label']);
    // })).subscribe();

    this.opzioniFiltroTipologiaServizio$ =
      this.tipologiaServizioService.opzioni(parametriRicerca, this.idFunzione);
  }

  recuperaFiltroServizio(
    parametriRicerca: ParametriRicercaRendicontazione
  ): void {
    this.gestisciPortaleService
      .gestisciPortaleFiltroServizio(parametriRicerca, this.idFunzione)
      .pipe(
        map((listaServizio) => {
          listaServizio.forEach((servizio) => {
            this.opzioniFiltroServizio.push({
              value: servizio.id,
              label: servizio.nome,
            });
          });
          this.opzioniFiltroServizio = _.sortBy(this.opzioniFiltroServizio, [
            "label",
          ]);
        })
      )
      .subscribe();
  }

  recuperaFiltroCanale(): void {
    this.gestisciPortaleService
      .gestisciPortaleFiltroCanale(this.idFunzione)
      .pipe(
        map((listaCanale) => {
          listaCanale.forEach((canale) => {
            this.opzioniFiltroCanale.push({
              value: canale.id,
              label: canale.nome,
            });
          });
          this.opzioniFiltroCanale = _.sortBy(this.opzioniFiltroCanale, [
            "label",
          ]);
        })
      )
      .subscribe();
  }

  recuperaFiltroVersanteIndirizzoIP(): void {
    this.gestisciPortaleService
      .gestisciPortaleFiltroVersante(this.idFunzione)
      .pipe(
        map((listaVersanti) => {
          listaVersanti.forEach((versante) => {
            this.opzioniFiltroVersanteIndirizzoIP.push({
              value: versante.indirizzoIp,
              label: versante.indirizzoIp,
            });
          });
        })
      )
      .subscribe();
  }

  recuperaFiltroStatoTransazione(): void {
    const statoTransazioneEnum = StatoTransazioneEnum;
    const keys = Object.keys(statoTransazioneEnum).filter(
      (k) => typeof statoTransazioneEnum[k as any] === "number"
    );
    keys.forEach((key, index) => {
      this.opzioniFiltroStatoTransazione.push({
        value: index,
        label: key.replace(/_/g, " "),
      });
    });
  }

  caricaCodiciFlussoRendicontazione(): void {
    this.FlussoRendicontazioneService.recuperaFlussiRendicontazione(
      this.idFunzione
    ).subscribe((listaFlussiRendicontazione) => {
      this.listaFlussiRendicontazione = listaFlussiRendicontazione;
    });
  }

  filtraOpzioniFlussoQuadratura(event): void {
    const input = event.query;
    this.opzioniFiltroFlussoQuadraturaFiltrate =
      this.opzioniFiltroFlussoQuadratura.filter((value) =>
        value.toLowerCase().startsWith(input.toLowerCase())
      );
  }

  cercaElementi(): void {
    localStorage.setItem(
      "filtroRicercaMonitoraggioTransazioni",
      JSON.stringify(this.filtroRicercaTransazioni)
    );
    const filtri = this.formattaFiltri();
    this.onChangeFiltri.emit(filtri);
  }

  private formattaFiltri() {
    const filtri: ParametriRicercaTransazioni = JSON.parse(
      JSON.stringify(this.filtroRicercaTransazioni)
    );
    filtri.dataTransazioneDa = filtri.dataTransazioneDa
      ? moment(filtri.dataTransazioneDa, Utils.FORMAT_DATE_CALENDAR).format(
          Utils.FORMAT_LOCAL_DATE_TIME
        )
      : null;
    filtri.dataTransazioneA = filtri.dataTransazioneA
      ? moment(filtri.dataTransazioneA, Utils.FORMAT_DATE_CALENDAR).format(
          Utils.FORMAT_LOCAL_DATE_TIME_TO
        )
      : null;
    return filtri;
  }

  pulisciFiltri(filtroForm: NgForm): void {
    localStorage.setItem("filtroRicercaMonitoraggioTransazioni", null);
    filtroForm.resetForm();
    const filtri = this.formattaFiltri();
    const dataTransazioneDa = moment().subtract(1, "day");
    filtroForm.controls["dataTransazioneDa"].setValue(dataTransazioneDa.format(this.formatoData));
    filtri.dataTransazioneDa = dataTransazioneDa.format(Utils.FORMAT_LOCAL_DATE_TIME);
    this.onChangeFiltri.emit(filtri);
  }

  openDatepicker(datePickerComponent: DatePickerComponent): void {
    datePickerComponent.api.open();
    this.isCalendarOpen = !this.isCalendarOpen;
  }

  isCampoInvalido(campo: NgModel) {
    return campo?.errors != null;
  }

  getMessaggioErrore(campo: NgModel): string {
    if (campo.control?.errors?.required) {
      return "Il campo è obbligatorio";
    } else {
      return "Campo non valido";
    }
  }

  disabilitaBottone(filtroForm: NgForm, nomeBottone: string): boolean {
    const isAtLeastOneFieldValued = Object.keys(filtroForm.value).some(
      (key) => filtroForm.value[key]
    );
    if (nomeBottone === BottoneEnum.PULISCI) {
      return !isAtLeastOneFieldValued;
    } else if (nomeBottone === BottoneEnum.CERCA) {
      return !filtroForm.valid;
    }
  }

  getNomeFlussoSelect(nome, descrizione) {
    return nome + (descrizione != null ? " - " + descrizione : "");
  }

  selezionaSocieta() {
    this.opzioniFiltroLivelliTerritoriale = [];
    this.opzioniFiltroEnte = [];
    // this.opzioniFiltroTipologiaServizio$ = [];
    this.opzioniFiltroServizio = [];
    const parametriRicerca = this.changeFilter();
    this.recuperaFiltroLivelloTerritoriale(parametriRicerca);
    this.recuperaFiltroEnte(parametriRicerca);
    this.recuperaFiltroTipologiaServizio(parametriRicerca);
    this.recuperaFiltroServizio(parametriRicerca);
    this.filtroRicercaTransazioni.livelloTerritorialeId = null;
    this.filtroRicercaTransazioni.enteId = null;
    this.filtroRicercaTransazioni.tipologiaServizioId = null;
    this.filtroRicercaTransazioni.servizioId = null;
  }

  selezionaLivelloTerritoriale(): void {
    this.opzioniFiltroEnte = [];
    // this.opzioniFiltroTipologiaServizio$ = [];
    this.opzioniFiltroServizio = [];
    const parametriRicerca = this.changeFilter();
    this.recuperaFiltroEnte(parametriRicerca);
    this.recuperaFiltroTipologiaServizio(parametriRicerca);
    this.recuperaFiltroServizio(parametriRicerca);
    this.filtroRicercaTransazioni.enteId = null;
    this.filtroRicercaTransazioni.tipologiaServizioId = null;
    this.filtroRicercaTransazioni.servizioId = null;
  }

  selezionaEnte(): void {
    // this.opzioniFiltroTipologiaServizio$ = [];
    this.opzioniFiltroServizio = [];
    const parametriRicerca = this.changeFilter();
    this.recuperaFiltroTipologiaServizio(parametriRicerca);
    this.recuperaFiltroServizio(parametriRicerca);
    this.filtroRicercaTransazioni.tipologiaServizioId = null;
    this.filtroRicercaTransazioni.servizioId = null;
  }

  selezionaTipologiaServizio(): void {
    this.opzioniFiltroServizio = [];
    const parametriRicerca = this.changeFilter();
    this.recuperaFiltroServizio(parametriRicerca);
    this.filtroRicercaTransazioni.servizioId = null;
  }

  changeFilter(): ParametriRicercaRendicontazione {
    const parametriRicerca = new ParametriRicercaRendicontazione();
    parametriRicerca.societaId = this.filtroRicercaTransazioni.societaId;
    parametriRicerca.livelloTerritorialeId = this.filtroRicercaTransazioni.livelloTerritorialeId;
    parametriRicerca.enteId = this.filtroRicercaTransazioni.enteId;
    parametriRicerca.tipologiaServizioId = this.filtroRicercaTransazioni.tipologiaServizioId;
    return parametriRicerca;
  }

  selezionaLivelloIntegrazione() {
    
  }

  private caricaLivelliIntegrazione() {
    this.configuraServizioService
      .filtroLivelliIntegrazione(null, this.idFunzione)
      .pipe(map((value) => {
        // console.log("caricaLivelliIntegrazione : value = ", JSON.stringify(value));
        this.listaLivelloIntegrazione = value;
      }
      ))
      .subscribe();
  }
  
}
