import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { PayerService } from "../abstracts/payer-service.abstract";
import { ParametriRicercaEnte } from "../../../modules/main/model/ente/ParametriRicercaEnte";
import { ParametriRicercaRendicontazione } from "../../../modules/main/model/rendicontazione/ParametriRicercaRendicontazione";
import { FiltroSelect } from "../../../modules/main/model/servizio/FiltroSelect";
import { ParametriRicercaServizio } from "../../../modules/main/model/servizio/ParametriRicercaServizio";
import { ParametriRicercaTipologiaServizio } from "../../../modules/main/model/tipologiaServizio/ParametriRicercaTipologiaServizio";
import { TipologiaServizio } from "../../../modules/main/model/tipologiaServizio/TipologiaServizio";
import { SelectOption } from "../../../shared/common/model/select.model";

@Injectable({
  providedIn: "root",
})
export class TipologiaServizioService extends PayerService {
  private readonly BASE_URL: string =
    environment.bffBaseUrl + "/gestisciTipologiaServizi";
  private readonly TS_URL: string = this.BASE_URL + "/tipologiaServizi";
  private readonly DELETE_TS_URL: string =
    this.TS_URL + "/eliminaTipologiaServizi";
  private readonly CAMPO_TS_URL: string =
    this.BASE_URL + "/campiTipologiaServizio";
  private configurazioneCampiNuovoPagamentoUrl =
    "/configurazioneCampiNuovoPagamento";
  private inserimentoTipoCampoUrl = "/tipoCampo";

  constructor(private http: HttpClient) {
    super();
  }

  opzioni(
    filtri:
      | ParametriRicercaTipologiaServizio
      | ParametriRicercaServizio
      | ParametriRicercaRendicontazione
      | ParametriRicercaEnte,
    funzione: number | string
  ): Observable<SelectOption<TipologiaServizio>[]> {
    return this.cerca(filtri, funzione).pipe(
      map((res) => {
        return _.sortBy(res, "descrizione").map((ts) => {
          return {
            value: ts,
            label: ts.descrizione + " - " + ts.codice,
          };
        });
      }),
      catchError(this.observeNull)
    );
  }

  cerca(
    filtro:
      | ParametriRicercaTipologiaServizio
      | ParametriRicercaServizio
      | ParametriRicercaRendicontazione
      | ParametriRicercaEnte,
    funzione: number | string
  ): Observable<TipologiaServizio[]> {
    if (filtro instanceof ParametriRicercaEnte) {
      return this.cercaConfiguraServizio(filtro, funzione);
    } else {
      return this.cercaConfiguraTipologiaServizio(filtro, funzione);
    }
  }

  private cercaConfiguraTipologiaServizio(
    filter:
      | ParametriRicercaTipologiaServizio
      | ParametriRicercaServizio
      | ParametriRicercaRendicontazione,
    funzione: number | string
  ): Observable<TipologiaServizio[]> {
    let params = new HttpParams();
    if (filter) {
      if (filter.raggruppamentoId) {
        params = params.set(
          "raggruppamentoId",
          String(filter.raggruppamentoId)
        );
      }
      if (
        filter instanceof ParametriRicercaTipologiaServizio &&
        typeof filter.tipologia === "string"
      ) {
        params = params.set("codiceTipologia", filter.tipologia);
      } else if (
        filter instanceof ParametriRicercaTipologiaServizio &&
        filter.tipologia?.codice
      ) {
        params = params.set("codiceTipologia", filter.tipologia?.codice);
      } else if (
        filter instanceof ParametriRicercaServizio &&
        filter.tipologiaServizio instanceof TipologiaServizio &&
        filter.tipologiaServizio?.codice
      ) {
        params = params.set("codiceTipologia", filter.tipologiaServizio.codice);
      }

      if (filter instanceof ParametriRicercaTipologiaServizio) {
        // if (filter.tipoFlusso?.id)
        //   params = params.set(
        //     "tipoFlussoRendicontazioneId",
        //     filter.tipoFlusso.id.toString()
        //   );
        if (filter.nome) params = params.set("nomeTipologia", filter.nome);
      }

      if (filter instanceof ParametriRicercaRendicontazione) {
        if (filter.societaId != null) {
          params = params.set("societaId", String(filter.societaId));
        }
        if (filter.livelloTerritorialeId != null) {
          params = params.set(
            "livelloTerritorialeId",
            String(filter.livelloTerritorialeId)
          );
        }
        if (filter.enteId != null) {
          params = params.set("enteId", String(filter.enteId));
        }
      }
    }

    return this.http
      .get<TipologiaServizio[]>(this.TS_URL, {
        params: params,
        headers: this.getHeadersConFunzioneAmministratore(funzione),
        withCredentials: true,
      })
      .pipe(catchError(this.observeNull));
  }

  private cercaConfiguraServizio(
    filtro: ParametriRicercaEnte,
    funzione: number | string
  ): Observable<TipologiaServizio[]> {
    let params = new HttpParams();
    if (filtro) {
      params = params.set("filtroRicerca ", "true");
      if (filtro.societaId != null) {
        params = params.set("societaId", String(filtro.societaId));
      }
      if (filtro.enteId != null) {
        params = params.set("enteId", String(filtro.enteId));
      }
      if (filtro.livelloIntegrazioneId != null) {
        params = params.set(
          "livelloIntegrazioneId",
          String(filtro.livelloIntegrazioneId)
        );
      }
    }

    return this.http
      .get<FiltroSelect[]>(
        environment.bffBaseUrl + "/configuraServizi/filtroTipologiaServizio",
        {
          params: params,
          headers: this.getHeadersConFunzioneAmministratore(funzione),
          withCredentials: true,
        }
      )
      .pipe(
        map((res) => {
          return res.map((el) => {
            const ts = new TipologiaServizio();
            ts.id = el.id;
            ts.codice = el.codice;
            ts.descrizione = el.nome;
            return ts;
          });
        }),
        catchError(this.observeNull)
      );
  }
}
