<div #containerLoginBar class="container-fluid">
  <div class="row main-header">
    <div class="col-7 col-lg-5 col-md-6 col-sm-6 mt-2 font-msg">
      <a id="lepida-link" rel="noopener noreferrer" target="_blank" href="https://www.lepida.net/home">Lepida ScpA</a>
    </div>
    <div class="text-right offset-2 offset-sm-3 offset-md-3
                                  offset-lg-5 col-1 col-lg-2 col-md-2 col-sm-2 font-msg align-middle pl-0">
      <button (click)="getLoginLink()" [disabled]="isL1" type="button" class="btn btn-outline-secondary btn-sm">{{testoAccedi}}</button>
    </div>
  </div>
</div>
