<!-- Sottotitolo -->
<div class="collapse-header" id="idCollapseFiltri" *ngIf="isPaginaGestione()">
  <button data-toggle="collapse" data-target="#contenutoSezioneFiltri" aria-expanded="true"
          aria-controls="contenutoSezioneFiltri">
    Filtri
  </button>
</div>

<!-- Form filtri -->
<form #filtroForm="ngForm">
  <div id="contenutoSezioneFiltri" class="collapse show" role="tabpanel" aria-labelledby="idCollapseFiltri">
    <div class="collapse-body">
      <!-- Filtri -->
      <div class="row mt-2 mb-0">
        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="selectRaggruppamento" class="title"
                     [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Raggruppamento</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select [ngClass]="{'text-disabled': !opzioniRaggruppamento.length}" id="selectRaggruppamento"
                      name="raggruppamento"
                      [required]="true"
                      #filtroRaggruppamento="ngModel"
                      [(ngModel)]="filtriRicerca.raggruppamentoId"
                      (ngModelChange)="selezionaRaggruppamento()"
                      [disabled]="disabilitaFiltroRaggruppamento()"
              >
                <option [ngValue]="null" selected *ngIf="funzione === FunzioneGestioneEnum.AGGIUNGI">Seleziona</option>
                <option *ngFor="let item of opzioniRaggruppamento" [ngValue]="item.value">{{item.label}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-invalid': isCampoInvalido(filtroRaggruppamento)}">
                {{setPlaceholder(filtroRaggruppamento, TipoCampoEnum.SELECT)}}
              </p>
            </div>
          </div>

        </div>
        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="inputCodice" class="title"
                     [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Tipologia di Servizio</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select id="inputCodice"
                name="codice"
                [required]="true"
                #filtroCodice="ngModel"
                [(ngModel)]="filtriRicerca.tipologiaServizio"
                [disabled]="disabilitaFiltroCodice()">
                <option [ngValue]="null" [selected]="true">Non selezionato</option>
                <option *ngFor="let item of listaCodiciTipologiaFiltrati"
                        [ngValue]="item">{{item["codice"]}} - {{item["descrizione"]}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': disabilitaFiltroCodice(), 'text-invalid': isCampoInvalido(filtroCodice)}">
                {{setPlaceholder(filtroCodice, TipoCampoEnum.INPUT_TESTUALE)}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 pb-2">
          <div class="row">
            <div class="col">
              <label for="servizio" class="title" [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Servizio</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input id="servizio" name="servizio" type="text" class="input-text pt-0" autocomplete="off"
                     [required]="true" [size]="255" [maxLength]="255"
                     #filtroServizio="ngModel" [(ngModel)]="filtriRicerca.nomeServizio" [disabled]="disabilitaCampi()">
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-invalid': isCampoInvalido(filtroServizio)}">
                {{setPlaceholder(filtroServizio, TipoCampoEnum.INPUT_TESTUALE)}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 pb-2">
          <div class="row">
            <div class="col">
              <label for="codiceTassonomia" style="display: flex;" class="title" [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}"><div>Codice Tassonomia</div>
                <div>
              <span ngbTooltip="{{codiceTassonomiaErroreRegex}}" placement="top" style="fill: '#403d39'; margin: '5px'">
               <svg *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" fill='var(--lepida-primary)' class="bg-light icon cursor-pointer">
               <use xlink:href="assets/img/sprite.svg#it-info-circle"></use>
               </svg>
              </span>
           </div>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input id="codiceTassonomia" name="codiceTassonomia" type="text" class="input-text pt-0" autocomplete="off"
                    [required]="true" [maxLength]="20" [pattern]="codiceTassonomiaRegex"
                     #codiceTassonomia="ngModel" [(ngModel)]="filtriRicerca.codiceTassonomia" [disabled]="disabilitaCampiModifica()">
            </div>


          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-invalid': isCampoInvalido(codiceTassonomia)}">
                {{setPlaceholder(codiceTassonomia, TipoCampoEnum.INPUT_TESTUALE)}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-2 pb-2">
          <div class="row">
            <div class="col">
              <label for="codiceCarta" class="title" [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Codice Carta</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input id="codiceCarta" name="codiceCarta" type="text" class="input-text pt-0" autocomplete="off"
                    [required]="true" [maxLength]="2"
                    #codiceCarta="ngModel" [(ngModel)]="filtriRicerca.codiceCarta" [disabled]="disabilitaCampiModifica()">
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-invalid': isCampoInvalido(codiceCarta)}">
                {{setPlaceholder(codiceCarta, TipoCampoEnum.INPUT_TESTUALE)}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-2 pb-1">
          <div class="row">
            <div class="col">
              <label for="switchAttestato" class="title">Attestato pagamento</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <it-toggle id="switchAttestato" [disabled]="disabilitaCampi()" [(ngModel)]="filtriRicerca.attestato"
                         name="attestato"></it-toggle>
            </div>
          </div>
        </div>
        <div class="col-md-2 pb-2">
          <div class="row">
            <div class="col">
              <label for="scadenzaDataDa" class="title"
                     [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Abilita Da</label>
            </div>
          </div>
          <div class="row">
            <div class="col calendar-wrapper">
              <svg class="bg-light icon img-calendar" (click)="openDatepicker(datePickerScadenzaDa)">
                <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
              </svg>
              <dp-date-picker
                [required]="true"
                [customvalidator]="validateRange()"
                [disabled]="disabilitaCampi()"
                mode="day"
                id="scadenzaDataDa"
                theme="dp-material"
                [config]="{
                  locale: 'it',
                  format: 'DD/MM/YYYY',
                  firstDayOfWeek: 'mo',
                  showGoToCurrent: false,
                  openOnClick: isCalendarOpen,
                  openOnFocus: false,
                  min: minDateDDMMYY,
                  returnedValueType: tipoData
	              }"
                #scadenzaDaInput="ngModel"
                [(ngModel)]="filtriRicerca.abilitaDa"
                name="abilitaDa"
                #datePickerScadenzaDa
                (click)="isCalendarOpen = false"
              ></dp-date-picker>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-invalid': isCampoInvalido(scadenzaDaInput)}">
                {{setPlaceholder(scadenzaDaInput, TipoCampoEnum.DATEDDMMYY)}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-2 pb-2">
          <div class="row">
            <div class="col">
              <label for="scadenzaDataA" class="title">Abilita A</label>
            </div>
          </div>
          <div class="row">
            <div class="col calendar-wrapper">
              <svg class="bg-light icon img-calendar" (click)="openDatepicker(datePickerScadenzaA)">
                <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
              </svg>
              <dp-date-picker
                [customvalidator]="validateRange()"
                [disabled]="disabilitaCampi()"
                mode="day"
                id="scadenzaDataA"
                theme="dp-material"
                [config]="{
                  locale: 'it',
                  format: 'DD/MM/YYYY',
                  firstDayOfWeek: 'mo',
                  showGoToCurrent: false,
                  openOnClick: isCalendarOpen,
                  openOnFocus: false,
                  min: minDateDDMMYY,
                  returnedValueType: tipoData
                }"
                #scadenzaAInput="ngModel"
                [(ngModel)]="filtriRicerca.abilitaA"
                name="abilitaA"
                #datePickerScadenzaA
                (click)="isCalendarOpen = false"
              ></dp-date-picker>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p
                [ngClass]="{'text-invalid': isCampoInvalido(scadenzaAInput)}">
                {{setPlaceholder(scadenzaAInput, TipoCampoEnum.DATEDDMMYY)}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-1 pb-1">
          <div class="row">
            <div class="col">
              <label for="switchAttivo" class="title">Attivo</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <it-toggle id="switchAttivo" [disabled]="disabilitaCampi()" [(ngModel)]="filtriRicerca.attivo" name="attivo"></it-toggle>
            </div>
          </div>
        </div>
        <div class="col-md-3 pb-2" id="containerBottoneCrea"
             *ngIf="funzione == FunzioneGestioneEnum.AGGIUNGI">
          <button id="bottoneCrea" class="btn"
                  [disabled]="filtroForm.invalid || isTipologiaServizio(filtriRicerca.tipologiaServizio) || disabilitaFiltri"
                  (click)="creaTipologia()">Crea
          </button>
        </div>
      </div>
      <!-- Bottoni-->
      <div class="row" *ngIf="isPaginaGestione()">
        <div class="col-6">
          <button id="bottonePulisci" type="button" class="btn btn-secondary" [disabled]="disabilitaBottonePulisci()"
                  (click)="pulisciFiltri(filtroForm)">Pulisci
          </button>
        </div>
        <div class="col-6 btn-find">
          <button id="bottoneCerca" class="btn"
                  (click)="cercaElementi()">Cerca
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
