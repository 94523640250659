<!-- Sottotitolo -->
<div class="collapse-header" id="idCollapseFiltriRaggruppamentiTipologieServizi">
  <button data-toggle="collapse" data-target="#contenutoSezioneFiltri" aria-expanded="true" aria-controls="contenutoSezioneFiltri">
    Filtri
  </button>
</div>

<!-- Form filtri -->
<form #filtroRaggruppamentoTipologieForm="ngForm">
  <div id="contenutoSezioneFiltri" class="collapse show" role="tabpanel" aria-labelledby="idCollapseFiltriRaggruppamentiTipologieServizi">
    <div class="collapse-body">
      <!-- Combo box Raggruppamenti tipologie servizi -->
      <div class="row mt-2 mb-0">
        <div class="col-md-6 pb-2">
          <div class="row">
            <div class="col">
              <label for="raggruppamentoTipologieSelect" class="title">Raggruppamento tipologie servizi</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select id="raggruppamentoTipologieSelect" [(ngModel)]="filtroRaggruppamentiTipologieServizi"
                      name="raggruppamentoTipologiaServizioId" #raggruppamentoTipologiaServizioInput = "ngModel"
                      [ngClass]="{'text-disabled': !opzioniFiltroRaggruppamentiTipologie.length}">
                <option [ngValue]="null" selected>Tutti</option>
                <option *ngFor="let item of opzioniFiltroRaggruppamentiTipologie" [ngValue]="item.value">{{item.label}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': !isCampoInvalido(raggruppamentoTipologiaServizioInput), 'text-invalid': isCampoInvalido(raggruppamentoTipologiaServizioInput), 'text-disabled': !listaElementi.length}">
                {{setPlaceholder(raggruppamentoTipologiaServizioInput)}}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Bottoni -->
      <div class="row">
        <div class="col-6">
          <button type="button" id="bottonePulisci" class="btn btn-secondary"
                  [disabled]="disabilitaBottone(filtroRaggruppamentoTipologieForm)"
                  (click)="pulisciFiltri(filtroRaggruppamentoTipologieForm)">Pulisci</button>
        </div>
        <div class="col-6 btn-find">
          <button type="button" id="bottoneCerca" class="btn"
                  (click)="cercaElementi()">Cerca</button>
        </div>
      </div>
    </div>
  </div>
</form>
