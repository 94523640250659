import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DettaglioPendenza} from '../../../../../../../model/transazione/DettaglioPendenza';
import {LivelloIntegrazioneEnum} from '../../../../../../../../../enums/livelloIntegrazione.enum';
import { Utils } from '../../../../../../../../../utility/Utils';
import * as moment from 'moment';
import { TipoCampoEnum } from '../../../../../../../../../enums/TipoCampoEnum';
import { CampoDettaglioTransazione } from '../../../../../../../model/bollettino/CampoDettaglioTransazione';

@Component({
  selector: 'app-dati-pendenza',
  templateUrl: './dati-pendenza.component.html',
  styleUrls: ['./dati-pendenza.component.scss']
})
export class DatiPendenzaComponent implements OnInit, OnChanges {

  @Input() datiPendenza: DettaglioPendenza;

  readonly livelloIntegrazioneEnum = LivelloIntegrazioneEnum;
  readonly tipoCampoEnum = TipoCampoEnum

  id = null;
  codiceFiscalePagatore = null;
  livelloIntegrazioneId = null;
  livelloIntegrazioneNome = null;
  emailVersante = null;
  dataScadenza = null;
  codiceTassonomia = null;
  enteImpositore = null;
  servizioNome = null;
  enteBeneficiario = null;
  numeroDocumento = null;
  annoDocumento = null;
  causale = null;
  importo = null;
  listaCampoDettaglioTransazioni = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(sc: SimpleChanges) {
    if (sc.datiPendenza) {
      this.id = this.datiPendenza.id;
      this.codiceFiscalePagatore = this.datiPendenza.codiceFiscalePagatore;
      this.livelloIntegrazioneId = this.datiPendenza.livelloIntegrazioneId;
      this.livelloIntegrazioneNome = this.datiPendenza.livelloIntegrazioneNome;
      this.emailVersante = this.datiPendenza.emailVersante;
      this.dataScadenza = this.datiPendenza.dataScadenza ? moment(this.datiPendenza.dataScadenza).format(Utils.FORMAT_DATE_CALENDAR) : "";
      this.enteImpositore = this.datiPendenza.enteImpositore;
      this.codiceTassonomia = this.datiPendenza.codiceTassonomia;
      this.servizioNome = this.datiPendenza.servizioNome;
      this.enteBeneficiario = this.datiPendenza.enteBeneficiario;
      this.numeroDocumento = this.datiPendenza.numeroDocumento;
      this.annoDocumento = this.datiPendenza.annoDocumento;
      this.causale = this.datiPendenza.causale;
      this.importo = this.datiPendenza.importo;
      this.listaCampoDettaglioTransazioni = this.getListaCampoDettaglioTransazioni(this.datiPendenza.listaCampoDettaglioTransazioni);
    }
    window.scrollTo(0, 0);
  }

  getListaCampoDettaglioTransazioni(listaCampoDettaglioTransazioni) :CampoDettaglioTransazione[]{
    listaCampoDettaglioTransazioni.map(campo => {
      switch(campo.tipoCampo){
        case this.tipoCampoEnum.DATE:
          campo.valore = moment(campo.valore).format(Utils.FORMAT_DATE_CALENDAR)
      }
    })
    return listaCampoDettaglioTransazioni
  }

  showListaCampoDettaglio(): boolean{
    return this.datiPendenza?.listaCampoDettaglioTransazioni?.length > 0
  }

}
