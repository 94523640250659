export class ParametriRicercaTransazioni {
  societaId: number;
  livelloTerritorialeId: number;
  enteId: number;
  servizioId: number;
  tassonomia: string;
  transazioneId: number;
  versanteIndirizzoIP: string;
  tipologiaServizioId: number;
  canaleId: number;
  iuv: string;
  emailNotifica: string;
  dataTransazioneDa: string;
  dataTransazioneA: string;
  statoTransazione: number;
  importoTransazioneDa: number;
  importoTransazioneA: number;
  pagatore: string;
  flussoQuadratura: string;
  flussoRendicontazione: number;
  numeroPagina: string;
  numeroElementi: string;
  sort: string;
  ascending: string;
  tab: string;
  pagination: boolean;
  livelloIntegrazioneId: number;

  constructor() {
    this.societaId = null;
    this.livelloTerritorialeId = null;
    this.enteId = null;
    this.tipologiaServizioId = null;
    this.servizioId = null;
    this.transazioneId = null;
    this.canaleId = null;
    this.versanteIndirizzoIP = null;
    this.statoTransazione = null;
    this.flussoRendicontazione = null;
    this.livelloIntegrazioneId = null;
  }

}
