import { Optional, SkipSelf } from "@angular/core";
import { ControlContainer, ControlValueAccessor } from "@angular/forms";

export const containerFactory = (container: ControlContainer) => {
  if (!container) {
    throw new Error(
      "Questo componente può essere usato solo come elemento di una reactive form."
    );
  }

  return container;
};

export const parentFormGroupProvider = [
  {
    provide: ControlContainer,
    useFactory: containerFactory,
    deps: [[new SkipSelf(), new Optional(), ControlContainer]],
  },
];

export const NOOP_VALUE_ACCESSOR: ControlValueAccessor = {
  writeValue(): void {},
  registerOnChange(): void {},
  registerOnTouched(): void {}
}