<div class="collapse-header" id="idCollapseFiltri">
    <button data-toggle="collapse" data-target="#contenutoSezioneFiltri" aria-expanded="true" aria-controls="contenutoSezioneFiltri">
      Filtri
    </button>
</div>

<form #filtroForm="ngForm">
    <div id="contenutoSezioneFiltri" class="collapse show" role="tabpanel" aria-labelledby="idCollapseFiltri">
        <div class="collapse-body">
            <div class="row mt-2 mb-0">
                <div class="col-md-4 pb-2">
                    <div class="row">
                      <div class="col">
                        <label for="tipoFlusso" class="title">Tipo flusso di rendicontazione</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <select id="tipoFlusso" name="tipoFlusso"
                                #filtroFlusso = "ngModel"
                                [(ngModel)]="filtro"
                        >
                          <option [ngValue]="null" selected>Tutti</option>
                          <option *ngFor="let item of listaFlussiRendicontazione"
                            [ngValue]="item">{{getNomeFlussoSelect(item["nome"], item["descrizione"])}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p>
                        </p>
                      </div>
                    </div>
          
                  </div>
            </div>
        </div>
    </div>
</form>   

<div class="row" >
    <div class="col-6">
      <button id="bottonePulisci" type="button" class="btn btn-secondary" [disabled]="disabilitaBottonePulisci()"
              (click)="pulisciFiltri(filtroForm)">Pulisci</button>
    </div>
    <div class="col-6 btn-find">
      <button id="bottoneCerca" class="btn"
              (click)="cercaElementi()">Cerca</button>
    </div>
  </div>