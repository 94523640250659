import { Component, HostListener, Input, OnInit } from "@angular/core";
import { PayerTableComponent } from "../table/table.component";

@Component({
  selector: "payer-sort-table-header",
  templateUrl: "./sort-table-header.component.html",
  styleUrls: ["./sort-table-header.component.scss"],
  host: {
    click: "sort",
  },
})
export class SortTableHeaderComponent {
  @Input()
  field: string;

  get mode(): "asc" | "desc" | "unsorted" {
    if (this.table.sortField === this.field) {
      if (this.table.sortDirection == 1) {
        return "asc";
      } else {
        return "desc";
      }
    }

    return "unsorted";
  }

  constructor(private table: PayerTableComponent) {}

  sort(): void {
    this.table.requestSort(this.field);
  }
}
