import {AfterViewInit, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {GestisciElementoComponent} from '../../gestisci-elemento.component';
import {SintesiRendicontazione} from '../../../../model/rendicontazione/SintesiRendicontazione';
import {RicercaRendicontazione} from '../../../../model/rendicontazione/RicercaRendicontazione';
import {ParametriRicercaRendicontazione} from '../../../../model/rendicontazione/ParametriRicercaRendicontazione';
import {ToolEnum} from '../../../../../../enums/Tool.enum';
import {ComunicazioneEnum} from '../../../../../../enums/Comunicazione.enum';
import {Tabella} from '../../../../model/tabella/Tabella';
import {tipoColonna} from '../../../../../../enums/TipoColonna.enum';
import {tipoRiga} from '../../../../../../enums/TipoRiga.enum';
import {tipoTabella} from '../../../../../../enums/TipoTabella.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AmministrativoService} from '../../../../../../services-old/amministrativo.service';
import {MenuService} from '../../../../../../services-old/menu.service';
import {Observable} from 'rxjs';
import {Colonna} from '../../../../model/tabella/Colonna';
import {ImmaginePdf} from '../../../../model/tabella/ImmaginePdf';
import {RendicontazioneService} from '../../../../../../services-old/rendicontazione.service';
import {Utils} from '../../../../../../utility/Utils';
import * as moment from 'moment';
import {Banner} from "../../../../model/banner/Banner";
import {getBannerType, LivelloBanner} from "../../../../../../enums/livelloBanner.enum";
import {BannerService} from "../../../../../../services-old/banner.service";
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-rendicontazione',
  templateUrl: './rendicontazione.component.html',
  styleUrls: ['./rendicontazione.component.scss']
})
export class RendicontazioneComponent extends GestisciElementoComponent implements OnInit, AfterViewInit {

  readonly tooltipTitolo = 'In questa pagina puoi visualizzare la lista completa dei flussi di rendicontazione agli enti che utilizzano Payer e filtrarli';

  breadcrumbList = [];

  elemento: RicercaRendicontazione = new RicercaRendicontazione();
  filtriRicerca: ParametriRicercaRendicontazione = null;

  righeSelezionate: any[];
  aggiornaTabella: boolean = false
  counts = 0
  firstCall = true
  listaEnti
  readonly formatoData = Utils.FORMAT_DATE_CALENDAR;

  toolbarIcons = [
    {type: ToolEnum.EXPORT_PDF, tooltip: 'Stampa Pdf'},
    {type: ToolEnum.EXPORT_XLS, tooltip: 'Download'},
    {type: ToolEnum.VISUALIZE_STATISTICS, tooltip: 'Statistiche'},
    {type: ToolEnum.DOWNLOAD, disabled: true, tooltip: 'Esporta flussi'},
    {type: ToolEnum.SEND_NOTIFICATION_TO_ENTE, disabled: true, tooltip: 'Invia flusso all\'ente'}
  ];

  indiceIconaScaricaFlussi = 3;
  indiceIconaInviaNotifica = 4;

  tableData: Tabella = {
    rows: [],
    cols: [
      {field: 'ente', header: 'Ente', type: tipoColonna.LINK},
      {field: 'servizio', header: 'Servizio', type: tipoColonna.TESTO},
      {field: 'canale', header: 'Canale', type: tipoColonna.TESTO},
      {field: 'tipoFlusso', header: 'Tipo flusso', type: tipoColonna.TESTO},
      {field: 'dataRendiconto', header: 'Data rendiconto', type: tipoColonna.TESTO},
      {field: 'id', header: 'ID flusso rendicontazione', type: tipoColonna.TESTO},
      {field: 'numeroPagamenti', header: 'Numero Pagamenti', type: tipoColonna.TESTO},
      {field: 'importoNetto', header: 'Importo netto', type: tipoColonna.IMPORTO},
      {field: 'statoInvio', header: 'Stato invio', type: tipoColonna.ARRAY},
    ],
    dataKey: 'id.value',
    tipoTabella: tipoTabella.CHECKBOX_SELECTION
  };

  tableDataStatisticheRendicontazioni: Tabella = {
    rows: [],
    cols: [
      {field: 'enteId', header: 'Ente', type: tipoColonna.TESTO},
      {field: 'numeroTransazioni', header: 'Numero di rendicondazioni', type: tipoColonna.TESTO},
      {field: 'importi', header: 'Totale degli importi', type: tipoColonna.TESTO}
    ],
    dataKey: 'enteId.value',
    tipoTabella: tipoTabella.TEMPLATING
  };
  displayModalWithTable: boolean = false;

  isMenuCarico = false;

  constructor(protected router: Router, protected route: ActivatedRoute, protected http: HttpClient,
              protected amministrativoService: AmministrativoService, private renderer: Renderer2,
              private el: ElementRef, private menuService: MenuService,
              private rendicontazioneService: RendicontazioneService,
              private bannerService: BannerService) {
    super(router, route, http, amministrativoService);
  }

  ngOnInit(): void {
    this.waitingEmitter.subscribe(() => {
      if (this.amministrativoService.mappaFunzioni) {
        this.isMenuCarico = Object.keys(this.amministrativoService.mappaFunzioni).length > 0;
      }

      if (this.isMenuCarico) {
        this.inizializzaPagina();
      } else {
        this.menuService.menuCaricatoEvent.subscribe(() => {
          this.inizializzaPagina();
        });
      }
    });
  }

  inizializzaPagina() {
    this.breadcrumbList = this.inizializzaBreadcrumbList([
      {label: 'Rendicontazione', link: null}
    ], true);
    this.inizializzaFiltriRicerca();
    this.waiting = false
    //this.popolaElemento();
  }

  private inizializzaFiltriRicerca(): void {
    if (localStorage.getItem('filtroRicercaRendicontazione')){
      this.filtriRicerca = JSON.parse(localStorage.getItem('filtroRicercaRendicontazione'));
    } else {
      this.filtriRicerca = new ParametriRicercaRendicontazione();
      this.filtriRicerca.societaId = null;
      this.filtriRicerca.livelloTerritorialeId = null;
      this.filtriRicerca.enteId = null;
      this.filtriRicerca.servizioId = null;
      this.filtriRicerca.transazioneId = null;
      this.filtriRicerca.tipologiaServizioId = null;
      this.filtriRicerca.canaleId = null;
      this.filtriRicerca.dataPagamentoDa = null;
      this.filtriRicerca.dataPagamentoA = null;
      this.filtriRicerca.importoTransazioneDa = null;
      this.filtriRicerca.importoTransazioneA = null;
      this.filtriRicerca.flussoRendicontazioneId = null;
      this.filtriRicerca.dataCreazioneRendicontoDa = null;
      this.filtriRicerca.dataCreazioneRendicontoA = null;
      this.filtriRicerca.tipoFlussoId = null;
      this.filtriRicerca.codiceIUV = null;
    }
  }

  popolaElemento(): void {
    this.elemento.sintesiRendicontazioni = [];
    this.elemento.statisticheEnte = [];
    this.tableData.rows = [];
    this.getObservableFunzioneRicerca().subscribe(elemento => {
      if (elemento != null) {
        this.elemento.sintesiRendicontazioni = elemento.sintesiRendicontazioni;
        this.elemento.statisticheEnte = elemento.statisticheEnte;
        this.impostaTabella(this.elemento.sintesiRendicontazioni);
        this.callbackPopolaLista();
      }
      this.waiting = false;
    });
  }

  creaTabellaStatisticheRendicontazione(): void {
    this.tableDataStatisticheRendicontazioni.rows = [];
    if (this.elemento.sintesiRendicontazioni && this.listaEnti) {
      this.elemento.sintesiRendicontazioni.forEach(statisticaEnte => {
        this.tableDataStatisticheRendicontazioni.rows.push(this.creaRigaTabellaStatisticheRendicontazione(statisticaEnte));
      });
    }
  }

  creaRigaTabellaStatisticheRendicontazione(statisticaEnte) {
    return {
      enteId: {value: this.listaEnti.filter(rendicontazione => rendicontazione.value === statisticaEnte.enteId)[0].label},
      numeroTransazioni: {value: statisticaEnte.numeroTransazioni},
      importi: {value: statisticaEnte.importi}
    };
  }

  ngAfterViewInit(): void {
    if (!this.waiting) {
      this.renderer.addClass(this.el.nativeElement.querySelector('#breadcrumb-item-1 > li'), 'active');
    }
  }

  creaRigaTabella(rendicontazione: SintesiRendicontazione) {
    const iconaGruppoUtenti = 'assets/img/users-solid.svg#users-group';
    const linkGestisciEnti = '/gestisciEnti?enteId=' + rendicontazione.enteId;

    let statoInvio = [];
    if (rendicontazione.statoInvioEmail === true) {
      const pathIconaEmail = '#it-mail';
      const coloreIconaEmail = '#008758';
      const tooltipIconaEmail = 'Invio effettuato via email';
      const placementIconaEmail = 'top';
      const displayIconaEmail = 'inline';
      const icon =  Utils.creaIcona(null, pathIconaEmail, coloreIconaEmail, tooltipIconaEmail, displayIconaEmail, placementIconaEmail);
      statoInvio.push({type: tipoRiga.ICONA, ...icon});
    } else if (rendicontazione.statoInvioEmail === false) {
      statoInvio.push({type: tipoRiga.TESTO, value: 'Invio mail KO'});
    }

    if (rendicontazione.statoInvioFtp === true) {
      const pathIconaFtp = '#it-mail';
      const coloreIconaFtp = '#D9364F';
      const tooltipIconaFtp = 'Invio effettuato via FTP';
      const placementIconaFtp = 'top';
      const displayIconaFtp = 'inline';
      const icon =  Utils.creaIcona(null, pathIconaFtp, coloreIconaFtp, tooltipIconaFtp, displayIconaFtp, placementIconaFtp);
      statoInvio.push({type: tipoRiga.ICONA, ...icon});
    } else if (rendicontazione.statoInvioFtp === false) {
      statoInvio.push({type: tipoRiga.TESTO, value: 'Invio via FTP KO'});
    }

    return {
      ente: Utils.creaLink(rendicontazione.enteNome, linkGestisciEnti, iconaGruppoUtenti),
      servizio: {value: rendicontazione.servizioNome},
      canale: {value: rendicontazione.canale},
      tipoFlusso: {value: rendicontazione.tipoFlusso},
      dataRendiconto: {value: rendicontazione.dataRendiconto
          ? moment(rendicontazione.dataRendiconto).format(Utils.FORMAT_DATE_CALENDAR)
          : null},
      id: {value: rendicontazione.flussoRendicontazioneId},
      numeroPagamenti: {value: rendicontazione.numeroPagamenti},
      importoNetto: {value: rendicontazione.importoNetto},
      statoInvio: statoInvio,
      invioNotifica: {value: rendicontazione.invioNotifica}
    };
  }

  getObservableFunzioneRicerca(): Observable<RicercaRendicontazione> {
    return this.rendicontazioneService.ricercaRendicontazioni(this.filtriRicerca, this.idFunzione);
  }

  popolaListaElementiPaginata = async(pagination) => {
    let page = pagination.rows ? pagination.rows : this.counts;
    this.filtriRicerca.numeroPagina = pagination.first ? String(pagination.first / page) : "0"
    this.filtriRicerca.numeroElementi = page
    if(this.firstCall == true){
      this.firstCall = false
      this.filtriRicerca.sort =  "dataRendiconto"
      this.filtriRicerca.ascending = "false"
    }else{
      pagination.sortField ? this.filtriRicerca.sort =  pagination.sortField : ""
      pagination.ascending ? this.filtriRicerca.ascending = String(pagination.ascending == 1) : ""
    }
    pagination.pagination != undefined ? this.filtriRicerca.pagination = pagination.pagination : true
    this.filtriRicerca.dataCreazioneRendicontoDa ? "" : this.filtriRicerca.dataCreazioneRendicontoDa = String(moment(moment().subtract(1, 'day'), Utils.FORMAT_DATE_CALENDAR).format(Utils.FORMAT_LOCAL_DATE_TIME));
    return await this.getObservableFunzioneRicerca()
  }

  getEntiList(listaEnti){
    this.listaEnti = listaEnti
    this.creaTabellaStatisticheRendicontazione()
  }

  callbackPopolaLista() {}

  eseguiAzioni(azioneTool) {
    switch (azioneTool) {
      case ToolEnum.EXPORT_PDF:
        this.getTableDataPdflDownload()
        break;
      case ToolEnum.EXPORT_XLS:
        this.getTableDataExcelDownload();
        break;
      case ToolEnum.VISUALIZE_STATISTICS:
        this.displayModalWithTable = !this.displayModalWithTable;
        break;
      case ToolEnum.DOWNLOAD:
        this.esportaFlussiInZipFile(this.getListaIdElementiSelezionati());
        break;
      case ToolEnum.SEND_NOTIFICATION_TO_ENTE:
        this.inviaNotificaAEnte(this.getListaIdElementiSelezionati()[0]);
        break;
    }
  }

  async getTableDataExcelDownload() {
    let table: Tabella = {
      cols: this.tableData.cols,
      rows: [],
      dataKey: this.tableData.dataKey,
      tipoTabella: this.tableData.tipoTabella
    }
    let pagination = {pagination: false}
    await this.popolaListaElementiPaginata(pagination).then(dataFunction=>{
      dataFunction.subscribe(data=>{
        table.rows = []
        data.sintesiRendicontazioni["elementi"].forEach(elemento => {
          table.rows.push(this.creaRigaTabella(elemento));
        });
        this.esportaTabellaInFileExcelFlussi(table, 'Lista flussi di rendicontazione');
      })
    })
  }

  async getTableDataPdflDownload() {
    let table: Tabella = {
      cols: this.tableData.cols,
      rows: [],
      dataKey: this.tableData.dataKey,
      tipoTabella: this.tableData.tipoTabella
    }
    let pagination = {pagination: false}
    await this.popolaListaElementiPaginata(pagination).then(dataFunction=>{
      dataFunction.subscribe(data=>{
        table.rows = []
        data.sintesiRendicontazioni["elementi"].forEach(elemento => {
          table.rows.push(this.creaRigaTabella(elemento));
        });
        this.esportaTabellaInFilePdf(table, 'Lista flussi di rendicontazione');
      })
    })
  }

  esportaTabellaInFileExcelFlussi(tableData, title){
    let table = JSON.parse(JSON.stringify(tableData))
    var wb = XLSX.utils.book_new();
    wb.Props = {
      Title: title,
  };
  wb.SheetNames.push(title.substring(0, 30));
  var worksheet  = XLSX.utils.json_to_sheet(this.getRigheFileExcel(table.rows));
  worksheet = XLSX.utils.sheet_add_aoa(worksheet, [this.getColonneFileExcel(table.cols).map(col => col.header)]);
  wb.Sheets[title.substring(0, 30)] = worksheet;
  var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
  saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), title+ '_export_' + moment().format('DD-MM-YYYY HH:mm')+'.xlsx');
  }

  s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
}

  getColonneFilePdf(colonne: Colonna[]): Colonna[] {
    return colonne;
  }

  getRigheFilePdf(righe: any[]) {
    return righe;
  }

  getImmaginiFilePdf(righe: any[]): any[] {
    const iconaGruppoUtenti = new ImmaginePdf();
    iconaGruppoUtenti.indiceColonna = 0;
    iconaGruppoUtenti.srcIcona = 'assets/img/users-solid-pdf-img.png';
    iconaGruppoUtenti.posizioneX = 60;
    iconaGruppoUtenti.posizioneY = 8;
    iconaGruppoUtenti.larghezza = 18;
    iconaGruppoUtenti.altezza = 15;

    const iconaStatoInvioRendicontazione = [];
    righe.forEach((riga, index) => {
      if (riga.statoInvio.tooltip) {
        const iconaStato = new ImmaginePdf();
        iconaStato.indiceColonna = 8;
        iconaStato.indiceRiga = index;
        if (riga.statoInvio.tooltip.includes('email')) {
          iconaStato.srcIcona = 'assets/img/rendicontazione-stato-invio-via-mail.png';
        } else if (riga.statoInvio.tooltip.includes('FTP')) {
          iconaStato.srcIcona = 'assets/img/rendicontazione-stato-invio-via-FTP.png';
        }
        iconaStato.posizioneX = 3;
        iconaStato.posizioneY = 1;
        iconaStato.larghezza = 18;
        iconaStato.altezza = 19;
        iconaStatoInvioRendicontazione.push(iconaStato);
      }
    });

    return [iconaGruppoUtenti, iconaStatoInvioRendicontazione];
  }

  getColonneFileExcel(colonne: Colonna[]): Colonna[] {
    return colonne;
  }

  getRigheFileExcel(righe: any[]) {
    return righe.map(riga => {
      const rigaFormattata = riga;
      rigaFormattata.ente = riga.ente.value;
      rigaFormattata.servizio = riga.servizio.value;
      rigaFormattata.canale = riga.canale.value;
      rigaFormattata.tipoFlusso = riga.tipoFlusso.value;
      rigaFormattata.dataRendiconto = riga.dataRendiconto.value;
      rigaFormattata.id = riga.id.value;
      rigaFormattata.numeroPagamenti = riga.numeroPagamenti.value;
      rigaFormattata.importoNetto = riga.importoNetto.value;
      if (riga.statoInvio.tooltip) {
        if (riga.statoInvio.tooltip.includes('email')) {
          rigaFormattata.statoInvio = 'Invio effettuato via email';
        } else if (riga.statoInvio.tooltip.includes('FTP')) {
          rigaFormattata.statoInvio = 'Invio effettuato via FTP';
        } else {
          rigaFormattata.statoInvio = null;
        }
      } else {
        rigaFormattata.statoInvio = null;
      }
      return rigaFormattata;
    });
  }

  getCounts(counts){
    this.counts = counts
  }

  getStatisticheRendicontazioni(statistiche){
    this.elemento.sintesiRendicontazioni = statistiche
    this.creaTabellaStatisticheRendicontazione()
  }

  getNumeroRecord(): string {
    return "Totale: " + this.counts + "  rendicontazioni";
  }

  selezionaRigaTabella(righeSelezionate: any[]): void {
    this.righeSelezionate = righeSelezionate;
    this.toolbarIcons[this.indiceIconaScaricaFlussi].disabled = righeSelezionate.length === 0;
    this.toolbarIcons[this.indiceIconaInviaNotifica].disabled = righeSelezionate.length !== 1 ||
      righeSelezionate[0].invioNotifica.value === false;
  }

  mostraDettaglioRendicontazione(rigaCliccata: any) {
    this.mostraDettaglioElemento('/dettaglioRendicontazione', rigaCliccata.id.value);
  }

  onChangeFiltriRendicontazione(filtri: ParametriRicercaRendicontazione): void {
    this.filtriRicerca = filtri;
    this.aggiornaTabella = !this.aggiornaTabella
  }

  esportaFlussiInZipFile(listaRendicontazioneId: Array<number>): void {
    this.rendicontazioneService.downloadFlussi(listaRendicontazioneId, this.idFunzione).subscribe(response => {

      if (!(response instanceof HttpErrorResponse)) {
        let flussiNonRecuperati = response.filter((flusso) => flusso.file == null);
        let flussi = response.filter((flusso) => flusso.file != null);
        flussi.forEach((flusso, index) => {
          if (flusso) {
              Utils.downloadFile(flusso.file, flusso.nome);
          }
        });
        if (flussiNonRecuperati?.length) {
          let nomiFlussi = flussiNonRecuperati.map(f => f.nome).join(', ');
          const banner: Banner = {
            titolo: 'ATTENZIONE',
            testo: 'Non è stato possibile recuperare i seguenti flussi di rendicontazione: ' + nomiFlussi,
            tipo: getBannerType(LivelloBanner.WARNING)
          };
          this.bannerService.bannerEvent.emit([banner]);
        }
      }
    });
  }

  inviaNotificaAEnte(rendicontazioneId: number): void {
    this.rendicontazioneService.inviaNotificaEnte(rendicontazioneId, this.idFunzione).subscribe((response) => {
      if (response === ComunicazioneEnum.OK) {
        const banner: Banner = {
          titolo: 'SUCCESSO',
          testo: 'Invio flusso all\'ente eseguito',
          tipo: getBannerType(LivelloBanner.SUCCESS)
        };
        this.bannerService.bannerEvent.emit([banner]);
      } else if (response === ComunicazioneEnum.KO) {
        const banner: Banner = {
          titolo: 'ATTENZIONE',
          testo: 'Invio flusso all\'ente non è stato eseguito correttamente',
          tipo: getBannerType(LivelloBanner.WARNING)
        };
        this.bannerService.bannerEvent.emit([banner]);
      }
      this.aggiornaTabella = !this.aggiornaTabella;
    }, error => {
      if (error) {
        const banner: Banner = {
          titolo: 'ATTENZIONE',
          testo: 'Invio flusso all\'ente non è stato eseguito correttamente',
          tipo: getBannerType(LivelloBanner.WARNING)
        };
        this.bannerService.bannerEvent.emit([banner]);
      }
      this.aggiornaTabella = !this.aggiornaTabella;
    });
  }
}
