import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormFeedbackComponent, PayerFormDisabledDirective, PayerFormErrorDirective, PayerFormHintDirective } from "./form-feedback/form-feedback.component";
import { FormFieldComponent } from "./form-field/form-field.component";
import {  DpDatePickerModule } from "ng2-date-picker";
import { DatePickerComponent } from "./date-picker/date-picker.component";

@NgModule({
  declarations: [
    FormFieldComponent,
    FormFeedbackComponent,
    PayerFormErrorDirective,
    PayerFormHintDirective,
    PayerFormDisabledDirective,
    DatePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule
  ],
  exports: [
    FormFieldComponent,
    FormFeedbackComponent,
    PayerFormErrorDirective,
    PayerFormHintDirective,
    PayerFormDisabledDirective,
    DatePickerComponent
  ]
})
export class PayerFormsModule {

}
