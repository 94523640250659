import {
  CdkDragDrop,
  CdkDropList,
  CdkDropListGroup,
} from "@angular/cdk/drag-drop";
import { ViewportRuler } from "@angular/cdk/overlay";
import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService } from "primeng/api";
import { Observable, of } from "rxjs";
import { flatMap, map } from "rxjs/operators";
import { CampoTipoRendicontazione } from "src/app/modules/main/model/flussiRenditcontazione/CampoTipoRendicontazione";
import { Utils } from "src/app/utility/Utils";
import { ModalitaPaginaGestioneElemento } from "src/app/utility/enums/modalita-pagina-gestione-elemento";
import { v4 as uuidv4 } from "uuid";
import { LivelloIntegrazioneEnum } from "../../../../../../enums/livelloIntegrazione.enum";
import { TipoCampoEnum } from "../../../../../../enums/tipoCampo.enum";
import { TipoModaleEnum } from "../../../../../../enums/tipoModale.enum";
import { AmministrativoService } from "../../../../../../services-old/amministrativo.service";
import { BannerService } from "../../../../../../services-old/banner.service";
import { CampoTipologiaServizioService } from "../../../../../../services-old/campo-tipologia-servizio.service";
import { FlussoRendicontazioneService } from "../../../../../../services-old/flusso-rendicontazione.service";
import { OverlayService } from "../../../../../../services-old/overlay.service";
import { RoutingService } from "../../../../../../services-old/routing.service";
import { Breadcrumb, SintesiBreadcrumb } from "../../../../dto/Breadcrumb";
import { CampoTipologiaServizio } from "../../../../model/CampoTipologiaServizio";
import { ConfiguratoreCampiNuovoPagamento } from "../../../../model/campo/ConfiguratoreCampiNuovoPagamento";
import { InserimentoTipologiaServizio } from "../../../../model/campo/InserimentoTipologiaServizio";
import { ModificaTipologiaServizio } from "../../../../model/campo/ModificaTipologiaServizio";
import { FlussoRendicontazione } from "../../../../model/flussiRenditcontazione/FlussoRendicontazione";
import { ParametriRicercaTipologiaServizio } from "../../../../model/tipologiaServizio/ParametriRicercaTipologiaServizio";
import { FormElementoParentComponent } from "../../form-elemento-parent.component";
import { aggiungiTipoCampoEvent } from "../modale-campo-form/modale-aggiungi-tipo-campo/modale-aggiungi-tipo-campo.component";
import { aggiornaTipoCampoEvent } from "../modale-campo-form/modale-campo-form.component";

@Component({
  selector: "app-form-tipologia-servizio",
  templateUrl: "./form-tipologia-servizio.component.html",
  styleUrls: ["./form-tipologia-servizio.component.scss"],
})
export class FormTipologiaServizioComponent
  extends FormElementoParentComponent
  implements OnInit, OnDestroy
{
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  waiting = false;

  funzione: ModalitaPaginaGestioneElemento;

  FunzioneGestioneEnum = ModalitaPaginaGestioneElemento;

  titoloPagina: string;
  tooltip: string;

  breadcrumbList: Breadcrumb[] = [];

  readonly lunghezzaMaxCol1: number = 5;
  readonly lunghezzaMaxCol2: number = 10;
  readonly lunghezzaMaxCol3: number = 15;

  showEditId: string | number;
  filtro: ParametriRicercaTipologiaServizio;
  tipologiaServizioId: number;
  private livelloIntegrazione: LivelloIntegrazioneEnum;
  private listaDipendeDa: CampoTipologiaServizio[];
  private refreshItemsEvent: EventEmitter<any> = new EventEmitter<any>();
  private tipoCampoIdSelect: number;
  listaFlussiRendicontazione: FlussoRendicontazione[] = [];
  listaCampoTipoRendicontazione: CampoTipoRendicontazione[];
  listaCampoTipoRendicontazioneUsed: CampoTipoRendicontazione[];

  codiceTipologia: string;
  nomeTipologia: string;
  // tipoFlusso: FlussoRendicontazione;
  // tipoFlussoOriginal: FlussoRendicontazione;
  serviziRendicontati: boolean = false;
  private listaTipiCampo: any[];

  private isSingleClick = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private overlayService: OverlayService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected http: HttpClient,
    protected amministrativoService: AmministrativoService,
    private viewportRuler: ViewportRuler,
    protected confirmationService: ConfirmationService,
    public campoTipologiaServizioService: CampoTipologiaServizioService,
    private FlussoRendicontazioneService: FlussoRendicontazioneService,
    private bannerService: BannerService,
    private routingService: RoutingService
  ) {
    super(
      confirmationService,
      activatedRoute,
      amministrativoService,
      http,
      router
    );
    this.target = null;
    this.source = null;
  }

  ngOnDestroy(): void {
    this.campoTipologiaServizioService.items = [];
    if (this.amministrativoService.salvaCampoFormEvent.observers)
      this.amministrativoService.salvaCampoFormEvent.unsubscribe();
  }

  ngOnInit() {
    this.amministrativoService.salvaCampoFormEvent = new EventEmitter<any>();
    this.amministrativoService.salvaCampoFormEvent.subscribe(
      (campoForm: CampoTipologiaServizio) => {
        let campoFormIdx = this.campoTipologiaServizioService.items.findIndex(
          (value: CampoTipologiaServizio) =>
            value.uuid && campoForm.uuid && value.uuid == campoForm.uuid
        );
        campoForm.campoInput = true;
        if (campoFormIdx != -1) {
          this.campoTipologiaServizioService.items[campoFormIdx] = campoForm;
        } else {
          campoForm.uuid = uuidv4();
          this.campoTipologiaServizioService.items.push(campoForm);
          this.cdr.detectChanges();
        }
        // this.removeUsedCampiRendicontazione()
        this.overlayService.mostraModaleCampoEvent.emit(null);
      }
    );
    this.refreshItemsEvent.subscribe((items) => {
      this.listaDipendeDa = items.filter(
        (value) => value.tipoCampoId === this.tipoCampoIdSelect
      );
    });
    aggiungiTipoCampoEvent.subscribe((idTipoCampo) => {
      this.impostaConfigurazioneCampi(of(null), idTipoCampo);
    });
    localStorage.setItem("listaCampoTipoRendicontazione", null);
  }

  controllaTipoFunzione() {
    const url = this.activatedRoute.snapshot.url[1].path;
    switch (url) {
      case "dettaglioTipologia":
        this.funzione = ModalitaPaginaGestioneElemento.DETTAGLIO;
        break;
      case "aggiungiTipologia":
        this.funzione = ModalitaPaginaGestioneElemento.AGGIUNGI;
        break;
      case "modificaTipologia":
        this.funzione = ModalitaPaginaGestioneElemento.MODIFICA;
        break;
    }
  }

  initFormPage(snapshot: ActivatedRouteSnapshot) {
    this.controllaTipoFunzione();
    this.inizializzaBreadcrumb();
    const observableListaFlussi =
      this.FlussoRendicontazioneService.recuperaFlussiRendicontazione(
        this.idFunzione
      );
    this.titoloPagina =
      this.getTestoFunzione(this.funzione) + " Tipologia Servizio";
    this.tooltip =
      "In questa pagina puoi " +
      this.getTestoFunzione(this.funzione, false) +
      " i dettagli di una tipologia servizio";

    let obs = of(null);
    if (
      this.funzione === ModalitaPaginaGestioneElemento.MODIFICA ||
      this.funzione === ModalitaPaginaGestioneElemento.DETTAGLIO
    ) {
      this.tipologiaServizioId = parseInt(
        this.activatedRoute.snapshot.paramMap.get("tipologiaServizioId")
      );
      this.impostaDettaglioTipologia(observableListaFlussi);

      obs = this.caricaCampi(this.tipologiaServizioId);
    }

    this.impostaConfigurazioneCampi(obs);
  }

  impostaDettaglioTipologia(observableListaFlussi): void {
    observableListaFlussi.subscribe((listaFlussiRendicontazione) => {
      this.listaFlussiRendicontazione = listaFlussiRendicontazione;
      this.campoTipologiaServizioService
        .recuperaDettaglioTipologiaServizio(
          this.tipologiaServizioId,
          this.idFunzione
        )
        .subscribe((tipologiaServizio) => {
          this.filtro = new ParametriRicercaTipologiaServizio();
          this.filtro.raggruppamentoId = tipologiaServizio.raggruppamentoId;
          this.codiceTipologia = tipologiaServizio.codice;
          this.nomeTipologia = tipologiaServizio.descrizione;
          // this.tipoFlusso = this.listaFlussiRendicontazione?.find(x => x.id == tipologiaServizio.tipoFlussoRendicontazione.id)
          // this.tipoFlussoOriginal = this.tipoFlusso
          this.serviziRendicontati = tipologiaServizio.serviziRendicontati;
          // this.recuperaTipoFlusso();
        });
    });
  }

  impostaConfigurazioneCampi(
    observableIniziale = of(null),
    idTipoCampo: number = null
  ): void {
    let observable: Observable<number> = this.campoTipologiaServizioService
      .letturaConfigurazioneCampiNuovoPagamento(this.idFunzione)
      .pipe(
        map((configuratore: ConfiguratoreCampiNuovoPagamento) => {
          localStorage.setItem(
            "listaCampiDettaglioTransazione",
            JSON.stringify(configuratore.listaCampiDettaglioTransazione)
          );
          localStorage.setItem(
            "listaControlliLogici",
            JSON.stringify(configuratore.listaControlliLogici)
          );
          localStorage.setItem(
            "listaTipologiche",
            JSON.stringify(configuratore.listaTipologiche)
          );
          localStorage.setItem(
            "listaJsonPath",
            JSON.stringify(configuratore.listaJsonPath)
          );

          this.listaTipiCampo = configuratore.listaTipiCampo;

          let filter = configuratore.listaTipiCampo.filter(
            (tc) => tc.nome === TipoCampoEnum.SELECT
          );
          if (filter && filter.length > 0)
            this.tipoCampoIdSelect = filter[0].id;

          localStorage.setItem(
            "listaTipiCampo",
            JSON.stringify(configuratore.listaTipiCampo)
          );

          if (idTipoCampo) {
            aggiornaTipoCampoEvent.emit(idTipoCampo);
          }
        })
      )
      .pipe(flatMap(() => observableIniziale));
    observable.subscribe();
  }

  caricaCampi(tipologiaServizioId: number): Observable<any> {
    this.waiting = true;
    return this.campoTipologiaServizioService
      .campiTipologiaServizio(tipologiaServizioId, this.idFunzione)
      .pipe(
        map((value) => {
          this.campoTipologiaServizioService.items = _.sortBy(
            value,
            "posizione"
          );

          // this.removeUsedCampiRendicontazione()
          // // Nel caso della funzione Aggiungi, i campi vengono copiati da un'altra tipologia servizio, ma andranno ricreati sul db come nuove entità
          // if (this.funzione === ModalitaPaginaGestioneElemento.AGGIUNGI) {
          //   this.campoTipologiaServizioService.items.forEach(campo => {
          //     campo.id = null;
          //     if (campo.dipendeDa)
          //       campo.dipendeDa.id = null;
          //   });
          // }

          if (this.funzione !== ModalitaPaginaGestioneElemento.DETTAGLIO) {
            this.campoTipologiaServizioService.items.forEach((campo) => {
              campo.uuid = uuidv4();
            });
          }

          this.refreshItemsEvent.emit(this.campoTipologiaServizioService.items);
          this.waiting = false;
        })
      );
  }

  caricaCodiciFlussoRendicontazione(): void {
    this.FlussoRendicontazioneService.recuperaFlussiRendicontazione(
      this.idFunzione
    ).subscribe((listaFlussiRendicontazione) => {
      this.listaFlussiRendicontazione = listaFlussiRendicontazione;
    });
  }

  // recuperaTipoFlusso(): void{
  //   if(this.funzione === ModalitaPaginaGestioneElemento.MODIFICA && this.tipoFlussoOriginal != this.tipoFlusso)
  //   this.confirmationService.confirm(
  //     Utils.getModale(() => {
  //        this.setTipoFlusso();
  //        this.eliminaCampiTipoRendicontazioneAssociati()
  //       },
  //       TipoModaleEnum.CUSTOM,
  //         'Conferma modifiche',
  //         'La modifica del tipo flusso di rendicontazione elimina tutte le associazioni tra i campi tipologia servizio e i campi tipo rendicontazione.    '+
  //         'Le associazioni dopo il salvataggio non potranno più essere ripristinate.    ' +
  //         'Si vuole procedere comunque?',
  //         ()=>{
  //           this.tipoFlusso = this.listaFlussiRendicontazione?.find(x => x.id==this.tipoFlussoOriginal.id)
  //         }
  //     )
  //   );
  //   else
  //     this.setTipoFlusso()
  // }

  // setTipoFlusso(): void{
  //   if(this.tipoFlusso == null)
  //     localStorage.setItem('listaCampoTipoRendicontazione', null)
  //   else
  //     this.FlussoRendicontazioneService.recuperaCampoTipoRendicontazione(this.tipoFlusso?.id?.toString(), this.idFunzione).subscribe(listaCampoTipoRendicontazione=>{
  //       this.listaCampoTipoRendicontazione = listaCampoTipoRendicontazione
  //       localStorage.setItem('listaCampoTipoRendicontazione', JSON.stringify(listaCampoTipoRendicontazione));
  //       this.listaCampoTipoRendicontazioneUsed = listaCampoTipoRendicontazione
  //     })
  //     this.tipoFlussoOriginal = this.tipoFlusso
  // }

  // eliminaCampiTipoRendicontazioneAssociati(): void{
  //   this.campoTipologiaServizioService.items.map(campo => {
  //     campo.listaCampoTipoRendicontazioneId = []
  //   })
  // }

  inizializzaBreadcrumb(): void {
    const breadcrumbs: SintesiBreadcrumb[] = [];
    breadcrumbs.push(new SintesiBreadcrumb("Gestisci Anagrafiche", null));
    breadcrumbs.push(
      new SintesiBreadcrumb("Gestisci Tipologie Servizio", this.basePath)
    );
    breadcrumbs.push(
      new SintesiBreadcrumb(
        this.getTestoFunzione(this.funzione) + " Tipologie Servizio",
        null
      )
    );
    this.breadcrumbList = this.inizializzaBreadcrumbList(breadcrumbs);
  }

  onClickSalva(): void {
    // if(this.tipoFlusso?.id){
    //   let idSelezionati = []
    //   let totaleId = []
    //   this.listaCampoTipoRendicontazione.forEach(campo => {
    //     totaleId.push(campo.id)
    //   })
    //   this.campoTipologiaServizioService.items.forEach(item => {
    //     item.listaCampoTipoRendicontazioneId.forEach(campo => {
    //       idSelezionati.includes(campo) ? "" : idSelezionati.push(campo)
    //     })
    //   })
    //   if(idSelezionati.length != totaleId.length){
    //     const banner: Banner = {
    //       titolo: 'ATTENZIONE',
    //       testo: 'E’ obbligatorio associare tutti i nomi della lista Campo tipo rendicontazione ai Campi di tipologia servizio creati',
    //       tipo: getBannerType(LivelloBanner.ERROR)
    //     };
    //     this.bannerService.bannerEvent.emit([banner]);
    //     return
    //   }
    // }
    if (this.funzione === ModalitaPaginaGestioneElemento.AGGIUNGI) {
      this.campoTipologiaServizioService.items.forEach(
        (value, index) => (value.posizione = index + 1)
      );

      let inserimento: InserimentoTipologiaServizio =
        new InserimentoTipologiaServizio();
      inserimento.raggruppamentoId = this.filtro.raggruppamentoId;
      inserimento.codice = this.codiceTipologia;
      inserimento.descrizione = this.nomeTipologia;
      // inserimento.tipoFlussoRendicontazioneId = this.tipoFlusso?.id;
      inserimento.listaCampiTipologiaServizio =
        this.campoTipologiaServizioService.items;
      this.campoTipologiaServizioService
        .inserimentoTipologiaServizio(inserimento, this.idFunzione)
        .subscribe((id) => {
          if (id) {
            this.routingService.configuraRouterAndNavigate(
              this.basePath + "/aggiungiTipologia"
            );
          }
        });
    } else if (this.funzione === ModalitaPaginaGestioneElemento.MODIFICA) {
      this.campoTipologiaServizioService.items.forEach(
        (value, index) => (value.posizione = index + 1)
      );

      let modificaTipologiaServizio: ModificaTipologiaServizio =
        new ModificaTipologiaServizio();
      modificaTipologiaServizio.raggruppamentoId = this.filtro.raggruppamentoId;
      modificaTipologiaServizio.descrizione = this.nomeTipologia;
      // modificaTipologiaServizio.tipoFlussoRendicontazioneId = this.tipoFlusso?.id;
      modificaTipologiaServizio.listaCampiTipologiaServizio =
        this.campoTipologiaServizioService.items;
      this.campoTipologiaServizioService
        .modificaTipologiaServizio(
          this.tipologiaServizioId,
          modificaTipologiaServizio,
          this.idFunzione
        )
        .subscribe((resp) => {
          if (!resp) {
            const observableListaFlussi =
              this.FlussoRendicontazioneService.recuperaFlussiRendicontazione(
                this.idFunzione
              );
            this.impostaDettaglioTipologia(observableListaFlussi);
          }
          let obs = this.caricaCampi(this.tipologiaServizioId);
          this.impostaConfigurazioneCampi(obs);
        });
    }
  }

  abilitaSalva(): boolean {
    return (
      this.codiceTipologia &&
      this.codiceTipologia != "" &&
      this.nomeTipologia &&
      this.nomeTipologia != ""
    );
  }

  onChangeFiltri(filtri: ParametriRicercaTipologiaServizio) {
    this.filtro = filtri;

    if (this.filtro.tipologia?.codice) {
      this.campoTipologiaServizioService
        .recuperaTipologieServizio(this.filtro, this.idFunzione)
        .subscribe((listaTipologie) => {
          // Con una ricerca di raggruppamento+codice, arriva una sola tipologia, che sarà usata per precaricarne e copiarne i campi
          if (listaTipologie && listaTipologie.length) {
            this.caricaCampi(listaTipologie[0].id).subscribe();
          }
        });
    }
  }

  add() {
    const campoForm = new CampoTipologiaServizio();
    this.refreshItemsEvent.emit(this.campoTipologiaServizioService.items);
    this.showModal(campoForm);
  }

  removeItem(item: CampoTipologiaServizio) {
    if (this.funzione != ModalitaPaginaGestioneElemento.DETTAGLIO) {
      this.confirmationService.confirm(
        Utils.getModale(() => {
          this.campoTipologiaServizioService.items.splice(
            this.campoTipologiaServizioService.items.findIndex(
              (v) => v.id === item.id
            ),
            1
          );
          this.refreshItemsEvent.emit(this.campoTipologiaServizioService.items);
          // this.removeUsedCampiRendicontazione()
        }, TipoModaleEnum.ELIMINA)
      );
    }
  }

  calcolaDimensioneCampo(campo: CampoTipologiaServizio): string {
    let classe;

    if (
      this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.DATEDDMMYY ||
      this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.DATEMMYY ||
      this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.DATEYY
    ) {
      classe = "col-lg-2 col-md-4 col-xs-6";
    } else if (
      this.decodeTipoCampo(campo.tipoCampoId) === TipoCampoEnum.INPUT_PREZZO
    ) {
      classe = "col-lg-2 col-md-4 col-xs-6";
    } else {
      if (
        campo.lunghezza <= this.lunghezzaMaxCol1 ||
        campo.lunghezza == undefined
      ) {
        classe = "col-lg-2 col-md-4 col-xs-6";
      } else if (campo.lunghezza <= this.lunghezzaMaxCol2) {
        classe = "col-lg-3 col-md-4 col-xs-6";
      } else if (campo.lunghezza <= this.lunghezzaMaxCol3) {
        classe = "col-lg-4 col-md-5 col-xs-6";
      } else {
        classe = "col-lg-5 col-md-6 col-xs-6";
      }
    }
    return classe;
  }

  private decodeTipoCampo(tipoCampoId: number): string {
    let find = this.listaTipiCampo.find((value) => (value.id = tipoCampoId));
    if (find) return find.nome;
    else return "";
  }

  drop(
    event: CdkDragDrop<{ item: CampoTipologiaServizio; index: number }, any>
  ) {
    let arr = _.cloneDeep(this.campoTipologiaServizioService.items);

    arr.splice(event.previousContainer.data.index, 1);
    arr.splice(
      event.container.data.index,
      0,
      event.previousContainer.data.item
    );

    this.campoTipologiaServizioService.items = arr;
  }

  showModal(item: CampoTipologiaServizio) {
    this.overlayService.mostraModaleCampoEvent.emit({
      campoForm: _.cloneDeep(item),
      funzione: this.funzione,
      idFunzione: this.idFunzione,
      livelloIntegrazione: this.livelloIntegrazione,
      mostraLivelloIntegrazione: false,
      listaDipendeDa: this.listaDipendeDa,
      listaCampoTipoRendicontazione: this.listaCampoTipoRendicontazioneUsed,
      listaCampiDettaglioInUso: this.listaCampiTransazioneInUso(item),
    });
  }

  showModalAtClick(item: CampoTipologiaServizio) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.overlayService.mostraModaleCampoEvent.emit({
          campoForm: _.cloneDeep(item),
          funzione: this.funzione,
          idFunzione: this.idFunzione,
          livelloIntegrazione: this.livelloIntegrazione,
          mostraLivelloIntegrazione: false,
          listaDipendeDa: this.listaDipendeDa,
          listaCampoTipoRendicontazione: this.listaCampoTipoRendicontazioneUsed,
          listaCampiDettaglioInUso: this.listaCampiTransazioneInUso(item),
        });
      }
    }, 250);
  }

  private listaCampiTransazioneInUso(item: CampoTipologiaServizio): string[] {
    return this.campoTipologiaServizioService.items
      .filter(
        (campo) =>
          !!campo.campoDettaglioTransazione &&
          campo.campoDettaglioTransazione !== item.campoDettaglioTransazione
      )
      .map((campo) => campo.campoDettaglioTransazione);
  }

  dblClick(item: CampoTipologiaServizio, index: number) {
    this.isSingleClick = false;
    this.showEditId = index;
  }

  isPaginaGestione(): boolean {
    return this.funzione === undefined;
  }

  // removeUsedCampiRendicontazione(): void {
  //   let usedCampi = []
  //   this.campoTipologiaServizioService.items.forEach(campo => {
  //     campo.listaCampoTipoRendicontazioneId.forEach(id => {
  //       usedCampi.push(id)
  //     })
  //   })
  //   let difference = this.listaCampoTipoRendicontazione?.filter(x => !usedCampi.includes(x.id));
  //   this.listaCampoTipoRendicontazioneUsed = difference
  // }
}
