import { Component, EventEmitter, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { OverlayService } from "../../../../services-old/overlay.service";
import { AmministrativoService } from "../../../../services-old/amministrativo.service";
import { map } from 'rxjs/operators';
import { Observable, of } from "rxjs";

@Component({
  selector: 'base-amm',
  template: '<div></div>'
})
export class AmministrativoParentComponent implements OnInit {

  public waitingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  private inserimentoLogAzioneUrl = '/inserimentoLogAzione';
  private idFunzioneString;

  constructor(protected router: Router, protected route: ActivatedRoute,
    protected http: HttpClient, protected amministrativoService: AmministrativoService) {

    this.waitingEmitter.emit(true);
    this.amministrativoService.asyncAmministrativoSubject.subscribe((isAmministrativo) => {
      if (isAmministrativo) {
        this.route.url.subscribe((url) => {
          // TODO Orribile, da aggiornare con nuova versione di typescript
          const path = this.route.snapshot.pathFromRoot.filter(p => p.url.length > 0).map(p => p.url)[0][0].path;
          const basePath = '/' + path;
          let h: HttpHeaders = new HttpHeaders();
          this.idFunzioneString = String(this.amministrativoService.mappaFunzioni[basePath]);
          h = h.append('idFunzione', this.idFunzioneString);
          let observable: Observable<any> = this.http.get(environment.bffBaseUrl + '/verificaAbilitazione', {
            headers: h,
            withCredentials: true
          }).pipe(map(() => {
            this.waitingEmitter.emit(false);
            this.inserimentoLogAzione().subscribe();
            return of(null);
          }));
          observable.subscribe();
        });
      } else {
        this.router.navigateByUrl('/nonautorizzato');
      }
    });
  }

  ngOnInit(): void {
  }

  private inserimentoLogAzione(): Observable<any> {
    return this.http.post(environment.bffBaseUrl + this.inserimentoLogAzioneUrl, this.idFunzioneString, {
      withCredentials: true
    });
  }
}
