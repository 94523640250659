<div class="container-fluid">
  <div class="d-flex justify-content-md-center">
    <h3 class="header"><b>Il suo pagamento &egrave; stato preso in carico.</b></h3>
  </div>
  <div class="d-flex justify-content-center">
    <div class="progress-spinner progress-spinner-active">
      <span class="sr-only">Caricamento...</span>
    </div>
  </div>
  <div class="d-flex justify-content-md-center">
    <h3 class="collapse-header"><b>La preghiamo di attendere.</b></h3>
  </div>
  <div class="d-flex justify-content-md-center">
    <h3 class="font-msg">{{msg}}</h3>
  </div>
</div>
