import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'payer-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {

  @Input()
  icona: string;

  constructor() { }

}
