<div class="ml-3">
  <form #datiPermessoForm="ngForm">
    <div class="row mt-2 mb-0">
      <div class="col text-right" [id]="indexSezionePermesso">
         <span ngbTooltip="{{testoTooltipIconaElimina}}" placement="bottom"
               [ngStyle]="{fill: '#403d39'}"
         >
          <svg *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" fill="var(--lepida-primary)" class="bg-light icon cursor-pointer"
               (click)="onClickDeleteIcon($event)">
            <use xlink:href="assets/img/sprite.svg#it-delete"></use>
          </svg>
         </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 pb-2">
        <div class="row">
          <div class="col">
            <label for="selectSocieta"
                   [ngClass]="{'title': true, 'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Società</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <select [ngClass]="{'text-disabled': !listaSocieta.length}" id="selectSocieta" name="societaId"
                    #societaInput="ngModel" [ngModel]="datiPermesso.societaId"
                    (change)="onChangeModel(societaInput)"
                    [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
              <option *ngFor="let item of listaSocieta" [ngValue]="item.value">{{item.label}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
               [ngClass]="{'text-muted': !isCampoInvalido(societaInput), 'text-invalid': isCampoInvalido(societaInput), 'text-disabled': !listaSocieta.length}">
              {{setPlaceholder(societaInput, 'select')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-3 pb-2">
        <div class="row">
          <div class="col">
            <label for="selectEnte"
                   [ngClass]="{'title': true, 'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Ente</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <select [ngClass]="{'input-disabled': !listaEnti.length}"
                    id="selectEnte" name="enteId" [required]="datiPermesso.enteId === undefined"
                    #enteInput="ngModel" [ngModel]="datiPermesso.enteId" (change)="onChangeModel(enteInput)"
                    [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
              <option [ngValue]="undefined" [selected]="true">Seleziona</option>
              <option *ngFor="let item of listaEnti" [ngValue]="item.value">{{item.label}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
               [ngClass]="{'text-muted': !isCampoInvalido(enteInput), 'text-invalid': isCampoInvalido(enteInput), 'text-disabled': !listaEnti.length}">
              {{setPlaceholder(enteInput, 'select')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-2 pb-2">
        <div class="row">
          <div class="col">
            <label for="selectServizio"
                   [ngClass]="{'title': true}">
              Servizio
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <select [ngClass]="{'input-disabled': enteInput.value == null}"
                    id="selectServizio" name="servizioId"
                    #servizioInput="ngModel" [ngModel]="datiPermesso.servizioId"
                    (change)="onChangeModel(servizioInput)"
                    [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
              <option [ngValue]="null" [selected]="true" *ngIf="datiPermesso.enteId != null">Seleziona</option>
              <option [ngValue]="null" [selected]="true" *ngIf="datiPermesso.enteId == null">Tutto</option>
              <option *ngFor="let item of listaServizi" [ngValue]="item.value">{{item.label}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div *ngIf="listaServizi.length > 0" class="col">
            <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
               [ngClass]="{'text-muted': !isCampoInvalido(servizioInput), 'text-invalid': isCampoInvalido(servizioInput), 'text-disabled': !isSelectValida(datiPermesso.enteId, listaEnti)}">
              {{setPlaceholder(servizioInput, 'select')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-2 pb-2">
        <div class="row">
          <div class="col">
            <label for="dataInizioValidita" class="title">Attivazione</label>
          </div>
        </div>
        <div class="row">
          <div class="col calendar-wrapper">
            <svg class="bg-light icon img-calendar" (click)="openDatepicker(datePickerAttivazione)"
                 [ngClass]="{'disabled': funzione === FunzioneGestioneEnum.DETTAGLIO}">
              <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
            </svg>
            <dp-date-picker
              mode="day"
              id="dataInizioValidita"
              theme="dp-material"
              [config]="{
              locale: 'it',
              format: 'DD/MM/YYYY',
              firstDayOfWeek: 'mo',
              showGoToCurrent: false,
              openOnClick: isCalendarOpen,
              openOnFocus: false,
              min: minDateDDMMYYYY,
              max: setMaxDate(datePickerScadenza),
              returnedValueType: tipoData
            }"
              #attivazioneInput="ngModel"
              [ngModel]="datiPermesso.dataInizioValidita"
              name="dataInizioValidita"
              #datePickerAttivazione
              (onSelect)="onChangeModel(attivazioneInput)"
              (click)="isCalendarOpen = false"
              [ngClass]="{'disabled': funzione === FunzioneGestioneEnum.DETTAGLIO}"
            ></dp-date-picker>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
               [ngClass]="{'text-muted': !isCampoInvalido(attivazioneInput), 'text-invalid': isCampoInvalido(attivazioneInput)}">
              {{setPlaceholder(attivazioneInput, 'date')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-2 pb-2">
        <div class="row">
          <div class="col">
            <label for="dataFineValidita" class="title">Scadenza</label>
          </div>
        </div>
        <div class="row">
          <div class="col calendar-wrapper">
            <svg class="bg-light icon img-calendar" (click)="openDatepicker(datePickerScadenza)"
                 [ngClass]="{'disabled': funzione === FunzioneGestioneEnum.DETTAGLIO}">
              <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
            </svg>
            <dp-date-picker
              mode="day"
              id="dataFineValidita"
              theme="dp-material"
              [config]="{
              locale: 'it',
              format: 'DD/MM/YYYY',
              firstDayOfWeek: 'mo',
              showGoToCurrent: false,
              openOnClick: isCalendarOpen,
              openOnFocus: false,
              min: setMinDate(datePickerAttivazione),
              returnedValueType: tipoData
            }"
              #scadenzaInput="ngModel"
              [ngModel]="datiPermesso.dataFineValidita"
              name="dataFineValidita"
              #datePickerScadenza
              (onSelect)="onChangeModel(scadenzaInput)"
              (click)="isCalendarOpen = false"
              [ngClass]="{'disabled': funzione === FunzioneGestioneEnum.DETTAGLIO}"
            ></dp-date-picker>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
               [ngClass]="{'text-muted': !isCampoInvalido(scadenzaInput), 'text-invalid': isCampoInvalido(scadenzaInput)}">
              {{setPlaceholder(scadenzaInput, 'date')}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isSelectValida(enteInput.value, listaEnti)">
      <div class="row">
        <div *ngFor="let funzion of listaFunzioni; let i = index" class="col-3">
          <it-checkbox [id]="i" [label]="funzion.label" (change)="onChangeCheckBox($event, funzion.value)"
                       [checked]="funzion.checked"
                       [disabled]="disabilitaCheckboxFunzione(funzion, servizioInput)"></it-checkbox>
        </div>
      </div>
    </div>
    <hr>
  </form>
</div>
