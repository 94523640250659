import { NgModule } from "@angular/core";
import { ErrorePage } from "./errors/pagina-errore/errore.page";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { PayerPaginaManutenzione } from "./pages/pagina-manutenzione/manutenzione.page";
import { PrivacyPage } from "./pages/privacy/privacy.page";
import { FooterComponent } from "./components/footer/footer.component";

@NgModule({
  declarations:[
    FooterComponent,
    
    ErrorePage,
    PrivacyPage,
    PayerPaginaManutenzione
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    FooterComponent,
    
    ErrorePage,
    PrivacyPage,
    PayerPaginaManutenzione
  ]
})
export class PayerCoreModule {}
