<!-- Sottotitolo -->
<div class="collapse-header" id="idCollapseFiltri" *ngIf="isPaginaGestione()">
  <button data-toggle="collapse" data-target="#contenutoSezioneFiltri" aria-expanded="true"
    aria-controls="contenutoSezioneFiltri">
    Filtri
  </button>
</div>

<!-- Form filtri -->
<form #filtroForm="ngForm">
  <div id="contenutoSezioneFiltri" class="collapse show" role="tabpanel" aria-labelledby="idCollapseFiltri">
    <div class="collapse-body">
      <!-- Filtri -->
      <div class="row mt-2 mb-0">

        <div class="col-md pb-2">
          <div class="row">
            <div class="col">
              <label for="selectRaggruppamento" class="title"
                [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Raggruppamento</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select [ngClass]="{'text-disabled': !opzioniRaggruppamento.length}" id="selectRaggruppamento"
                name="raggruppamento" #filtroRaggruppamento="ngModel" [(ngModel)]="filtriRicerca.raggruppamentoId"
                (ngModelChange)="selezionaRaggruppamento()" [disabled]="disabilitaFiltroRaggruppamento()">
                <option [ngValue]="null" selected *ngIf="isPaginaGestione()">Tutti</option>
                <option *ngFor="let item of opzioniRaggruppamento" [ngValue]="item.value">{{item.label}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p>
                {{setPlaceholder(filtroRaggruppamento, TipoCampoEnum.SELECT)}}
              </p>
            </div>
          </div>

        </div>

        <div class="col-md pb-2" *ngIf="funzione === FunzioneGestioneEnum.AGGIUNGI || isPaginaGestione()">
          <div class="row">
            <div class="col">
              <label for="inputCodice" class="title">{{getLabelCodice()}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select id="inputCodice" class="pt-0" name="codice" [disabled]="disabilitaFiltroCodice()"
                #filtroCodice="ngModel" [(ngModel)]="filtriRicerca.tipologia">
                <option [ngValue]="null" [selected]="true">{{gatLabelValue()}}</option>
                <option *ngFor="let item of listaTipologie" [ngValue]="item">{{item["codice"]}} -
                  {{item["descrizione"]}}</option>
              </select>

            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': disabilitaFiltroCodice(), 'text-invalid': isCampoInvalido(filtroCodice)}">
                {{setPlaceholder(filtroCodice, TipoCampoEnum.SELECT)}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md pb-2" *ngIf="isPaginaGestione()">
          <div class="row">
            <div class="col">
              <label for="inputNome" class="title">Nome Tipologia Servizio</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input id="inputNome" class="pt-0" name="nome" type="text" #filtroNome="ngModel"
                [(ngModel)]="filtriRicerca.nome" />
            </div>
          </div>
        </div>

        <!-- <div class="col-md pb-2" *ngIf="isPaginaGestione()">
          <div class="row">
            <div class="col">
              <label for="inputCodice" class="title">Tipo flusso rendicontazione</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select id="inputFlusso" class="pt-0" name="flusso" [disabled]="disabilitaFiltroTipoRendicontazione()"
                #filtroFlusso="ngModel" [(ngModel)]="filtriRicerca.tipoFlusso">
                <option [ngValue]="null" [selected]="true">{{gatLabelValue()}}</option>
                <option *ngFor="let item of listaFlussiRendicontazione" [ngValue]="item">
                  {{getNomeFlussoSelect(item["nome"], item["descrizione"])}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p [ngClass]="{'text-muted': disabilitaFiltroCodice(), 'text-invalid': isCampoInvalido(filtroFlusso)}">
                {{setPlaceholder(filtroFlusso, TipoCampoEnum.SELECT)}}
              </p>
            </div>
          </div>
        </div> -->

        <div class="col-md-1 pb-2" id="containerBottoneCrea" *ngIf="funzione === FunzioneGestioneEnum.AGGIUNGI">
          <button id="bottoneCrea" class="btn" [disabled]="disabilitaPulsanteCrea()"
            (click)="creaTipologia()">Crea</button>
        </div>

      </div>

      <!-- Bottoni-->
      <div class="row" *ngIf="isPaginaGestione()">
        <div class="col-6">
          <button id="bottonePulisci" type="button" class="btn btn-secondary" [disabled]="disabilitaBottonePulisci()"
            (click)="pulisciFiltri(filtroForm)">Pulisci</button>
        </div>
        <div class="col-6 btn-find">
          <button id="bottoneCerca" class="btn" (click)="cercaElementi()">Cerca</button>
        </div>
      </div>
    </div>
  </div>
</form>
