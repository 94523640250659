<div class="container-fluid" *ngIf="!waiting">
  <!-- breadcrumb -->
  <it-breadcrumb [dark]="false" [separator]="'/'">
    <it-breadcrumb-item [id]="'breadcrumb-item-' + item.id" *ngFor="let item of breadcrumbList; let i = index"
                        >
      {{item.label}}
    </it-breadcrumb-item>
  </it-breadcrumb>

  <!-- titolo -->
  <h3 class="mb-2 page-title"><strong>{{titoloPagina}}</strong>
    <span it-tooltip="{{tooltipTitolo}}">
      <svg class="bg-light icon icon-lg icon-padded">
        <use xlink:href="assets/img/sprite.svg#it-help-circle"></use>
      </svg>
    </span>
  </h3>

  <!-- dati dettaglio transazione -->
  <app-dati-transazione
    [datiTransazione]="datiTransazione"
    [idFunzione]="idFunzione"
  ></app-dati-transazione>

  <!-- Tabella Pendenze contenute nella transazione -->
  <div class="row mt-5 ml-3">
    <label class="title">Lista Pendenze</label>
  </div>
  <div class="card-body p-0">
    <div class="row align-items-baseline align-content-lg-around">
      <div class="col-lg pb-2 pb-sm-2 pb-lg-0"></div>
      <!-- azioni -->
      <div class="col-auto">
        <app-toolbar [toolbarIcons]="toolbarIcons" (onClick)="eseguiAzioni($event)"></app-toolbar>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-table [dataKey]="tableData.dataKey"
                   [cols]="tableData.cols"
                   [rows]="tableData.rows"
                   [tipoTabella]="tableData.tipoTabella"
                   [textLeft]="getNumeroRecord()"
                   (onSelection)="selezionaRigaTabella($event)"
                   [selectedRows]="true"
                   (onClickRow)="mostraDettaglioPendenza($event)"
                   [selection]="righeSelezionate">
        </app-table>
      </div>
    </div>
  </div>

  <hr/>

  <!-- Bottoni -->
  <div class="row pt-4">
    <button type="button" id="buttonIndietro" class="btn btn-secondary btn-indietro" (click)="onClickAnnulla()">Indietro</button>
  </div>
</div>
