import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import {ToolEnum} from '../../../../../enums/Tool.enum';
import {tipoTabella} from '../../../../../enums/TipoTabella.enum';
import { AmministrativoService } from '../../../../../services-old/amministrativo.service';
import {CampoTipologiaServizioService} from '../../../../../services-old/campo-tipologia-servizio.service';
import {MenuService} from '../../../../../services-old/menu.service';
import { Breadcrumb, SintesiBreadcrumb } from '../../../dto/Breadcrumb';
import { Colonna } from '../../../model/tabella/Colonna';
import { ImmaginePdf } from '../../../model/tabella/ImmaginePdf';
import { Tabella } from '../../../model/tabella/Tabella';
import { GestisciElementoComponent } from '../gestisci-elemento.component';
import {tipoColonna} from '../../../../../enums/TipoColonna.enum';
import { FlussoRendicontazione } from '../../../model/flussiRenditcontazione/FlussoRendicontazione';
import { FlussoRendicontazioneService } from '../../../../../services-old/flusso-rendicontazione.service';
import { Utils } from '../../../../../utility/Utils';
import { TipoModaleEnum } from '../../../../../enums/tipoModale.enum';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import * as moment from 'moment';


@Component({
  selector: 'app-gestisci-flussi-rendicontazione',
  templateUrl: './gestisci-flussi-rendicontazione.component.html',
  styleUrls: ['./gestisci-flussi-rendicontazione.component.scss']
})
export class GestisciFlussiRendicontazioneComponent extends GestisciElementoComponent implements OnInit {

  filtriRicerca: any;
  righeSelezionate: any[];

  readonly tooltipTitolo = 'In questa pagina puoi consultare la lista completa delle tipologie flussi rendicontazione a cui sei abilitato e filtrarle';
  breadcrumbList: Breadcrumb[] = [];

  readonly toolbarIcons = [
    {type: ToolEnum.INSERT, tooltip: 'Aggiungi Flusso Rendicontazione'},
    {type: ToolEnum.UPDATE, disabled: true, tooltip: 'Modifica Flusso Rendicontazione'},
    {type: ToolEnum.DELETE, disabled: true, tooltip: 'Elimina Flusso Rendicontazione'},
    {type: ToolEnum.EXPORT_PDF, tooltip: 'Stampa Pdf'},
    {type: ToolEnum.EXPORT_XLS, tooltip: 'Download'}
  ];

  tableData: Tabella = {
    rows: [],
    cols: [
      {field: 'nome', header: 'Nome', type: tipoColonna.TESTO},
      {field: 'descrizione', header: 'Descrizione', type: tipoColonna.TESTO}
    ],
    dataKey: 'id.value',
    tipoTabella: tipoTabella.CHECKBOX_SELECTION
  };
  readonly indiceIconaModifica = 1;
  readonly indiceIconaElimina = 2;

  waiting: boolean = false
  isMenuCarico: boolean;


  constructor(router: Router,
    route: ActivatedRoute, http: HttpClient, amministrativoService: AmministrativoService,
    private renderer: Renderer2, private campoTipologiaServizioService: CampoTipologiaServizioService, private el: ElementRef,
    private menuService: MenuService,
    private confirmationService: ConfirmationService,
    private flussoRendicontazioneService: FlussoRendicontazioneService) {
    super(router, route, http, amministrativoService);
  }

  ngOnInit(): void {
    this.waitingEmitter.subscribe(() => {

      if (this.amministrativoService.mappaFunzioni) {
        this.isMenuCarico = Object.keys(this.amministrativoService.mappaFunzioni).length > 0;
      }

      if (this.isMenuCarico) {
        this.init();
      } else {
        this.menuService.menuCaricatoEvent.subscribe(() => {
          this.init();
        });
      }
    });
  }

  init() {
    this.breadcrumbList = this.inizializzaBreadcrumbList([
      {label: 'Gestisci Anagrafiche', link: null},
      {label: 'Tipo Flusso Rendicontazione', link: null}
    ]);
    this.popolaListaElementi();
  }

  inizializzaBreadcrumbList(breadcrumbs: SintesiBreadcrumb[]): Breadcrumb[] {
    return super.inizializzaBreadcrumbList(breadcrumbs);
  }

  getColonneFilePdf(colonne: Colonna[]): Colonna[] {
    return colonne;
  }

  getRigheFilePdf(righe: any[]): any[] {
    return righe;
  }

  getImmaginiFilePdf(): ImmaginePdf[] {
    return [];
  }

  getNumeroRecord(): string {
    return 'Totale: ' + this.tableData.rows.length + ' tipologie flussi rendicontazione';
  }

  getObservableFunzioneRicerca(): Observable<any[]> {
    return this.flussoRendicontazioneService.recuperaFlussiRendicontazione(this.idFunzione, this.filtriRicerca?.id?.toString());
  }

  getColonneFileExcel(colonne: Colonna[]): Colonna[] {
    return colonne.filter(col => col.field !== 'id');
  }

  getRigheFileExcel(righe: any[]): any[] {
    return righe.map(riga => {
      delete riga.id;
      riga.descrizione = riga.descrizione.value;
      riga.nome = riga.nome.value;
      return riga;
    });
  }

  selezionaRigaTabella(righeSelezionate: any[]): void {
    this.righeSelezionate = righeSelezionate;
    this.toolbarIcons[this.indiceIconaModifica].disabled = this.righeSelezionate.length !== 1;
    this.toolbarIcons[this.indiceIconaElimina].disabled = this.righeSelezionate.length === 0;
  }

  creaRigaTabella(flussoRendicontazione: FlussoRendicontazione): object {
    const riga = {
      id: {value: flussoRendicontazione.id},
      nome: {value: flussoRendicontazione.nome},
      descrizione: {value: flussoRendicontazione.descrizione}
    };
    return riga;
  }

  eseguiAzioni(azioneTool) {
    switch (azioneTool) {
      case ToolEnum.INSERT:
        this.aggiungiElemento('/aggiungiFlussoRendicontazione');
        break;
      case ToolEnum.UPDATE:
        this.modificaElementoSelezionato('/modificaFlussoRendicontazione', this.getListaIdElementiSelezionati()[0]);
        break;
      case ToolEnum.DELETE:
        this.eliminaTipologieServizioSelezionate();
        break;
      case ToolEnum.EXPORT_PDF:
        this.esportaPdfTabella("Lista Tipologie Flussi Rendicontazione")
        break;
      case ToolEnum.EXPORT_XLS:
        this.esportaExcelTabella("Lista Tipologie Flussi Rendicontazione")
        break;
    }
  }


  esportaTabellaInFileExcelFlussi(tableData, title){
    let table = JSON.parse(JSON.stringify(tableData))
    var wb = XLSX.utils.book_new();
    wb.Props = {
      Title: title,
  };
  wb.SheetNames.push(title.substring(0, 30));
  var worksheet  = XLSX.utils.json_to_sheet(this.getRigheFileExcel(table.rows));
  worksheet = XLSX.utils.sheet_add_aoa(worksheet, [this.getColonneFileExcel(table.cols).map(col => col.header)]);
  wb.Sheets[title.substring(0, 30)] = worksheet;
  var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
  saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), title+ '_export_' + moment().format('DD-MM-YYYY HH:mm')+'.xlsx');
  }

  s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
}

  eliminaTipologieServizioSelezionate(): void {
    this.confirmationService.confirm(
      Utils.getModale(() => {
          this.flussoRendicontazioneService.eliminaTipoFlussoRendicontazione(this.getListaIdElementiSelezionati(), this.idFunzione).subscribe((response) => {
            if (!(response instanceof HttpErrorResponse)) {
              this.popolaListaElementi();
              this.righeSelezionate = [];
              this.toolbarIcons[this.indiceIconaModifica].disabled = true;
              this.toolbarIcons[this.indiceIconaElimina].disabled = true;
            }
          });
        },
        TipoModaleEnum.ELIMINA
      )
    );
  }

  callbackPopolaLista() {}

  mostraDettaglioTipologiaServizio(rigaTabella: any) {
    super.mostraDettaglioElemento('/dettaglioFlussoRendicontazione', rigaTabella.id.value);
  }

}
