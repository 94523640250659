import {Component, EventEmitter, OnInit, Output, SimpleChanges} from '@angular/core';
import {FiltroGestioneElementiComponent} from '../../../filtro-gestione-elementi.component';
import {ActivatedRoute} from '@angular/router';
import {AmministrativoService} from '../../../../../../../services-old/amministrativo.service';
import {NgForm} from '@angular/forms';
import { OpzioneSelect } from '../../../../../../../modules/main/model/OpzioneSelect';
import { SocietaService } from '../../../../../../../services-old/societa.service';
import { Societa } from '../../../../../../../modules/main/model/Societa';
import * as _ from 'lodash';

@Component({
  selector: 'app-filtro-iuv-senza-bonifico',
  templateUrl: './filtro-iuv-senza-bonifico.component.html',
  styleUrls: ['./filtro-iuv-senza-bonifico.component.scss']
})
export class FiltroIuvSenzaBonificoComponent extends FiltroGestioneElementiComponent implements OnInit {

  filtro: string;
  societaId = null
  opzioniFiltroSocieta: Array<OpzioneSelect> = new Array<OpzioneSelect>();


  @Output()
  onChangeFiltri: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected activatedRoute: ActivatedRoute, protected amministrativoService: AmministrativoService, private societaService: SocietaService) {
    super(activatedRoute, amministrativoService);
  }

  ngOnInit(): void {
    this.letturaSocieta();
  }

  cercaElementi(): void {
    let filtro = {}
    filtro["stringa"] = this.filtro
    filtro["societaId"] = this.societaId
    this.onChangeFiltri.emit(filtro);
  }

  letturaSocieta(): void {
    let filtro = {visibile: true}
    this.societaService.ricercaSocieta(filtro, this.idFunzione)
      .subscribe(societa => {
        this.popolaOpzioniFiltroSocieta(societa);
      });
  }

  private popolaOpzioniFiltroSocieta(societa: Societa[]) {
    if (societa != null) {
      societa.forEach(s => {
        this.opzioniFiltroSocieta.push({
          value: s.id,
          label: s.nome
        });
      });
      this.opzioniFiltroSocieta = _.sortBy(this.opzioniFiltroSocieta, ['label']);
    }
    if(this.opzioniFiltroSocieta.length == 1)
      this.societaId = this.opzioniFiltroSocieta[0].value
  }

  disabilitaBottone(): boolean {
    return !this.filtro && !this.societaId
  }

  pulisciFiltri(): void {
    this.filtro = null
    this.societaId = null
    this.cercaElementi()
  }

}
