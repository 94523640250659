import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LivelloBanner, getBannerType } from '../enums/livelloBanner.enum';
import { Banner } from '../modules/main/model/banner/Banner';
import { ParametriRicercaRendicontazione } from '../modules/main/model/rendicontazione/ParametriRicercaRendicontazione';
import { FiltroSelect } from '../modules/main/model/servizio/FiltroSelect';
import { FiltroVersante } from '../modules/main/model/transazione/FiltroVersante';
import { BannerService } from './banner.service';

@Injectable({
  providedIn: 'root'
})
export class  GestisciPortaleService {

  private readonly gestisciPortaleBasePath = '/gestisciPortale';
  private readonly gestisciPortaleFiltroLivelloTerritorialeUrl = this.gestisciPortaleBasePath + '/filtroLivelloTerritoriale';
  private readonly gestisciPortaleFiltroSocietaUrl = this.gestisciPortaleBasePath + '/filtroSocieta';
  private readonly gestisciPortaleFiltroEnteUrl = this.gestisciPortaleBasePath + '/filtroEnte';
  private readonly gestisciPortaleFiltroServizioUrl = this.gestisciPortaleBasePath + '/filtroServizio';
  private readonly gestisciPortaleFiltroTransazioneUrl = this.gestisciPortaleBasePath + '/filtroTransazione';
  private readonly gestisciPortaleFiltroCanaleUrl = this.gestisciPortaleBasePath + '/filtroCanale';
  private readonly gestisciPortaleFiltroTipoFlussoUrl = this.gestisciPortaleBasePath + '/filtroTipoFlusso';
  private readonly gestisciPortaleFiltroVersanteUrl = this.gestisciPortaleBasePath + '/filtroVersante';

  private readonly stampaRTUrl = this.gestisciPortaleBasePath + '/stampaRT';
  private readonly stampaRTRendicontoUrl = this.gestisciPortaleBasePath + '/stampaRTRendiconto';
  private readonly stampaRPTUrl = this.gestisciPortaleBasePath + '/stampaRPT';
  private readonly stampaPRUrl = this.gestisciPortaleBasePath + '/stampaPR';
  private readonly stampaPDUrl = this.gestisciPortaleBasePath + '/stampaPD';
  private readonly stampaCommitMsgUrl = this.gestisciPortaleBasePath + '/stampaCommitMsg';

  private readonly inviaNotificaEnteUrl = this.gestisciPortaleBasePath + '/inviaNotificaEnte';
  private readonly inviaNotificaCittadinoUrl = this.gestisciPortaleBasePath + '/inviaNotificaCittadino';

  constructor(private readonly http: HttpClient, private bannerService: BannerService) {
  }

  gestisciPortaleFiltroLivelloTerritoriale(parametriRicercaRendicontazione: ParametriRicercaRendicontazione, idFunzione: string): Observable<FiltroSelect[]> {
    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    let params = new HttpParams();
    if (parametriRicercaRendicontazione.societaId != null) {
      params = params.set('societaId', String(parametriRicercaRendicontazione.societaId));
    }

    return this.http.get(environment.bffBaseUrl + this.gestisciPortaleFiltroLivelloTerritorialeUrl, {
      params,
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as FiltroSelect;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  gestisciPortaleFiltroLivelloTerritoriale2(parametriRicercaRendicontazione: ParametriRicercaRendicontazione, idFunzione: string): Observable<FiltroSelect[]> {
    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.gestisciPortaleFiltroLivelloTerritorialeUrl, {
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as FiltroSelect;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  gestisciPortaleFiltroSocieta(idFunzione: string): Observable<FiltroSelect[]> {
    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.gestisciPortaleFiltroSocietaUrl, {
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as FiltroSelect;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  gestisciPortaleFiltroEnte(parametriRicercaRendicontazione: ParametriRicercaRendicontazione, idFunzione: string): Observable<FiltroSelect[]> {
    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    let params = new HttpParams();
    if (parametriRicercaRendicontazione.societaId != null) {
      params = params.set('societaId', String(parametriRicercaRendicontazione.societaId));
    }
    if (parametriRicercaRendicontazione.livelloTerritorialeId != null) {
      params = params.set('livelloTerritorialeId', String(parametriRicercaRendicontazione.livelloTerritorialeId));
    }
    if (parametriRicercaRendicontazione.enteId != null) {
      params = params.set('enteId', String(parametriRicercaRendicontazione.enteId));
    }
    if (parametriRicercaRendicontazione.tipologiaServizioId != null) {
      params = params.set('tipologiaServizioId', String(parametriRicercaRendicontazione.tipologiaServizioId));
    }

    return this.http.get(environment.bffBaseUrl + this.gestisciPortaleFiltroEnteUrl, {
      params,
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as FiltroSelect;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  gestisciPortaleFiltroServizio(parametriRicercaRendicontazione: ParametriRicercaRendicontazione, idFunzione: string): Observable<FiltroSelect[]> {
    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    let params = new HttpParams();
    if (parametriRicercaRendicontazione.societaId != null) {
      params = params.set('societaId', String(parametriRicercaRendicontazione.societaId));
    }
    if (parametriRicercaRendicontazione.livelloTerritorialeId != null) {
      params = params.set('livelloTerritorialeId', String(parametriRicercaRendicontazione.livelloTerritorialeId));
    }
    if (parametriRicercaRendicontazione.enteId != null) {
      params = params.set('enteId', String(parametriRicercaRendicontazione.enteId));
    }
    if (parametriRicercaRendicontazione.tipologiaServizioId != null) {
      params = params.set('tipologiaServizioId', String(parametriRicercaRendicontazione.tipologiaServizioId));
    }

    return this.http.get(environment.bffBaseUrl + this.gestisciPortaleFiltroServizioUrl, {
      params,
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as FiltroSelect;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  gestisciPortaleFiltroTransazione(idFunzione: string): Observable<FiltroSelect[]> {
    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.gestisciPortaleFiltroTransazioneUrl, {
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as FiltroSelect;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  gestisciPortaleFiltroCanale(idFunzione: string): Observable<FiltroSelect[]> {
    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.gestisciPortaleFiltroCanaleUrl, {
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as FiltroSelect;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  gestisciPortaleFiltroTipoFlusso(idFunzione: string): Observable<FiltroSelect[]> {
    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.gestisciPortaleFiltroTipoFlussoUrl, {
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as FiltroSelect;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  gestisciPortaleFiltroVersante(idFunzione: string): Observable<FiltroVersante[]> {
    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.gestisciPortaleFiltroVersanteUrl, {
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as FiltroVersante;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  stampaRT(listaDettaglioTransazioneId: Array<number>, listaTransazioneId: Array<number>, idFunzione: string): Observable<Array<string>> {
    let params = new HttpParams();
    if (listaDettaglioTransazioneId) {
      params = params.set('listaDettaglioTransazioneId', listaDettaglioTransazioneId.join(', '));
    }
    if (listaTransazioneId) {
      params = params.set('listaTransazioneId', listaTransazioneId.join(', '));
    }

    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.stampaRTUrl, {
      params: params,
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as Array<string>;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  stampaRTRendiconto(listaTransazioneId: Array<number>, idFunzione: string): Observable<Array<string>> {
    let params = new HttpParams();
    if (listaTransazioneId) {
      params = params.set('listaTransazioneId', listaTransazioneId.join(', '));
    }

    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.stampaRTRendicontoUrl, {
      params: params,
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as Array<string>;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  stampaRPT(listaDettaglioTransazioneId: Array<number>, idFunzione: string): Observable<Array<string>> {
    let params = new HttpParams();
    if (listaDettaglioTransazioneId) {
      params = params.set('listaDettaglioTransazioneId', listaDettaglioTransazioneId.join(', '));
    }

    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.stampaRPTUrl, {
      params: params,
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as Array<string>;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  stampaPR(listaTransazioneId: Array<number>, idFunzione: string): Observable<Array<string>> {
    let params = new HttpParams();
    if (listaTransazioneId) {
      params = params.set('listaTransazioneId', listaTransazioneId.join(', '));
    }

    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.stampaPRUrl, {
      params: params,
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as Array<string>;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  stampaPD(listaTransazioneId: Array<number>, idFunzione: string): Observable<Array<string>> {
    let params = new HttpParams();
    if (listaTransazioneId) {
      params = params.set('listaTransazioneId', listaTransazioneId.join(', '));
    }

    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.stampaPDUrl, {
      params: params,
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as Array<string>;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  stampaCommitMsg(transazioneId: number, listaNotificaId: Array<number>, idFunzione: string): Observable<Array<string>> {
    let params = new HttpParams();
    if (transazioneId) {
      params = params.set('transazioneId', String(transazioneId));
    }
    if (listaNotificaId) {
      params = params.set('listaNotificaId', listaNotificaId.join(', '));
    }

    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.stampaCommitMsgUrl, {
      params: params,
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body as Array<string>;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

  inviaNotificaEnte(transazioneId: number, idFunzione: string): Observable<any> {
    let params = new HttpParams();
    if (transazioneId) {
      params = params.set('transazioneId', String(transazioneId));
    }

    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.inviaNotificaEnteUrl, {
      params: params,
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body;
        }),
        catchError((err, caught) => {
          if (err.status === 401 || err.status === 400) {
            return of(null);
          } else if (err.status === 500) {
            this.mostraBannerError(err.error);
            return of(err);
          } else {
            return of(null);
          }
        }));
  }

  private mostraBannerError(message: string): void {
    let banner: Banner;
    banner = {
      titolo: 'ATTENZIONE',
      testo: message,
      tipo: getBannerType(LivelloBanner.WARNING)
    };
    this.bannerService.bannerEvent.emit([banner]);
  }
  // private mostraBanner(message: string): void {
  //   let banner: Banner;
  //   if (response.esito != null) {
  //     banner = {
  //       titolo: 'ATTENZIONE',
  //       testo: response.esito,
  //       tipo: getBannerType(LivelloBanner.WARNING)
  //     };
  //   } else {
  //     banner = Utils.bannerOperazioneSuccesso();
  //   }
  //   this.bannerService.bannerEvent.emit([banner]);
  // }

  inviaNotificaCittadino(transazioneId: number, idFunzione: string): Observable<any> {
    let params = new HttpParams();
    if (transazioneId) {
      params = params.set('transazioneId', String(transazioneId));
    }

    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', idFunzione);

    return this.http.get(environment.bffBaseUrl + this.inviaNotificaCittadinoUrl, {
      params: params,
      headers: h,
      withCredentials: true
    })
      .pipe(map((body: any) => {
          return body;
        }),
        catchError((err, caught) => {
          if (err.status == 401 || err.status == 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
  }

}
