<div class="fluid-container">
  <!--  breadcrumb -->
  <it-breadcrumb [dark]="false" [separator]="'/'">
    <it-breadcrumb-item [id]="'breadcrumb-item-' + item.id" *ngFor="let item of breadcrumbList; let i = index"
                         [icon]="item.icon">
      {{item.label}}
    </it-breadcrumb-item>
  </it-breadcrumb>
  <!--  titolo -->
  <h3 class="mb-2 page-title"><strong>I Miei Pagamenti</strong>
    <a it-tooltip="{{tooltipOnPageTitle}}">
      <svg class="bg-light icon icon-lg icon-padded">
        <use xlink:href="assets/img/sprite.svg#it-help-circle"></use>
      </svg>
    </a>
  </h3>

  <!-- Sottosezione Filtri -->
  <app-filtri-i-miei-pagamenti (onChangeListaPagamenti)="riempiListaPagamenti($event)"></app-filtri-i-miei-pagamenti>

  <!-- Sottosezione Lista Pagamenti -->
  <div class="collapse-header" id="heading2">
    <button data-toggle="collapse" data-target="#contenutoSezioneListaPagamenti" aria-expanded="true"
            aria-controls="contenutoSezioneListaPagamenti">
      Lista Pagamenti
    </button>
  </div>
  <div id="contenutoSezioneListaPagamenti" class="collapse show" role="tabpanel" aria-labelledby="heading2">
    <div class="collapse-body">
      <!--      tabs -->
      <div class="row align-items-baseline align-content-lg-around">
        <div class="col-lg pb-2 pb-sm-2 pb-lg-0">
          <app-tab-view [tabs]="tabs" (onChange)="onChangeTab($event)"></app-tab-view>
        </div>
        <!--      azioni -->
        <div class="col-auto">
          <app-toolbar (onClick)="eseguiAzioni($event)" [toolbarIcons]="toolbarIcons"></app-toolbar>
        </div>
      </div>
      <!--      table -->
      <div class="row">
        <div class="col">
          <app-table [dataKey]="tableData.dataKey"
                     [cols]="tableData.cols"
                     [rows]="tableData.rows"
                     [tipoTabella]="tableData.tipoTabella"
                     (onSelection)="selezionaPagamenti($event)"
                     [selectedRows]="true"
                     (onClickRow)="dettaglioPagamento($event)"
                     [textLeft]="testoTabella()"
                     [selection]="selectionPagamenti">
          </app-table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modale -->
<p-confirmDialog #cb icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" class="undoButton" pButton icon="pi pi-times" label="Annulla" (click)="cb.reject()"></button>
    <button type="button" class="okButton" pButton icon="pi pi-check" label="Ok" (click)="cb.accept()"></button>
  </p-footer>
</p-confirmDialog>

