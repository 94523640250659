import {Component, OnInit} from '@angular/core';
import {ModalitaPaginaGestioneElemento} from 'src/app/utility/enums/modalita-pagina-gestione-elemento';
import {DettaglioPendenza} from '../../../../../../model/transazione/DettaglioPendenza';
import {FormElementoParentComponent} from '../../../../form-elemento-parent.component';
import {ConfirmationService} from 'primeng/api';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AmministrativoService} from '../../../../../../../../services-old/amministrativo.service';
import {MonitoraggioTransazioniService} from '../../../../../../../../services-old/monitoraggio-transazioni.service';
import {Tabella} from '../../../../../../model/tabella/Tabella';
import {tipoColonna} from '../../../../../../../../enums/TipoColonna.enum';
import {tipoTabella} from '../../../../../../../../enums/TipoTabella.enum';
import {ToolEnum} from '../../../../../../../../enums/Tool.enum';
import {EsitoRTEnum} from '../../../../../../../../enums/esitoRT.enum';
import {DettaglioElementoComponent} from '../../../../dettaglio-elemento.component';
import {DettaglioTransazione} from '../../../../../../model/transazione/DettaglioTransazione';
import {Utils} from '../../../../../../../../utility/Utils';
import {EsitoPendenza} from '../../../../../../model/transazione/EsitoPendenza';
import {Colonna} from '../../../../../../model/tabella/Colonna';
import {ImmaginePdf} from '../../../../../../model/tabella/ImmaginePdf';
import {Observable} from 'rxjs';
import {Transazione} from '../../../../../../model/transazione/Transazione';
import {Banner} from '../../../../../../model/banner/Banner';
import {getBannerType, LivelloBanner} from '../../../../../../../../enums/livelloBanner.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-dettaglio-pendenza',
  templateUrl: './dettaglio-pendenza.component.html',
  styleUrls: ['./dettaglio-pendenza.component.scss']
})
export class DettaglioPendenzaComponent extends DettaglioElementoComponent implements OnInit {

  FunzioneGestioneEnum = ModalitaPaginaGestioneElemento;
  funzione: ModalitaPaginaGestioneElemento = ModalitaPaginaGestioneElemento.DETTAGLIO;

  datiPendenza: DettaglioPendenza = new DettaglioPendenza();
  titoloPagina = 'Dettaglio Pendenza';
  tooltip = 'In questa pagina puoi visualizzare il dettaglio di una singola pendenza selezionata dal dettaglio della transazione';

  breadcrumbList = [];
  righeSelezionate: any[];

  toolbarIcons = [
    {type: ToolEnum.EXPORT_PDF, tooltip: 'Stampa Pdf'},
    {type: ToolEnum.EXPORT_XLS, tooltip: 'Download'},
    {type: ToolEnum.DOWNLOAD, disabled: true, tooltip: 'Esporta RT e RPT'}
  ];

  tableData: Tabella = {
    rows: [],
    cols: [
      {field: 'dataCreazioneRpt', header: 'Data creazione RPT', type: tipoColonna.TESTO},
      {field: 'iuv', header: 'IUV', type: tipoColonna.TESTO},
      {field: 'carrelloPagoPa', header: 'Carrello PagoPA', type: tipoColonna.TESTO},
      {field: 'esitoRt', header: 'Esito', type: tipoColonna.TESTO},
    ],
    dataKey: 'id.value',
    tipoTabella: tipoTabella.CHECKBOX_SELECTION
  };

  constructor(confirmationService: ConfirmationService, protected router: Router, http: HttpClient,
              protected amministrativoService: AmministrativoService, protected activatedRoute: ActivatedRoute,
              private monitoraggioTransazioniService: MonitoraggioTransazioniService, private location: Location) {
    super(activatedRoute, amministrativoService, http, router);
  }

  initFormPage(snapshot: ActivatedRouteSnapshot) {
  }

  ngOnInit(): void {
    this.breadcrumbList = this.inizializzaBreadcrumbList([
      {label: 'Monitoraggio Transazioni', link: this.basePath},
      {label: 'Dettaglio Transazione', link: null},
      {label: 'Dettaglio Pendenza', link: null}
    ], true);

    this.listaElementi = [];
    this.tableData.rows = [];
    this.getObservableFunzioneRicerca().subscribe(datiPendenza => {
      if (datiPendenza != null) {
        this.listaElementi = datiPendenza.listaEsitiPendenza;
        this.datiPendenza = datiPendenza;
        this.impostaTabella(this.listaElementi);
      }
      this.waiting = false;
    });
  }

  redirect(index, link): void {
    if (index === 3) {
      this.tornaIndietro();
    } else {
      this.router.navigateByUrl(link);
    }
  }

  tornaIndietro(): void {
    this.location.back();
  }

  onClickSalva(): void {
  }

  getNumeroRecord(): string {
    return 'Totale ' + this.tableData.rows.length + ' esiti pendenza';
  }

  selezionaRigaTabella(righeSelezionate: any[]): void {
    this.righeSelezionate = righeSelezionate;
    this.toolbarIcons[2].disabled = righeSelezionate.length === 0;
  }

  mostraDettaglioPendenza(rigaCliccata: any): void {
  }

  eseguiAzioni(azioneTool: ToolEnum): void {
    switch (azioneTool) {
      case ToolEnum.EXPORT_PDF:
        this.esportaTabellaInFilePdf(this.tableData, 'Lista Pendenze');
        break;
      case ToolEnum.EXPORT_XLS:
        this.esportaTabellaInFileExcel(this.tableData, 'Lista Pendenze');
        break;
      case ToolEnum.DOWNLOAD:
        this.esportaFlussiInZipFile(this.getListaIdElementiSelezionati());
        break;
    }
  }

  esportaFlussiInZipFile(listaEsitiPendenza: Array<number>): void {
    this.monitoraggioTransazioniService.scaricaRPTRT(listaEsitiPendenza, this.idFunzione).subscribe(files => {

      if (!(files instanceof HttpErrorResponse)) {
        files.forEach((file, index) => {
          if (file) {
            Utils.downloadBase64ToZipFile(file.file, file.nome);
          }
        });
      }
    });
  }

  creaRigaTabella(esitoPendenza: EsitoPendenza) {

    return {
      id: {value: esitoPendenza.id},
      iuv: {value: esitoPendenza.iuv},
      dataCreazioneRpt: {value: esitoPendenza.dataCreazioneRpt ?
          moment(esitoPendenza.dataCreazioneRpt).format('DD/MM/YYYY HH:mm:ss') : null},
      carrelloPagoPa: {value: esitoPendenza.carrelloPagoPa},
      esitoRt: {value: this.getLabelEsitoRt(esitoPendenza.esitoRt)}
    };
  }

  getLabelEsitoRt = (esito) => {
    let label = null;
    switch (esito) {
      case EsitoRTEnum.PagamentoEseguito:
        label = 'Pagamento eseguito';
        break;
      case EsitoRTEnum.PagamentoNonEseguito:
        label = 'Pagamento non eseguito';
        break;
      case EsitoRTEnum.PagamentoParzialmenteEseguito:
        label = 'Pagamento parzialmente eseguito';
        break;
      case EsitoRTEnum.DecorrenzaTermini:
        label = 'Decorrenza termini';
        break;
      case EsitoRTEnum.DecorrenzaTerminiParziale:
        label = 'Decorrenza termini parziale';
        break;
      default:
        label = 'Non trovato esito valido';
        break;
    }
    return label;
  }

  getObservableFunzioneRicerca(): Observable<DettaglioPendenza> {
    const idSelezionato = parseInt(this.activatedRoute.snapshot.paramMap.get('dettaglioTransazioneId'));
    const idTransazione = parseInt(this.activatedRoute.snapshot.paramMap.get('idTransazione'));
    return this.monitoraggioTransazioniService.dettaglioPendenza(idSelezionato, idTransazione, this.idFunzione);
  }


  getColonneFilePdf(colonne: Colonna[]): Colonna[] {
    return colonne;
  }

  getRigheFilePdf(righe: any[]): any[] {
    return righe;
  }

  getImmaginiFilePdf(): ImmaginePdf[] {
    const iconaGruppoUtenti = new ImmaginePdf();
    iconaGruppoUtenti.indiceColonna = 2;
    iconaGruppoUtenti.srcIcona = 'assets/img/users-solid-pdf-img.png';
    iconaGruppoUtenti.posizioneX = 30;
    iconaGruppoUtenti.posizioneY = 15;
    iconaGruppoUtenti.larghezza = 18;
    iconaGruppoUtenti.altezza = 15;

    return [iconaGruppoUtenti];
  }

  getColonneFileExcel(colonne: Colonna[]): Colonna[] {
    return colonne;
  }

  getRigheFileExcel(righe: any[]): any[] {
    return righe.map(riga => {
      const rigaFormattata = riga;
      rigaFormattata.id = riga.id.value;
      rigaFormattata.iuv = riga.iuv.value;
      rigaFormattata.dataCreazioneRpt = riga.dataCreazioneRpt.value;
      rigaFormattata.carrelloPagoPa = riga.carrelloPagoPa.value;
      rigaFormattata.esitoRt = riga.esitoRt.value;
      return rigaFormattata;
    });
  }

}
