import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommonData, CommonDataService } from "./common-data.service";

export class LivelloIntegrazione {
  id: number
  nome: string
  sigla: string
}

@Injectable({
  providedIn: 'root'
})
export class LivelloIntegrazioneService implements OnDestroy {

  private subs: Subscription[] = [];
  private livelliIntegrazione: LivelloIntegrazione[] = [];

  constructor(dataService: CommonDataService) {
    this.subs.push(dataService.getData(CommonData.LIVELLI_INTEGRAZIONE).subscribe(li => this.livelliIntegrazione = li));
  }

  getLivelliIntegrazione(): LivelloIntegrazione[] {
    return this.livelliIntegrazione;
  }

  getLivelloIntegrazione(id: number): LivelloIntegrazione {
    return this.livelliIntegrazione.find(li => li.id == id);
  }

  getNome(id: number): string {
    return this.getLivelloIntegrazione(id).nome;
  }

  getSigla(id: number): string {
    return this.getLivelloIntegrazione(id).sigla;
  }

  ngOnDestroy(): void {
      this.subs.forEach(s => s.unsubscribe());
  }
}
