import { Ufficio } from "./Ufficio";

export class LivelloIntegrazioneServizio {
  codiceUtente: string = null;
  livelloIntegrazioneId: number = null;

  ufficio: Ufficio = new Ufficio();

  codiceIdServizio: number = null;
  numeroTentativiNotifica = 0;
  redirect: boolean;

  urlWebServiceBackoffice: string;
  portaleEsternoId: number;
}
