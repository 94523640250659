export class ParametriRicercaQuadratura {
  societaId: number = null;
  enteId: number = null;
  flussoId: string = null;
  iban: string = null;
  pspId: number = null;
  iuv: string = null;
  dataQuadraturaDa: string = null;
  dataQuadraturaA: string = null;
  numeroPagina: string;
  numeroElementi: string;
  sort: string;
  ascending: string;
  tab: string;
  pagination: boolean
}
