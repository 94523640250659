export class Pagamento {
  numDocumento: string;
  causale: string;
  servizio: string;
  ente: string;
  anno: string;
  importo: number;
  id: number;

}
