<div id="nuovoPagamento">
  <it-breadcrumb [dark]="false" [separator]="'/'">
    <it-breadcrumb-item [id]="'breadcrumb-item-' + item.id" *ngFor="let item of breadcrumbList; let i = index"
                         [icon]="item.icon">
      {{item.label}}
    </it-breadcrumb-item>
  </it-breadcrumb>
  <h3 class="mb-2 page-title"><strong>{{titolo}}</strong></h3>

  <app-compila-nuovo-pagamento [servizioId]="servizioId" [filtroPagamento]="filtroPagamento" [datiPagamento]="datiPagamento"></app-compila-nuovo-pagamento>

  <div *ngIf="servizioSelezionato">
    <app-dati-nuovo-pagamento [servizio]="servizioSelezionato" [datiPagamento]="datiPagamento"></app-dati-nuovo-pagamento>
  </div>
</div>

