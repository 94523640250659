import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";

export const PAGE_SIZE_OPTIONS: number[] = [5, 10, 20];
export const DEFAULT_PAGE_SIZE: number = PAGE_SIZE_OPTIONS[0];

export type TableDisplay = {
  start: number;
  end: number;
};

@Component({
  selector: "payer-table-footer",
  templateUrl: "./table-footer.component.html",
  styleUrls: ["./table-footer.component.scss"],
})
export class PayerTableFooterComponent {
  readonly pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;

  private _length: number;
  @Input()
  get length(): number {
    return this._length;
  }
  set length(value: number) {
    this._length = value;
    this.index = 0;
  }

  @Output()
  displayChange: EventEmitter<TableDisplay> = new EventEmitter<TableDisplay>();

  index: number = 0;
  pageSize: number = DEFAULT_PAGE_SIZE;

  get pages(): number {
    return Math.round(this.length / this.pageSize);
  }
  get availablePages(): number[] {
    if (this.pages > 5) {
      if (this.page < 3) {
        return [1, 2, 3, 4, 5];
      } else if (this.page > this.pages - 3) {
        return [
          this.pages - 4,
          this.pages - 3,
          this.pages - 2,
          this.pages - 1,
          this.pages,
        ];
      } else {
        return [
          this.page - 2,
          this.page - 1,
          this.page,
          this.page + 1,
          this.page + 2,
        ];
      }
    } else {
      const res: number[] = [];
      for (let i = 1; i <= this.pages; i++) {
        res.push(i);
      }
      return res;
    }
  }
  get page(): number {
    return this.index + 1;
  }
  get offset(): number {
    return this.index * this.pageSize;
  }
  get firstElement(): number {
    return this.offset + 1;
  }
  get lastElement(): number {
    const last = this.offset + this.pageSize;
    return last > this.length ? this.length : last;
  }
  get display(): TableDisplay {
    return {
      start: this.offset,
      end: this.lastElement,
    };
  }

  pageChange(newPage: number): void {
    if (newPage < 1) {
      this.index = 0;
    } else if (newPage > this.pages) {
      this.index = this.pages - 1;
    } else {
      this.index = newPage - 1;
    }

    this.displayChange.emit(this.display);
  }

  pageSizeChange(newPageSize: number): void {
    this.index = 0;
    this.pageSize = newPageSize;
    this.displayChange.emit(this.display);
  }
}
