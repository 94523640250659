<svg class="icon">
  <use [attr.href]="this.icon.bootstrapItalia('it-calendar')"></use>
</svg>
<input
  type="text"
  placeholder="Nessuna data"
  [formControl]="this.control"
  [dpDayPicker]="config"
  [mode]="mode"
  theme="dp-material"
/>
