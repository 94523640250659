<span>Tutti i campi contrassegnati con * sono obbligatori</span>
<div class="ml-3">
  <form #datiUtenteForm="ngForm" (input)="onChangeForm(datiUtenteForm)">
    <div class="row mt-4 mb-0">
      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="codiceFiscaleId" [ngClass]="{'title': true, 'required': funzione === FunzioneGestioneEnum.AGGIUNGI}">Codice fiscale</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="email" id="codiceFiscaleId" name="codiceFiscale" #codicefiscaleinput="ngModel"
                   [pattern]="codiceFiscaleRegex"
                   [(ngModel)]="codiceFiscale" [required]="funzione === FunzioneGestioneEnum.AGGIUNGI" (ngModelChange)="controlloCodiceFiscale($event)"
                   class="pt-0" autocomplete="off" aria-describedby="codiceFiscaleIdHelpDescription" [disabled]="funzione !== FunzioneGestioneEnum.AGGIUNGI">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(codicefiscaleinput), 'text-invalid': isCampoInvalido(codicefiscaleinput)}">
              {{setPlaceholder(codicefiscaleinput, 'string')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="nomeUtente" class="title">Nome</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="text" id="nomeUtente" name="nome" #nomeInput="ngModel" aria-describedby="nomeUtenteHelpDescription"
                   [(ngModel)]="datiUtente.nome"
                   class="pt-0" autocomplete="off" [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(nomeInput), 'text-invalid': isCampoInvalido(nomeInput)}">
              {{setPlaceholder(nomeInput, 'string')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="col">
            <label for="cognomeUtente" class="title">Cognome</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="text" id="cognomeUtente" name="cognome" #cognomeInput="ngModel" aria-describedby="cognomeUtenteHelpDescription"
                   [(ngModel)]="datiUtente.cognome"
                   class="pt-0" autocomplete="off" [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(cognomeInput), 'text-invalid': isCampoInvalido(cognomeInput)}">
              {{setPlaceholder(cognomeInput, 'string')}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4 mb-0">
      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="emailUtente" class="title">E-mail</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="email" id="emailUtente" name="email" #emailInput="ngModel"
                   [pattern]="emailRegex"
                   [(ngModel)]="datiUtente.email"
                   class="pt-0" autocomplete="off" aria-describedby="emailUtenteHelpDescription" [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(emailInput), 'text-invalid': isCampoInvalido(emailInput)}">
              {{setPlaceholder(emailInput, 'email')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="telefonoUtente" class="title">Telefono</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="text" id="telefonoUtente" name="telefono" #telefonoInput="ngModel"
                   [pattern]="telefonoRegex"
                   [(ngModel)]="datiUtente.telefono"
                   class="pt-0" autocomplete="off" aria-describedby="telefonoUtenteHelpDescription" [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(telefonoInput), 'text-invalid': isCampoInvalido(telefonoInput)}">
              {{setPlaceholder(telefonoInput, 'string')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-2 pb-2">
        <div class="row">
          <div class="col">
            <label for="attivazione" class="title">Attivazione</label>
          </div>
        </div>
        <div class="row">
          <div [ngClass]="{'col calendar-wrapper': true}">
            <svg class="bg-light icon img-calendar" (click)="openDatepicker(datePickerAttivazione)"
                 [ngClass]="{'disabled': funzione !== FunzioneGestioneEnum.AGGIUNGI}">
              <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
            </svg>
            <dp-date-picker
              mode="day"
              id="attivazione"
              theme="dp-material"
              [config]="{
                locale: 'it',
                format: 'DD/MM/YYYY',
                firstDayOfWeek: 'mo',
                showGoToCurrent: false,
                openOnClick: isCalendarOpen,
                openOnFocus: false,
                min: minDateDDMMYYYY,
                max: setMaxDate(datePickerScadenza),
                returnedValueType: tipoData
              }"
              #attivazioneInput = "ngModel"
              [(ngModel)]="datiUtente.attivazione"
              name="attivazione"
              #datePickerAttivazione
              (click)="isCalendarOpen = false"
              [ngClass]="{'disabled': funzione !== FunzioneGestioneEnum.AGGIUNGI}"
            ></dp-date-picker>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="funzione === FunzioneGestioneEnum.AGGIUNGI" [ngClass]="{'text-muted': !isCampoInvalido(attivazioneInput), 'text-invalid': isCampoInvalido(attivazioneInput)}">
              {{setPlaceholder(attivazioneInput, 'date')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-2 pb-2">
        <div class="row">
          <div class="col">
            <label for="scadenza" class="title">Scadenza</label>
          </div>
        </div>
        <div class="row">
          <div class="col calendar-wrapper">
            <svg class="bg-light icon img-calendar" (click)="openDatepicker(datePickerScadenza)"
                 [ngClass]="{'disabled': funzione === FunzioneGestioneEnum.DETTAGLIO}">
              <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
            </svg>
            <dp-date-picker
              mode="day"
              id="scadenza"
              theme="dp-material"
              [config]="{
                locale: 'it',
                format: 'DD/MM/YYYY',
                firstDayOfWeek: 'mo',
                showGoToCurrent: false,
                openOnClick: isCalendarOpen,
                openOnFocus: false,
                min: setMinDate(datePickerAttivazione),
                returnedValueType: tipoData
              }"
              #scadenzaInput = "ngModel"
              [(ngModel)]="datiUtente.scadenza"
              name="scadenza"
              #datePickerScadenza
              (click)="isCalendarOpen = false"
              [ngClass]="{'disabled': funzione === FunzioneGestioneEnum.DETTAGLIO}"
            ></dp-date-picker>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO" [ngClass]="{'text-muted': !isCampoInvalido(scadenzaInput), 'text-invalid': isCampoInvalido(scadenzaInput)}">
              {{setPlaceholder(scadenzaInput, 'date')}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
