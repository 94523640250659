export class FlussoRiversamentoPagoPA {
  flagNotificaEmail = false;
  flagNotificaFtp = false;
  email: string;
  ccn: string;
  maxKbEmailAttachment: string;
  server: string;
  username: string;
  password: string;
  directory: string;
  apiKey: string;
  apiSecret: string;
  dataModifica: number;
}
