import { map } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AsyncSubject, Observable } from 'rxjs';
import { finished } from 'stream';

@Injectable({
  providedIn: 'root'
})
export class AmministrativoService {

  mappaFunzioni = {};

  asyncAmministrativoSubject: AsyncSubject<any> = new AsyncSubject<any>();

  salvaCampoFormEvent: EventEmitter<any> = new EventEmitter<any>();

  salvaTipoPortaleEsternoEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  // getFunzioneAmministratoreId(route: ActivatedRouteSnapshot): Observable<number> {
  //   return this.asyncAmministrativoSubject.pipe(map(finished => {
  //     const funzione = '/' + route.pathFromRoot.filter(snap => snap.url && snap.url.length > 0)[0].url[0].path;
  //     return this.mappaFunzioni[funzione];
  //   }));
  // }

  getFunzioneAmministratoreId(funzione: string): Observable<number> {
    const f = funzione.startsWith('/') ? funzione : '/' + funzione;

    return this.asyncAmministrativoSubject.pipe(map(finished => {
      return this.mappaFunzioni[f];
    }));
  }
}
