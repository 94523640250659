import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, NgForm, NgModel } from "@angular/forms";
import * as _ from "lodash";
import * as moment from "moment";
import { DatePickerComponent, ECalendarValue } from "ng2-date-picker";
import { ComponenteDinamico } from "src/app/modules/main/model/ComponenteDinamico";
import { ContoCorrente } from "src/app/modules/main/model/ente/ContoCorrente";
import { Utils } from "src/app/utility/Utils";
import { ModalitaPaginaGestioneElemento } from "src/app/utility/enums/modalita-pagina-gestione-elemento";

@Component({
  selector: "app-conto-corrente",
  templateUrl: "./conto-corrente.component.html",
  styleUrls: ["./conto-corrente.component.scss"],
})
export class ContoCorrenteComponent implements OnInit, AfterViewInit {
  FunzioneGestioneEnum = ModalitaPaginaGestioneElemento;

  @Input() uuid: string;
  @Input() indexDatiContoCorrente: number;
  @Input() datiContoCorrente: ContoCorrente;
  @Input() funzione: ModalitaPaginaGestioneElemento;
  @Input() listaContiCorrente: ContoCorrente[];
  @Input() inputDisabled: boolean;

  @Output() onChangeContoCorrente: EventEmitter<ComponenteDinamico> =
    new EventEmitter<ComponenteDinamico>();

  contoSelezionato = false;

  // calendar
  isCalendarOpen = false;
  readonly minDateDDMMYYYY = moment().format("DD/MM/YYYY");
  readonly tipoData = ECalendarValue.String;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // bind button collapse to new section beneficiario
    const collapseButton = document.getElementById(
      "buttonCCiCollapse" + this.uuid.toString()
    );
    collapseButton.dataset.target = "#collapseCCi" + this.uuid;
    this.inizializzaDatiContoCorrente();
  }

  private inizializzaDatiContoCorrente() {
    let isFormValid: boolean;
    if (this.funzione === ModalitaPaginaGestioneElemento.AGGIUNGI) {
      isFormValid = false;
    } else {
      isFormValid = true;
    }
    if (!this.inputDisabled) {
      this.onChangeContoCorrente.emit(this.setComponenteDinamico(isFormValid));
    }
  }

  onClickRow(contoCorrente: ContoCorrente, form: NgForm) {
    this.datiContoCorrente.id = contoCorrente.id;
    this.datiContoCorrente.iban = contoCorrente.iban;
    this.datiContoCorrente.intestazione = contoCorrente.intestazione;
    this.datiContoCorrente.ibanCCPostale = contoCorrente.ibanCCPostale;
    this.datiContoCorrente.intestazioneCCPostale =
      contoCorrente.intestazioneCCPostale;
    this.datiContoCorrente.inizioValidita = contoCorrente.inizioValidita;
    this.contoSelezionato = true;
    this.onChangeContoCorrente.emit(this.setComponenteDinamico(form.valid));
  }

  setComponenteDinamico(isFormValid?: boolean): ComponenteDinamico {
    const replacer = (key, value) =>
      typeof value === "undefined" ? null : value;
    const contoCorrente = JSON.parse(
      JSON.stringify(this.datiContoCorrente, replacer)
    );
    const componenteDinamico: ComponenteDinamico = new ComponenteDinamico(
      this.uuid,
      this.indexDatiContoCorrente,
      contoCorrente,
      isFormValid
    );
    return componenteDinamico;
  }

  disabilitaSelezionaCC() {
    // if (this.funzione !== ModalitaPaginaGestioneElemento.AGGIUNGI &&
    //   this.datiContoCorrente.id && !this.contoSelezionato) {
    // return false;
    // } else {
    return true;
    // }
  }

  requiredCampi() {
    return (
      this.funzione !== ModalitaPaginaGestioneElemento.DETTAGLIO &&
      !this.inputDisabled
    );
  }

  openDatepicker(datePickerComponent: DatePickerComponent): void {
    datePickerComponent.api.open();
    this.isCalendarOpen = !this.isCalendarOpen;
  }

  disabilitaCampi() {
    return (
      this.funzione === ModalitaPaginaGestioneElemento.DETTAGLIO ||
      this.inputDisabled
    );
  }

  setMaxDate(datePicker: DatePickerComponent): string {
    return datePicker.inputElementValue
      ? moment(datePicker.inputElementValue, "DD/MM/YYYY")
          .subtract(1, "day")
          .format("DD/MM/YYYY")
      : null;
  }

  onChangeModelFlusso(form: NgForm, campo: NgModel) {
    this.onChangeContoCorrente.emit(
      this.setComponenteDinamico(this.controlloForm(form))
    );
  }

  controlloForm(form: NgForm): boolean {
    return form.valid;
  }

  onChangeModel(form: NgForm, campo: NgModel) {
    if (campo.value == "") {
      this.datiContoCorrente[campo.name] = null;
    }
    // fix date picker
    if (
      Utils.isObjectEmpty(this.datiContoCorrente) &&
      !_.isEqual(this.datiContoCorrente, new ContoCorrente())
    ) {
      this.datiContoCorrente = new ContoCorrente();
    }
    if (campo.name == "iban")
      this.datiContoCorrente.iban = form.value.iban.replaceAll(/\s/g, "");
    this.onChangeContoCorrente.emit(
      this.setComponenteDinamico(this.controlloForm(form))
    );
  }

  campiDisabilitati(campo?: NgModel) {
    if (this.inputDisabled) {
      return true;
    } else if (
      this.funzione !== ModalitaPaginaGestioneElemento.AGGIUNGI &&
      campo?.value != null &&
      this.datiContoCorrente.id &&
      !this.contoSelezionato
    ) {
      return true;
    } else {
      return false;
    }
  }

  isCampoInvalido(campo: NgModel | FormControl) {
    return campo?.errors;
  }

  getMessaggioErrore(campo: NgModel): string {
    if (campo.control?.errors?.required) {
      return "Il campo è obbligatorio";
    } else {
      return "Campo non valido";
    }
  }

  getIbanHeader(): string {
    return `[${[
      this.datiContoCorrente.iban,
      this.datiContoCorrente.ibanCCPostale,
    ]
      .filter((v) => !!v)
      .join(", ")}]`;
  }
}
