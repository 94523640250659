import { AfterContentInit, Component, ContentChild, Input } from '@angular/core';
import { FormControl, FormControlDirective, FormControlName } from '@angular/forms';

@Component({
  selector: 'payer-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements AfterContentInit {

  @Input()
  showLabel: boolean = true;

  @Input()
  showRequiredIndicator: boolean = true;

  private _required: boolean;

  @ContentChild(FormControlName)
  private _controlName: FormControlName;

  @ContentChild(FormControlDirective)
  private _control: FormControlDirective;

  get control(): FormControl {
    if (this._controlName) {
      return this._controlName.control;
    } else if (this._control) {
      return this._control.control;
    } else {
      return null;
    }
  }

  get feedback(): string {
    if (this.control.dirty && this.control.errors) {
      return Object.keys(this.control.errors)[0];
    }

    return null;
  }

  constructor() { }

  ngAfterContentInit(): void {
    if (this.control && this.control.validator) {
      this._required = !!this.control.validator(new FormControl(''))?.hasOwnProperty('required');
    }
  }

  isControlRequired(): boolean {
    return this._required && !this.control.disabled;
  }
}
