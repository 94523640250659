import { TipoModaleEnum } from '../../../../../../../enums/tipoModale.enum';
import { ConfirmationService } from 'primeng/api';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {ModalitaPaginaGestioneElemento} from '../../../../../../../utility/enums/modalita-pagina-gestione-elemento';
import {FormControl, NgForm, NgModel, ValidatorFn} from '@angular/forms';
import {ContoCorrente} from '../../../../../model/ente/ContoCorrente';
import * as moment from 'moment';
import {Utils} from '../../../../../../../utility/Utils';
import {ComponenteDinamico} from '../../../../../model/ComponenteDinamico';
import {TipoCampoEnum} from "../../../../../../../enums/tipoCampo.enum";
import {FlussoRiversamentoPagoPA} from "../../../../../model/servizio/FlussoRiversamentoPagoPA";
import * as _ from 'lodash';

@Component({
  selector: 'app-config-conto-corrente',
  templateUrl: './config-conto-corrente.component.html',
  styleUrls: ['./config-conto-corrente.component.scss']
})
export class ConfigContoCorrenteComponent implements OnInit, AfterViewInit {

  // enums consts
  FunzioneGestioneEnum = ModalitaPaginaGestioneElemento;
  testoTooltipIconaElimina = 'Elimina dati conto corrente';
  ibanRegex = Utils.IBAN_ITALIA_REGEX;

  @Input() uuid: string;
  @Input() indexDatiContoCorrente: number;
  @Input() datiContoCorrente: ContoCorrente;
  @Input() funzione: ModalitaPaginaGestioneElemento;
  @Input() listaContiCorrente: ContoCorrente[];
  @Input() inputDisabled: boolean;
  @Output()
  onChangeDatiContoCorrente: EventEmitter<ComponenteDinamico> = new EventEmitter<ComponenteDinamico>();
  @Output()
  onDeleteDatiContoCorrente: EventEmitter<ComponenteDinamico> = new EventEmitter<ComponenteDinamico>();

  flussoRiversamentoPagoPA = new FlussoRiversamentoPagoPA();

  TipoCampoEnum = TipoCampoEnum;

  constructor(private cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    if (this.datiContoCorrente.flussoRiversamentoPagoPA)
      this.flussoRiversamentoPagoPA = this.datiContoCorrente.flussoRiversamentoPagoPA;
  }

  ngAfterViewInit(): void {
    // bind button collapse to new section beneficiario
    const collapseButton = document.getElementById('buttonCCCollapse' + this.uuid.toString());
    collapseButton.dataset.target = '#collapseCC' + this.uuid;
    this.inizializzaDatiContoCorrente();
  }

  private inizializzaDatiContoCorrente() {
    let isFormValid: boolean;
    if (this.funzione === ModalitaPaginaGestioneElemento.AGGIUNGI) {
      isFormValid = false;
    } else {
      isFormValid = true;
    }
    if (!this.inputDisabled) {
      this.onChangeDatiContoCorrente.emit(this.setComponenteDinamico(isFormValid));
    }
  }

  onClickDeleteIcon(event) {
    //this.onDeleteDatiContoCorrente.emit(this.setComponenteDinamico());
    this.confirmationService.confirm(
      Utils.getModale(() => {
        this.onDeleteDatiContoCorrente.emit(this.setComponenteDinamico());
        },
        TipoModaleEnum.ELIMINA
      )
    );
  }

  getMessaggioErrore(campo: NgModel): string {
    if (campo.control?.errors?.required) {
      return 'Il campo è obbligatorio';
    } else {
      return 'Campo non valido';
    }
  }

  onChangeModelFlusso(form: NgForm, campo: NgModel) {
    if (campo.value == '') {
      this.flussoRiversamentoPagoPA[campo.name] = null;
    } else
      this.flussoRiversamentoPagoPA[campo.name] = campo.value;
    this.flussoRiversamentoPagoPA.dataModifica = moment().unix();
    this.datiContoCorrente.flussoRiversamentoPagoPA = this.flussoRiversamentoPagoPA;
    this.onChangeDatiContoCorrente.emit(this.setComponenteDinamico(this.controlloForm(form)));
  }

  onChangeModel(form: NgForm, campo: NgModel) {
    if (campo.value == '') {
      this.datiContoCorrente[campo.name] = null;
    }
    // fix date picker
    if(Utils.isObjectEmpty(this.datiContoCorrente) && !_.isEqual(this.datiContoCorrente, new ContoCorrente())) {
      this.datiContoCorrente = new ContoCorrente();
    }
    if (campo.name == "iban")
      this.datiContoCorrente.iban = form.value.iban.replaceAll(/\s/g,'')
    this.onChangeDatiContoCorrente.emit(this.setComponenteDinamico(this.controlloForm(form)));
  }

  setComponenteDinamico(isFormValid?: boolean): ComponenteDinamico {
    const replacer = (key, value) =>
      typeof value === 'undefined' ? null : value;
    const contoCorrente = JSON.parse(JSON.stringify(this.datiContoCorrente, replacer));
    const componenteDinamico: ComponenteDinamico =
      new ComponenteDinamico(this.uuid, this.indexDatiContoCorrente, contoCorrente, isFormValid);
    return componenteDinamico;
  }

  controlloForm(form: NgForm): boolean {
    return form.valid;
  }

  campiDisabiitati(campo?: NgModel) {
    if(this.inputDisabled) {
      return true;
    } else if (this.funzione !== ModalitaPaginaGestioneElemento.AGGIUNGI && campo?.value != null &&
      this.datiContoCorrente.id) {
      return true;
    } else {
      return false;
    }
  }

  disabilitaSelezionaCC() {
    if (this.funzione !== ModalitaPaginaGestioneElemento.AGGIUNGI &&
      this.datiContoCorrente.id) {
      return false;
    } else {
      return true;
    }
  }

  isCampoInvalido(campo: NgModel | FormControl) {
    return campo?.errors;
  }

  setPlaceholder(campo: NgModel | FormControl, tipoCampo: TipoCampoEnum): string {
    if (this.funzione === ModalitaPaginaGestioneElemento.DETTAGLIO) {
      return null;
    } else if (campo instanceof NgModel && campo.control?.errors?.required) {
      return 'Il campo è obbligatorio';
    } else if (this.isCampoInvalido(campo)) {
      return 'campo non valido';
    } else {
      switch (tipoCampo) {
        case TipoCampoEnum.SELECT:
          return 'Seleziona un elemento dalla lista';
        case TipoCampoEnum.INPUT_TESTUALE:
          return 'Inserisci testo';
        case TipoCampoEnum.INPUT_TESTUALE_EMAILS:
          return 'Inserisci le email separate da ;';
        case TipoCampoEnum.DATEDDMMYY:
          return 'Inserisci data';
      }
    }
  }

  getPlaceholderRequired(label: string, required: boolean) {
    if (required) {
      return label + ' *';
    }
    return label;
  }

  disabilitaCampi() {
    return this.funzione === ModalitaPaginaGestioneElemento.DETTAGLIO || this.inputDisabled;
  }

  requiredCampi() {
    return this.funzione !== ModalitaPaginaGestioneElemento.DETTAGLIO && !this.inputDisabled;
  }

  changeEmailFlussoPagoPA(form: NgForm, event: boolean) {
    if (!this.datiContoCorrente.flussoRiversamentoPagoPA)
      this.datiContoCorrente.flussoRiversamentoPagoPA = new FlussoRiversamentoPagoPA();
    this.datiContoCorrente.flussoRiversamentoPagoPA.flagNotificaEmail = event;
    this.datiContoCorrente.flussoRiversamentoPagoPA.dataModifica = moment().unix();
    let res = !event || (event && this.datiContoCorrente.flussoRiversamentoPagoPA.email != null);
    this.onChangeDatiContoCorrente.emit(this.setComponenteDinamico(res));
  }

  changeFtpFlussoPagoPA(form: NgForm, event: boolean) {
    if (!this.datiContoCorrente.flussoRiversamentoPagoPA)
      this.datiContoCorrente.flussoRiversamentoPagoPA = new FlussoRiversamentoPagoPA();
    this.datiContoCorrente.flussoRiversamentoPagoPA.flagNotificaFtp = event;
    this.datiContoCorrente.flussoRiversamentoPagoPA.dataModifica = moment().unix();
    let res = !event || (event && this.datiContoCorrente.flussoRiversamentoPagoPA.server != null &&
      this.datiContoCorrente.flussoRiversamentoPagoPA.username != null && this.datiContoCorrente.flussoRiversamentoPagoPA.password != null &&
      this.datiContoCorrente.flussoRiversamentoPagoPA.directory != null);
    this.onChangeDatiContoCorrente.emit(this.setComponenteDinamico(res));
  }

  validateUrl() {
    return ((control: FormControl) => {

      if (control.value) {
        const regex = '(http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?';
        if (!new RegExp(regex).test(control.value)) {
          return {url: false};
        }
      }

      return null;
    }) as ValidatorFn;
  }


  validateServer() {
    return ((control: FormControl) => {

      if (control.value) {
        const regex = '[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?';
        const regexIp = '^([0-9]{1,3})\\.([0-9]{1,3})\\.([0-9]{1,3})\\.([0-9]{1,3})$';
        if (new RegExp(regex).test(control.value) || new RegExp(regexIp).test(control.value)) {
          return null;
        } else {
          return {url: false};
        }
      }

      return null;
    }) as ValidatorFn;
  }

  validateEmail() {
    return ((control: FormControl) => {

      if (control.value) {
        const regex = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
        if (!new RegExp(regex).test(control.value)) {
          return {email: false};
        }
      }

      return null;
    }) as ValidatorFn;
  }

  validateEmails() {
    return ((control: FormControl) => {

      if (control.value) {
        let emails: string[] = control.value.split(";");
        const regex = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
        for (let idx in emails) {
          let value = emails[idx];
          if (value != "" && !new RegExp(regex).test(value)) {
            return {email: false};
          }
        }
      }

      return null;
    }) as ValidatorFn;
  }
}
