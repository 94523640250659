<div class="container-fluid">
  <it-breadcrumb [dark]="isDark" [separator]="separator">
    <it-breadcrumb-item
      [id]="'breadcrumb-item-' + item.id"
      *ngFor="let item of breadcrumbList; let i = index"
      [icon]="item.icon"
    >
      {{ item.label }}
    </it-breadcrumb-item>
  </it-breadcrumb>
  <h3 class="pl-3 mb-2 header">
    <b>Carrello</b>
    <a it-tooltip="{{ tooltipTitle }}">
      <svg class="bg-light icon icon-lg icon-padded">
        <use xlink:href="assets/img/sprite.svg#it-help-circle"></use>
      </svg>
    </a>
  </h3>

  <div id="collapseDiv1" class="collapse-div" role="tablist">
    <div class="collapse-header" id="heading1">
      <button
        data-toggle="collapse"
        data-target="#collapse1"
        aria-expanded="true"
        aria-controls="collapse1"
      >
        Lista pagamenti
      </button>
    </div>
    <div
      id="collapse1"
      class="collapse show"
      role="tabpanel"
      aria-labelledby="heading1"
    >
      <div class="collapse-body">
        <app-lista-pagamenti-l1
          [rid]="rid"
          (urlBackEmitterChange)="urlBack = $event"
          (onChangeTotalePagamento)="totalePagamento = $event"
          (onChangeEmailPagamento)="email = $event"
          (onChangeNumeroPagamenti)="numeroPagamenti = $event"
        ></app-lista-pagamenti-l1>
      </div>
    </div>
    <div class="collapse-header" id="heading2">
      <button
        data-toggle="collapse"
        data-target="#collapse2"
        aria-expanded="true"
        aria-controls="collapse2"
      >
        Modalità pagamento
      </button>
    </div>
    <div
      id="collapse2"
      class="collapse show"
      role="tabpanel"
      aria-labelledby="heading2"
    >
      <div class="collapse-body">
        <div class="form-check">
          <input id="pagopa-checkbox" type="radio" name="pagopa" checked />
          <label for="pagopa-checkbox" style="height: auto">
            <img
              src="assets/img/pagopa.png"
              alt="PagoPA"
              width="80"
              style="min-width: 80px; max-width: 80px"
            />
          </label>
          <div style="font-size: 16px; padding-left: 36px">
            <p class="mb-1">
              PagoPA è il sistema nazionale Italiano, previsto dalle normative
              vigenti (CAD. Codice dell'Amministrazione Digitale) per effettuare
              i pagamenti elettronici verso la Pubblica Amministrazione.
            </p>
            <p class="mb-1">
              Per procedere con il pagamento occorre selezionare il bottone
              <strong>"Avanti"</strong> in fondo alla pagina.
            </p>
            <p>Tenendo presente che:</p>
            <ul>
              <li>
                verrà chiesta la registrazione a pagoPA; tale registrazione è
                facoltativa e non necessaria a portare a termine il pagamento
              </li>
              <li>
                al termine del pagamento è necessario cliccare il bottone
                <strong>"Chiudi"</strong> per ritornare al servizio;
              </li>
              <li>
                il sistema propone tre modalità di pagamento (carta di credito,
                conto corrente e altro)
              </li>
            </ul>
            <!-- <div class="overflow-auto text-justify">
                <video
                  controls
                  (click)="toggleVideo()"
                  #videoPlayer
                  class="mx-3 my-2 float-right"
                  style="height: 25vh;"
                >
                  <source
                    src="https://multipler.lepida.it/lepida/video/lepida_8517.mp4"
                    type="video/mp4"
                  />
                </video>
                <div class="inline-block">
                  Si sottolinea che scegliendo il pagamento con carte di Intesa
                  Sanpaolo, seguendo la procedura di seguito riportata, è
                  possibile usufruire di costi di commissione agevolati, ottenuti
                  da Lepida S.c.p.A. (0,45€ per i pagamenti nella fascia
                  0,01€-50,00€; 0,90€ per la fascia 50,01€-150,00€; 1,25€ per la
                  fascia 150,01€-500,00€ e 2€ per la fascia 500,01€-1.500,00€).
                  Per usufruire delle commissioni agevolate utilizzando la carta
                  di credito/debito, occorre scegliere "Carta di credito/debito" e
                  nella sezione "Il pagamento sarà gestito da" verificare che sia
                  riportato "Intesa Sanpaolo", altrimenti utilizzare il link
                  <strong>"Modifica"</strong> per selezionarlo.
                </div>
              </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="collapse-header" id="heading3">
      <button
        data-toggle="collapse"
        data-target="#collapse3"
        aria-expanded="true"
        aria-controls="collapse3"
      >
        Email per la notifica
      </button>
    </div>
    <div
      id="collapse3"
      class="collapse show"
      role="tabpanel"
      aria-labelledby="heading3"
    >
      <div class="collapse-body">
        <div class="row">
          <div class="col-xl-4 col-xs-12">
            <form #emailForm="ngForm">
              <it-input
                [(ngModel)]="email"
                name="emailInput"
                [label]="'E-mail'"
                [type]="'email'"
                required
                [pattern]="emailRegex"
                [icon]="'it-email'"
                [note]="getNote(emailForm)"
              ></it-input>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row pl-4 row justify-content-between align-items-center h-100">
    <div class="col-12 col-sm-12 col-md-auto col-lg-auto font-msg align-middle">
      Disposizioni di pagamento: {{ numeroPagamenti }}
    </div>
    <div class="col-12 col-sm-12 col-md-auto col-lg-auto font-msg align-middle">
      <div class="row align-items-center">
        <div
          class="text-sm-left text-md-right text-lg-right col-12 col-sm-12 col-md-auto col-lg-auto font-msg align-middle"
        >
          Totale da pagare:
          {{
            totalePagamento
              | currency : "EUR" : "€ "
              | replace : "." : "|"
              | replace : "," : "."
              | replace : "|" : ","
          }}
        </div>
      </div>
    </div>
  </div>
  <div class="row pl-4 row justify-content-between align-items-center h-100">
    <div class="col-12 col-sm-12 col-md-auto col-lg-auto font-msg align-middle">
      <button
        *ngIf="urlBack"
        (click)="tornaAlServizio()"
        type="button"
        class="btn btn-secondary"
      >
        Torna al servizio
      </button>
    </div>
    <div class="col-12 col-sm-12 col-md-auto col-lg-auto font-msg align-middle">
      <div class="row align-items-center">
        <div
          class="text-sm-left text-md-right text-lg-right col-12 col-sm-12 col-md-auto col-lg-auto font-msg align-middle font-msg align-middle"
        >
          <button
            [disabled]="emailForm.invalid"
            (click)="navigaInPresaInCaricoPagamento()"
            type="button"
            class="btn btn-outline-secondary"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Paga
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
