<div class="ml-3">
  <!-- Società, Ente, Servizio -->
  <div class="row mt-4 mb-0">
    <div class="col-md-2 pb-2">
      <div class="row">
        <div class="col">
          <label for="societa" class="title">Società</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            id="societa" name="societa"
            [(ngModel)]="societa"
          />
        </div>
      </div>
    </div>
    <div class="col-md-2 pb-2">
      <div class="row">
        <div class="col">
          <label for="ente" class="title">Ente</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            [(ngModel)]="ente"
            id="ente" name="ente"
          />
        </div>
      </div>
    </div>
    <div class="col-md-2 pb-2">
      <div class="row">
        <div class="col">
          <label for="servizio" class="title">Servizio</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            id="servizio" name="servizio"
            type="text"
            disabled
            [(ngModel)]="servizio"
          />
        </div>
      </div>
    </div>
    <div class="col-md-2 pt-5">
      <a href="javascript:void(0)" (click)="redirectToMonitoraggioTransazioni()">Monitoraggio transazioni</a>
    </div>
    <div class="col-md-2 pt-5">
      <a href="javascript:void(0)" (click)="esportaFlussi()">Esporta flussi</a>
    </div>
  </div>

  <!-- Tabella lista transazioni contenute nel flusso selezionato -->
  <div class="row mt-5 ml-0">
    <label class="title">Lista Transazioni</label>
  </div>
  <div class="card-body p-0">
    <div class="row align-items-baseline align-content-lg-around">
      <div class="col-lg pb-2 pb-sm-2 pb-lg-0"></div>
      <!-- azioni -->
      <div class="col-auto">
        <app-toolbar [toolbarIcons]="toolbarIcons" (onClick)="eseguiAzioni($event)"></app-toolbar>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-table [dataKey]="tableData.dataKey"
                   [cols]="tableData.cols"
                   [rows]="tableData.rows"
                   [tipoTabella]="tableData.tipoTabella"
                   [textLeft]="getNumeroRecord()"
                   (onSelection)="selezionaRigaTabella($event)"
                   [selection]="righeSelezionate">
        </app-table>
      </div>
    </div>
  </div>
</div>
