<p-table #table [columns]="cols" [value]="rows" styleClass="p-datatable-responsive-demo"
         dataKey="{{dataKey}}" [(selection)]="selection"
         (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
         [paginator]="true" [rows]="5" [showCurrentPageReport]="false"
         (onHeaderCheckboxToggle)="onRowSelect($event)" (sortFunction)="customSort($event)" [customSort]="true"
         (onLazyLoad)="requestRowsFromBE($event)" [lazy]="isPaginataBE" [totalRecords]="totalRecords" [(first)]="first">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3rem" *ngIf="tipoTabella == tipoTabellaEnum.CHECKBOX_SELECTION">
        <!-- HeaderCheckbox viene attivato solo se la tabella non è paginata -->
        <p-tableHeaderCheckbox class="icon-box" *ngIf="!isPaginataBE"></p-tableHeaderCheckbox>
        <div *ngIf="isPaginataBE"></div>
      </th>
      <th *ngFor="let col of columns" pSortableColumn="{{col.field}}"
      [ngStyle]="{width: (col.type == tipoColonnaEnum.ICONA) ? '3rem' : ''}">
        {{col.header.toUpperCase()}}
        <p-sortIcon field="{{col.field}}" *ngIf="col.type != tipoColonnaEnum.ICONA"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-columns="columns" >
    <tr [ngClass]="{'row-selected':selectedRows}">
      <td *ngIf="tipoTabella == tipoTabellaEnum.CHECKBOX_SELECTION">
        <p-tableCheckbox [value]="row"></p-tableCheckbox>
      </td>
      <td *ngFor="let col of columns" (click)="selectedRows ? onRowClick(row) : null">
        <span class="p-column-title">{{col.header}}</span>
        <!--  template ICONA  -->
        <div it-tooltip="{{row[col.field].tooltip}}" [placement]="row[col.field].placement"
              *ngIf="col.type == tipoColonnaEnum.ICONA"
             [ngStyle]="{fill: row[col.field] != null ? row[col.field].color : '#403d39', display: row[col.field].display}"
              (click)="onIconClick(row[dataKey])"
        >
          <svg class="icon icon-sm" *ngIf="isIconInSpriteFile(row[col.field].path)">
            <use [attr.xlink:href]="sprite + row[col.field].path"></use>
          </svg>

          <img *ngIf="!isIconInSpriteFile(row[col.field].path)" src="{{row[col.field].path}}"
               alt="Alert Icon" [ngStyle]="{cursor: 'pointer', width: '25px', height: '24px'}"/>
        </div>
        <!--  template array  -->
        <div *ngIf="col.type == tipoColonnaEnum.ARRAY">
        <!--   render righe       -->
          <div *ngFor="let elem of row[col.field]">
            <!--      riga testo      -->
            <div *ngIf="elem.type == tipoRigaEnum.TESTO" [ngClass]="elem.class" [innerHTML]="elem.value | parseHtml">
              {{elem.value}}
            </div>
            <!--      riga icona      -->
            <div it-tooltip="{{elem.tooltip}}" [placement]="elem.placement"
                 *ngIf="elem.type == tipoRigaEnum.ICONA"
                 [ngStyle]="{fill: elem != null ? elem.color : '#403d39', display: elem.display}"
                 (click)="onIconClick(row[dataKey])"
            >
              <svg class="icon icon-sm" *ngIf="isIconInSpriteFile(elem.path)">
                <use [attr.xlink:href]="sprite + elem.path"></use>
              </svg>

              <img *ngIf="!isIconInSpriteFile(elem.path)" src="{{elem.path}}"
                   alt="Alert Icon" [ngStyle]="{cursor: 'pointer', width: '25px', height: '24px'}"/>
            </div>
          </div>
          <!--  fine render righe  -->
        </div>
        <!--  template TESTO  -->
        <div *ngIf="col.type == tipoColonnaEnum.TESTO" [ngClass]="row[col.field].class" [innerHTML]="row[col.field].value | parseHtml">
          {{row[col.field].value}}
        </div>
        <!--  template LINK  -->
        <div *ngIf="col.type == tipoColonnaEnum.LINK">
          {{row[col.field] != null  ? row[col.field].value : null}}
          <a href="{{row[col.field].link}}" *ngIf="row[col.field].link != null" (click)="onLinkClick()">
            <svg class="icon icon-sm" fill="var(--lepida-primary)" *ngIf="!row[col.field].iconHref">
              <use xlink:href="assets/img/sprite.svg#it-external-link"></use>
            </svg>
            <svg class="icon icon-sm" *ngIf="row[col.field].iconHref">
              <use [attr.xlink:href]="row[col.field].iconHref"></use>
            </svg>
          </a>
        </div>
        <!--  template IMPORTO  -->
        <div *ngIf="col.type == tipoColonnaEnum.IMPORTO" [ngClass]="row[col.field].class">
          {{row[col.field].value != null && row[col.field].value != undefined ? (row[col.field].value|currency:"EUR":"€ "
          | replace:'.':'|' | replace:',':'.'
          | replace:'|':',') : null}}
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length+1" class="text-center">
        <div>Nessun elemento disponibile per la ricerca effettuata</div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <span style="white-space: pre-line;">{{textLeft}}</span>
  </ng-template>
  <ng-template pTemplate="paginatorright">
    <app-paginator (onChangePageSize)="onChangePageSize($event)" pageSize="{{rowsPerPageOption[0]}}"
                   [rowsPerPageOptions]="rowsPerPageOption"></app-paginator>
  </ng-template>
</p-table>

