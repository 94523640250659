export class RegexSchedulazione {
  regexSecondi: string;
  regexMinuti: string;
  regexOre: string;
  regexGiorniMese: string;
  regexMesi: string;
  regexGiorniSettimana: string;
  regexAnni: string;
  regexSchedulazioniExtra: string;
  regexFestivita: string;
}
