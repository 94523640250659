<span *ngIf="funzione != FunzioneGestioneEnum.DETTAGLIO">Tutti i campi contrassegnati con * sono obbligatori</span>
<div class="ml-3">
  <form #societaForm="ngForm" (input)="onChangeForm(societaForm)">

    <div class="row mt-4 mb-0">

      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="nomeSocieta" class="title" [ngClass]="{'required': funzione !== FunzioneGestioneEnum.DETTAGLIO}">Nome</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              id="nomeSocieta"
              type="text"
              name="nomeSocieta"
              autocomplete="off"
              #nomeSocieta = "ngModel"
              [(ngModel)]="societa.nome" (ngModelChange)="formattaInput($event, 'nome')"
              [required]="funzione !== FunzioneGestioneEnum.DETTAGLIO"
              [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
            />
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(nomeSocieta)">Inserisci nome società</p>
            <p *ngIf="isCampoInvalido(nomeSocieta)" class="text-invalid">{{getMessaggioErrore(nomeSocieta)}}</p>
          </div>
        </div>
      </div>

      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="telefonoSocieta" class="title">Telefono</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              id="telefonoSocieta"
              type="text"
              name="telefonoSocieta"
              autocomplete="off"
              #telefonoSocieta = "ngModel"
              [(ngModel)]="societa.telefono" (ngModelChange)="formattaInput($event, 'telefono')"
              [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
              [pattern]="telefonoRegex"
            />
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(telefonoSocieta)">Inserisci telefono società</p>
            <p *ngIf="isCampoInvalido(telefonoSocieta)" class="text-invalid">{{getMessaggioErrore(telefonoSocieta)}}</p>
          </div>
        </div>
      </div>

      <div class="col-md-4 pb-2">
        <div class="row">
          <div class="col">
            <label for="emailSocieta" class="title">Email</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              id="emailSocieta"
              type="text"
              name="emailSocieta"
              autocomplete="off"
              #emailSocieta = "ngModel"
              [(ngModel)]="societa.email" (ngModelChange)="formattaInput($event, 'email')"
              [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
              [pattern]="emailRegex"
            />
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(emailSocieta)">Inserisci email società</p>
            <p *ngIf="isCampoInvalido(emailSocieta)" class="text-invalid">{{getMessaggioErrore(emailSocieta)}}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="row mt-4 mb-0">

      <div class="col-md-8 pb-2">
        <div class="row">
          <div class="col">
            <label for="descrizioneSocieta" class="title">Descrizione</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <textarea
              id="descrizioneSocieta"
              type="text"
              name="descrizioneSocieta"
              autocomplete="off"
              #descrizioneSocieta = "ngModel"
              [(ngModel)]="societa.descrizione" (ngModelChange)="formattaInput($event, 'descrizione')"
              maxlength="255"
              [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
            ></textarea>
          </div>
        </div>
        <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
          <div class="col">
            <p *ngIf="!isCampoInvalido(descrizioneSocieta)">Inserisci descrizione società</p>
            <p *ngIf="isCampoInvalido(descrizioneSocieta)" class="text-invalid">{{getMessaggioErrore(descrizioneSocieta)}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-1 pb-2">
        <div class="row">
          <div class="col">
            <label for="visibile" class="title">Visibile</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <it-toggle id="visibile" [(ngModel)]="societa.visibile" name="visibile" #visibile="ngModel" [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
                       (ngModelChange)="formattaInput($event, 'visibile')"></it-toggle>
          </div>
        </div>
      </div>

    </div>

  </form>
</div>
