<h3>
  Payer - INFORMATIVA per il trattamento dei dati personali ai sensi dell'art 13 del Regolamento europeo n. 679/2016
</h3>

<h4>1. Premessa</h4>
<p>
  In questo documento sono descritte le regole di gestione del servizio PayER e dei servizi ad esso legati, con
  specifico riferimento al
  trattamento dei dati personali dei navigatori che lo consultano.<br>
  Payer &egrave; la piattaforma per i pagamenti on-line verso gli Enti pubblici, implementata da Lepida e rivolta ai
  cittadini e alle imprese.
  Le funzioni di pagamento sono messe a disposizione dell'utente attraverso un'interfaccia che permette di effettuare
  completamente online
  le transazioni che prevedono un pagamento. Il servizio, offerto agli enti soci e ai cittadini, prevede inoltre un
  supporto online con helpdesk.
</p>

<h4>2. Identit&agrave; e dati di contatto del titolare del trattamento</h4>
<p>
  I Titolari del trattamento dei dati personali di cui alla presente Informativa sono gli Enti aderenti al servizio
  Payer (Enti locali, Regione Emilia Romagna,
  Aziende Sanitarie e Aziende Ospedaliere) che integrano i propri software gestionali e che ne richiedono
  l&rsquo;attivazione nell'ambito delle rispettive competenze,
  secondo quanto previsto dalle disposizioni normative vigenti. In particolare tramite l&rsquo;utilizzo delle
  piattaforme <a href='https://payer.lepida.net'>payer.lepida.net</a>, <a
    href='https://payer.lepida.it'>payer.lepida.it</a>, <a href='https://pagamento.lepida.it'>pagamento.lepida.it</a>,
  payer&#64;e.bollo:
</p>

<ul>
  <li>gli Enti Locali aderenti sono Titolari del trattamento dei dati riguardanti i pagamenti che verranno effettuati a
    favore di tali enti (es. Tasse, contributi e altri oneri);</li>
  <li>gli enti appartenenti al comparto della Sanit&agrave; quali AUSL, Aziende Ospedaliere, sono Titolari del
    trattamento dei dati riguardanti i pagamenti che verranno effettuati a favore di tali enti quali TIcket e altri
    tributi:</li>
  <li>la Regione Emilia Romagna &egrave; Titolare del trattamento dei dati riguardanti i pagamenti che verranno
    effettuati in suo favore.</li>
</ul>

<h4>3. Il Responsabile della protezione dei dati personali</h4>
<p>
  Cos&igrave; come previsto dal Regolamento (UE) 2016/679 del Parlamento Europeo e del Consiglio del 27 aprile 2016
  relativo alla protezione delle persone fisiche con
  riguardo al trattamento dei dati personali, i Titolari hanno designato il proprio Responsabile della protezione dei
  dati personali i cui contatti possono essere reperiti
  sui rispettivi siti istituzionali.
</p>

<h4>4. Responsabili del trattamento</h4>
<p>
  Per garantire il buon funzionamento della piattaforma dei pagamenti nelle sue diverse forme, e nell&rsquo;ambito del
  rapporto con i soci, &egrave; nominata quale Responsabile del Trattamento Lepida Scpa. Essa pu&ograve; avvalersi a sua
  volta di soggetti terzi per l&rsquo;espletamento di attivit&agrave; e relativi trattamenti di dati personali.
  Conformemente a quanto stabilito dalla normativa, tali soggetti assicurano livelli di esperienza, capacit&agrave; e
  affidabilit&agrave; tali da garantire il rispetto delle vigenti disposizioni in materia di trattamento, ivi compreso
  il profilo della sicurezza dei dati.
</p>
<p>
  Lepida S.c.p.A. formalizza istruzioni, compiti ed oneri in capo a tali soggetti terzi con la designazione degli stessi
  a &quot;Sub-Responsabili del trattamento&quot;. Sottopone tali soggetti a verifiche periodiche al fine di constatare
  il mantenimento dei livelli di garanzia registrati in occasione dell&rsquo;affidamento dell&rsquo;incarico iniziale.
</p>

<h4>5. Soggetti autorizzati al trattamento</h4>
<p>
  I Suoi dati personali sono trattati dal personale interno agli enti titolari e dal personale di Lepida ScpA
  previamente autorizzato e designato quale incaricato del trattamento, a cui
  sono impartite idonee istruzioni in ordine a misure, accorgimenti, modus operandi, tutti volti alla concreta tutela
  dei suoi dati personali.
</p>

<h4>6. Finalit&agrave; e base giuridica del trattamento</h4>
<p>
  La base giuridica del trattamento &egrave; costituita, ai sensi dell&rsquo;art. 6 par. 1, lettera e), Regolamento (UE)
  679/2016, dall&rsquo;esecuzione di un compito di interesse pubblico e dall&rsquo;esercizio dei pubblici poteri. I dati
  personali da Lei forniti sono trattati unicamente per finalit&agrave; connesse all&rsquo;erogazione del Servizio e,
  nello specifico, per consentire la visualizzazione e il pagamento telematico di tasse, contributi e di altri oneri
  richiesti dagli Enti aderenti.
</p>
<p>
  Ai sensi dell&rsquo;art. 13 par. 3 Regolamento UE 679/2016, qualora il titolare del trattamento intenda trattare
  ulteriormente i dati personali per una finalit&agrave; diversa da quella per cui essi sono stati raccolti, prima di
  tale ulteriore trattamento, fornir&agrave; all'interessato informazioni in merito a tale diversa finalit&agrave; e
  ogni ulteriore informazione pertinente.
</p>
<p>In particolare, i dati richiesti agli utenti del servizio &ldquo;Payer&rdquo; sono raccolti e trattati nei limiti
  strettamente indispensabili all&rsquo;erogazione del servizio.
</p>


<h5>6.1 Tipi di dati trattati e finalit&agrave; del trattamento</h5>
<p>
  Sono oggetto di trattamento, i dati personali di cui all&rsquo;art. 4, n. 1, del Reg. UE n. 679/2016, ovvero i dati
  anagrafici, il codice fiscale e l&rsquo;indirizzo di posta elettronica degli utenti, che effettuano pagamento. Il
  sistema &ldquo;Payer&rdquo; non tratta dati relativi a carte di credito, in quanto l&rsquo;effettiva operazione di
  pagamento con questi strumenti avviene sul sito del prestatore di servizi di pagamento (PSP).
</p>
<p>
  I dati inseriti dagli utenti di titolarit&agrave; degli enti aderenti sono trattati dagli stessi e dal personale delle
  societ&agrave; che gestisce il sistema informativo degli enti, opportunamente autorizzati ai sensi dell&rsquo;art. 2
  quaterdecies del D.Lgs. n. 196/2003 e s.m.i. ed istruiti sugli obblighi derivanti dalla normativa in materia di
  privacy, tramite l&rsquo;utilizzo di strumenti elettronici, nel rispetto delle regole di riservatezza. Il Titolare e i
  fornitori di cui si avvale impiegano idonee misure di sicurezza, organizzative, tecniche e fisiche, per tutelare le
  informazioni dall&rsquo;alterazione, la distruzione, la perdita, il furto o l&rsquo;utilizzo improprio o illegittimo.
</p>

<h5>6.2 Obbligo di conferimento dei dati</h5>
<p>
  ll conferimento dei dati &egrave; obbligatorio per il conseguimento delle finalit&agrave; di cui sopra.
</p>
<p>
  Il loro mancato, parziale o inesatto conferimento potrebbe avere come conseguenza l&rsquo;impossibilit&agrave; di
  fornirLe il servizio &ldquo;Payer&rdquo;.
</p>
<p>
  L'invio facoltativo, esplicito e volontario di messaggi di posta elettronica agli indirizzi indicati sul Sito comporta
  la successiva acquisizione dell'indirizzo del mittente, necessario per rispondere alle richieste, nonch&eacute; degli
  eventuali altri dati personali inseriti nella stessa richiesta. La registrazione facoltativa, esplicita e volontaria
  tramite appositi moduli web (form) presenti sul Sito comporta la successiva acquisizione di tutti i dati riportati nei
  campi compilati dall'utente ed il trattamento, conformemente a quanto riportato nelle specifiche informative riportate
  per ogni singolo form, &egrave; effettuato esclusivamente in adempimento di attivit&agrave; proprie del Titolare.<br>
</p>

<h4>7. Periodo di conservazione</h4>
<p>
  Al fine di garantire un trattamento corretto e trasparente, i dati sono conservati per un periodo di tempo non
  superiore a quello necessario agli scopi per i quali essi sono stati raccolti o successivamente trattati conformemente
  a quanto previsto dagli obblighi di legge e nel rispetto dell&rsquo;art. 5, comma 1, lett. e) del Reg. UE n.
  679/2016.
</p>
<p>
  Conformemente a quanto stabilito da Agid e alla Risposta ad Interpello dell&rsquo;Agenzia delle entrate n. 431 del 2
  ottobre 2020 che equipara la RT ad una ricevuta fiscale, le RT (Ricevute Telematiche) RPT (RIchieste di Pagamento
  Telematico) e Receipt (RIcevute di Pagamento Telematico di nuova introduzione) vengono inviate al Polo Archivistico
  Regionale per essere avviate alla conservazione a norma.
</p>

<h4>8. Destinatari dei dati personali</h4>
<p>
  I suoi dati personali non sono oggetto di comunicazione o diffusione.
</p>

<h4>9. Trasferimento dei dati personali a Paesi extra UE</h4>
<p>
  I suoi dati personali non sono trasferiti al di fuori dell&rsquo;Unione europea.
</p>

<h4>10. Diritti degli interessati</h4>
<p>
  Il Regolamento (UE) 2016/679 attribuisce ai soggetti interessati i seguenti diritti:
</p>
<ul>
  <li>diritto di accesso (art. 15), ovvero di ottenere in particolare:
    <ul>
      <li>diritto di accesso (art. 15), ovvero di ottenere in particolare:</li>
      <li>la conferma dell&rsquo;esistenza dei dati personali,</li>
      <li>l&rsquo;indicazione dell&rsquo;origine e delle categorie di dati personali, della finalit&agrave; e della
        modalit&agrave; del loro trattamento,</li>
      <li>la logica applicata in caso di trattamento effettuato con l&rsquo;ausilio di strumenti elettronici,</li>
      <li>gli estremi identificativi del Titolare del trattamento dei dati personali, del Responsabile del trattamento
        dei dati personali e dei soggetti o categorie di soggetti ai quali i dati sono stati o possono essere
        comunicati,</li>
    </ul>
  </li>
  <li>il periodo di conservazione;</li>
  <li>diritto di rettifica (art. 16);</li>
  <li>diritto alla cancellazione (art. 17);</li>
  <li>diritto di limitazione di trattamento (art. 18);</li>
  <li>diritto alla portabilit&agrave; dei dati (art. 20);</li>
  <li>diritto di opposizione (art. 21);</li>
  <li>diritto di revoca del consenso (art. 7).</li>
</ul>

<p>
  In relazione al trattamento dei dati che La riguardano, si potr&agrave; rivolgere ai rispettivi Titolari del
  trattamento
  per esercitare i Suoi diritti. In particolare si potr&agrave; rivolgere:
</p>

<ul>
  <li>alla Regione Emilia Romagna per i trattamenti relativi ai pagamenti a favore della Regione scrivendo ai contatti
    pubblicati al seguente indirizzo: <a href="https://www.regione.emilia-romagna.it/privacy">www.regione.emilia-romagna.it/privacy</a></li>
  <li>agli Enti Locali di riferimento per i trattamenti relativi ai pagamenti dei contributi richiesti dagli stessi per
    i servizi indirizzando una comunicazione all&rsquo;indirizzo di posta elettronica indicato sul sito istituzionale
    dell&rsquo;ente;</li>
  <li>Alle istituzioni sanitarie di riferimento per i trattamenti relativi ai pagamenti dei contributi richiesti dagli
    stessi per i servizi erogati indirizzando una comunicazione all&rsquo;indirizzo di posta elettronica indicato sul
    sito istituzionale dell&rsquo;ente;</li>
</ul>
<p>
  Per l&rsquo;esercizio dei Suoi diritti pu&ograve; fare riferimento anche al &ldquo;Disciplinare riguardante
  l&rsquo;esercizio dei diritti dell&rsquo;interessato&rdquo; ed utilizzare il &ldquo;Modulo per l&rsquo;esercizio di
  diritti in materia di protezione dei dati personali&rdquo;, disponibili a questo <a
    href="https://lepida.net/societa-trasparente/altri-contenuti/dati-ulteriori">link</a> del sito istituzionale del
  Responsabile del trattamento, che provveder&agrave; a trasmettere l&rsquo;istanza al competente Titolare del
  trattamento.
</p>

<h4>11. Diritto di reclamo</h4>
<p>
  Gli interessati che ritengono che il trattamento dei dati personali a loro riferiti effettuato attraverso il servizio
  Payer avvenga in violazione di quanto previsto dal Regolamento hanno il diritto di proporre reclamo al Garante per la
  protezione dei dati personali, come previsto dall'art. 77 del Regolamento stesso, o di adire le opportune sedi
  giudiziarie (art. 79 del Regolamento).
</p>

<h4>12. Processi decisionali automatizzati</h4>
<p>
  Il sistema &ldquo;Payer&rdquo; non utilizza i dati raccolti nell&rsquo;ambito di processi decisionali automatizzati ai
  sensi dell'art. 13, par. 2, lettera f), del Regolamento (UE) 2016/679.
</p>

<h4>13. Aggiornamenti alla presente Informativa sul trattamento dei dati personali</h4>
<p>
  La presente informativa sul trattamento dei dati personali potr&agrave; essere aggiornata, dandone pubblicit&agrave;
  agli utenti sulla presente pagina, che si invita a consultare periodicamente, utilizzando come riferimento la data di
  ultima modifica indicata a conclusione del testo, al fine di conformarsi alla eventuale normativa, nazionale ed
  europea in materia di trattamento dei dati personali e/o di adeguarsi all&rsquo;adozione di nuovi sistemi, procedure
  interne o comunque per ogni altro motivo che si rendesse opportuno e/o necessario.
</p>
<p>
  Salvo quanto diversamente specificato, l&rsquo;informativa pubblicata sulla presente pagina continua ad applicarsi al
  trattamento dei dati personali raccolti sino al momento della sua sostituzione.
</p>

<hr>

<p>
  <em>Data Pubblicazione: 20/07/2023</em>
</p>
