import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private version: string;
  private major: number;
  private minor: number;
  private patch: number;

  constructor() {
    this.version = environment.sentry.release;

    const v = this.version.split('.');

    this.major = Number(v[0]);
    this.minor = Number(v[1]);
    this.patch = Number(v[2]);
  }

  getVersion(): string {
    return this.version;
  }

  getMajor(): number {
    return this.major;
  }

  getMinor(): number {
    return this.minor;
  }

  getPatch(): number {
    return this.patch;
  }

}
