import { PdfBuilder, PdfTable } from '../../../../utility/stampa/PdfBuilder';
import { ExcelPrintUtils, ExcelBuilder, DatiStampaExcel, ExcelSheet } from '../../../../utility/stampa/ExcelBuilder';
import { tipoColonna } from './../../../../enums/TipoColonna.enum';
import { Breadcrumb, SintesiBreadcrumb } from '../../dto/Breadcrumb';
import { AmministrativoService } from '../../../../services-old/amministrativo.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '../../../../utility/Utils';
import { AmministrativoParentComponent } from './amministrativo-parent.component';
import { Tabella } from '../../model/tabella/Tabella';
import { Colonna } from '../../model/tabella/Colonna';
import { ToolEnum } from '../../../../enums/Tool.enum';
import { ImmaginePdf } from '../../model/tabella/ImmaginePdf';
import { Observable } from 'rxjs';


export abstract class GestisciElementoComponent extends AmministrativoParentComponent {

  protected constructor(router: Router,
    route: ActivatedRoute, protected http: HttpClient,
    amministrativoService: AmministrativoService) {
    super(router, route, http, amministrativoService);
    this.amministrativoService.asyncAmministrativoSubject.subscribe((isAmministrativo) => {
      if (isAmministrativo) {
        route.url.subscribe((url) => {
          const path = this.route.snapshot.pathFromRoot.filter(p => p.url.length > 0).map(p => p.url)[0][0].path;
          const basePath = '/' + path;
          this.basePath = basePath;
          this.idFunzione = String(this.amministrativoService.mappaFunzioni[basePath]);
        });
      } else {
        this.router.navigateByUrl('/nonautorizzato');
      }
    });
  }

  inizializzaBreadcrumbList(breadcrumbs: SintesiBreadcrumb[], flag?: boolean): Breadcrumb[] {
    const breadcrumbList: SintesiBreadcrumb[] = [];
    breadcrumbList.push(new SintesiBreadcrumb(flag ? 'Gestisci Portale' : 'Amministra Portale', null));
    breadcrumbList.push(...breadcrumbs);
    return Utils.popolaListaBreadcrumb(breadcrumbList);
  }

  idFunzione: string
  basePath: string

  abstract tableData: Tabella
  abstract filtriRicerca: any
  abstract righeSelezionate: any[]

  listaElementi: any[]
  elementiTotali: number
  pagineTotali: number

  waiting: boolean = true

  // Funzioni astratte

  abstract creaRigaTabella(elemento: any): void
  abstract eseguiAzioni(azioneTool: ToolEnum): void
  abstract callbackPopolaLista(): void
  abstract getObservableFunzioneRicerca(): Observable<any[] | any>
  abstract selezionaRigaTabella(righeSelezionate: any[]): void
  abstract getNumeroRecord(): string

  // Funzioni sulla tabella

  popolaListaElementi(): void {
    this.listaElementi = [];
    this.tableData.rows = [];
    this.getObservableFunzioneRicerca().subscribe(listaElementi => {
      if (listaElementi != null) {
        this.listaElementi = listaElementi;
        this.impostaTabella(this.listaElementi);
        this.callbackPopolaLista();
      }
      this.waiting = false;
    });
  }

  impostaTabella(listaElementi: any[]): void {
    this.tableData.rows = [];
    if (listaElementi) {
      listaElementi.forEach(elemento => {
        this.tableData.rows.push(this.creaRigaTabella(elemento));
      });
    }
  }

  onChangeFiltri(filtri: any): void {
    this.filtriRicerca = filtri;
    this.popolaListaElementi();
  }

  // Funzioni sugli elementi

  mostraDettaglioElemento(linkFunzioneDettaglio: string, id: number) {
    this.righeSelezionate = [];
    this.router.navigateByUrl(this.basePath + linkFunzioneDettaglio + '/' + id);
  }

  aggiungiElemento(linkFunzioneAggiungi: string) {
    this.righeSelezionate = [];
    this.router.navigateByUrl(this.basePath + linkFunzioneAggiungi);
  }

  modificaElementoSelezionato(linkFunzioneModifica: string, id: number | string) {
    this.righeSelezionate = [];
    this.router.navigateByUrl(this.basePath + linkFunzioneModifica + '/' + id);
  }

  // Funzioni stampa excel

  esportaTabellaInFileExcel(tabella: Tabella, nomeFile: string): void {
    const copiaTabella = JSON.parse(JSON.stringify(tabella));
    const headerColonne = this.getColonneFileExcel(copiaTabella.cols).map(col => col.header);
    const righe = this.getRigheFileExcel(copiaTabella.rows);

    const fogli = {};
    fogli[nomeFile] = null;
    const workbook = { Sheets: fogli, SheetNames: [] };
    Utils.creaFileExcel(righe, headerColonne, nomeFile, [nomeFile.substring(0, 30)], workbook, nomeFile);
  }

  abstract getColonneFileExcel(colonne: Colonna[]): Colonna[];

  abstract getRigheFileExcel(righe: any[]): any[];

  // Funzioni stampa pdf - old

  esportaTabellaInFilePdf(tabella: Tabella, nomeFile: string, dontShowImage?: boolean): void {
    const copiaTabella = JSON.parse(JSON.stringify(tabella));
    const colonne = this.getColonneFilePdf(copiaTabella.cols);
    const righe = this.getRigheFilePdf(copiaTabella.rows);
    let immagini = this.getImmaginiFilePdf(righe);
    if (!immagini) {
      immagini = [];
    }
    if (dontShowImage == true) {
      immagini = []
    }
    Utils.esportaTabellaInFilePdf(colonne, righe, nomeFile, immagini);
  }

  abstract getColonneFilePdf(colonne: Colonna[]): Colonna[];

  abstract getRigheFilePdf(righe: any[]): any[];

  abstract getImmaginiFilePdf(righe?: any[]): ImmaginePdf[] | any[];

  // Utils

  getListaIdElementiSelezionati(): number[] {
    let listaId = [];
    if (this.righeSelezionate && this.righeSelezionate.length > 0) {
      listaId = this.righeSelezionate.map(riga => riga.id.value);
    }
    return listaId;
  }

  protected getElementiStampa(): any[] {
    if (this.righeSelezionate && this.righeSelezionate.length > 0) {
      return this.righeSelezionate
    } else {
      return this.tableData.rows
    }
  }

  // Nuove funzioni di stampa

  /**
   * Funzione standard per la stampa della tabella nella pagina in formato xlsx.
   * Non usare in caso di lazy load della tabella.
   *
   * @param {string} filename Nome file esportato
   */
  protected async esportaExcelTabella(filename: string): Promise<void> {
    const headers = []
    for (let col of this.tableData.cols) {
      if (col.type !== tipoColonna.ICONA && col.type !== tipoColonna.LINK)
        headers.push(col.header)
    }

    const excel = new ExcelBuilder(filename)
    const sheetName = filename.substring(0, 31)
    excel.sheets[sheetName] = new ExcelSheet()
    excel.sheets[sheetName].headers = headers

    const righeStampa = this.getElementiStampa()

    for (let r of righeStampa) {
      const row: any = {}

      for (let c of this.tableData.cols) {
        row[c.header] = r[c.field].value
      }

      excel.sheets[sheetName].rows.push(row)
    }

    excel.print()
  }

  /**
   * Funzione standard per la stampa della tabella nella pagina in formato pdf.
   * Non usare in caso di lazy load della tabella.
   *
   * @param filename Nome file esportato
   */
  protected async esportaPdfTabella(filename: string): Promise<void> {
    const headers = []
    for (let col of this.tableData.cols) {
      if (col.type !== tipoColonna.ICONA && col.type !== tipoColonna.LINK)
        headers.push(col.header)
    }

    const pdf = new PdfBuilder(filename)
    pdf.tables[filename] = new PdfTable()
    pdf.tables[filename].headers = headers

    const righeStampa = this.getElementiStampa()

    for (let r of righeStampa) {
      const row: any = {}

      for (let c of this.tableData.cols) {
        row[c.header] = r[c.field].value
      }

      pdf.tables[filename].rows.push(row)
      pdf.tables[filename].images = this.getImmaginiFilePdf()
    }

    pdf.print()
  }
}
