import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avviso-campi-obbligatori',
  templateUrl: './avviso-campi-obbligatori.component.html',
  styleUrls: ['./avviso-campi-obbligatori.component.scss']
})
export class AvvisoCampiObbligatoriComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
