import { ErrorHandler, Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as Sentry from "@sentry/browser";
import { Router } from "@angular/router";
import { NotFoundError, PayerError } from "./errors";

@Injectable({
  providedIn: "root",
})
export class PayerErrorHandler implements ErrorHandler {
  private useSentry: boolean = false;

  constructor(private router: Router) {
    Sentry.init(environment.sentry);
    this.useSentry =
      environment.sentry.environment === "prod" ||
      environment.sentry.environment === "dev";
  }

  handleError(error: any) {
    if (this.useSentry) {
      Sentry.captureException(error.originalError || error);
    }

    if (!(error instanceof HttpErrorResponse)) {
      console.error(error);

      if (error instanceof PayerError) {
        let urlErrore = "/erroregenerico";

        switch (error.constructor) {
          case NotFoundError:
            urlErrore = "/notfound";
        }

        // this.router.navigateByUrl(urlErrore);
      }
    }
  }
}
