import {SintesiBreadcrumb} from "../../dto/Breadcrumb";
import {Utils} from "../../../../utility/Utils";
import {TipoModaleEnum} from "../../../../enums/tipoModale.enum";
import {ModalitaPaginaGestioneElemento} from "../../../../utility/enums/modalita-pagina-gestione-elemento";
import {ConfirmationService} from "primeng/api";
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {AmministrativoService} from "../../../../services-old/amministrativo.service";


export abstract class FormElementoParentComponent {

  protected constructor(protected confirmationService: ConfirmationService,
                        protected activatedRoute: ActivatedRoute,
                        protected amministrativoService: AmministrativoService,
                        protected http: HttpClient,
                        protected router: Router) {
    window.scroll(0, 0);
    this.amministrativoService.asyncAmministrativoSubject.subscribe((isAmministrativo) => {
      if(isAmministrativo) {
        activatedRoute.url.subscribe((url) => {
          const basePath = '/' + url[0].path;
          this.basePath = basePath;
          this.idFunzione = String(this.amministrativoService.mappaFunzioni[basePath]);
          this.verificaAbilitazioneSottopath().subscribe(() => {
            this.initFormPage(activatedRoute.snapshot);
          });
        });
      } else {
        this.router.navigateByUrl('/nonautorizzato');
      }
    });
  }

  idFunzione;
  basePath;

  abstract funzione: ModalitaPaginaGestioneElemento;

  abstract initFormPage(snapshot: ActivatedRouteSnapshot);

  verificaAbilitazioneSottopath(): Observable<any> {
    let h: HttpHeaders = new HttpHeaders();
    h = h.append('idFunzione', this.idFunzione);
    //console.log("FormElementoParentComponent: " + this.basePath);
    if ("/gestisciAttivitaPianificate" === this.basePath || "/gestisciStatistiche" === this.basePath) {
      return this.http.get(
        environment.attivitaPianificateBaseUrl +
          this.basePath +
          "/verificaAbilitazioneSottoPath",
        {
          headers: h,
          withCredentials: true,
        }
      );
    } else {
      return this.http.get(environment.bffBaseUrl + this.basePath + '/verificaAbilitazioneSottoPath', {
        headers: h,
        withCredentials: true
      });
    }
  }

  inizializzaBreadcrumbList(breadcrumbs: SintesiBreadcrumb[], flag?: boolean) {
    const breadcrumbList: SintesiBreadcrumb[] = [];
    breadcrumbList.push(new SintesiBreadcrumb(flag ? 'Gestisci Portale' : 'Amministra Portale', null));
    breadcrumbList.push(...breadcrumbs);
    return Utils.popolaListaBreadcrumb(breadcrumbList);
  }

  abstract onClickSalva(): void;

  onClickAnnulla(funzione: ModalitaPaginaGestioneElemento) {
    if (funzione === ModalitaPaginaGestioneElemento.DETTAGLIO) {
      this.tornaIndietro();
    } else {
      this.confirmationService.confirm(
        Utils.getModale(() => {
            this.tornaIndietro();
          },
          TipoModaleEnum.ANNULLA
        )
      );
    }
  }

  tornaIndietro(): void {
    this.router.navigateByUrl(this.basePath);
  };

  getTestoFunzione(funzione: ModalitaPaginaGestioneElemento, isTitolo: boolean = true): string {
    switch (funzione) {
      case ModalitaPaginaGestioneElemento.DETTAGLIO:
        return isTitolo ? 'Dettaglio' : 'visualizzare';
        break;
      case ModalitaPaginaGestioneElemento.AGGIUNGI:
        return isTitolo ? 'Aggiungi' : 'aggiungere';
        break;
      case ModalitaPaginaGestioneElemento.MODIFICA:
        return isTitolo ? 'Modifica' : 'modificare';
        break;
      default:
        return '';
    }
  }

}
