export class SorterUtils {

  private static readonly ESTERO_LABEL = "estero"

  static sorterProvince = function (p1, p2) {
    if (p1["label"].toLowerCase() === SorterUtils.ESTERO_LABEL)
      return -1;

    if (p2["label"].toLowerCase() === SorterUtils.ESTERO_LABEL)
      return 1;

    if (p1["label"] < p2["label"])
      return -1;

    if (p1["label"] > p2["label"])
      return 1;

    return 0;
  }
}
