import { DettaglioQuadratura } from '../../modules/main/model/quadratura/DettaglioQuadratura';
import { PdfTable, PdfBuilder } from './PdfBuilder';
import { Utils } from '../Utils';
export class StampaDettagliPdf {
  static async stampaDettagli(filename: string, quadrature: DettaglioQuadratura[]): Promise<void> {
    const commonHeaders = [
      "ID Flusso",
      "Data transazione",
      "IUV",
      "Pagatore (Cod. fiscale)",
      "Importo",
      "Stato",
      "Servizio",
      "Codice Tassonomia",
      "Altro"
    ]

    const pdf = new PdfBuilder(filename)

    // Esamino i dettagli delle transazioni recuperate
    quadrature.forEach(quadratura => {
      for (let t of quadratura.listaDettaglioTransazione) {
        if (t.servizioNome) {
          const table = t.servizioNome

          // Controllo se nell'excel esiste già la sheet del nome servizio, altrimenti la aggiungo con gli headers comuni
          if (!pdf.tables[table]) {
            pdf.tables[table] = new PdfTable()
            pdf.tables[table].headers = commonHeaders
            //headersCampiPersonalizzati.set(sheetName, new Set<string>())
          }

          // Crea riga per excel builder
          const row = {}
          row["ID Flusso"] = quadratura.flussoId
          row["Data transazione"] = t.dataTransazione
          row["IUV"] = t.iuv
          row["Pagatore (Cod. fiscale)"] = t.pagatoreCodiceFiscale
          row["Importo"] = Utils.formattaImporto(t.importo)
          row["Stato"] = t.stato
          row["Servizio"] = t.servizioNome
          row["Codice Tassonomia"] = t.codiceTassonomia
          row["Altro"] = ""

          let annotationTitle: string = ""
          let annotationText: string = ""

          // Analisi dei campi personalizzati
          if (t.campiPersonalizzati) { // Se i campi personalizzati sono null, il servizio è del modello 3
            const campi: DettaglioCampoPersonalizzato[] = []

            t.campiPersonalizzati.forEach((cp) => {
              campi.push(new DettaglioCampoPersonalizzato(cp.codice, cp.valore, cp.posizione))
            })

            campi.sort((cp1, cp2) => {
              return cp1.posizione - cp2.posizione
            })

            annotationTitle = "Campi Personalizzati"
            annotationText = campi.map(d => d.toAnnotazione()).join(" | ")
          } else {
            const metadati: DettaglioMetadata[] = []

            t.listaMetadatiPagamento.forEach((mdp) => {
              metadati.push(new DettaglioMetadata(mdp.chiave, mdp.valore, true, mdp.pagopa))
            })
            t.listaMetadatiVersamento.forEach((mdv) => {
              metadati.push(new DettaglioMetadata(mdv.chiave, mdv.valore, false, mdv.pagopa))
            })

            metadati.sort((m1, m2) => {
              if (m1.pagamento == m2.pagamento) {
                if (m1.pagopa == m2.pagopa) {
                  return m1.chiave.localeCompare(m2.chiave)
                } else {
                  if (m1.pagopa) {
                    return -1
                  } else {
                    return 1
                  }
                }
              } else {
                if (m1.pagamento) {
                  return -1
                } else {
                  return 1
                }
              }
            })

            annotationTitle = "Metadata"
            annotationText = metadati.map(d => d.toAnnotazione()).join(" | ")
          }

          const rowIndex = pdf.tables[table].rows.length
          const columnIndex = 8

          pdf.tables[table].addCellAnnotation(rowIndex, columnIndex, annotationTitle, annotationText)

          // Aggiungo il dettaglio transazione come row nello sheet appropriato
          pdf.tables[table].rows.push(row)
        }
      }
    })

    pdf.print()
  }
}

class DettaglioCampoPersonalizzato {
  codice: string
  valore: string
  posizione: number

  constructor(codice: string, valore: string, posizione: number) {
    this.codice = codice
    this.valore = valore
    this.posizione = posizione
  }

  toAnnotazione(): string {
    return "[" + this.codice + "] " + this.valore
  }
}

class DettaglioMetadata {
  chiave: string
  valore: string
  pagamento: boolean
  pagopa: boolean

  constructor(chiave: string, valore: string, pagamento: boolean = false, pagopa: boolean = false) {
    this.chiave = chiave
    this.valore = valore
    this.pagamento = pagamento
    this.pagopa = pagopa
  }

  toAnnotazione(): string {
    let res = "[" + this.chiave + "] " + this.valore

    if (this.pagamento || this.pagopa) {
      res += " ("

      const flags: string[] = []

      if (this.pagamento)
        flags.push("pagamento")
      if (this.pagopa)
        flags.push("pagopa")

      res += flags.join(", ") + ")"
    }

    return res
  }
}
