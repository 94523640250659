export class LepidaPalette {

  constructor() {}

  static PRIMARY = '#602d9b';
  static SECONDARY = '#636363';
  static SUCCESS = '#77EE00';
  static DANGER = '#ee0000';
  static WARNING = '#EEEE00';
  static INFO = '#00EEEE';
  static BG = '#f6f6f6';
}
