<div class="sidebar" data-color="white" data-active-color="danger">
  <div class="logo" *ngIf="!menuService.isUtenteAnonimo">
    <a routerLink="/" class="navbar-toggle simple-text logo-mini">
      <div class="logo-image-small">
        <img alt="" src="../assets/img/it-user.png">
      </div>
    </a>
    <a routerLink="/" class="navbar-toggle simple-text logo-normal">
      {{ nomeUtente }}
    </a>
  </div>
  <div *ngIf="!waiting"
       [ngClass]="{'sidebar-wrapper': true, 'sidebar-overflow': isUtenteAnonimo, 'pb-0': !isUtenteAnonimo}">
    <ul class="link-list none-parent">
      <ng-container *ngFor="let item of menu | orderBy:'posizione'; let i = index">
        <li [ngClass]="{'active': item.nome == selectedElement}" (click)="getRouterLink(item, menu)">
          <a class="list-item medium" [ngClass]="{'active': item.nome == selectedElement}"
             data-toggle="collapse" [attr.data-target]="'#coll-' + i" [attr.aria-expanded]="false"
             (click)="item['isExpanded'] = !item['isExpanded']">
            {{item.nome}}
            <ng-container *ngIf="!item.route">
              <span>
                <svg class="icon icon-sm icon-primary right" aria-hidden="true">
                  <use [attr.xlink:href]="item['isExpanded'] ? 'assets/img/sprite.svg#it-collapse' : 'assets/img/sprite.svg#it-expand'"
                       xlink:href=""></use>
                </svg>
              </span>
            </ng-container>
          </a>
          <ul [attr.id]="'coll-' + i" class="link-sublist collapse none"
              *ngIf="item.sotto_menu">
            <ng-container *ngFor="let sub of item.sotto_menu | orderBy:'posizione'; let j = index">
              <li (click)="getRouterLink(sub)">
                <a class="list-item medium"
                   [ngClass]="{'active': sub.nome == selectedElement}"
                   data-toggle="collapse" [attr.data-target]="'#collSub-' + j"
                   [attr.aria-expanded]="false" (click)="sub['isExpanded'] = !sub['isExpanded']">
                  {{sub.nome}}
                  <ng-container *ngIf="!sub.route">
                     <span>
                       <svg class="icon icon-sm icon-primary right" aria-hidden="true">
                         <use [attr.xlink:href]="!sub['isExpanded'] ? 'assets/img/sprite.svg#it-collapse' : 'assets/img/sprite.svg#it-expand'"
                              xlink:href=""></use>
                       </svg>
                     </span>
                  </ng-container>
                </a>
                <ul [attr.id]="'collSub-' + j"
                    class="link-sublist collapse none show"
                    *ngIf="sub.funzioni">
                  <ng-container *ngFor="let subItem of sub.funzioni">
                    <li (click)="getRouterLink(subItem)">
                      <a class="list-item medium active"
                         data-toggle="collapse" aria-expanded="true">{{subItem.nome}}</a>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ul>
          <ul [attr.id]="'coll-' + i"
              class="link-sublist collapse none"
              *ngIf="item.funzioni">
            <ng-container *ngFor="let sub of item.funzioni">
              <li (click)="getRouterLink(sub)">
                <a class="list-item medium" [ngClass]="{'active': sub.nome == selectedElement}"
                   data-toggle="collapse" [attr.aria-expanded]="false"
                   (click)="sub['isExpanded'] = !sub['isExpanded']">
                  {{sub.nome}}
                  <ng-container *ngIf="!sub.route">
                     <span>
                       <svg class="icon icon-sm icon-primary right" aria-hidden="true">
                         <use [attr.xlink:href]="sub['isExpanded'] ? 'assets/img/sprite.svg#it-collapse' : 'assets/img/sprite.svg#it-expand'"
                              xlink:href=""></use>
                       </svg>
                     </span>
                  </ng-container>
                </a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="logo-rer">
    <small>Versione: {{ versionApplicativo }}</small>
  </div>
</div>
