<div class="my-2">
  <div *ngIf="this.showLabel" class="field-label">
    <ng-content select="label"></ng-content><span class="required-mark" *ngIf="this.isControlRequired() && this.showRequiredIndicator"> *</span>
  </div>

  <ng-content></ng-content>

  <ng-content select="payer-form-feedback"></ng-content>

</div>
