import {
  AfterContentInit,
  Component,
  Directive,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { FormFieldComponent } from "./../form-field/form-field.component";

@Component({
  selector: "payer-form-feedback",
  templateUrl: "./form-feedback.component.html",
  styleUrls: ["./form-feedback.component.scss"],
})
export class FormFeedbackComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  @Input()
  @Optional()
  private control: FormControl;

  @Input()
  private showHintOnDisabled: boolean = false;

  private _feedbacks: { [k: string]: FeedbackView } = {};
  private _hintView: FeedbackView;
  private _disabledView: FeedbackView;
  private _activeView: FeedbackView;

  private _subs: Subscription[] = [];

  constructor(@Optional() private field: FormFieldComponent) {}

  ngOnInit(): void {
    this.showHint();
  }

  ngAfterContentInit() {
    // TODO Versione da usare una volta aggiornato angular
    // this.control.registerOnChange(this.updateFeedback.bind(this));
    // this.control.registerOnDisabledChange(this.updateDisabled.bind(this));

    if (!this.control && this.field) {
      this.control = this.field.control;
    }

    const changeSub = this.control.valueChanges.subscribe(() => {
      this.updateFeedback();
    });
    this._subs.push(changeSub);

    const statusSub = this.control.statusChanges.subscribe((s) => {
      this.updateDisabled(s === "DISABLED");
    });
    this._subs.push(statusSub);

    this.updateDisabled(this.control.disabled);
  }

  ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  updateFeedback(): void {
    if (this.control.errors) {
      const err: string = Object.keys(this.control.errors)[0];

      if (this._feedbacks[err]) {
        this._activateView(this._feedbacks[err]);
      } else {
        this.showHint();
      }
    } else {
      this.showHint();
    }
  }

  updateDisabled(isDisabled: boolean): void {
    if (isDisabled) {
      if (this._disabledView) {
        this._activateView(this._disabledView);
      } else if (this.showHintOnDisabled) {
        this.showHint();
      } else {
        this._clearView();
      }
    } else {
      this.updateFeedback();
    }
  }

  showHint(): void {
    if (this._hintView) {
      this._activateView(this._hintView);
    } else {
      this._clearView();
    }
  }

  addFeedbackView(error: string, view: FeedbackView): void {
    this._feedbacks[error] = view;
  }

  addDisabledView(view: FeedbackView) {
    if (this._disabledView) {
      throw new Error(
        "payer-form-feedback può contenere solo un messaggio di controllo disabilitato."
      );
    }
    this._disabledView = view;
  }

  addHintView(view: FeedbackView) {
    if (this._hintView) {
      throw new Error(
        "payer-form-feedback può contenere solo un messaggio di hint."
      );
    }
    this._hintView = view;
  }

  private _clearView(): void {
    if (this._activeView) this._activeView.destroy();
  }

  private _activateView(view: FeedbackView): void {
    if (view && view != this._activeView) {
      this._clearView();
      this._activeView = view;
      this._activeView.create();
    }
  }
}

@Directive({
  selector: "[payerFormError]",
})
export class PayerFormErrorDirective implements OnInit {
  @Input()
  payerFormError: string;

  constructor(
    private _vcr: ViewContainerRef,
    private _tr: TemplateRef<Object>,
    @Optional() @Host() private feedbacker: FormFeedbackComponent
  ) {
    if (!this.feedbacker) {
      throw new Error("payer-form-feedback assente.");
    }
  }

  ngOnInit(): void {
    this.feedbacker.addFeedbackView(
      this.payerFormError,
      new FeedbackView(this._vcr, this._tr, "error-feedback")
    );
  }
}

@Directive({
  selector: "[payerFormHint]",
})
export class PayerFormHintDirective {
  constructor(
    private _vcr: ViewContainerRef,
    private _tr: TemplateRef<Object>,
    @Optional() @Host() protected feedbacker: FormFeedbackComponent
  ) {
    if (!feedbacker) {
      throw new Error("payer-form-feedback assente.");
    }
    this.feedbacker.addHintView(new FeedbackView(_vcr, _tr));
  }
}

@Directive({
  selector: "[payerFormDisabled]",
})
export class PayerFormDisabledDirective {
  constructor(
    protected _vcr: ViewContainerRef,
    protected _tr: TemplateRef<Object>,
    @Optional() @Host() protected feedbacker: FormFeedbackComponent
  ) {
    if (!feedbacker) {
      throw new Error("payer-form-feedback assente.");
    }
    this.feedbacker.addDisabledView(new FeedbackView(_vcr, _tr));
  }
}

class FeedbackView {
  private _useClass: boolean = false;

  constructor(
    private _vcr: ViewContainerRef,
    private _tr: TemplateRef<Object>,
    private _class?: string
  ) {
    this._useClass = this._class != null;
  }

  create(): void {
    const view = this._vcr.createEmbeddedView(this._tr);
    if (this._useClass) {
      view.rootNodes[0].classList.add(this._class);
    }
  }

  destroy(): void {
    this._vcr.clear();
  }
}
