<div class="container-fluid">
  <!-- breadcrumb -->
  <it-breadcrumb [dark]="false" [separator]="'/'">
    <it-breadcrumb-item [id]="'breadcrumb-item-' + item.id" *ngFor="let item of breadcrumbList; let i = index"
                        >
      {{item.label}}
    </it-breadcrumb-item>
  </it-breadcrumb>

  <!-- titolo -->
  <h3 class="mb-2 page-title"><strong>{{titoloPagina}}</strong>
    <span it-tooltip="{{tooltipTitolo}}">
      <svg class="bg-light icon icon-lg icon-padded">
        <use xlink:href="assets/img/sprite.svg#it-help-circle"></use>
      </svg>
    </span>
  </h3>
  <span *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">Tutti i campi contrassegnati con * sono obbligatori</span>
  <!-- inizio Dati Ente -->
  <app-dati-ente [datiEnte]="datiEnte" [funzione]="funzione" (isFormValid)="isFormDatiEnteValido = $event"
  [idFunzione]="idFunzione" (onChangeDatiEnte)="onChangeDatiEnte($event)"></app-dati-ente>
  <!-- fine Dati Ente -->

  <!-- inizio Conti Correnti Ente -->
  <!--  inizio Header -->
  <div class="collapse-header" id="idCollapseContiCorrentiEnte">
    <button data-toggle="collapse" [id]="'buttonCCECollapse' + uuid" aria-expanded="true" aria-controls="contenutoSezioneDatiContoCorrente">
      <div class="row">
        <div class="col">
          Conti Correnti Ente
        </div>
      </div>
    </button>
  </div>
  <!--  fine Header -->
  <div [id]="'collapseCCE'+uuid" class="collapse show" role="tabpanel" aria-labelledby="idCollapseContiCorrentiEnte">
    
    <div class="row">
      <div class="col">
        <div #datiContoCorrente></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-right">
        <button id="buttonAggiungiContoCorrente" class="btn btn-secondary"
                [disabled]="disabilitaBottoneContoCorrente()" (click)="aggiungiContoCorrente()">
          Aggiungi conto corrente
        </button>
        <button id="buttonSalvaContoCorrente" class="btn" [disabled]="disabilitaBottone()" (click)="onClickSalva()">
          Salva
        </button>
      </div>
    </div>
  
  </div>
  <!--  fine Conti Correnti Ente -->

  <!-- inizio Dati Beneficiario -->
  <div #datiBeneficiario></div>
  <!--  fine Dati Beneficiario -->

</div>

<!-- Bottoni -->
<div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
  <!-- Bottone sx -->
  <div class="col-2">
    <button id="buttonAnnulla" class="btn btn-secondary"
            (click)="onClickAnnullaButton()">Annulla</button>
  </div>
  <!-- Bottoni dx -->
  <div class="col-10 text-right">
    <button id="buttonAggiungiBeneficiario" class="btn btn-secondary"
            [disabled]="disabilitaBottoneBeneficiario()" (click)="aggiungiBeneficiario()">
      Aggiungi beneficiario
    </button>
    <button id="buttonSalva" class="btn" [disabled]="disabilitaBottone()" (click)="onClickSalva()">
      Salva
    </button>
  </div>
</div>
<div class="row pt-4" *ngIf="funzione === FunzioneGestioneEnum.DETTAGLIO">
  <button type="button" id="buttonIndietro" class="btn btn-secondary btn-indietro" (click)="onClickAnnulla(funzione)">Indietro</button>
</div>

<!-- Modale -->
<p-confirmDialog #cb icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" class="undoButton" pButton icon="pi pi-times" label="Annulla" (click)="cb.reject()"></button>
    <button type="button" class="okButton" pButton icon="pi pi-check" label="Ok" (click)="cb.accept()"></button>
  </p-footer>
</p-confirmDialog>
