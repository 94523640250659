<h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>
<h4>Heading 4</h4>
<h5>Heading 5</h5>
<h6>Heading 6</h6>

<hr>

<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc pretium quis elit eu venenatis. Nullam placerat tempus
  nisi ut interdum. Mauris dignissim aliquet turpis ac ultricies. Praesent pretium quis turpis a tincidunt. Nulla
  tincidunt dapibus sodales. In eget libero laoreet, laoreet mi convallis, semper odio. Ut elementum lacus eget pretium
  accumsan.</p>

<hr>

<div class="d-flex flex-column">
  <a href=""> Anchor text </a>
  <b> Bold text </b>
  <strong> Important text </strong>
  <i> Italic text </i>
  <em> Emphasized text </em>
  <mark> Marked text </mark>
  <small> Smaller text </small>
  <del> Deleted text </del>
  <ins> Inserted text </ins>
  <span>Normal text<sub> Subscript text </sub></span>
  <span>Normal text<sup> Superscript text </sup></span>
</div>

<hr>

<div>
  <button type="button" class="btn btn-primary">Primary</button>
  <button type="button" class="btn btn-secondary">Secondary</button>
  <button type="button" class="btn btn-success">Success</button>
  <button type="button" class="btn btn-danger">Danger</button>
  <button type="button" class="btn btn-warning">Warning</button>
  <button type="button" class="btn btn-info">Info</button>
  <button type="button" class="btn btn-light">Light</button>
  <button type="button" class="btn btn-dark">Dark</button>
  <button type="button" class="btn btn-link">Link</button>
</div>

<div>
  <button disabled type="button" class="btn btn-primary">Primary</button>
  <button disabled type="button" class="btn btn-secondary">Secondary</button>
  <button disabled type="button" class="btn btn-success">Success</button>
  <button disabled type="button" class="btn btn-danger">Danger</button>
  <button disabled type="button" class="btn btn-warning">Warning</button>
  <button disabled type="button" class="btn btn-info">Info</button>
  <button disabled type="button" class="btn btn-light">Light</button>
  <button disabled type="button" class="btn btn-dark">Dark</button>
  <button disabled type="button" class="btn btn-link">Link</button>
</div>

<div>
  <button type="button" class="btn btn-outline-primary">Primary</button>
  <button type="button" class="btn btn-outline-secondary">Secondary</button>
  <button type="button" class="btn btn-outline-success">Success</button>
  <button type="button" class="btn btn-outline-danger">Danger</button>
  <button type="button" class="btn btn-outline-warning">Warning</button>
  <button type="button" class="btn btn-outline-info">Info</button>
  <button type="button" class="btn btn-outline-light">Light</button>
  <button type="button" class="btn btn-outline-dark">Dark</button>
  <button type="button" class="btn btn-outline-link">Link</button>
</div>

<div>
  <button disabled type="button" class="btn btn-outline-primary">Primary</button>
  <button disabled type="button" class="btn btn-outline-secondary">Secondary</button>
  <button disabled type="button" class="btn btn-outline-success">Success</button>
  <button disabled type="button" class="btn btn-outline-danger">Danger</button>
  <button disabled type="button" class="btn btn-outline-warning">Warning</button>
  <button disabled type="button" class="btn btn-outline-info">Info</button>
  <button disabled type="button" class="btn btn-outline-light">Light</button>
  <button disabled type="button" class="btn btn-outline-dark">Dark</button>
  <button disabled type="button" class="btn btn-outline-link">Link</button>
</div>

<hr>

<div class="d-flex justify-content-around">
  <input type="checkbox">
  <input type="checkbox" disabled>
  <input type="checkbox" checked>
  <input type="checkbox" checked disabled>
  <input type="checkbox" [indeterminate]="true">
  <input type="checkbox" [indeterminate]="true" disabled>
</div>

<hr>

<div class="d-flex justify-content-around">
  <input type="checkbox" class="toggle">
  <input type="checkbox" class="toggle" disabled>
  <input type="checkbox" class="toggle" checked>
  <input type="checkbox" class="toggle" checked disabled>
</div>