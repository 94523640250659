import { boolean } from "fp-ts";

export type SelectOption<T> = {
  value: T;
  label: string;
};

export type BffFiltroSelect = {
  id: number;
  nome: string;
};

export type SimpleComparator<T> = (a: T, b: T) => boolean;

export function compareOn<T>(field: string): SimpleComparator<T> {
  return (a: T, b: T) => {
    return a && b ? a[field] === b[field] : a == b;
  }
}
