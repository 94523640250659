import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { UserIdleModule } from "angular-user-idle";
import { DesignAngularKitModule } from "design-angular-kit";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { DpDatePickerModule } from "ng2-date-picker";
import { NgxCurrencyModule } from "ngx-currency";
import { CustomFormsModule } from "ngx-custom-validators";
import { AccordionModule } from "primeng/accordion";
import { ConfirmationService } from "primeng/api";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { ToolbarModule } from "primeng/toolbar";
import { TreeTableModule } from "primeng/treetable";
import { configUserIdle } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BannerComponent } from "./components/banner/banner.component";
import { CommonComponentModule } from "./components/common-component.module";
import { HeaderComponent } from "./components/header/header.component";
import { LoginBarComponent } from "./components/login-bar/login-bar.component";
import { OverlayComponent } from "./components/overlay-dati-pagamento/overlay.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { PayerCoreModule } from "./core/core.module";
import { AmministrativoModule } from "./modules/main/components/amministrativo/amministrativo.module";
import { HomeComponent } from "./modules/main/components/home/home.component";
import { IMieiPagamentiComponent } from "./modules/main/components/i-miei-pagamenti/i-miei-pagamenti.component";
import { CompilaNuovoPagamentoComponent } from "./modules/main/components/nuovo-pagamento/compila-nuovo-pagamento/compila-nuovo-pagamento.component";
import { DatiNuovoPagamentoComponent } from "./modules/main/components/nuovo-pagamento/dati-nuovo-pagamento/dati-nuovo-pagamento.component";
import { NuovoPagamentoComponent } from "./modules/main/components/nuovo-pagamento/nuovo-pagamento.component";
import { MainModule } from "./modules/main/main.module";
import { PipeModule } from "./pipe/pipe.module";
import { PayerUtilityPagesModule } from "./utility/pages/payer-utility-pages.module";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SidebarComponent,
    LoginBarComponent,
    BannerComponent,
    HeaderComponent,
    NuovoPagamentoComponent,
    IMieiPagamentiComponent,
    CompilaNuovoPagamentoComponent,
    DatiNuovoPagamentoComponent,
    OverlayComponent,
  ],
  imports: [
    PayerCoreModule,
    PayerUtilityPagesModule,

    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MainModule,
    NgbModule,
    FormsModule,

    ReactiveFormsModule,
    DesignAngularKitModule,
    NgxDatatableModule,
    AccordionModule,
    TableModule,
    ButtonModule,
    BrowserAnimationsModule,
    TabViewModule,
    ToolbarModule,
    DpDatePickerModule,
    NgxCurrencyModule,
    CustomFormsModule,
    ConfirmDialogModule,
    BrowserAnimationsModule,
    CustomFormsModule,
    AutoCompleteModule,
    TreeTableModule,
    PipeModule,
    CommonComponentModule,
    AmministrativoModule,
    NgMultiSelectDropDownModule,

    UserIdleModule.forRoot(configUserIdle),
  ],
  exports: [
    UserIdleModule
  ],
  providers: [ConfirmationService],
  bootstrap: [AppComponent],
})
export class AppModule {}
