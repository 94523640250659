<div class="container-fluid">
  <!-- breadcrumb -->
  <it-breadcrumb [dark]="false" [separator]="'/'">
    <it-breadcrumb-item [id]="'breadcrumb-item-' + item.id" *ngFor="let item of breadcrumbList; let i = index"
                        >
      {{item.label}}
    </it-breadcrumb-item>
  </it-breadcrumb>

  <!-- titolo -->
  <h3 class="mb-2 page-title"><strong>{{titoloPagina}}</strong>
    <span it-tooltip="{{tooltipTitle}}">
      <svg class="bg-light icon icon-lg icon-padded">
        <use xlink:href="assets/img/sprite.svg#it-help-circle"></use>
      </svg>
    </span>
  </h3>

  <!-- sezione aggiungi dati-utente -->
  <app-dati-utente (onChangeDatiUtente)="onChangeDatiUtenti($event)"
                   (onValidaFormDatiUtenti)="isFormDatiUtenteValido = $event"
                   [datiUtente]="datiUtente"  [codiceFiscale]="codiceFiscaleRecuperato"
                   [idFunzione]="idFunzione" [funzione]="funzione"></app-dati-utente>

  <hr>

  <!-- sezione aggiungi dati-permesso -->
  <div #datiPermesso></div>
</div>
<!-- Bottoni -->
<div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
  <!-- Bottone sx -->
  <div class="col-2">
    <button id="buttonAnnulla" class="btn btn-secondary" (click)="onClickAnnullaButton()">Annulla</button>
  </div>
  <!-- Bottoni dx -->
  <div class="col-10 text-right">
    <button id="buttonAggiungiPermessi" class="btn btn-secondary"
            [disabled]="disabilitaBottone()"
            (click)="aggiungiSezionePermesso()">Aggiungi permessi
    </button>
    <button id="buttonSalva" class="btn" [disabled]="disabilitaBottone()" (click)="onClickSalva()">
      Salva
    </button>
  </div>
</div>
<div class="row pt-4" *ngIf="funzione === FunzioneGestioneEnum.DETTAGLIO">
  <button type="button" id="buttonIndietro" class="btn btn-secondary btn-indietro" (click)="onClickAnnulla(funzione)">Indietro</button>
</div>

<!-- Modale -->
<p-confirmDialog #cb icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" class="undoButton" pButton icon="pi pi-times" label="Annulla" (click)="cb.reject()"></button>
    <button type="button" class="okButton" pButton icon="pi pi-check" label="Ok" (click)="cb.accept()"></button>
  </p-footer>
</p-confirmDialog>
