import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { map } from 'rxjs/operators';
import { EsitoEnum } from '../../../../enums/esito.enum';
import { getBannerType, LivelloBanner } from '../../../../enums/livelloBanner.enum';
import { StatoPagamentoEnum } from '../../../../enums/statoPagamento.enum';
import { tipoColonna } from '../../../../enums/TipoColonna.enum';
import { TipoModaleEnum } from '../../../../enums/tipoModale.enum';
import { TipoPagamentoEnum } from '../../../../enums/tipoPagamento.enum';
import { tipoTabella } from '../../../../enums/TipoTabella.enum';
import { ToolEnum } from '../../../../enums/Tool.enum';
import { ParametriRicercaPagamenti } from '../../../../features/amministra-portale/gestisci-utenti/model/ParametriRicercaPagamenti';
import { BannerService } from '../../../../services-old/banner.service';
import { IMieiPagamentiService } from '../../../../services-old/i-miei-pagamenti.service';
import { NuovoPagamentoService } from '../../services/nuovo-pagamento.service';
import { OverlayService } from '../../../../shared/services/overlay.service';
import { SpinnerOverlayService } from "../../../../shared/services/spinner-overlay.service";
import { Banner } from '../../../../shared/common/model/Banner';
import { Utils } from '../../../../utility/Utils';
import { DatiPagamento } from '../../model/DatiPagamento';
import { Breadcrumb } from 'src/app/modules/main/dto/Breadcrumb';
import { ListaPagamentiFiltri } from '../../model/ListaPagamentiFiltri';
import { DettagliTransazione } from '../../model/DettagliTransazione';

@Component({
  selector: "app-i-miei-pagamenti",
  templateUrl: "./i-miei-pagamenti.component.html",
  styleUrls: ["./i-miei-pagamenti.component.scss"],
})
export class IMieiPagamentiComponent implements OnInit {
  tooltipOnPageTitle =
    "In questa pagina puoi consultare la lista completa dei pagamenti e filtrarli";
  tooltipPendenzaNotInsertedFromEnte =
    "Attenzione: questo non è un pagamento inserito automaticamente dall'Ente";
  tooltipNewPagamento = "Nuovo Pagamento";
  tooltipDeletePagamento = "Elimina Pagamento";
  tooltipVisualizeDownloadPagamento =
    "Visualizza/Scarica gli attestati del pagamento";
  tooltipAddToCarrello = "Aggiungi al carrello";

  MESSAGGIO_ERRORE_AZIONE =
    "Operazione non consentita! Uno o più bollettini sono già stati pagati o in corso di pagamento. Per maggiori informazioni contattare l’help desk";
  MESSAGGIO_ERRORE_AZIONE_INSERIMENTO_CARRELLO =
    "Operazione non consentita! Uno o più bollettini sono già presenti nel tuo carrello";
  MESSAGGIO_ERRORE_AZIONE_STAMPA_ATTESTATO =
    "Operazione non consentita! Uno o più bollettini non sono stati pagati o in corso di pagamento. Per maggiori informazioni contattare l’help desk";
  TOOLTIP_ICONA_MATITA =
    "Attenzione: questo non è un pagamento inserito automaticamente dall'Ente";
  // breadcrumb
  breadcrumbList = [];

  // tabs
  tabs = [
    { value: TipoPagamentoEnum.IN_ATTESA },
    { value: TipoPagamentoEnum.PAGATI },
  ];

  // toolbar
  toolbarIcons = [
    { type: ToolEnum.INSERT, tooltip: "Aggiungi Nuovo Pagamento" },
    {
      type: ToolEnum.INSERT_CARRELLO,
      disabled: true,
      tooltip: "Aggiungi al carrello",
    },
    { type: ToolEnum.DELETE, disabled: true, tooltip: "Elimina pagamento" },
    {
      type: ToolEnum.EXPORT_PDF,
      disabled: true,
      tooltip: "Stampa/download pdf",
    },
  ];

  readonly indiceIconaElimina = 2;
  readonly indiceIconaCarrello = 1;
  readonly indiceIconaExport = 3;

  // table
  tableData = {
    rows: [],
    cols: [
      {
        field: "identificativo",
        header: "IDENTIFICATIVO",
        type: tipoColonna.TESTO,
      },
      { field: "annoDocumento", header: "Anno", type: tipoColonna.TESTO },
      { field: "causale", header: "Causale", type: tipoColonna.TESTO },
      { field: "nomeServizio", header: "Servizio", type: tipoColonna.TESTO },
      { field: "nomeEnte", header: "Ente", type: tipoColonna.TESTO },
      { field: "dataScadenza", header: "Scadenza", type: tipoColonna.TESTO },
      { field: "importo", header: "Importo", type: tipoColonna.IMPORTO },
    ],
    dataKey: "identificativo.value",
    tipoTabella: tipoTabella.CHECKBOX_SELECTION,
  };

  tableDataInAttesa = {
    rows: [],
    cols: [
      {
        field: "identificativo",
        header: "IDENTIFICATIVO",
        type: tipoColonna.TESTO,
      },
      { field: "annoDocumento", header: "Anno", type: tipoColonna.TESTO },
      { field: "causale", header: "Causale", type: tipoColonna.TESTO },
      { field: "nomeServizio", header: "Servizio", type: tipoColonna.TESTO },
      { field: "nomeEnte", header: "Ente", type: tipoColonna.TESTO },
      { field: "dataScadenza", header: "Scadenza", type: tipoColonna.TESTO },
      { field: "importo", header: "Importo", type: tipoColonna.IMPORTO },
    ],
    dataKey: "identificativo.value",
    tipoTabella: tipoTabella.CHECKBOX_SELECTION,
  };

  tableDataPagati = {
    rows: [],
    cols: [
      {
        field: "identificativo",
        header: "IDENTIFICATIVO",
        type: tipoColonna.TESTO,
      },
      { field: "annoDocumento", header: "Anno", type: tipoColonna.TESTO },
      { field: "causale", header: "Causale", type: tipoColonna.TESTO },
      { field: "nomeServizio", header: "Servizio", type: tipoColonna.TESTO },
      { field: "nomeEnte", header: "Ente", type: tipoColonna.TESTO },
      { field: "dataScadenza", header: "Scadenza", type: tipoColonna.TESTO },
      { field: "importo", header: "Importo", type: tipoColonna.IMPORTO },
      { field: "dataPagamento", header: "Pagamento", type: tipoColonna.TESTO },
    ],
    dataKey: "identificativo.value",
    tipoTabella: tipoTabella.CHECKBOX_SELECTION,
  };

  tempTableData;
  private listaPagamenti: DatiPagamento[] = [];
  private pagamentiSelezionati: DatiPagamento[];
  selectionPagamenti: any[];
  private filtri: ParametriRicercaPagamenti;
  private nomeTabCorrente: string;

  constructor(
    private iMieiPagamentiService: IMieiPagamentiService,
    private router: Router,
    private nuovoPagamentoService: NuovoPagamentoService,
    private bannerService: BannerService,
    private overlayService: OverlayService,
    private confirmationService: ConfirmationService,
    private spinnerOverlayService: SpinnerOverlayService
  ) {
    // init breadcrumb
    this.inizializzaBreadcrumb();
  }

  private inizializzaBreadcrumb() {
    this.breadcrumbList.push(new Breadcrumb(0, "Home", null, null));
    this.breadcrumbList.push(new Breadcrumb(1, "Pagamenti", null, null));
    this.breadcrumbList.push(new Breadcrumb(2, "I Miei Pagamenti", null, null));
  }

  ngOnInit(): void {
    localStorage.removeItem("parziale");
    this.inizializzaListaPagamenti(new ParametriRicercaPagamenti());
    this.getCarrello();
  }

  private inizializzaListaPagamenti(filtri: ParametriRicercaPagamenti) {
    this.iMieiPagamentiService
      .ricercaPagamenti(filtri)
      .pipe(
        map((listaPagamenti) => {
          this.riempiListaPagamenti({ listaPagamenti, filtri });
        })
      )
      .subscribe();
  }

  onChangeTab(value) {
    const subscription = this.spinnerOverlayService.spinner$.subscribe();
    let tempListaPagamenti;
    if (value === TipoPagamentoEnum.IN_ATTESA) {
      this.tableData = this.tableDataInAttesa;
      tempListaPagamenti = this.listaPagamenti.filter(
        (pagamento) =>
          pagamento.esitoPagamento !== EsitoEnum.OK &&
          (pagamento.statoPagamento != null
            ? pagamento.statoPagamento === StatoPagamentoEnum.IN_ATTESA
            : true)
      );
      tempListaPagamenti.sort((a, b) => this.sortByDataScadenza(a, b));
    } else if (value === TipoPagamentoEnum.PAGATI) {
      this.tableData = this.tableDataPagati;
      tempListaPagamenti = this.listaPagamenti.filter(
        (pagamento) => pagamento.esitoPagamento === EsitoEnum.OK
      );
      tempListaPagamenti.sort((a, b) => this.sortByPagamento(a, b));
    }
    this.nomeTabCorrente = value;
    this.riempiTabella(tempListaPagamenti);
    setTimeout(() => subscription.unsubscribe(), 500);
  }

  eseguiAzioni(tool) {
    if (tool === ToolEnum.INSERT) {
      // redirect nuovo pagamento page
      this.router.navigateByUrl("/nuovoPagamento");
    } else if (tool === ToolEnum.INSERT_CARRELLO) {
      // inserisce pagamento nel carrello
      this.inserimentoCarrello();
    } else if (tool === ToolEnum.DELETE) {
      // elimina pagamenti
      this.eliminaPagamenti();
    } else if (tool === ToolEnum.EXPORT_PDF) {
      // esporta in excel
      this.stampaAttestatiPagamento();
    }
    this.selectionPagamenti = [];
  }

  testoTabella(): string {
    if (this.listaPagamenti) {
      const numeroPagati = this.listaPagamenti.filter(
        (pagamento) => pagamento.esitoPagamento === EsitoEnum.OK
      ).length;
      const numeroInAttesa = this.listaPagamenti.filter(
        (pagamento) =>
          pagamento.esitoPagamento !== EsitoEnum.OK &&
          (pagamento.statoPagamento != null
            ? pagamento.statoPagamento === StatoPagamentoEnum.IN_ATTESA
            : true)
      ).length;
      return (
        "Totale: " +
        this.listaPagamenti.length +
        "\b Di cui pagati: " +
        numeroPagati +
        "\b\b Di cui in attesa: " +
        numeroInAttesa
      );
    } else {
      return "";
    }
  }

  riempiListaPagamenti(listaPagamentiFiltri: ListaPagamentiFiltri) {
    this.listaPagamenti = listaPagamentiFiltri.listaPagamenti;
    this.filtri = listaPagamentiFiltri.filtri;
    if (listaPagamentiFiltri.listaPagamenti != null) {
      if (this.nomeTabCorrente == null) {
        this.nomeTabCorrente = TipoPagamentoEnum.IN_ATTESA;
        this.tableData = this.tableDataInAttesa;
        listaPagamentiFiltri.listaPagamenti =
          listaPagamentiFiltri.listaPagamenti.sort((a, b) =>
            this.sortByDataScadenza(a, b)
          );
        this.riempiTabella(listaPagamentiFiltri.listaPagamenti);
      }
      this.onChangeTab(this.nomeTabCorrente);
    }
  }

  sortByDataScadenza(a, b) {
    if (a.dataScadenza == null && b.dataScadenza == null) {
      return 0;
    }
    if (a.dataScadenza != null && b.dataScadenza == null) {
      return -1;
    }
    if (a.dataScadenza == null && b.dataScadenza != null) {
      return 1;
    }
    if (a.dataScadenza != null && b.dataScadenza != null) {
      if (a.dataScadenza < b.dataScadenza) {
        return -1;
      }
      if (a.dataScadenza > b.dataScadenza) {
        return 1;
      }
      if (a.dataScadenza === b.dataScadenza) {
        return 0;
      }
    }
  }

  sortByPagamento(a, b) {
    if (a.dataPagamento < b.dataPagamento) {
      return 1;
    }
    if (a.dataPagamento > b.dataPagamento) {
      return -1;
    }
    if (a.dataPagamento === b.dataPagamento) {
      return 0;
    }
  }

  riempiTabella(listaPagamenti: DatiPagamento[]) {
    if (listaPagamenti != null) {
      const pagamenti = listaPagamenti.map((pagamento) => {
        const row = {};
        row["identificativo"] = { value: pagamento.identificativo };
        row["causale"] = { value: pagamento.causale };
        row["annoDocumento"] = { value: pagamento.annoDocumento };
        row["numeroDocumento"] = { value: pagamento.numeroDocumento };
        row["nomeServizio"] = { value: pagamento.nomeServizio };
        row["nomeEnte"] = { value: pagamento.nomeEnte };
        row["dataScadenza"] = {
          value: pagamento.dataScadenza
            ? moment(pagamento.dataScadenza).format("DD/MM/YYYY")
            : null,
        };
        row["importo"] = {
          value: pagamento.importo,
          class:
            pagamento.statoPagamento !== StatoPagamentoEnum.PAGATO &&
            pagamento.esitoPagamento !== EsitoEnum.OK &&
            "evidenziato",
        };
        if (this.nomeTabCorrente === TipoPagamentoEnum.PAGATI) {
          row["dataPagamento"] = {
            value: pagamento.dataPagamento
              ? moment(pagamento.dataPagamento).format("DD/MM/YYYY")
              : null,
          };
        }
        return row;
      });
      this.tableData.rows = pagamenti;
    }
  }

  selezionaPagamenti(rows: any[]) {
    let tempPagamentiSelezionati: DatiPagamento[] = [];
    rows.forEach((value) => {
      const pagamentoSelezionato: DatiPagamento[] = this.listaPagamenti.filter(
        (pagamento) => pagamento.identificativo === value.identificativo.value
      );
      tempPagamentiSelezionati.push(...pagamentoSelezionato);
    });
    this.selectionPagamenti = rows;
    this.pagamentiSelezionati = tempPagamentiSelezionati;

    this.toolbarIcons[this.indiceIconaElimina].disabled =
      this.pagamentiSelezionati.length === 0 ||
      this.nomeTabCorrente === TipoPagamentoEnum.PAGATI;
    this.toolbarIcons[this.indiceIconaCarrello].disabled =
      this.pagamentiSelezionati.length === 0 ||
      this.nomeTabCorrente === TipoPagamentoEnum.PAGATI;
    this.toolbarIcons[this.indiceIconaExport].disabled =
      this.pagamentiSelezionati.length === 0;
  }

  inserimentoCarrello() {
    const messaggioInserimentoCarrello =
      this.controlloValiditaPagamentiInserimentoCarrello();
    if (messaggioInserimentoCarrello == null) {
      this.iMieiPagamentiService
        .inserimentoPagamentiCarrello(this.pagamentiSelezionati)
        .pipe(
          map((res) => {
            this.nuovoPagamentoService.getCarrello().subscribe((data) => {
              this.nuovoPagamentoService.prezzoEvent.emit({ value: 0 });
              this.nuovoPagamentoService.prezzoEvent.emit({
                value: data.totale,
                type: "add",
              });
            });
            this.inizializzaListaPagamenti(this.filtri);
            this.rimuoviPagamentiSelezionati([
              this.indiceIconaCarrello,
              this.indiceIconaElimina,
              this.indiceIconaExport,
            ]);
          })
        )
        .subscribe();
    } else {
      this.mostraBannerError(messaggioInserimentoCarrello);
    }
  }

  eliminaPagamenti() {
    this.confirmationService.confirm(
      Utils.getModale(() => {
        const possibilitaInserimentoCarrello =
          this.controlloValiditaPagamentiEliminaPagamenti();
        if (possibilitaInserimentoCarrello) {
          const dettagliTransazione: DettagliTransazione =
            new DettagliTransazione();
          dettagliTransazione.listaDettaglioTransazioneId =
            this.pagamentiSelezionati.map(
              (pagamento) => pagamento.dettaglioTransazioneId
            );
          this.iMieiPagamentiService
            .eliminaBollettino(dettagliTransazione)
            .pipe(
              map((value) => {
                this.inizializzaListaPagamenti(this.filtri);
                this.rimuoviPagamentiSelezionati([
                  this.indiceIconaCarrello,
                  this.indiceIconaElimina,
                  this.indiceIconaExport,
                ]);
              })
            )
            .subscribe();
        } else {
          this.mostraBannerError(this.MESSAGGIO_ERRORE_AZIONE);
        }
      }, TipoModaleEnum.ELIMINA)
    );
  }

  private rimuoviPagamentiSelezionati(indici: number[]) {
    this.pagamentiSelezionati = [];
    indici.forEach((i) => {
      this.toolbarIcons[i].disabled = this.pagamentiSelezionati?.length === 0;
    });
  }

  getCarrello() {
    this.nuovoPagamentoService.prezzoEvent.emit({ value: 0 });
    this.nuovoPagamentoService.getCarrello().subscribe((carrello) => {
      this.nuovoPagamentoService.prezzoEvent.emit({
        value: carrello.totale,
        type: "add",
      });
    });
  }

  stampaAttestatiPagamento() {
    const messaggioValiditaPagamentiStampaAttestatoPagamenti =
      this.controlloValiditaPagamentiStampaAttestatoPagamenti();
    if (messaggioValiditaPagamentiStampaAttestatoPagamenti == null) {
      const listaDettaglioTransazione: number[] = this.pagamentiSelezionati.map(
        (pagamentoSelezionato) => pagamentoSelezionato.dettaglioTransazioneId
      );
      const listaPdf: string[] = [];
      this.iMieiPagamentiService
        .stampaAttestatiPagamento(listaDettaglioTransazione)
        .pipe(
          map((result) => {
            listaPdf.push(...result);
            listaPdf.forEach((pdf) => {
              Utils.aperturaFile(pdf);
            });
            this.rimuoviPagamentiSelezionati([
              this.indiceIconaCarrello,
              this.indiceIconaElimina,
              this.indiceIconaExport,
            ]);
          })
        )
        .subscribe();
    } else {
      this.mostraBannerError(
        messaggioValiditaPagamentiStampaAttestatoPagamenti
      );
    }
  }

  controlloValiditaPagamentiInserimentoCarrello() {
    let messaggio = null;
    this.pagamentiSelezionati.forEach((pagamento) => {
      if (
        pagamento.esitoPagamento === EsitoEnum.OK ||
        pagamento.esitoPagamento === EsitoEnum.PENDING
      ) {
        // aggiorno flag per inserimento carrello
        messaggio = this.MESSAGGIO_ERRORE_AZIONE;
      } else if (pagamento.flagCarrello === true) {
        messaggio = this.MESSAGGIO_ERRORE_AZIONE_INSERIMENTO_CARRELLO;
      }
    });
    return messaggio;
  }

  controlloValiditaPagamentiEliminaPagamenti() {
    let ret = true;
    this.pagamentiSelezionati.forEach((pagamento) => {
      if (
        pagamento.esitoPagamento === EsitoEnum.OK ||
        pagamento.esitoPagamento === EsitoEnum.PENDING ||
        pagamento.statoPagamento === StatoPagamentoEnum.IN_ATTESA
      ) {
        // aggiorno flag per inserimento carrello
        ret = false;
      }
    });
    return ret;
  }

  controlloValiditaPagamentiStampaAttestatoPagamenti() {
    let messaggio = null;
    this.pagamentiSelezionati.forEach((pagamento) => {
      if (pagamento.esitoPagamento !== EsitoEnum.OK) {
        // aggiorno flag per inserimento carrello
        messaggio = this.MESSAGGIO_ERRORE_AZIONE_STAMPA_ATTESTATO;
      }
    });
    return messaggio;
  }

  mostraBannerError(messaggio: string) {
    // mostro banner errore
    const banner: Banner = {
      titolo: "ERRORE",
      testo: messaggio,
      tipo: getBannerType(LivelloBanner.ERROR),
    };
    this.bannerService.bannerEvent.emit([banner]);
  }

  dettaglioPagamento(row: any) {
    const pagamento: DatiPagamento = this.listaPagamenti.find(
      (datiPagamento) =>
        datiPagamento.identificativo === row.identificativo.value
    );
    // console.log("pagamento:", pagamento);
    if (pagamento.livelloIntegrazione === 5) {
      //TODO mostro banner info con un link per aprire una nuova pagina verso l'url https://uat.checkout.pagopa.it/ utilizzando target='_blank'
      const banner: Banner = {
        titolo: "INFO",
        testo:
          "Puoi effetuare il pagamento tramite PagoPA utilizzando il codice avviso " +
          pagamento.codiceAvviso +
          " ed il codice fiscale dell'ente " +
          pagamento.cfEnte +
          " (" +
          pagamento.nomeEnte +
          "), per maggiori informazioni clicca <a href='https://uat.checkout.pagopa.it/' target='_blank'>qui</a>",
        tipo: getBannerType(LivelloBanner.INFO),
      };
      if (this.nomeTabCorrente === TipoPagamentoEnum.IN_ATTESA) {
        this.bannerService.bannerEvent.emit([banner]);
      }
    } else {
      if (this.nomeTabCorrente === TipoPagamentoEnum.IN_ATTESA) {
        pagamento.inAttesa = true;
      } else {
        pagamento.inAttesa = false;
      }
      this.overlayService.mostraModaleDettaglioPagamentoEvent.emit(pagamento);
    }
  }
}
