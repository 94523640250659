<div class="ml-3">
  <!-- Ente impositore, Servizio, Ente beneficiario -->
  <div class="row mt-4 mb-0">
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="enteImpositore" class="title">Ente Impositore</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            id="enteImpositore" name="enteImpositore"
            [(ngModel)]="enteImpositore"
          />
        </div>
      </div>
    </div>
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="enteBeneficiario" class="title">Ente Beneficiario</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            id="enteBeneficiario" name="enteBeneficiario"
            type="text"
            disabled
            [(ngModel)]="enteBeneficiario"
          />
        </div>
      </div>
    </div>
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="numeroDocumento" class="title">Numero Documento</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            id="numeroDocumento" name="numeroDocumento"
            [(ngModel)]="numeroDocumento"
          />
        </div>
      </div>
    </div>
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="servizio" class="title">Servizio</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            [(ngModel)]="servizioNome"
            id="servizio" name="servizioNome"
          />
        </div>
      </div>
    </div>
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="livelloIntegrazioneNome" class="title">Integrazione</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            [(ngModel)]="livelloIntegrazioneNome"
            id="livelloIntegrazioneNome" name="livelloIntegrazioneNome"
          />
        </div>
      </div>
    </div>
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="codiceTassonomia" class="title">Codice tassonomia</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            id="codiceTassonomia" name="codiceTassonomia"
            [(ngModel)]="codiceTassonomia"
          />
        </div>
      </div>
    </div>
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="annoDocumento" class="title">Anno Documento</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            id="annoDocumento" name="annoDocumento"
            [(ngModel)]="annoDocumento"
          />
        </div>
      </div>
    </div>
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="codiceFiscalePagatore" class="title">Codice fiscale pagatore</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            id="codiceFiscalePagatore" name="codiceFiscalePagatore"
            [(ngModel)]="codiceFiscalePagatore"
          />
        </div>
      </div>
    </div>
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="emailVersante" class="title">Email pagatore</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            id="emailVersante" name="emailVersante"
            [(ngModel)]="emailVersante"
          />
        </div>
      </div>
    </div>
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="dataScadenza" class="title">Data scadenza</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            id="dataScadenza" name="dataScadenza"
            [(ngModel)]="dataScadenza"
          />
        </div>
      </div>
    </div>
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="causale" class="title">Causale</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            disabled
            id="causale" name="causale"
            [(ngModel)]="causale"
          />
        </div>
      </div>
    </div>
    <div class="col-md-4 pb-2">
      <div class="row">
        <div class="col">
          <label for="importo" class="title">Importo</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            class="price-input"
            id="importo"
            name="importo"
            [(ngModel)]="importo"
            type="tel"
            currencyMask
            [options]="{allowNegative: false, prefix: '', precision: 2, decimal: ',', thousands: '', nullable: true, inputMode: 'FINANCIAL'}"
            disabled
          />
        </div>
      </div>
    </div>
</div>
</div>

<label style="margin-top: 40px;" class="title" *ngIf="showListaCampoDettaglio()">Dati configurati dall'amministratore per il servizio</label>
<div class="ml-3">
<div class="row mt-4 mb-0" *ngIf="showListaCampoDettaglio()">
  <div class="col-md-4 pb-2" *ngFor="let campoDettaglioTransazione of listaCampoDettaglioTransazioni; let i = index">
    <div class="row">
      <div class="col">
        <label class="title">{{campoDettaglioTransazione['titolo']}}</label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <input *ngIf="campoDettaglioTransazione.tipoCampo != this.tipoCampoEnum.IMPORTO"
          type="text"
          disabled
          name="valore"
          [(ngModel)]="campoDettaglioTransazione['valore']"
        />
        <input *ngIf="campoDettaglioTransazione.tipoCampo == this.tipoCampoEnum.IMPORTO"
          class="price-input"
          disabled
          name="valore"
          [(ngModel)]="campoDettaglioTransazione['valore']"
          type="tel"
          currencyMask
          [options]="{allowNegative: false, prefix: '', precision: 2, decimal: ',', thousands: '', nullable: true, inputMode: 'FINANCIAL'}"
        />
      </div>
    </div>
  </div>
</div>
</div>
