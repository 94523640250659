import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BannerService } from "./banner.service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { FlussoRendicontazione } from "../modules/main/model/flussiRenditcontazione/FlussoRendicontazione";
import { CampoTipoRendicontazione } from "../modules/main/model/flussiRenditcontazione/CampoTipoRendicontazione";
import { Utils } from "../utility/Utils";
import { InserimentoCampoTipoRendicontazione } from "../modules/main/model/flussiRenditcontazione/InserimentoCampoTipoRendicontazione";

@Injectable({
    providedIn: 'root'
  })

export class FlussoRendicontazioneService {

    private readonly baseUrl = '/gestisciFlussiRendicontazione'
    private readonly getTipoFlussoRendicontazione = '/tipoFlussoRendicontazione';
    private readonly getCampoTipoRendicontazione = '/campoTipoRendicontazione';
    private readonly deleteCampoTipoRendicontazione = '/eliminaTipoFlussoRendicontazione';


    constructor(private http: HttpClient, private bannerService: BannerService) {
    }

    recuperaFlussiRendicontazione(idFunzione: string, idFlussoTipoRendicontazione?: string): Observable<FlussoRendicontazione[]>{
      let h: HttpHeaders = new HttpHeaders();
      h = h.append('idFunzione', idFunzione);
      let params = new HttpParams();
      if(idFlussoTipoRendicontazione != null){
        params = params.set('tipoRendicontazioneId', idFlussoTipoRendicontazione);
      }
        return this.http.get(environment.bffBaseUrl + this.baseUrl + this.getTipoFlussoRendicontazione, {
            withCredentials: true,
            headers: h,
            params
          }).pipe(map((body: FlussoRendicontazione) => {
            return body;
          }),
          catchError((err, caught) => {
            if (err.status === 401 || err.status === 400) {
              return of(null);
            } else {
              return of(null);
            }
          }));
    }

    inserisciTipoFlussoRendicontazione(body: InserimentoCampoTipoRendicontazione, idFunzione): Observable<number> {
      let h: HttpHeaders = new HttpHeaders();
      h = h.append('idFunzione', idFunzione);
      return this.http.post(environment.bffBaseUrl + this.baseUrl +
        this.getTipoFlussoRendicontazione, body, {
        withCredentials: true,
        headers: h
      }).pipe(map((id: number) => {
          this.bannerService.bannerEvent.emit([Utils.bannerOperazioneSuccesso()]);
          return id;
        }),
        catchError((err, caught) => {
          if (err.status === 401 || err.status === 400) {
            return of(null);
          } else {
            return of(null);
          }
        }));
    }

    recuperaDettaglio(id, idFunzione): Observable<InserimentoCampoTipoRendicontazione[]> {
      let h: HttpHeaders = new HttpHeaders();
      h = h.append('idFunzione', idFunzione);
      let url = environment.bffBaseUrl + this.baseUrl + this.getTipoFlussoRendicontazione
      return this.http.get(`${url}/${id}`, {
        headers: h,
        withCredentials: true
      }).pipe(map((body: FlussoRendicontazione) => {
            return body;
          }),
          catchError((err, caught) => {
            if (err.status === 401 || err.status === 400) {
              return of(null);
            } else {
              return of(null);
            }
          }));
    }

    eliminaTipoFlussoRendicontazione(righeSelezionate: number[], idFunzione): Observable<any>{
      let h: HttpHeaders = new HttpHeaders();
      h = h.append('idFunzione', idFunzione);
        return this.http.post(environment.bffBaseUrl + this.baseUrl + this.getTipoFlussoRendicontazione + this.deleteCampoTipoRendicontazione, righeSelezionate, {
            withCredentials: true,
            headers: h
          }).pipe(map((body: any) => {
            this.bannerService.bannerEvent.emit([Utils.bannerOperazioneSuccesso()]);
            return body;
          }),
          catchError((err, caught) => {
            if (err.status === 401 || err.status === 400) {
              return of(null);
            } else {
              return of(null);
            }
          }));
    }

    recuperaCampoTipoRendicontazione(idFlusso: string, idFunzione: string): Observable<CampoTipoRendicontazione[]>{
      let h: HttpHeaders = new HttpHeaders();
      h = h.append('idFunzione', idFunzione);
      let params = new HttpParams();
      params = params.set('tipoRendicontazioneId', idFlusso);
        return this.http.get(environment.bffBaseUrl + this.baseUrl + this.getCampoTipoRendicontazione, {
            withCredentials: true,
            headers: h,
            params
          }).pipe(map((body: CampoTipoRendicontazione) => {
            return body;
          }),
          catchError((err, caught) => {
            if (err.status === 401 || err.status === 400) {
              return of(null);
            } else {
              return of(null);
            }
          }));
    }
}