import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { tipoColonna } from "../../../../../../enums/TipoColonna.enum";
import { tipoTabella } from "../../../../../../enums/TipoTabella.enum";
import { ToolEnum } from "../../../../../../enums/Tool.enum";
import { LivelloIntegrazioneEnum } from "../../../../../../enums/livelloIntegrazione.enum";
import {
  StatoTransazioneEnum,
  getStatoTransazioneValue,
} from "../../../../../../enums/statoTransazione.enum";
import { TipoTransazioneEnum } from "../../../../../../enums/tipoTransazione.enum";
import { AmministrativoService } from "../../../../../../services-old/amministrativo.service";
import { GestisciPortaleService } from "../../../../../../services-old/gestisci-portale.service";
import { MenuService } from "../../../../../../services-old/menu.service";
import { MonitoraggioTransazioniService } from "../../../../../../services-old/monitoraggio-transazioni.service";
import { SpinnerOverlayService } from "../../../../../../services-old/spinner-overlay.service";
import { Utils } from "../../../../../../utility/Utils";
import { Colonna } from "../../../../model/tabella/Colonna";
import { ImmaginePdf } from "../../../../model/tabella/ImmaginePdf";
import { Tabella } from "../../../../model/tabella/Tabella";
import { ParametriRicercaTransazioni } from "../../../../model/transazione/ParametriRicercaTransazioni";
import { SintesiTransazione } from "../../../../model/transazione/SintesiTransazione";
import { GestisciElementoComponent } from "../../gestisci-elemento.component";
import { DettaglioTransazione } from "./../../../../model/transazione/DettaglioTransazione";
import { tipoRiga } from "src/app/enums/TipoRiga.enum";

@Component({
  selector: "app-monitoraggio-transazioni",
  templateUrl: "./monitoraggio-transazioni.component.html",
  styleUrls: ["./monitoraggio-transazioni.component.scss"],
})
export class MonitoraggioTransazioniComponent
  extends GestisciElementoComponent
  implements OnInit
{
  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected http: HttpClient,
    protected amministrativoService: AmministrativoService,
    private menuService: MenuService,
    private monitoraggioTransazioniService: MonitoraggioTransazioniService,
    private spinnerOverlayService: SpinnerOverlayService,
    private gestisciPortaleService: GestisciPortaleService
  ) {
    super(router, activatedRoute, http, amministrativoService);

    this.route.queryParams.subscribe((params) => {
      if (params.flussoRendicontazione) {
        this.flussoRendicontazione = parseInt(params.flussoRendicontazione);
      }
      if (params.flussoQuadratura) {
        this.flussoQuadratura = params.flussoQuadratura;
      }
    });
  }

  readonly formatoData = Utils.FORMAT_DATE_CALENDAR;
  firstCall = true;
  listaElementi: Array<SintesiTransazione> = new Array<SintesiTransazione>();
  filtriRicerca: ParametriRicercaTransazioni = null;

  elementiTotali: number;
  pagineTotali: number;
  aggiornaTabella: boolean = false;
  counts;

  flussoRendicontazione = null;
  flussoQuadratura: string = null;

  righeSelezionate: any[];

  tableData: Tabella = {
    rows: [],
    cols: [
      {
        field: "carrelloPagopa",
        header: "ID Transazione",
        type: tipoColonna.TESTO,
      },
      { field: "iuvList", header: "IUV", type: tipoColonna.TESTO },
      { field: "data", header: "Data Transazione", type: tipoColonna.TESTO },
      {
        field: "versante",
        header: "Versante (IP/email)",
        type: tipoColonna.TESTO,
      },
      {
        field: "numeroPagamenti",
        header: "Numero Pagamenti",
        type: tipoColonna.TESTO,
      },
      { field: "importo", header: "Importo", type: tipoColonna.IMPORTO },
      { field: "stato", header: "Stato", type: tipoColonna.TESTO },
      { field: "notificata", header: "Notificata", type: tipoColonna.ARRAY },
    ],
    dataKey: "id.value",
    tipoTabella: tipoTabella.CHECKBOX_SELECTION,
  };

  // page
  isMenuCarico: boolean;
  readonly tooltipTitolo =
    "In questa pagina puoi consultare la lista delle transazioni e filtrarle";
  breadcrumbList = [];

  readonly toolbarIcons = [
    { type: ToolEnum.EXPORT_PDF, tooltip: "Stampa Pdf" },
    { type: ToolEnum.EXPORT_XLS, tooltip: "Download" },
    {
      type: ToolEnum.PRINT_PR,
      disabled: true,
      tooltip: "Stampa PR (Payment Request)",
    },
    {
      type: ToolEnum.PRINT_PD,
      disabled: true,
      tooltip: "Stampa PD (Payment Data)",
    },
    {
      type: ToolEnum.SEND_NOTIFICATION_TO_CITIZEN,
      disabled: true,
      tooltip: "Invia notifica al cittadino",
    },
    {
      type: ToolEnum.SEND_NOTIFICATION_TO_ENTE,
      disabled: true,
      tooltip: "Invia notifica all'ente",
    },
    {
      type: ToolEnum.ALLINEA,
      disabled: true,
      tooltip: "Recupera stato pagamento da PagoPA",
    },
    {
      type: ToolEnum.EXPORT_PDF_DETAILS,
      disabled: false,
      tooltip: "Stampa Pdf Dettaglio",
    },
    {
      type: ToolEnum.EXPORT_XLS_DETAILS,
      disabled: false,
      tooltip: "Download Dettaglio",
    },
  ];
  indiceIconaStampaPR = 2;
  indiceIconaStampaPD = 3;
  indiceIconaInviaNotificaACittadino = 4;
  indiceIconaInviaNotificaAEnte = 5;
  allineaPagoPA = 6;
  indiceIconaStampaPdfDettaglio = 7;
  indiceIconaDownloadDettaglio = 8;

  readonly tabs = [
    { value: TipoTransazioneEnum.TUTTE },
    { value: TipoTransazioneEnum.ESEGUITE },
    { value: TipoTransazioneEnum.NON_ESEGUITE },
    { value: TipoTransazioneEnum.RENDICONTATE },
    { value: TipoTransazioneEnum.NON_RENDICONTATE },
    { value: TipoTransazioneEnum.QUADRATE },
    { value: TipoTransazioneEnum.NON_QUADRATE },
  ];
  nomeTabCorrente: TipoTransazioneEnum = TipoTransazioneEnum.TUTTE;

  ngOnInit(): void {
    let tempFiltriRicerca = JSON.parse(
      localStorage.getItem("filtroRicercaMonitoraggioTransazioni")
    );
    if (tempFiltriRicerca) this.filtriRicerca = tempFiltriRicerca;

    this.controlloCaricamentoMenu();
  }

  private controlloCaricamentoMenu() {
    this.waitingEmitter.subscribe(() => {
      if (this.amministrativoService.mappaFunzioni) {
        this.isMenuCarico =
          Object.keys(this.amministrativoService.mappaFunzioni).length > 0;
      }

      if (this.isMenuCarico) {
        this.init();
      } else {
        this.menuService.menuCaricatoEvent.subscribe(() => {
          this.init();
        });
      }
    });
  }

  init() {
    this.inizializzaBreadcrumbs();
    this.inizializzaFiltriRicerca();
    this.waiting = false;
  }

  private inizializzaBreadcrumbs() {
    this.breadcrumbList = this.inizializzaBreadcrumbList(
      [{ label: "Monitoraggio Transazioni", link: null }],
      true
    );
  }

  private inizializzaFiltriRicerca() {
    this.filtriRicerca = new ParametriRicercaTransazioni();
    this.filtriRicerca.dataTransazioneDa = moment(
      String(moment().subtract(1, "day").format(this.formatoData)),
      Utils.FORMAT_DATE_CALENDAR
    ).format(Utils.FORMAT_LOCAL_DATE_TIME);
  }

  callbackPopolaLista() {
    this.onChangeTab(this.nomeTabCorrente);
  }

  popolaListaElementiPaginata = async (pagination) => {
    let page = pagination.rows ? pagination.rows : this.getElementsTab();
    this.filtriRicerca.numeroPagina = pagination.first
      ? String(pagination.first / page)
      : "0";
    this.filtriRicerca.numeroElementi = page;
    if (this.firstCall == true) {
      this.firstCall = false;
      this.filtriRicerca.sort = "data";
      this.filtriRicerca.ascending = "false";
    } else {
      pagination.sortField
        ? (this.filtriRicerca.sort = pagination.sortField)
        : "";
      pagination.ascending
        ? (this.filtriRicerca.ascending = String(pagination.ascending == -1))
        : "";
    }
    pagination.pagination != undefined
      ? (this.filtriRicerca.pagination = pagination.pagination)
      : true;
    this.filtriRicerca.tab = Utils.getKeyByValue(
      TipoTransazioneEnum,
      this.nomeTabCorrente
    );
    return await this.getObservableFunzioneRicerca();
  };

  private getElementsTab() {
    let elementsTab = 0;
    switch (this.nomeTabCorrente) {
      case TipoTransazioneEnum.TUTTE:
        elementsTab = this.counts?.transazioniTotali ?? 0;
        break;
      case TipoTransazioneEnum.ESEGUITE:
        elementsTab = this.counts?.transazioniEseguite ?? 0;
        break;
      case TipoTransazioneEnum.NON_ESEGUITE:
        elementsTab = this.counts?.transazioniNonEseguite ?? 0;
        break;
      case TipoTransazioneEnum.NON_QUADRATE:
        elementsTab = this.counts?.transazioniNonQuadrate ?? 0;
        break;
      case TipoTransazioneEnum.NON_RENDICONTATE:
        elementsTab = this.counts?.transazioniNonRendicontate ?? 0;
        break;
      case TipoTransazioneEnum.QUADRATE:
        elementsTab = this.counts?.transazioniQuadrate ?? 0;
        break;
      case TipoTransazioneEnum.RENDICONTATE:
        elementsTab = this.counts?.transazioniRendicontate ?? 0;
        break;
      default:
        elementsTab = 0;
        break;
    }
    return elementsTab;
  }

  onChangeFiltriPaginazioneBE(filtri: any): void {
    this.filtriRicerca = filtri;
    this.aggiornaTabella = !this.aggiornaTabella;
  }

  getCounts(counts) {
    this.counts = counts;
  }

  creaRigaTabella(transazione: SintesiTransazione) {
    let notificata = [];
    const placementIconaEmail = "top";
    const displayIconaEmail = "inline";
    //console.log(transazione.id + " - " + transazione.notificata);
    if (transazione.notificata === 1) {
      const pathIconaEmail = "#it-check";
      const coloreIconaEmail = "#008758";
      const tooltipIconaEmail = "Invio notifica OK";
      const icon = Utils.creaIcona(
        transazione.notificata,
        pathIconaEmail,
        coloreIconaEmail,
        tooltipIconaEmail,
        displayIconaEmail,
        placementIconaEmail
      );
      notificata.push({ type: tipoRiga.ICONA, ...icon });
    } else if (transazione.notificata === 0) {
      const pathIconaEmail = "#it-close-big";
      const coloreIconaEmail = "#D9364F";
      const tooltipIconaEmail = "Invio notifica KO";
      const icon = Utils.creaIcona(
        transazione.notificata,
        pathIconaEmail,
        coloreIconaEmail,
        tooltipIconaEmail,
        displayIconaEmail,
        placementIconaEmail
      );
      notificata.push({ type: tipoRiga.ICONA, ...icon });
    } else {
      notificata.push({ type: tipoRiga.TESTO, value: "Notifica M3" });
    }

    return {
      id: { value: transazione.id },
      carrelloPagopa: { value: transazione.carrelloPagopa },
      iuvList: { value: transazione.iuvList },
      data: {
        value: transazione.dataCreazione
          ? moment(transazione.dataCreazione).format("DD/MM/YYYY HH:mm:ss")
          : null,
      },
      versante: {
        value:
          (transazione.versanteIndirizzoIP != null
            ? transazione.versanteIndirizzoIP + " "
            : null) +
            transazione.emailNotifica !=
          null
            ? transazione.emailNotifica
            : null,
      },
      numeroPagamenti: { value: transazione.numeroPendenze },
      importo: { value: transazione.importoTotale },
      stato: { value: transazione.statoTransazione },
      livelloIntegrazioneId: { value: transazione.livelloIntegrazioneId },
      notificata: notificata,
    };
  }

  onChangeTab(value: TipoTransazioneEnum) {
    this.nomeTabCorrente = value;
    this.aggiornaTabella = !this.aggiornaTabella;
  }

  eseguiAzioni(azioneTool: ToolEnum): void {
    switch (azioneTool) {
      case ToolEnum.EXPORT_PDF:
        this.getTableDataPdflDownload();
        break;
      case ToolEnum.EXPORT_XLS:
        this.getTableDataExcelDownload();
        break;
      case ToolEnum.PRINT_PR:
        this.stampaPaymentRequestInTxtFile(
          this.getListaIdElementiSelezionati()
        );
        break;
      case ToolEnum.PRINT_PD:
        this.stampaPaymentDataInTxtFile(this.getListaIdElementiSelezionati());
        break;
      case ToolEnum.SEND_NOTIFICATION_TO_CITIZEN:
        this.inviaNotificaACittadino(this.getListaIdElementiSelezionati()[0]);
        break;
      case ToolEnum.SEND_NOTIFICATION_TO_ENTE:
        this.inviaNotificaAEnte(this.getListaIdElementiSelezionati()[0]);
        break;
      case ToolEnum.ALLINEA:
        this.allineaTransazionePagoPA(this.getListaIdElementiSelezionati()[0]);
        break;
      case ToolEnum.EXPORT_PDF_DETAILS:
        this.getListaIdStampaDettagli().subscribe((ids) =>
          this.esportaTabellaDettagliInFilePdf(
            ids,
            "Lista Dettagli Transazione"
          )
        );
        break;
      case ToolEnum.EXPORT_XLS_DETAILS:
        this.getListaIdStampaDettagli().subscribe((ids) =>
          this.esportaTabellaDettagliInFileExcel(
            ids,
            "Lista Dettagli Transazione"
          )
        );
        break;
    }
  }

  async getTableDataExcelDownload() {
    let table: Tabella = {
      cols: this.tableData.cols,
      rows: [],
      dataKey: this.tableData.dataKey,
      tipoTabella: this.tableData.tipoTabella,
    };
    let pagination = { pagination: false };
    await this.popolaListaElementiPaginata(pagination).then((dataFunction) => {
      dataFunction.subscribe((data) => {
        table.rows = [];
        data.elementi.forEach((elemento) => {
          table.rows.push(this.creaRigaTabella(elemento));
        });
        this.esportaTabellaInFileExcel(table, "Lista Transazioni");
      });
    });
  }

  async getTableDataPdflDownload() {
    let table: Tabella = {
      cols: this.tableData.cols,
      rows: [],
      dataKey: this.tableData.dataKey,
      tipoTabella: this.tableData.tipoTabella,
    };
    let pagination = { pagination: false };
    await this.popolaListaElementiPaginata(pagination).then((dataFunction) => {
      dataFunction.subscribe((data) => {
        table.rows = [];
        data.elementi.forEach((elemento) => {
          table.rows.push(this.creaRigaTabella(elemento));
        });
        this.esportaTabellaInFilePdf(table, "Lista Transazioni");
      });
    });
  }

  getColonneFilePdf(colonne: Colonna[]): Colonna[] {
    return colonne;
  }

  getRigheFilePdf(righe: any[]): any[] {
    return righe;
  }

  getImmaginiFilePdf(righe?: any[]): ImmaginePdf[] {
    return [];
  }

  getColonneFileExcel(colonne: Colonna[]): Colonna[] {
    return colonne;
  }

  getRigheFileExcel(righe: any[]): any[] {
    return righe.map((riga) => {
      return [
        riga.carrelloPagopa.value,
        riga.iuvList.value,
        riga.data.value,
        riga.versante.value,
        riga.numeroPagamenti.value,
        riga.importo.value,
        riga.stato.value,
      ];
    });
  }

  getNumeroRecord(): string {
    if (this.counts == undefined) return "";
    else
      return (
        "Totale " +
        this.counts?.transazioniTotali +
        " transazioni. " +
        "\b\bDi cui eseguite: " +
        this.counts?.transazioniEseguite +
        ". \b\bDi cui non eseguite: " +
        this.counts?.transazioniNonEseguite +
        ". \nDi cui rendicontate: " +
        this.counts?.transazioniRendicontate +
        ". \b\bDi cui non rendicontate: " +
        this.counts?.transazioniNonRendicontate +
        ". \nDi cui quadrate: " +
        this.counts?.transazioniQuadrate +
        ". \b\bDi cui non quadrate: " +
        this.counts?.transazioniNonQuadrate +
        "."
      );
  }

  getObservableFunzioneRicerca(): Observable<any[] | any> {
    return this.monitoraggioTransazioniService.ricercaTransazioni(
      this.filtriRicerca,
      this.idFunzione
    );
  }

  selezionaRigaTabella(righeSelezionate: any[]): void {
    this.righeSelezionate = righeSelezionate;

    if (
      righeSelezionate.length !== 0 &&
      righeSelezionate.every(
        (riga) =>
          riga.livelloIntegrazioneId.value === LivelloIntegrazioneEnum.LV1
      )
    ) {
      this.toolbarIcons[this.indiceIconaStampaPD].disabled = false;
      this.toolbarIcons[this.indiceIconaStampaPR].disabled = false;
    } else {
      this.toolbarIcons[this.indiceIconaStampaPD].disabled = true;
      this.toolbarIcons[this.indiceIconaStampaPR].disabled = true;
    }

    if (righeSelezionate.length === 1) {
      if (
        righeSelezionate[0].stato.value ===
        getStatoTransazioneValue(StatoTransazioneEnum.COMPLETATA_CON_SUCCESSO)
      ) {
        this.toolbarIcons[this.indiceIconaInviaNotificaACittadino].disabled =
          false;
      }
      if (
        (righeSelezionate[0].notificata[0].value === 0 &&
          (righeSelezionate[0].stato.value ===
            getStatoTransazioneValue(StatoTransazioneEnum.FALLITA) ||
            righeSelezionate[0].stato.value ===
              getStatoTransazioneValue(
                StatoTransazioneEnum.COMPLETATA_CON_SUCCESSO
              ))) ||
        righeSelezionate[0].notificata[0].value === 1
      ) {
        this.toolbarIcons[this.indiceIconaInviaNotificaAEnte].disabled = false;
      }
      if (
        righeSelezionate[0].stato.value ===
        getStatoTransazioneValue(StatoTransazioneEnum.PENDING)
      ) {
        this.toolbarIcons[this.allineaPagoPA].disabled = false;
      }
    } else {
      this.toolbarIcons[this.indiceIconaInviaNotificaACittadino].disabled =
        true;
      this.toolbarIcons[this.indiceIconaInviaNotificaAEnte].disabled = true;
      this.toolbarIcons[this.allineaPagoPA].disabled = true;
    }
    // this.toolbarIcons[this.indiceIconaStampaPdfDettaglio].disabled = this.righeSelezionate.length === 0;
    // this.toolbarIcons[this.indiceIconaDownloadDettaglio].disabled = this.righeSelezionate.length === 0;
  }

  stampaPaymentRequestInTxtFile(listaTransazioniId: Array<number>): void {
    this.gestisciPortaleService
      .stampaPR(listaTransazioniId, this.idFunzione)
      .subscribe((listaPR) => {
        listaPR.forEach((pr, index) => {
          if (pr) {
            Utils.downloadBase64ToTxtFile(pr, "pr_" + index);
          }
        });
      });
  }

  stampaPaymentDataInTxtFile(listaTransazioniId: Array<number>): void {
    this.gestisciPortaleService
      .stampaPD(listaTransazioniId, this.idFunzione)
      .subscribe((listaPD) => {
        listaPD.forEach((pd, index) => {
          if (pd) {
            Utils.downloadBase64ToTxtFile(pd, "pd_" + index);
          }
        });
      });
  }

  inviaNotificaACittadino(transazioneId: number): void {
    this.gestisciPortaleService
      .inviaNotificaCittadino(transazioneId, this.idFunzione)
      .subscribe();
  }

  inviaNotificaAEnte(transazioneId: number): void {
    this.gestisciPortaleService
      .inviaNotificaEnte(transazioneId, this.idFunzione)
      .subscribe((esito) => {
        //console.log("esito: " + esito);
        if (esito) {
          let notificata = [];
          const pathIconaEmail = "#it-check";
          const placementIconaEmail = "top";
          const displayIconaEmail = "inline";
          const coloreIconaEmail = "#008758";
          const tooltipIconaEmail = "Invio notifica OK";
          const icon = Utils.creaIcona(
            1,
            pathIconaEmail,
            coloreIconaEmail,
            tooltipIconaEmail,
            displayIconaEmail,
            placementIconaEmail
          );
          notificata.push({ type: tipoRiga.ICONA, ...icon });
          this.righeSelezionate[0].notificata = notificata;
        }
      });
  }

  dettaglioTransazione(rigaCliccata: any) {
    this.mostraDettaglioElemento(
      "/dettaglioTransazioni",
      rigaCliccata.id.value
    );
  }

  allineaTransazionePagoPA(transazioneId: number): void {
    this.monitoraggioTransazioniService
      .allineaTransazionePagoPA(transazioneId, this.idFunzione)
      .subscribe((esitoPagamento) => {
        // console.log("esitoPagamento: " + JSON.stringify(esitoPagamento))
        // console.log(JSON.stringify(this.righeSelezionate[0]))
        if (esitoPagamento != null) {
          this.righeSelezionate[0].stato.value =
            getStatoTransazioneValue(esitoPagamento);
          if (
            this.righeSelezionate[0].stato.value ===
            getStatoTransazioneValue(StatoTransazioneEnum.PENDING)
          ) {
            this.toolbarIcons[this.allineaPagoPA].disabled = false;
          } else {
            this.toolbarIcons[this.allineaPagoPA].disabled = true;
          }
        }
      });
  }

  esportaTabellaDettagliInFilePdf(
    listaTransazioniId: Array<number>,
    nomeFile: string
  ): void {
    this.monitoraggioTransazioniService
      .dettaglioTransazioni(listaTransazioniId, this.idFunzione)
      .subscribe((transazioneList) => {
        let colonne = [];
        let righe = [];
        let immagini = [];
        transazioneList.forEach((transazione) => {
          const listaDettaglioTransazione =
            transazione.listaDettaglioTransazione;
          const tableDataDettaglio: Tabella = {
            rows: [],
            cols: [
              { field: "id", header: "Id Bollettino", type: tipoColonna.TESTO },
              { field: "iuv", header: "IUV", type: tipoColonna.TESTO },
              { field: "ente", header: "Ente", type: tipoColonna.LINK },
              {
                field: "servizio",
                header: "Servizio",
                type: tipoColonna.TESTO,
              },
              {
                field: "pagatore",
                header: "Pagatore (Cod. Fiscale)",
                type: tipoColonna.TESTO,
              },
              { field: "causale", header: "Causale", type: tipoColonna.TESTO },
              {
                field: "codiceTassonomia",
                header: "Codice tassonomia",
                type: tipoColonna.TESTO,
              },
              {
                field: "numeroDocumento",
                header: "Numero Documento",
                type: tipoColonna.TESTO,
              },
              {
                field: "importo",
                header: "Importo",
                type: tipoColonna.IMPORTO,
              },
              {
                field: "dataPagamento",
                header: "Data pagamento",
                type: tipoColonna.TESTO,
              },
              {
                field: "dataRegolamento",
                header: "Data regolamento",
                type: tipoColonna.TESTO,
              },
              {
                field: "idQuadratura",
                header: "Id quadratura",
                type: tipoColonna.TESTO,
              },
            ],
            dataKey: "id.value",
            tipoTabella: tipoTabella.TEMPLATING,
          };
          tableDataDettaglio.rows = [];
          if (listaDettaglioTransazione) {
            listaDettaglioTransazione.forEach((elemento) => {
              tableDataDettaglio.rows.push(
                this.creaRigaTabellaDettagli(elemento)
              );
            });
          }
          const copiaTabella = JSON.parse(JSON.stringify(tableDataDettaglio));
          colonne = this.getColonneDettaglioFilePdf(copiaTabella.cols);
          righe = [...righe, ...this.getRigheFilePdf(copiaTabella.rows)];
          immagini = [];
        });
        Utils.esportaTabellaInFilePdf(colonne, righe, nomeFile, immagini);
      });
  }

  creaRigaTabellaDettagli(dettaglioTransazione: DettaglioTransazione) {
    return {
      id: { value: dettaglioTransazione.dettaglioTransazioneId },
      iuv: { value: dettaglioTransazione.iuv },
      ente: { value: dettaglioTransazione.enteNome },
      servizio: { value: dettaglioTransazione.servizioNome },
      pagatore: { value: dettaglioTransazione.pagatoreCodiceFiscale },
      causale: { value: dettaglioTransazione.causale },
      codiceTassonomia: { value: dettaglioTransazione.codiceTassonomia },
      numeroDocumento: { value: dettaglioTransazione.numeroDocumento },
      importo: { value: dettaglioTransazione.importo },
      dataPagamento: {
        value: dettaglioTransazione.dataPagamento
          ? moment(dettaglioTransazione.dataPagamento).format(
              "DD/MM/YYYY HH:mm:ss"
            )
          : null,
      },
      dataRegolamento: {
        value: dettaglioTransazione.dataRegolamento
          ? moment(dettaglioTransazione.dataRegolamento).format(
              "DD/MM/YYYY HH:mm:ss"
            )
          : null,
      },
      idQuadratura: { value: dettaglioTransazione.flussoId },
    };
  }

  getColonneDettaglioFilePdf(colonne: Colonna[]): Colonna[] {
    return colonne;
  }

  esportaTabellaDettagliInFileExcel(
    listaTransazioniId: Array<number>,
    nomeFile: string
  ): void {
    this.monitoraggioTransazioniService
      .dettaglioTransazioni(listaTransazioniId, this.idFunzione)
      .subscribe((transazioneList) => {
        let headerColonne = [];
        let righe = [];
        transazioneList.forEach((transazione) => {
          const listaDettaglioTransazione =
            transazione.listaDettaglioTransazione;
          const tableDataDettaglio: Tabella = {
            rows: [],
            cols: [
              { field: "id", header: "Id Bollettino", type: tipoColonna.TESTO },
              { field: "iuv", header: "IUV", type: tipoColonna.TESTO },
              { field: "ente", header: "Ente", type: tipoColonna.LINK },
              {
                field: "servizio",
                header: "Servizio",
                type: tipoColonna.TESTO,
              },
              {
                field: "pagatore",
                header: "Pagatore (Cod. Fiscale)",
                type: tipoColonna.TESTO,
              },
              { field: "causale", header: "Causale", type: tipoColonna.TESTO },
              {
                field: "codiceTassonomia",
                header: "Codice tassonomia",
                type: tipoColonna.TESTO,
              },
              {
                field: "numeroDocumento",
                header: "Numero documento",
                type: tipoColonna.TESTO,
              },
              {
                field: "importo",
                header: "Importo",
                type: tipoColonna.IMPORTO,
              },
              {
                field: "dataPagamento",
                header: "Data pagamento",
                type: tipoColonna.TESTO,
              },
              {
                field: "dataRegolamento",
                header: "Data regolamento",
                type: tipoColonna.TESTO,
              },
              {
                field: "idQuadratura",
                header: "Id quadratura",
                type: tipoColonna.TESTO,
              },
            ],
            dataKey: "id.value",
            tipoTabella: tipoTabella.TEMPLATING,
          };
          tableDataDettaglio.rows = [];
          if (listaDettaglioTransazione) {
            listaDettaglioTransazione.forEach((elemento) => {
              tableDataDettaglio.rows.push(
                this.creaRigaTabellaDettagli(elemento)
              );
            });
          }
          const copiaTabella = JSON.parse(JSON.stringify(tableDataDettaglio));
          headerColonne = this.getColonneFileExcel(copiaTabella.cols).map(
            (col) => col.header
          );
          righe = [
            ...righe,
            ...this.getRigheDettagliFileExcel(copiaTabella.rows),
          ];
        });
        const fogli = {};
        fogli[nomeFile] = null;
        const workbook = { Sheets: fogli, SheetNames: [] };
        Utils.creaFileExcel(
          righe,
          headerColonne,
          nomeFile,
          [nomeFile],
          workbook,
          nomeFile
        );
      });
  }

  getRigheDettagliFileExcel(righe: any[]): any[] {
    return righe.map((riga) => {
      const rigaFormattata = riga;
      rigaFormattata.id = riga.id.value;
      rigaFormattata.iuv = riga.iuv.value;
      rigaFormattata.ente = riga.ente.value;
      rigaFormattata.servizio = riga.servizio.value;
      rigaFormattata.pagatore = riga.pagatore.value;
      rigaFormattata.importo = riga.importo.value;
      rigaFormattata.dataPagamento = riga.dataPagamento.value;
      rigaFormattata.dataRegolamento = riga.dataRegolamento.value;
      rigaFormattata.idQuadratura = riga.idQuadratura.value;
      rigaFormattata.causale = riga.causale.value;
      rigaFormattata.codiceTassonomia = riga.codiceTassonomia.value;
      rigaFormattata.numeroDocumento = riga.numeroDocumento.value;
      return rigaFormattata;
    });
  }

  // Refactoring stampa
  private getListaIdStampaDettagli(): Observable<number[]> {
    if (this.righeSelezionate && this.righeSelezionate.length > 0) {
      return of(this.righeSelezionate.map((e) => e.id.value));
    } else {
      this.filtriRicerca.pagination = false;
      this.filtriRicerca.numeroElementi = this.getElementsTab().toString();
      return this.getObservableFunzioneRicerca().pipe(
        map((data) => data.elementi.map((e) => e.id))
      );
    }
  }
}
