import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef, ViewRef, Input, AfterViewInit
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { ModalitaPaginaGestioneElemento } from '../../../../../../../utility/enums/modalita-pagina-gestione-elemento';
import { AmministrativoService } from '../../../../../../../services-old/amministrativo.service';
import { EnteService } from '../../../../../../../services-old/ente.service';
import { OverlayService } from '../../../../../../../services-old/overlay.service';
import { RoutingService } from "../../../../../../../services-old/routing.service";
import { Utils } from '../../../../../../../utility/Utils';
import { SintesiBreadcrumb } from '../../../../../dto/Breadcrumb';
import { ComponenteDinamico } from '../../../../../model/ComponenteDinamico';
import { Beneficiario } from '../../../../../model/ente/Beneficiario';
import { ContoCorrente } from '../../../../../model/ente/ContoCorrente';
import { EnteCompleto } from '../../../../../model/ente/EnteCompleto';
import { FormElementoParentComponent } from '../../../form-elemento-parent.component';
import { DatiBeneficiarioComponent } from '../dati-beneficiario/dati-beneficiario.component';
import { FlussoRiversamentoPagoPA } from './../../../../../model/servizio/FlussoRiversamentoPagoPA';
import { ConfigContoCorrenteComponent } from '../config-conto-corrente/config-conto-corrente.component';

@Component({
  selector: 'app-form-ente',
  templateUrl: './form-ente.component.html',
  styleUrls: ['./form-ente.component.scss']
})
export class FormEnteComponent extends FormElementoParentComponent implements OnInit, AfterViewInit {
  // enums e consts class
  readonly FunzioneGestioneEnum = ModalitaPaginaGestioneElemento;
  // header page
  breadcrumbList = [];
  tooltipTitolo: string;
  titoloPagina: string;

  @Input() uuid: string;
  // data
  funzione: ModalitaPaginaGestioneElemento;
  datiEnte: EnteCompleto = new EnteCompleto();
  datiBeneficiario: Beneficiario;
  datiContoCorrente: ContoCorrente;
  listaContiCorrente: ContoCorrente[];
  mapContoCorrente: Map<string, ContoCorrente> = new Map();
  mapControlloCC: Map<string, boolean> = new Map();
  mapBeneficiario: Map<string, Beneficiario> = new Map();
  mapControllo: Map<string, boolean> = new Map();
  
  // form valid
  isFormDatiEnteValido = false;
  @ViewChild('datiContoCorrente', {static: false, read: ViewContainerRef}) targetCC: ViewContainerRef;
  @ViewChild('datiBeneficiario', {static: false, read: ViewContainerRef}) target: ViewContainerRef;
  targetCCMap: Map<string, ViewRef> = new Map<string, ViewRef>();
  targetMap: Map<string, ViewRef> = new Map<string, ViewRef>();

  private componentRef: ComponentRef<any>;

  private componentRefs: ComponentRef<any>[] = [];
  getListFromMap = (map: Map<string, any>) => Array.from(map, ([name, value]) => value);


  constructor(router: Router,
              activatedRoute: ActivatedRoute,
              private componentFactoryResolver: ComponentFactoryResolver, private renderer: Renderer2,
              private el: ElementRef, amministrativoService: AmministrativoService,
              private overlayService: OverlayService, http: HttpClient,
              confirmationService: ConfirmationService, private enteService: EnteService,
              private routingService: RoutingService) {
    super(confirmationService, activatedRoute, amministrativoService, http, router);
  }

  initFormPage(snapshot: ActivatedRouteSnapshot) {
    // get route per logica inserimento o modifica
    this.controllaTipoFunzione(snapshot);
    this.inizializzaBreadcrumbs();
    this.inizializzaTitolo();
    this.inizializzaDatiEnte();
    if (this.funzione !== ModalitaPaginaGestioneElemento.AGGIUNGI) {
      // inizializzazione form modifica o dettaglio
      const enteId = snapshot.params.enteId;
      this.letturaEnte(enteId);
    } else {
      // inizializzazione form inserimento
    }
  }

  ngOnInit(): void {
    this.uuid = Utils.uuidv4();
  }

  ngAfterViewInit(): void {
    // bind button collapse to new section beneficiario
    const collapseCCEButton = document.getElementById('buttonCCECollapse' + this.uuid.toString());
    collapseCCEButton.dataset.target = '#collapseCCE' + this.uuid;
  }

  controllaTipoFunzione(snapshot) {
    const url = snapshot.url[1].path;
    switch (url) {
      case 'dettaglioEnte':
        this.funzione = ModalitaPaginaGestioneElemento.DETTAGLIO;
        break;
      case 'aggiungiEnte':
        this.funzione = ModalitaPaginaGestioneElemento.AGGIUNGI;
        break;
      case 'modificaEnte':
        this.funzione = ModalitaPaginaGestioneElemento.MODIFICA;
        break;
    }
  }

  inizializzaBreadcrumbs(): void {
    const breadcrumbs: SintesiBreadcrumb[] = [];
    breadcrumbs.push(new SintesiBreadcrumb('Gestisci Anagrafiche', null));
    breadcrumbs.push(new SintesiBreadcrumb('Gestisci Enti', this.basePath));
    breadcrumbs.push(new SintesiBreadcrumb(this.getTestoFunzione(this.funzione) + ' Ente', null));
    this.breadcrumbList = this.inizializzaBreadcrumbList(breadcrumbs);
  }

  private inizializzaTitolo() {
    this.titoloPagina = this.getTestoFunzione(this.funzione) + ' Ente';
    this.tooltipTitolo = 'In questa pagina puoi ' + this.getTestoFunzione(this.funzione, false) + ' i dettagli di un ente';
  }

  inizializzaDatiEnte() {
    this.datiEnte.societaId = null;
    this.datiEnte.livelloTerritorialeId = null;
    this.datiEnte.comune = null;
    this.datiEnte.provincia = null;
    this.datiEnte.logo = null;
  }

  onChangeDatiEnte(datiEnte: EnteCompleto): void {
    this.datiEnte = datiEnte;
  }

  aggiungiBeneficiario(datiBeneficiario?: Beneficiario): number {
    // creazione Dati Beneficiario Component
    const childComponent = this.componentFactoryResolver.resolveComponentFactory(DatiBeneficiarioComponent);
    const indexBeneficiario = this.target.length;
    // input
    const uuid = Utils.uuidv4();
    const funzione = this.funzione;
    const idFunzione = this.idFunzione;
    let instanceDatiBeneficiario: Beneficiario;
    if (datiBeneficiario == null) {
      instanceDatiBeneficiario = new Beneficiario();
      instanceDatiBeneficiario.listaServizi = [];
    } else {
      instanceDatiBeneficiario = datiBeneficiario;
    }
    let listaContiCorrente = null;
    if (this.funzione === ModalitaPaginaGestioneElemento.MODIFICA && this.datiEnte.listaContiCorrenti != null) {
      listaContiCorrente = this.datiEnte.listaContiCorrenti;
    }
    this.inizializzaComponentRefInstance(childComponent, uuid, indexBeneficiario, funzione, idFunzione,
      instanceDatiBeneficiario, listaContiCorrente);
    return indexBeneficiario;
  }

  inizializzaComponentRefInstance(childComponent, uuid, index, funzione, idFunzione, datiBeneficiario, listaContiCorrente) {
    // creazione Dati Beneficiario Component
    this.componentRef = this.target.createComponent(childComponent);
    // input
    this.componentRef.instance.uuid = uuid;
    this.targetMap.set(uuid, this.componentRef.hostView);
    this.componentRef.instance.indexDatiBeneficiario = index;
    this.componentRef.instance.funzione = funzione;
    this.componentRef.instance.idFunzione = idFunzione;
    this.componentRef.instance.datiBeneficiario = datiBeneficiario;
    if (funzione === ModalitaPaginaGestioneElemento.MODIFICA && listaContiCorrente != null) {
      this.componentRef.instance.listaContiCorrente = listaContiCorrente;
    }
    // output
    this.componentRef.instance.onDeleteDatiBeneficiario.subscribe((componenteDinamico: ComponenteDinamico) => {

      const beneficiario = this.mapBeneficiario.get(componenteDinamico.uuid);
      if (beneficiario.id) {
        this.enteService.eliminaBeneficiario(beneficiario, this.idFunzione, this.datiEnte.societaId)
        .subscribe((esito) => {
          if (esito) {
            this.routingService.configuraRouterAndNavigate(this.basePath + '/modificaEnte/' + this.datiEnte.id);
          }
        });
      }

      const isBeneficiarioDaModificare: boolean = beneficiario != null;
      if (isBeneficiarioDaModificare) {
        this.mapBeneficiario.delete(componenteDinamico.uuid);
        this.mapControllo.delete(componenteDinamico.uuid);
      }
      // controllo se esiste un view ref e target ha solo un elemento, se vero uso remove altrimenti clear
      const viewRef = this.targetMap.get(componenteDinamico.uuid);
      const indexViewRef = this.target.indexOf(viewRef);
      if (this.target.length === 1) {
        this.target.clear();
        this.targetMap.clear();
      } else {
        this.target.remove(indexViewRef);
        this.targetMap.delete(componenteDinamico.uuid);
      }
    });
    this.componentRef.instance.onChangeDatiBeneficiario.subscribe((componenteDinamico: ComponenteDinamico) => {
      this.mapBeneficiario.set(componenteDinamico.uuid, componenteDinamico.oggetto);
      this.mapControllo.set(componenteDinamico.uuid, componenteDinamico.isFormValid);
    });
    this.componentRef.changeDetectorRef.detectChanges();
    this.componentRefs.push(this.componentRef);
  }

  letturaEnte(idEnte) {
    this.enteService.dettaglioEnte(idEnte, this.idFunzione).subscribe((ente) => {
      // inizializza dati ente per modifica
      this.datiEnte = ente;
      this.isFormDatiEnteValido = true;
      // recupero conti corrente
      this.setListaContiCorrente()
      this.setListaBeneficiari();
      this.datiEnte.flussoRiversamentoPagoPA = ente.flussoRiversamentoPagoPA != null ?
        ente.flussoRiversamentoPagoPA : new FlussoRiversamentoPagoPA();
    });
  }

  private setListaContiCorrente() {
    if (this.datiEnte.listaContiCorrenti) {
      this.datiEnte.listaContiCorrenti = this.formattaCampiCC(this.datiEnte.listaContiCorrenti);
      this.datiEnte.listaContiCorrenti.forEach((contoCorrente) => {
        if (this.target != null) {
          this.aggiungiContoCorrente(contoCorrente);
        }
      });
    }
  }

  private setListaBeneficiari() {
    if (this.datiEnte.listaBeneficiari) {
      this.datiEnte.listaBeneficiari = this.formattaCampi(this.datiEnte.listaBeneficiari);
      this.datiEnte.listaBeneficiari.forEach((beneficiario) => {
        if (this.target != null) {
          this.aggiungiBeneficiario(beneficiario);
        }
      });
    }
  }

  recuperoContiCorrente(idEnte) {
    return this.enteService.recuperaContiCorrenti(idEnte, this.idFunzione);
  }

  disabilitaBottoneBeneficiario(): boolean {
    const listaControllo: boolean[] = this.getListFromMap(this.mapControllo);
    const isListaBeneficiarioInvalid = listaControllo.length > 0 ? listaControllo.includes(false) : false;
    const isListaContiCorrenteInvalid = (this.datiEnte.listaContiCorrenti && this.datiEnte.listaContiCorrenti.length > 0) ? false : true;
    return !this.isFormDatiEnteValido || isListaBeneficiarioInvalid || isListaContiCorrenteInvalid;
  }

  disabilitaBottone(): boolean {
    const listaControllo: boolean[] = this.getListFromMap(this.mapControllo);
    const isListaBeneficiarioInvalid = listaControllo.length > 0 ? listaControllo.includes(false) : false;
    return !this.isFormDatiEnteValido || isListaBeneficiarioInvalid;
  }

  onClickSalva(): void {
    let listaContiCorrenti: ContoCorrente[] = this.getListFromMap(this.mapContoCorrente);
    listaContiCorrenti = this.formattaCampiCC(listaContiCorrenti, false);
    this.datiEnte.listaContiCorrenti = listaContiCorrenti;
    let listaBeneficiari: Beneficiario[] = this.getListFromMap(this.mapBeneficiario);
    listaBeneficiari = this.formattaCampi(listaBeneficiari);
    listaBeneficiari = this.setFlussoRiversamentoPagoPACondiviso(listaBeneficiari);
    this.datiEnte.listaBeneficiari = listaBeneficiari;
    if (this.funzione === ModalitaPaginaGestioneElemento.AGGIUNGI) {
      this.inserimentoEnte();
    } else if (this.funzione === ModalitaPaginaGestioneElemento.MODIFICA) {
      this.modificaEnte();
    }
  }

  private setFlussoRiversamentoPagoPACondiviso(listaBeneficiari: Beneficiario[]) {
    const flussiRiversamento = _.flatMap(listaBeneficiari, b => _.map(b.listaContiCorrenti, cc => {
      return {id: cc.id, flussoRiversamentoPagoPA: cc.flussoRiversamentoPagoPA};
    }));
    const listaIdBonificoFlussi = _.chain(flussiRiversamento)
      .groupBy('id')
      .toPairs()
      .map((currentItem) => {
        return _.zipObject(['id', 'listaFlussoRiversamentoPagoPA'], currentItem);
      })
      .value();
    const listaIdBonificoUltimoFlusso = _.map(listaIdBonificoFlussi, perId => {
      let frpp = _.maxBy(perId.listaFlussoRiversamentoPagoPA,
        o => o?.flussoRiversamentoPagoPA?.dataModifica)?.flussoRiversamentoPagoPA;

      if (frpp == null) {
        frpp = perId.listaFlussoRiversamentoPagoPA[0].flussoRiversamentoPagoPA;
      }

      return {
        id: perId.id,
        flussoRiversamentoPagoPA: frpp
      };
    })
    listaBeneficiari = _.map(listaBeneficiari, (beneficiario: Beneficiario) => {
      beneficiario.listaContiCorrenti = _.map(beneficiario.listaContiCorrenti, (cc: ContoCorrente) => {
        // tslint:disable-next-line:radix
        const ultimoFlussoRiversamentoModificato = _.find(listaIdBonificoUltimoFlusso, o => parseInt(o.id) === cc.id);
        if (ultimoFlussoRiversamentoModificato) {
          cc.flussoRiversamentoPagoPA = ultimoFlussoRiversamentoModificato.flussoRiversamentoPagoPA;
        }
        return cc;
      });
      return beneficiario;
    })
    return listaBeneficiari;
  }

  private inserimentoEnte(): void {
    this.enteService.inserimentoEnte(this.datiEnte, this.idFunzione, this.datiEnte.societaId).subscribe(
      (response) => {
        if (!(response instanceof HttpErrorResponse)) {
          this.routingService.configuraRouterAndNavigate(this.basePath + '/aggiungiEnte');
        }
      });
  }

  private modificaEnte() {
    this.enteService.modificaEnte(this.datiEnte, this.idFunzione, this.datiEnte.societaId)
      .subscribe((esito) => {
        if (esito) {
          this.routingService.configuraRouterAndNavigate(this.basePath + '/modificaEnte/' + this.datiEnte.id);
        }
      });
  }

  private formattaCampiCC(listaContiCorrenti: ContoCorrente[], dateIsIso?: boolean) {
    listaContiCorrenti = listaContiCorrenti.map((contoCorrente) => {
      contoCorrente.iban = contoCorrente.iban.replace(/\s+/g, '');
      const ibanCCPostale = contoCorrente.ibanCCPostale;
      if (ibanCCPostale != null) {
        contoCorrente.ibanCCPostale = ibanCCPostale.replace(/\s+/g, '');
      }
      return contoCorrente;
    });
    return listaContiCorrenti;
  }

  private formattaCampi(listaBeneficiari: Beneficiario[]) {
    listaBeneficiari = listaBeneficiari.map((beneficiario) => {
      const beneficiarioCopy = JSON.parse(JSON.stringify(beneficiario));
      return beneficiarioCopy;
    });
    return listaBeneficiari;
  }

  private formattaData(date: string, dateIsIso: boolean, to?: boolean): string {
    if (dateIsIso) {
      return moment(date, Utils.FORMAT_LOCAL_DATE_TIME_ISO)
        .format(Utils.FORMAT_DATE_CALENDAR);
    } else {
      return moment(date, Utils.FORMAT_DATE_CALENDAR)
        .format(to ? Utils.FORMAT_LOCAL_DATE_TIME_TO : Utils.FORMAT_LOCAL_DATE_TIME);
    }
  }

  onClickAnnullaButton() {
    this.onClickAnnulla(this.funzione);
  }

  disabilitaBottoneContoCorrente(): boolean {
    // const listaControllo: boolean[] = this.getListFromMap(this.mapControllo);
    // const isListaBeneficiarioInvalid = listaControllo.length > 0 ? listaControllo.includes(false) : false;
    // return !this.isFormDatiEnteValido || isListaBeneficiarioInvalid;
    return false;
  }

  aggiungiContoCorrente(datiContoCorrente?: ContoCorrente): number {
    // creazione Dati Conto Corrente Component
    const childComponent = this.componentFactoryResolver.resolveComponentFactory(ConfigContoCorrenteComponent);
    const indexContoCorrente = this.targetCC.length;
    // input
    const uuid = Utils.uuidv4();
    const funzione = this.funzione;
    const idFunzione = this.idFunzione;
    let instanceDatiContoCorrente: ContoCorrente;
    if (datiContoCorrente == null) {
      instanceDatiContoCorrente = new ContoCorrente();
    } else {
      instanceDatiContoCorrente = datiContoCorrente;
    }
    let listaContiCorrente = null;
    if (this.funzione === ModalitaPaginaGestioneElemento.MODIFICA && this.listaContiCorrente != null) {
      listaContiCorrente = this.listaContiCorrente;
    }
    this.inizializzaComponentRefInstanceContoCorrente(childComponent, uuid, indexContoCorrente, funzione, idFunzione,
      instanceDatiContoCorrente, listaContiCorrente);
    return indexContoCorrente;
  }

  inizializzaComponentRefInstanceContoCorrente(childComponent, uuid, index, funzione, idFunzione, datiContoCorrente, listaContiCorrente) {
    // creazione Dati Beneficiario Component
    this.componentRef = this.targetCC.createComponent(childComponent);
    // input
    this.componentRef.instance.uuid = uuid;
    this.targetCCMap.set(uuid, this.componentRef.hostView);
    this.componentRef.instance.indexDatiContoCorrente = index;
    this.componentRef.instance.funzione = funzione;
    this.componentRef.instance.idFunzione = idFunzione;
    this.componentRef.instance.datiContoCorrente = datiContoCorrente;
    if (funzione === ModalitaPaginaGestioneElemento.MODIFICA && listaContiCorrente != null) {
      this.componentRef.instance.listaContiCorrente = listaContiCorrente;
    }
    // output
    this.componentRef.instance.onDeleteDatiContoCorrente.subscribe((componenteDinamico: ComponenteDinamico) => {

      const contoCorrente = this.mapContoCorrente.get(componenteDinamico.uuid);
      if (contoCorrente.id) {
        this.enteService.eliminaContoCorrente(contoCorrente, this.idFunzione, this.datiEnte.societaId)
        .subscribe((esito) => {
          if (esito) {
            this.routingService.configuraRouterAndNavigate(this.basePath + '/modificaEnte/' + this.datiEnte.id);
          }
        });
      }

      const isContoCorrenteDaModificare: boolean = contoCorrente != null;
      if (isContoCorrenteDaModificare) {
        this.mapContoCorrente.delete(componenteDinamico.uuid);
        this.mapControlloCC.delete(componenteDinamico.uuid);
      }
      // controllo se esiste un view ref e target ha solo un elemento, se vero uso remove altrimenti clear
      const viewRef = this.targetCCMap.get(componenteDinamico.uuid);
      const indexViewRef = this.targetCC.indexOf(viewRef);
      if (this.targetCC.length === 1) {
        this.targetCC.clear();
        this.targetCCMap.clear();
      } else {
        this.targetCC.remove(indexViewRef);
        this.targetCCMap.delete(componenteDinamico.uuid);
      }
    });
    this.componentRef.instance.onChangeDatiContoCorrente.subscribe((componenteDinamico: ComponenteDinamico) => {
      this.mapContoCorrente.set(componenteDinamico.uuid, componenteDinamico.oggetto);
      this.mapControlloCC.set(componenteDinamico.uuid, componenteDinamico.isFormValid);
    });
    this.componentRef.changeDetectorRef.detectChanges();
    this.componentRefs.push(this.componentRef);
  }
}
