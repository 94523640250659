<!--      inizio modale cc -->
<div class="row mt-4 mb-0">
  <div class="col-md pb-2">
    <p-dialog header="Lista conti correnti" [(visible)]="display">
      <div class="row">
        <div class="col">
          <app-table [dataKey]="tableData.dataKey"
                     [cols]="tableData.cols"
                     [rows]="tableData.rows"
                     [tipoTabella]="tableData.tipoTabella"
                     [selectedRows]="true"
                     (onClickRow)="onClickRow($event)"
          >
          </app-table>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col text-center">
          <button type="button" class="undoButton" pButton icon="pi pi-times" label="Annulla"
                  (click)="showDialog()"></button>
        </div>
      </div>
    </p-dialog>
    <a href="javascript:void(0)" (click)="showDialog()">Seleziona da un conto corrente esistente</a>
  </div>
</div>
<!--      fine modale cc -->
