import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FlussoRendicontazione } from '../../../../model/flussiRenditcontazione/FlussoRendicontazione';
import { FlussoRendicontazioneService } from '../../../../../../services-old/flusso-rendicontazione.service';
import { FiltroGestioneElementiComponent } from '../../filtro-gestione-elementi.component';
import { AmministrativoService } from '../../../../../../services-old/amministrativo.service';
import { ActivatedRoute } from '@angular/router';
import { RaggruppamentoTipologiaServizioService } from '../../../../../../services-old/RaggruppamentoTipologiaServizio.service';
import { CampoTipologiaServizioService } from '../../../../../../services-old/campo-tipologia-servizio.service';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-filtro-flussi-rendicontazione',
  templateUrl: './filtro-flussi-rendicontazione.component.html',
  styleUrls: ['./filtro-flussi-rendicontazione.component.scss']
})
export class FiltroFlussiRendicontazioneComponent extends FiltroGestioneElementiComponent implements OnInit {

  @Output()
  onChangeFiltri: EventEmitter<FlussoRendicontazione> = new EventEmitter<FlussoRendicontazione>()
  

  filtro: FlussoRendicontazione= null
  listaFlussiRendicontazione: FlussoRendicontazione[] = [];


  constructor(
    protected amministrativoService: AmministrativoService, protected route: ActivatedRoute,
    private raggruppamentoTipologiaServizioService: RaggruppamentoTipologiaServizioService,
    private campoTipologiaServizioService: CampoTipologiaServizioService,
    private flussoRendicontazioneService: FlussoRendicontazioneService
    ) { 
      super(route, amministrativoService);
    }

  ngOnInit(): void {
    this.caricaCodiciFlussoRendicontazione()

  }

  caricaCodiciFlussoRendicontazione(): void{
    this.flussoRendicontazioneService.recuperaFlussiRendicontazione(this.idFunzione).subscribe(listaFlussiRendicontazione=>{
      this.listaFlussiRendicontazione = listaFlussiRendicontazione
    })
  }

  pulisciFiltri(form: NgForm): void {
    form.reset();
    this.onChangeFiltri.emit(null);
  }
  cercaElementi(): void {
    this.onChangeFiltri.emit(this.filtro);
  }

  disabilitaBottonePulisci(): boolean{
    return this.filtro == null
  }

  getNomeFlussoSelect(nome, descrizione){
    return nome + (descrizione!=null ? " - " + descrizione : "")
  }

}
