import { FlussoRendicontazione } from '../flussiRenditcontazione/FlussoRendicontazione';
import {TipologiaServizio} from './TipologiaServizio';

export class ParametriRicercaTipologiaServizio {
  societaId: number;
  livelloTerritorialeId: number;
  enteId: number;
  raggruppamentoId: number = null;
  tipologia: TipologiaServizio = null;
  tipoFlusso: FlussoRendicontazione = null;
  nome: string = null;
}
