import {NotifichePagamento} from './NotifichePagamento';
import {FlussoRiversamentoPagoPA} from './FlussoRiversamentoPagoPA';
import {RendicontazioneGiornaliera} from './RendicontazioneGiornaliera';

export class QuadraturaServizio {
  apiKey: string;
  apiSecret: string;
}

export class FlussiNotifiche {
  notifichePagamento: NotifichePagamento[];
  rendicontazioneGiornaliera: RendicontazioneGiornaliera;
  quadratura: QuadraturaServizio;
}
