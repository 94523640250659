import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { PayerTableFooterComponent } from "./components/table-footer/table-footer.component";
import { PayerTableComponent } from "./components/table/table.component";
import { PayerSortIconComponent } from "./components/sort-icon/sort-icon.component";
import { SortFieldDirective } from './directives/sort-field/sort-field.directive';
import { SortTableHeaderComponent } from "./components/sort-table-header/sort-table-header.component";

@NgModule({
  declarations: [	
    PayerTableComponent,
    PayerTableFooterComponent,
    SortTableHeaderComponent,
    PayerSortIconComponent,
    SortFieldDirective
   ],
  imports: [
    CommonModule,
    NgbDropdownModule,
  ],
  exports: [
    PayerTableComponent,
    PayerSortIconComponent,
    SortTableHeaderComponent,
    SortFieldDirective
  ]
})
export class PayerTableModule {}
