<form #datiForm="ngForm">
  <div class="row">
    <div class="col">
      <input type="text" id="{{'destinatario' + index}}" name="{{'destinatario' + index}}"
             class="pt-0" autocomplete="off" aria-describedby="destinatarioHelpDescription"
             [disabled]="funzione === FunzioneGestioneEnum.DETTAGLIO"
             [(ngModel)]="destinatario.email"
             [pattern]="emailRegex"
             #email="ngModel"
             (ngModelChange)="onChangeModel(datiForm, email)">
    </div>
    <div class="col-2 align-self-center" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
              <span ngbTooltip="Elimina destinatario" placement="bottom" [ngStyle]="{fill: '#403d39'}">
            <svg class="bg-light icon icon-sm filled"
                 (click)="onClickDeleteIcon()">
              <use xlink:href="assets/img/sprite.svg#it-close"></use>
            </svg>
              </span>
    </div>
  </div>
  <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
    <div class="col">
      <p *ngIf="!isCampoInvalido(email)">Inserisci destinatario</p>
      <p *ngIf="isCampoInvalido(email)"
         class="text-invalid">{{getMessaggioErrore(email)}}</p>
    </div>
  </div>
</form>
