<div class="ml-3">
  <!--  inizio Header -->
  <div class="collapse-header" id="idCollapseContiCorrenti">
    <button
      data-toggle="collapse"
      [id]="'buttonCCiCollapse' + uuid"
      aria-expanded="false"
      aria-controls="contenutoSezioneContiCorrenti"
    >
      <div class="row">
        <div class="col">
          Conti Correnti
          <span class="title ml-3">{{ getIbanHeader() }}</span>
        </div>
      </div>
    </button>
  </div>
  <!--  fine Header -->
  <div
    [id]="'collapseCCi' + uuid"
    class="collapse"
    role="tabpanel"
    aria-labelledby="idCollapseContiCorrenti"
  >
    <form #datiContoCorrenteForm="ngForm">
      <!--      inizio modale cc -->
      <app-seleziona-conto-corrente
        *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO"
        [funzione]="funzione"
        [listaContiCorrente]="listaContiCorrente"
        (onClick)="onClickRow($event, datiContoCorrenteForm)"
      ></app-seleziona-conto-corrente>
      <!--      fine modale cc -->

      <!--    inizio Iban, Intestazione -->
      <div class="row mt-4 mb-0 ml-4">
        <!--      inizio Iban -->
        <div class="col-md-3 pb-2">
          <div class="row">
            <div class="col">
              <label for="iban" class="title">Iban</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input
                type="text"
                id="iban"
                name="iban"
                #iban="ngModel"
                [(ngModel)]="datiContoCorrente.iban"
                class="pt-0"
                readonly="true"
              />
            </div>
          </div>
        </div>
        <!--      fine Iban -->
        <!--      inizio Intestazione -->
        <div class="col-md-7 pb-2">
          <div class="row">
            <div class="col">
              <label for="intestazione" class="title">Intestazione</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input
                type="text"
                id="intestazione"
                name="intestazione"
                #intestazione="ngModel"
                [(ngModel)]="datiContoCorrente.intestazione"
                class="pt-0"
                readonly="true"
              />
            </div>
          </div>
        </div>
        <!--      fine Intestazione -->
        <!--    fine Iban, Intestazione -->

        <!--      inizio Attivazione -->
        <div class="col-md-2 pb-2">
          <div class="row">
            <div class="col-2">
              <label
                for="inizioValidita"
                class="title"
                [ngClass]="{
                  required: funzione !== FunzioneGestioneEnum.DETTAGLIO
                }"
                >Attivazione</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col calendar-wrapper">
              <svg
                class="bg-light icon img-calendar"
                (click)="openDatepicker(datePickerAttivazione)"
              >
                <use xlink:href="assets/img/sprite.svg#it-calendar"></use>
              </svg>
              <dp-date-picker
                mode="day"
                id="inizioValidita"
                theme="dp-material"
                [config]="{
                  locale: 'it',
                  format: 'DD/MM/YYYY',
                  firstDayOfWeek: 'mo',
                  showGoToCurrent: false,
                  openOnClick: isCalendarOpen,
                  openOnFocus: false,
                  min: minDateDDMMYYYY,
                  max: null,
                  returnedValueType: tipoData
                }"
                #inizioValidita="ngModel"
                [(ngModel)]="datiContoCorrente.inizioValidita"
                name="inizioValidita"
                #datePickerAttivazione
                (click)="isCalendarOpen = false"
                (ngModelChange)="
                  onChangeModel(datiContoCorrenteForm, inizioValidita)
                "
                [ngClass]="{ disabled: campiDisabilitati(inizioValidita) }"
                [required]="requiredCampi()"
                [disabled]="campiDisabilitati(inizioValidita)"
              ></dp-date-picker>
            </div>
          </div>
          <div class="row" *ngIf="funzione !== FunzioneGestioneEnum.DETTAGLIO">
            <div class="col">
              <p *ngIf="!isCampoInvalido(inizioValidita)">
                Inserisci data attivazione
              </p>
              <p *ngIf="isCampoInvalido(inizioValidita)" class="text-invalid">
                {{ getMessaggioErrore(inizioValidita) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--      fine Attivazione -->

      <!--    inizio IbanCCPostale, IntestazioneCCPostale -->
      <div class="row mt-4 mb-0 ml-4">
        <!--      inizio IbanCC -->
        <div class="col-md-3 pb-2">
          <div class="row">
            <div class="col">
              <label for="ibanCCPostale" class="title">Iban cc postale</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input
                type="text"
                id="ibanCCPostale"
                name="ibanCCPostale"
                #ibanCCPostale="ngModel"
                [(ngModel)]="datiContoCorrente.ibanCCPostale"
                class="pt-0"
                readonly="true"
              />
            </div>
          </div>
        </div>
        <!--      fine IbanCC -->
        <!--      inizio IntestazioneCC -->
        <div class="col-md-7 pb-2">
          <div class="row">
            <div class="col">
              <label for="intestazioneCCPostale" class="title"
                >Intestazione cc postale</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input
                type="text"
                id="intestazioneCCPostale"
                name="intestazioneCCPostale"
                #intestazioneCCPostale="ngModel"
                [(ngModel)]="datiContoCorrente.intestazioneCCPostale"
                class="pt-0"
                readonly="true"
              />
            </div>
          </div>
        </div>
        <!--      fine IntestazioneCC -->
      </div>
      <!--    fine IbanCCPostale, IntestazioneCCPostale -->
    </form>
  </div>
</div>
